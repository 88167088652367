import React from 'react';
import { compose } from 'react-recompose';
import FeatureEnabled from '../../components/FeatureEnabled';
import FeatureDisabled from '../../components/FeatureDisabled';
import AppWithoutServerlessCounselorCommunity from './app-without-serverless-cc';
import AppWithServerlessCounselorCommunity from './app-with-serverless-cc';
import { authorized } from '../Authorization';
import * as constants from '../Authorization/constants';

export const SwitcherApp = (props) => (
  <React.Fragment>

    <FeatureEnabled allOf={constants.COMMUNITY_SERVERLESS}>
      <AppWithServerlessCounselorCommunity {...props} />
    </FeatureEnabled>

    <FeatureDisabled anyOf={constants.COMMUNITY_SERVERLESS}>
      <AppWithoutServerlessCounselorCommunity {...props} />
    </FeatureDisabled>

  </React.Fragment>
);

export default compose(authorized({ failure: AppWithoutServerlessCounselorCommunity }))(SwitcherApp);
