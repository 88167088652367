import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import List from '../../components/List';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import FeatureEnabled from '../../components/FeatureEnabled';
import { FeatureFlags } from '../../utils/features';
import * as appRoles from '../Authorization/constants';
import HasRole from '../../components/HasRole';

class ActionsGridColumn extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    linkBack: PropTypes.object,
  };

  render() {
    const { node, linkBack } = this.props;
    const id = node.get('id');
    const isActiveNavianceSchool = node.getIn(['highSchool', 'isActiveNavianceSchool']);
    const deNavianceRequired = node.get('deNavianceRequired');
    const navianceNid = node.get('highSchool').get('naviance').get('field_hs_na_nid');

    return (
      <Grid.Column as="section">
        <Header as="h2">
          <FormattedMessage id="hs-account.actions.header" defaultMessage="Actions" />
        </Header>
        <List as="dl">
          <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
            <List.Item as="dt">
              <Link
                to={{ pathname: `/hs-account/${id}/log-history/today/`, state: { linkBack } }}
                data-cy="hs-account-actions-log-history-link"
              >
                <FormattedMessage id="hs-account.actions.logHistory" defaultMessage="View Log History" />
              </Link>
            </List.Item>
          </HasRole>
          <HasRole anyOf={appRoles.NON_VIEW_ONLY_ROLES}>
            <List.Item as="dt">
              <Link
                data-cy="hs-see-all-users-link"
                to={`/hs-account/${id}/users`}
              >
                <FormattedMessage id="hs-account.actions.seeAllUsers" defaultMessage="See All Users" />
              </Link>
            </List.Item>
          </HasRole>
          {!isActiveNavianceSchool && !deNavianceRequired && (
            <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
              <List.Item as="dt">
                <Link
                  data-cy="hs-create-user-link"
                  to={`/hs-account/${id}/create-user`}
                >
                  <FormattedMessage id="hs-account.actions.createUser" defaultMessage="Create User" />
                </Link>
              </List.Item>
            </HasRole>
          )}
          <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
            <List.Item as="dt">
              <Link
                data-cy="hs-account-repvisits-details-link"
                to={{ pathname: `/hs-account/${id}/repvisits`, state: { linkBack } }}
              >
                <FormattedMessage id="hs-account.actions.repvisitsdetails" defaultMessage="Repvisits Details" />
              </Link>
            </List.Item>
          </HasRole>
          {navianceNid && (
            <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
              <FeatureEnabled allOf={FeatureFlags.NAVIANCE_SYNC_LOG}>
                <List.Item as="dt">
                  <Link to={`/naviance-sync-log/${id}/${navianceNid}/today/`} id="naviance-sync-logs-link">
                    <FormattedMessage id="hs-account.actions.viewNavianceSyncLog" defaultMessage="View Naviance Sync Log" />
                  </Link>
                </List.Item>
              </FeatureEnabled>
            </HasRole>
          )}
        </List>
      </Grid.Column>
    );
  }
}

export default ActionsGridColumn;
