import gql from 'graphql-tag';

export default gql`
  mutation AddFeatureFlag($input: addFeatureFlagInput!) {
    addFeatureFlag(input: $input) {
      viewer {
        id
        enabledFeatures
      }
    }
  }
`;
