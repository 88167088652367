import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon';
import Tab from '../../components/Tab';

import RepvisitsAccounts from './repvisits-accounts';
import RepvisitsAppointments from './repvisits-appointments';
import './styles.css';

class RepvisitsDetails extends React.Component {
  static propTypes = {
    node: PropTypes.shape({
      id: PropTypes.string,
      repVisitsAccounts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        ncesId: PropTypes.string,
        nguid: PropTypes.string,
        nid: PropTypes.string,
        availabilityVisible: PropTypes.bool,
        schoolYear: PropTypes.string,
        naviance: PropTypes.bool,
        setupComplete: PropTypes.bool,
      })),
      name: PropTypes.string,
      repVisitsAppointments: PropTypes.object,
    }),
    loadMore: PropTypes.func.isRequired,
  }

  getTabPanes = () => ([
    {
      menuItem: 'Repvisits Accounts',
      render: () => (
        <RepvisitsAccounts
          name={this.props.node.name}
          repVisitsAccounts={this.props.node.repVisitsAccounts}
        />
      ),
    },
    {
      menuItem: 'Repvisits Appointments',
      render: () => (
        <RepvisitsAppointments
          repVisitsAppointments={this.props.node.repVisitsAppointments}
          loadMore={this.props.loadMore}
        />
      ),
    },
  ]);

  render() {
    return (
      <div>
        <PageTitle title="Repvisits Details" category="Support" />
        <Link to={`/hs-account/${this.props.node.id}`}>
          <Icon name="angle left" />
          <FormattedMessage id="hs-account.repvisits-details-page.back" defaultMessage="Back" />
        </Link>

        <Tab menu={{ secondary: true, pointing: true }} panes={this.getTabPanes()} />
      </div>
    );
  }
}

export default RepvisitsDetails;
