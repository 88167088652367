import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addNew: {
    id: 'subscription.modal.new',
    defaultMessage: 'Add New Subscription',
  },
  addNewSubscription: {
    id: 'subscription.modal.newSubscription',
    defaultMessage: 'Add New {type} Subscription',
  },
  editSubscription: {
    id: 'subscription.modal.editSubscription',
    defaultMessage: 'Edit {type} Subscription',
  },
  state: {
    id: 'subscription.modal.stateSubscription',
    defaultMessage: 'State',
  },
  county: {
    id: 'subscription.modal.countySubscription',
    defaultMessage: 'County',
  },
  zip: {
    id: 'subscription.modal.zipSubscription',
    defaultMessage: 'Zip',
  },
  sector: {
    id: 'subscription.modal.sectorSubscription',
    defaultMessage: 'Sector',
  },
  subscriptionType: {
    id: 'subscription.modal.type',
    defaultMessage: 'What type of subscription do you want to add?',
  },
  next: {
    id: 'subscription.modal.next',
    defaultMessage: 'Next',
  },
  enterValue: {
    id: 'subscription.form.error.missingValue',
    defaultMessage: 'Please enter a value',
  },
  invalidStartDate: {
    id: 'subscription.form.error.invalidStartDate',
    defaultMessage: 'Please select a valid start date',
  },
  pastModuleExpiration: {
    id: 'subscription.form.error.pastModuleExpiration',
    defaultMessage: 'The audience subscription end date entered is beyond the Advanced Awareness/Connection product subscription end date. Do you want to extend the product subscription end date to match the audience subscription end date? If so, click Finish to continue.',
  },
  selectDate: {
    id: 'subscription.form.label.selectDate',
    defaultMessage: 'Select date',
  },
  chooseZips: {
    id: 'subscription.form.label.chooseZips',
    defaultMessage: 'Choose Zip(s)',
  },
  enterZip: {
    id: 'subscription.form.label.enterZip',
    defaultMessage: 'Enter Zip',
  },
  validZip: {
    id: 'subscription.form.label.validZip',
    defaultMessage: 'Please enter a valid zip',
  },
  chooseStates: {
    id: 'subscription.form.label.chooseStates',
    defaultMessage: 'Choose State(s)',
  },
  chooseState: {
    id: 'subscription.form.label.chooseState',
    defaultMessage: 'Choose State',
  },
  chooseCounties: {
    id: 'subscription.form.label.chooseCounties',
    defaultMessage: 'Choose Counties ({state})',
  },
  chooseCounty: {
    id: 'subscription.form.label.chooseCounty',
    defaultMessage: 'Choose County ({state})',
  },
  chooseSectors: {
    id: 'subscription.form.label.chooseSectors',
    defaultMessage: 'Choose Sector(s)',
  },
  chooseSector: {
    id: 'subscription.form.label.chooseSector',
    defaultMessage: 'Choose Sector',
  },
  chooseDiversityFilter: {
    id: 'subscription.form.label.chooseDiversityFilter',
    defaultMessage: 'Choose Diversity Filter',
  },
  chooseEngagement: {
    id: 'subscription.form.label.chooseEngagement',
    defaultMessage: 'Choose Naviance Engagement',
  },
  selectCompetitors: {
    id: 'subscription.form.label.selectCompetitors',
    defaultMessage: 'Select Competitors',
  },
  radius: {
    id: 'subscription.form.label.radius',
    defaultMessage: 'Radius from Zip',
  },
  radiusZips: {
    id: 'subscription.form.label.radiusZips',
    defaultMessage: 'Radius from Zip(s)',
  },
  selectRadius: {
    id: 'subscription.form.placeholder.selectRadius',
    defaultMessage: 'Choose a value between 1-100 miles',
  },
  selectStates: {
    id: 'subscription.form.error.states',
    defaultMessage: 'Please select at least one state',
  },
  selectCounties: {
    id: 'subscription.form.error.selectCounties',
    defaultMessage: 'Please select at least one county',
  },
  selectCounty: {
    id: 'subscription.form.error.selectCounty',
    defaultMessage: 'Please select a county',
  },
  selectSectors: {
    id: 'subscription.form.error.sectors',
    defaultMessage: 'Please select at least one sector',
  },
  selectFilter: {
    id: 'subscription.form.error.filter',
    defaultMessage: 'Please select a diversity filter or a naviance engagement(competitors, majors or connection)',
  },
  duplicatedZip: {
    id: 'subscription.form.error.duplicatedZip',
    defaultMessage: 'There are multiple subscriptions with Zip: {zip} and Diversity: {diversityText}.',
  },
  duplicatedZipPostGA: {
    id: 'subscription.postGA.form.error.duplicatedZip',
    defaultMessage: 'There are multiple subscriptions with Zip: {zip}, Diversity: {diversityText} and Start Date: {startDate}.',
  },
  duplicatedState: {
    id: 'subscription.form.error.duplicatedState',
    defaultMessage: 'There are multiple subscriptions with State: {state} and Diversity: {diversityText}.',
  },
  duplicatedStatePostGA: {
    id: 'subscription.postGA.form.error.duplicatedState',
    defaultMessage: 'There are multiple subscriptions with State: {state}, Diversity: {diversityText} and Start Date: {startDate}.',
  },
  duplicatedCounty: {
    id: 'subscription.form.error.duplicatedCounty',
    defaultMessage: 'There are multiple subscriptions with State: {state}, County: {county} and Diversity: {diversityText}.',
  },
  duplicatedCountyPostGA: {
    id: 'subscription.postGA.form.error.duplicatedCounty',
    defaultMessage: 'There are multiple subscriptions with State: {state}, County: {county}, Diversity: {diversityText} and Start Date: {startDate}.',
  },
  duplicatedSector: {
    id: 'subscription.form.error.duplicatedSector',
    defaultMessage: 'There are multiple subscriptions with Sector: {sector} and Diversity: {diversityText}.',
  },
  duplicatedSectorPostGA: {
    id: 'subscription.postGA.form.error.duplicatedSector',
    defaultMessage: 'There are multiple subscriptions with Sector: {sector}, Diversity: {diversityText} and Start Date: {startDate}.',
  },
  missingNavianceEngagement: {
    id: 'subscription.form.error.missingNavianceEngagement',
    defaultMessage: 'Please select a naviance engagement (competitors, majors or connection).',
  },
});

export const subscriptionTypes = {
  UNSELECTED: '',
  STATE: 'state',
  COUNTY: 'county',
  ZIP: 'zip',
  SECTOR: 'sector',
};

export const subscriptionTypesInput = {
  state: 'STATE',
  county: 'COUNTY',
  zip: 'ZIP_CODE',
  sector: 'SECTOR',
};

export const diversityOptions = [
  { text: 'All Students', value: 'ALL_STUDENTS' },
  { text: 'Female', value: 'FEMALE_STUDENTS' },
  { text: 'Male', value: 'MALE_STUDENTS' },
  { text: 'Racial & Ethnic Minority', value: 'RACIAL_AND_ETHNIC_MINORITY' },
  { text: 'Schools with High FRL Rate', value: 'SCHOOLS_WITH_HIGH_FRL_RATE' },
];

export const maxRadiusValue = 100;

export const minRadiusValue = 1;
