import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import Segment from '../../components/Segment';

import AdvancedGroupResults from '../AdvancedGroupSearchResults';
import AdvancedPeopleResults from '../AdvancedPeopleSearchResults';
import AdvancedHighSchoolResults from '../AdvancedHighSchoolSearchResults';
import AdvancedHigherEdResults from '../AdvancedHigherEdSearchResults';
import AdvancedInstitutionResults from '../AdvancedInstitutionSearchResults';

import '../AdvancedSearch/styles.css';

class AdvancedSearchResults extends React.PureComponent {
  static propTypes = {
    view: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
    advanced: PropTypes.object,
  };

  render() {
    const { view, keyword, advanced } = this.props;
    const active = {
      groups: view === 'groups',
      people: view === 'people',
      institutions: view === 'institutions',
    };
    const advancedParams = active.people ? omit(advanced, 'state', 'county') : omit(advanced, 'institutionType');
    const params = { filter: keyword, advanced: advancedParams };
    return (
      <Segment styleName="advancedResultsContainer" role="tabpanel" data-cy="advanced-results-container">
        {active.groups && <AdvancedGroupResults {...params} />}
        {active.people && <AdvancedPeopleResults {...params} />}
        {active.institutions && !advanced.institutionType && <AdvancedInstitutionResults {...params} />}
        {active.institutions && advanced.institutionType === 'HE' && <AdvancedHigherEdResults {...params} />}
        {active.institutions && advanced.institutionType === 'HS' && <AdvancedHighSchoolResults {...params} />}
      </Segment>
    );
  }
}

export default AdvancedSearchResults;
