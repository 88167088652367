import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { loadMorePaginationProp, SimpleLoadingComponent } from '../../utils/apollo';
import AdvancedGroupResults from './advanced-group-results';

const query = gql`
  query AdvancedGroupSearchResults($filter: String!, $first: Int!, $after: String, $advanced: AdvancedGroupFilter!) {
    viewer {
      id
      advancedGroupSearch(filter: $filter, first: $first, after: $after, advanced: $advanced) {
        edges {
          node {
            id
            name
            body_summary
            body_value
            group_access
            title
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ filter, advanced }) => ({
    variables: {
      first: 5,
      filter,
      advanced,
    },
  }),
  props: loadMorePaginationProp(query),
});

export default SimpleLoadingComponent(graphqlData)(AdvancedGroupResults);
