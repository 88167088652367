import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { SimpleLoadingComponent } from '../../utils/apollo';
import SyncLogHistory from './sync-log-history';
import { ranges } from '../../components/SearchControl/timeframes';

const query = gql`
query NavianceSyncLogs($id: ID!, $nid: String!, $from: DateTime!, $to: DateTime!) {
  viewer {
    id
    navianceSyncLogs(nid: $nid, from: $from, to: $to) {
      id
      result
      eventType
      externalId
      nid
      scid
      representative
      visitDate
      processedDate
    }
  }
  node(id: $id) {
    id
    ...on HSAccount {
      name
    }
  }
}`;

const graphqlData = graphql(query, {
  options: ({ match, location }) => {
    const urlQuery = queryString.parse(location.search);
    const { nid = '', timeframe = 'today', id } = match.params;
    const range = ranges[timeframe];
    const { from, to } = (range && range(urlQuery)) || {};

    return {
      fetchPolicy: 'no-cache',
      variables: {
        id,
        nid,
        from,
        to,
      },
    };
  },
});

export default withRouter(SimpleLoadingComponent(graphqlData)(SyncLogHistory));
