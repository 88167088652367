import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import { FormattedMessage, defineMessages } from 'react-intl';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Dimmer from '../../components/Dimmer';
import Message from '../../components/Message';
import updateHSUserMutation from '../../mutations/HSAccount/updateHSUser';
import { SimpleGraphQLComponent } from '../../utils/apollo';

const messages = defineMessages({
  errorActivate: {
    id: 'toggle-activation.user.activate.error',
    defaultMessage: 'Unable to activate user',
  },
  errorInactivate: {
    id: 'toggle-activation.user.inactivate.error',
    defaultMessage: 'Unable to inactivate user',
  },
  activate: {
    id: 'toggle-activation.user.activate.header',
    defaultMessage: 'Activate User',
  },
  inactivate: {
    id: 'toggle-activation.user.inactivate.header',
    defaultMessage: 'Inactivate User',
  },
  activateText: {
    id: 'toggle-activation.user.activate.text',
    defaultMessage: 'Are you sure you want to activate user {userName} for {accountName}?',
  },
  inactivateText: {
    id: 'toggle-activation.user.inactivate.text',
    defaultMessage: 'Are you sure you want to inactivate user {userName} for {accountName}?',
  },
});

export class HSAccountToggleActivationUserComponent extends React.PureComponent {
  static propTypes = {
    hsAccount: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    mutateUpdateHSUser: PropTypes.func.isRequired,
    activate: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    isError: false,
    requesting: false,
  };

  _handleClose = () => {
    this.setState({ requesting: false });
    this.props.onClose();
  };

  _handleSave = () => {
    const { user, hsAccount, activate } = this.props;

    this.setState({ isError: false, requesting: true });
    this.props.mutateUpdateHSUser({
      variables: {
        input: {
          hsAccount: {
            id: hsAccount.id,
            user: {
              id: user.id,
              active: activate,
            },
          },
        },
      },
    }).then(this._handleClose, () => {
      this.setState({ isError: true, requesting: false });
    });
  };

  render() {
    const { user, hsAccount, activate } = this.props;
    const { isError, requesting } = this.state;
    const userName = `${user.firstName} ${user.lastName}`;
    const errorDetails = isError ? (
      <Message role="alert" negative>
        <Message.Header><FormattedMessage {...activate ? messages.errorActivate : messages.errorInactivate} /></Message.Header>
      </Message>
    ) : '';

    return (
      <Dimmer.Dimmable as={Modal} open onClose={this._handleClose}>
        <Dimmer
          tabIndex={-1}
          role="progressbar"
          aria-describedby="Requesting Change to User Status..."
          aria-busy={requesting}
          inverted
          active={requesting}
        >
          <Loader active={requesting} />
        </Dimmer>
        <Header>
          <FormattedMessage {...activate ? messages.activate : messages.inactivate} />
        </Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              {...activate ? messages.activateText : messages.inactivateText}
              values={{
                userName,
                accountName: hsAccount.name,
              }}
            />
          </p>
          {errorDetails}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this._handleClose}>
            <Icon name="remove" />
            <FormattedMessage id="common.button.no" defaultMessage="No" />
          </Button>
          <Button primary onClick={this._handleSave}>
            <Icon name="checkmark" />
            <FormattedMessage id="common.button.yes" defaultMessage="Yes" />
          </Button>
        </Modal.Actions>
      </Dimmer.Dimmable>
    );
  }
}

const updateHSUser = graphql(updateHSUserMutation, {
  name: 'mutateUpdateHSUser',
});

export default SimpleGraphQLComponent(updateHSUser)(HSAccountToggleActivationUserComponent);
