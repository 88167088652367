import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import UserInfo from './userInfo';
import { SimpleLoadingComponent } from '../../utils/apollo';
import addFeatureFlagMutation from './add-feature-flag-mutation';
import removeFeatureFlagMutation from './remove-feature-flag-mutation';

const query = gql`
  query UserInfo {
    viewer {
      id
      enabledFeatures
      globallyEnabledFeatures
    }
  }
`;

const graphqlData = graphql(query);

const addFeatureFlag = graphql(addFeatureFlagMutation, {
  name: 'mutateAddFeatureFlag',
});
const removeFeatureFlag = graphql(removeFeatureFlagMutation, {
  name: 'mutateRemoveFeatureFlag',
});

export default SimpleLoadingComponent(graphqlData, addFeatureFlag, removeFeatureFlag)(UserInfo);
