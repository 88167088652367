import React from 'react';
import PropTypes from 'prop-types';
import { injectPageTitle, pageTitleShape } from '../../providers/PageTitleProvider';

class PageTitle extends React.PureComponent {
  static defaultPropTypes = {
    title: '',
    category: '',
  };

  static propTypes = {
    pageTitle: pageTitleShape,
    title: PropTypes.string,
    category: PropTypes.string,
  };

  componentDidMount() {
    const { title, category, pageTitle } = this.props;
    pageTitle.set(title, category);
  }

  componentWillUnmount() {
    this.props.pageTitle.set();
  }

  /* eslint-disable class-methods-use-this */
  render() {
    return null;
  }
}

export default injectPageTitle(PageTitle);
