import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import HSAccountDetails from '../HSAccountDetails';
import NavDeNav from './nav-denav';
import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon';
import './styles.css';

class HSAccountPage extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.shape({
        linkBack: PropTypes.object,
      }),
    }),
  };

  render() {
    const { node, location } = this.props;
    const linkBackToThis = location ? pick(location, 'pathname', 'search') : undefined;
    const linkBackToParent = get(location, 'state.linkBack');
    return (
      <div>
        <PageTitle title="Account" category="Support" />
        <NavDeNav node={node} />
        {linkBackToParent && <div styleName="back-button"><Link to={linkBackToParent}><Icon name="angle left" />Back</Link></div>}
        <HSAccountDetails node={node} linkBack={linkBackToThis} />
      </div>
    );
  }
}

HSAccountPage.getFragments = () => [
  HSAccountDetails.fragment,
  NavDeNav.fragment,
];

export default HSAccountPage;
