import { defineMessages } from 'react-intl';

const messages = defineMessages({
  keyword: {
    id: 'search-results.keyword',
    defaultMessage: 'Keyword',
  },
  updateSearch: {
    id: 'search-results.updateSearch',
    defaultMessage: 'Update Search',
  },
  general: {
    id: 'search-results.general',
    defaultMessage: 'GENERAL',
  },
  work: {
    id: 'search-results.work',
    defaultMessage: 'WORK',
  },
  type: {
    id: 'search-results.type',
    defaultMessage: 'Type',
  },
  firstName: {
    id: 'common.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'common.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'common.email',
    defaultMessage: 'Email',
  },
  position: {
    id: 'search-results.position',
    defaultMessage: 'Position',
  },
  populationServed: {
    id: 'search-results.populationServed',
    defaultMessage: 'Population Served (Territory)',
  },
  adviseStudents: {
    id: 'search-results.adviseStudents',
    defaultMessage: 'Advises Students on Admissions Process',
  },
  collegeVisits: {
    id: 'search-results.collegeVisits',
    defaultMessage: 'Schedules College Visits',
  },
  institutionTitle: {
    id: 'search-results.institutionTitle',
    defaultMessage: 'Institution',
  },
  institutionType: {
    id: 'search-results.institutionType',
    defaultMessage: 'Institution Type',
  },
  collegeType: {
    id: 'search-results.collegeType',
    defaultMessage: 'College Type',
  },
  private: {
    id: 'search-results.private',
    defaultMessage: 'Private',
  },
  public: {
    id: 'search-results.public',
    defaultMessage: 'Public',
  },
  institutionState: {
    id: 'search-results.state',
    defaultMessage: 'State',
  },
  institutionZipcode: {
    id: 'search-results.zipcode',
    defaultMessage: 'Zipcode',
  },
  almaMater: {
    id: 'search-results.almaMater',
    defaultMessage: 'Alma Mater',
  },
  stateServed: {
    id: 'search-results.stateServed',
    defaultMessage: 'State Served',
  },
  countyServed: {
    id: 'search-results.countyServed',
    defaultMessage: 'County Served',
  },
});

export default messages;
