import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../Button';
import './styles.css';

const CustomButton = (props) => {
  const { to, children, color, onClick } = props;
  return (
    <Button as={to ? Link : 'button'} to={to} basic className="link-button" color={color} onClick={onClick} styleName="link">
      { children }
    </Button>
  );
};

CustomButton.propTypes = {
  to: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};
CustomButton.defaultProps = {
  color: 'teal',
};
export default CustomButton;
