import ScriptLoader from 'react-script-loader-hoc';
import withGlobals from '../../components/Globals';
import AppWithoutServerlessCounselorCommunity from './app-without-serverless-cc';

const counselorCommunityUrl = global.CounselorCommunity?.default?.default ? null : process.env.COUNSELOR_COMMUNITY_URL;
/* istanbul ignore else */
if (counselorCommunityUrl) {
  const { origin } = new URL(counselorCommunityUrl);
  global.__webpack_counscomm_public_path__ = origin;
}

export default withGlobals(ScriptLoader(counselorCommunityUrl)(AppWithoutServerlessCounselorCommunity));
