import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, defineMessages } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';

import { ModuleNames } from '@purple/match-graphql-schema/lib/schema/common/types/SubscriptionModuleNameType/type';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input';
import Button from '../../components/Button';
import DatePickerNavigation from '../../components/DayPicker/date-picker-navigation';
import InteractivePopup from '../../components/InteractivePopup';
import Table from '../../components/Table';
import ScholarshipModal from '../HEScholarships';
import styles from './styles.css';

const messages = defineMessages({
  HUBPAGE: {
    id: 'moduleSubscription.hubpage',
    defaultMessage: 'Legacy: Hub page management',
  },
  COMMUNITY: {
    id: 'moduleSubscription.community',
    defaultMessage: 'Legacy: Community',
  },
  MATCHING: {
    id: 'moduleSubscription.matching',
    defaultMessage: 'Intersect Awareness Subscription',
  },
  REPVISITS: {
    id: 'moduleSubscription.repvisits',
    defaultMessage: 'Intersect Presence Subscription',
  },
  AMEVENTS: {
    id: 'moduleSubscription.amevents',
    defaultMessage: 'Legacy: ActiveMatch Events',
  },
  AMPLUS: {
    id: 'moduleSubscription.amplus',
    defaultMessage: 'ActiveMatch Plus',
  },
  ADVANCEDAWARENESS: {
    id: 'moduleSubscription.advancedawareness',
    defaultMessage: 'Advanced Awareness',
  },
  CONNECTION: {
    id: 'moduleSubscription.connection',
    defaultMessage: 'Connection',
  },
  CAMINO: {
    id: 'moduleSubscription.camino',
    defaultMessage: 'Camino HE Pilot',
  },
  SCHOLARSHIPS: {
    id: 'moduleSubscription.scholarships',
    defaultMessage: 'Scholarships',
  },
});

const statusConfig = [
  { text: 'active', value: 'active' },
  { text: 'inactive', value: 'inactive' },
];

const statusOptions = statusConfig.map((each) => ({
  text: each.text,
  value: each.value,
  role: 'option',
  tabIndex: '-1',
}));

const links = {
  [ModuleNames.ADVANCEDAWARENESS]: 'subscriptions/',
  [ModuleNames.CONNECTION]: 'subscriptions/',
  [ModuleNames.CAMINO]: 'am-camino/',
};

class ModuleSubscriptionTableRow extends React.PureComponent {
  static propTypes = {
    canEdit: PropTypes.bool,
    moduleNode: PropTypes.object.isRequired,
    savedModuleNode: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    heAccountId: PropTypes.string.isRequired,
    scholarshipsMatchStartDate: PropTypes.number,
  };

  state = {
    showScholarshipsModal: false,
  };

  onChange(change) {
    this.props.onChange(this.props.moduleNode, change);
  }

  onDateChange(day, disabled, property) {
    if (!disabled) {
      const change = {};
      if (property === 'endDate') {
        day.setHours(23, 59, 0, 0);
      } else {
        day.setHours(6, 1, 0, 0);
      }
      change[property] = day.getTime();
      this.onChange(change);
    }
  }

  showModal = () => this.setState({ showScholarshipsModal: true });

  hideModal = () => this.setState({ showScholarshipsModal: false });

  isScholarships = () => ModuleNames.SCHOLARSHIPS === (this.props.moduleNode.get('name') || '').toUpperCase();

  renderModuleName = ({ moduleNode, savedModuleNode, match }) => {
    const name = moduleNode.get('name');
    const status = savedModuleNode.get('status');
    const message = <FormattedMessage id="moduleSubscription.unknown" defaultMessage="Unknown" {...messages[name]} />;
    const isActive = status === 'active';
    const link = isActive && links[(name || '').toUpperCase()];

    if (link) {
      return <Link to={`${match.url}${link}`}>{message}</Link>;
    }

    if (this.isScholarships() && isActive) {
      return <Link to={`${match.url}`} onClick={this.showModal}>{message}</Link>;
    }

    return message;
  }

  render() {
    const { moduleNode, savedModuleNode, canEdit, match, heAccountId, scholarshipsMatchStartDate } = this.props;
    const startDate = new Date(moduleNode.get('startDate') || new Date().setHours(0, 0, 0, 0));
    const originalEndDate = moduleNode.get('endDate') ? new Date(moduleNode.get('endDate')) : null;
    const endDate = originalEndDate || startDate;
    const lastModifiedBy = moduleNode.get('lastModifiedBy', '');
    const ModuleName = this.renderModuleName;
    return (
      <React.Fragment>
        <Table.Row className={styles.tableRow}>
          <Table.Cell>
            <ModuleName
              moduleNode={moduleNode}
              savedModuleNode={savedModuleNode}
              match={match}
            />
          </Table.Cell>
          <Table.Cell>
            {!canEdit ? moduleNode.get('status') : (
              <Dropdown
                data-cy={`${moduleNode.get('name')}-Dropdown`}
                role="listbox"
                tabIndex="0"
                aria-label="Module Status Selector"
                value={moduleNode.get('status')}
                floating
                inline
                options={statusOptions}
                onChange={(e, { value }) => this.onChange({ status: value })}
              />
            )}
          </Table.Cell>
          <Table.Cell>
            {!canEdit ? moduleNode.get('quoteChargeId') : (
              <Input
                data-cy={`${moduleNode.get('name')}-QuoteChargeId`}
                value={moduleNode.get('quoteChargeId') || ''}
                onChange={(e, { value }) => this.onChange({ quoteChargeId: value })}
                fluid
                input={{
                  'aria-label': 'quote charge id',
                }}
              />
            )}
          </Table.Cell>
          <Table.Cell className={styles.noWrap} data-cy={`${moduleNode.get('name')}-startDateValue`}>
            {
              moduleNode.get('startDate') ?
                <FormattedDate value={moduleNode.get('startDate')} month="short" day="numeric" year="numeric" /> :
                ''
            }
            {canEdit && moduleNode.get('status') === 'active' && (
            <InteractivePopup
              trigger={<Button data-cy={`${moduleNode.get('name')}-startDate`} icon="calendar alternate outline" basic role="tooltip" aria-label="Start Date Calendar" />}
              on="click"
              position="right center"
            >
              <DatePickerNavigation
                labels={{ nextMonth: 'Next Month', previousMonth: 'Previous Month' }}
                className="startDayPicker"
                initialMonth={startDate}
                selectedDays={startDate}
                disabledDays={{ after: originalEndDate }}
                onDayClick={(day, { disabled, selected }) => this.onDateChange(day, disabled, 'startDate')}
              />
            </InteractivePopup>
            )}
          </Table.Cell>
          <Table.Cell data-cy={`${moduleNode.get('name')}-endDateValue`} className={styles.noWrap}>
            {
              moduleNode.get('endDate') ?
                <FormattedDate value={moduleNode.get('endDate')} month="short" day="numeric" year="numeric" /> :
                ''
            }
            {canEdit && moduleNode.get('status') === 'active' && (
            <InteractivePopup
              trigger={<Button data-cy={`${moduleNode.get('name')}-endDate`} icon="calendar alternate outline" basic role="tooltip" aria-label="End Date Calendar" />}
              on="click"
              position="right center"
            >
              <DatePickerNavigation
                labels={{ nextMonth: 'Next Month', previousMonth: 'Previous Month' }}
                className="endDayPicker"
                initialMonth={endDate}
                fromMonth={startDate}
                selectedDays={endDate}
                disabledDays={{ before: startDate }}
                onDayClick={(day, { disabled, selected }) => this.onDateChange(day, disabled, 'endDate')}
              />
            </InteractivePopup>
            )}

          </Table.Cell>
          <Table.Cell>{lastModifiedBy? lastModifiedBy.split('@')[0] : ''}</Table.Cell>
          <Table.Cell>
            {
              moduleNode.get('lastModifiedDate') ?
                <FormattedDate value={moduleNode.get('lastModifiedDate')} month="short" day="numeric" year="numeric" /> :
                ''
            }
          </Table.Cell>
        </Table.Row>
        {this.isScholarships() ? (
          <ScholarshipModal
            open={this.state.showScholarshipsModal}
            onCancel={this.hideModal}
            value={scholarshipsMatchStartDate}
            endDate={moduleNode.get('endDate')}
            heAccountId={heAccountId}
            canEdit={canEdit}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(ModuleSubscriptionTableRow);
