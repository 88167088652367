import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'react-recompose';

import Loader from './components/Loader';
import CommunityPage from './containers/Community';
import SchoolCoreLookupPage from './containers/SchoolCoreLookup';
import Home from './containers/HomePage';
import HEAccount from './containers/HEAccountPage';
import HSAccount from './containers/HSAccountPage';
import HEAccountUsers from './containers/HEAccountUsersPage';
import HSAccountUsers from './containers/HSAccountUsersPage';
import HEAccountLogHistory from './containers/HEAccountLogHistoryPage';
import HSAccountLogHistory from './containers/HSAccountLogHistoryPage';
import HSRepvisitsDetails from './containers/HSAccountRepvisitsDetails';
import AdminDashboard from './containers/AdminDashboard';
import NavianceSyncLogPage from './containers/NavianceSyncLogPage';
import UnauthorizedPage from './containers/UnauthorizedPage';
import { authorized, authorizedWithRole } from './containers/Authorization';
import * as appRoles from './containers/Authorization/constants';
import CreatePrimaryUserPage from './containers/CreatePrimaryUserPage';
import HSAccountCreatePrimaryUserPage from './containers/HSAccountCreatePrimaryUserPage';
import UpdatePrimaryUserPage from './containers/UpdatePrimaryUserPage';
import HSAccountUpdatePrimaryUserPage from './containers/HSAccountUpdatePrimaryUserPage';
import CreateSecondaryUserPage from './containers/CreateSecondaryUserPage';
import HSAccountCreateSecondaryUserPage from './containers/HSAccountCreateSecondaryUserPage';
import SearchResultsPage from './containers/SearchResultsPage';
import HESubscriptions from './containers/HESubscriptions';
import HESubscriptionsCamino from './containers/HESubscriptions/HESubscriptionsCamino';
import HEHealthSupport from './containers/HEHealthReport';
import CounselorCommunityServerless from './containers/CounselorCommunityServerless';
import EditCounselorCommunityProfile from './containers/CounselorCommunityServerless/edit-profile'; // eslint-disable-line import/no-named-as-default
import { FeatureFlags, onFeatureEnabled, onFeatureDisabled } from './utils/features';

const authorizeCommunityUser = (successPage) => compose(
  onFeatureEnabled(FeatureFlags.HEALTH_REPORT, { failure: UnauthorizedPage }),
  authorizedWithRole([appRoles.COMMUNITY_ROLE], { failure: UnauthorizedPage }),
)(successPage);

export default (
  <Suspense fallback={<Loader active />}>
    <Switch>
      <Route
        exact
        path="/"
        component={compose(
          authorizedWithRole(appRoles.COMMUNITY_VIEW_ROLES, { failure: UnauthorizedPage }),
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: CommunityPage }),
        )(Home)}
      />
      <Route
        path="/admin"
        component={compose(
          authorizedWithRole(appRoles.ADMIN_ROLES, { failure: UnauthorizedPage }),
        )(AdminDashboard)}
      />
      <Route
        exact
        path="/he-account/:id/"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: authorizeCommunityUser(HEAccount) }),
        )(HEAccount)}
      />
      <Route
        exact
        path="/hs-account/:id/"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(HSAccount)}
      />
      <Route
        exact
        path="/search-results/:view"
        component={compose(
          onFeatureDisabled(FeatureFlags.DISABLE_LEGACY_COMMUNITY),
        )(SearchResultsPage)}
      />
      <Route
        path="/counselor-community/"
        component={compose(
          authorizedWithRole(appRoles.COMMUNITY_VIEW_ROLES, { failure: UnauthorizedPage }),
          onFeatureDisabled(FeatureFlags.DISABLE_LEGACY_COMMUNITY),
        )(CommunityPage)}
      />
      <Route
        path="/counselorcommunity/*"
        component={compose(
          authorizedWithRole(appRoles.COMMUNITY_VIEW_ROLES, { failure: UnauthorizedPage }),
          onFeatureEnabled(FeatureFlags.COMMUNITY_SERVERLESS),
        )(CounselorCommunityServerless)}
      />
      <Route
        path="/school-core-lookup/"
        component={compose(
          authorizedWithRole(appRoles.SCHOOL_CORE_LOOKUP_ROLES, { failure: UnauthorizedPage }),
        )(SchoolCoreLookupPage)}
      />
      <Route
        path="/naviance-college-profile/view/:collegeId"
        component={compose(
          authorized({ failure: UnauthorizedPage }),
        )(lazy(() => import('./containers/CollegeProfileEmbedded/viewReact')))}
      />
      <Route
        path="/naviance-college-profile/viewprofile/:scid"
        component={lazy(() => import('./containers/CollegeProfileEmbedded/viewReact'))}
      />
      <Route
        exact
        path="/he-account/:id/log-history/:timeframe/"
        component={compose(
          authorized({ failure: UnauthorizedPage }),
        )(HEAccountLogHistory)}
      />
      <Route
        exact
        path="/hs-account/:id/log-history/:timeframe/"
        component={compose(
          authorized({ failure: UnauthorizedPage }),
        )(HSAccountLogHistory)}
      />
      <Route
        exact
        path="/hs-account/:id/repvisits"
        component={compose(
          authorized({ failure: UnauthorizedPage }),
        )(HSRepvisitsDetails)}
      />
      <Route
        exact
        path="/he-account/:id/create-primary-user"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(CreatePrimaryUserPage)}
      />
      <Route
        exact
        path="/hs-account/:id/create-primary-user"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(HSAccountCreatePrimaryUserPage)}
      />
      <Route
        exact
        path="/he-account/:id/update-primary-user"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(UpdatePrimaryUserPage)}
      />
      <Route
        exact
        path="/hs-account/:id/update-primary-user"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(HSAccountUpdatePrimaryUserPage)}
      />
      <Route
        exact
        path="/he-account/:id/create-user"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(CreateSecondaryUserPage)}
      />
      <Route
        exact
        path="/hs-account/:id/create-user"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(HSAccountCreateSecondaryUserPage)}
      />
      <Route
        exact
        path="/he-account/:id/users"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: authorizeCommunityUser(HEAccountUsers) }),
        )(HEAccountUsers)}
      />
      <Route
        exact
        path="/hs-account/:id/users"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(HSAccountUsers)}
      />
      <Route
        path="/edit-cc-profile/:userId"
        component={compose(
          authorizedWithRole(appRoles.SUPER_ADMIN_ROLE, { failure: UnauthorizedPage }),
        )(EditCounselorCommunityProfile)}
      />
      <Route
        exact
        path="/he-account/:id/subscriptions/"
        component={compose(
          authorized({ failure: UnauthorizedPage }),
        )(HESubscriptions)}
      />
      <Route
        exact
        path="/he-account/:id/am-camino/"
        component={compose(
          authorized({ failure: UnauthorizedPage }),
        )(HESubscriptionsCamino)}
      />
      <Route
        exact
        path="/naviance-sync-log/:id/:nid/:timeframe/"
        component={compose(
          authorizedWithRole(appRoles.NON_VIEW_ONLY_ROLES, { failure: UnauthorizedPage }),
        )(NavianceSyncLogPage)}
      />
      <Route
        exact
        path="/he-account/:id/health-report/"
        component={compose(
          onFeatureEnabled(FeatureFlags.HEALTH_REPORT),
          authorized({ failure: UnauthorizedPage }),
        )(HEHealthSupport)}
      />
      {process.env.GRAPHIQL && (
        <Route
          exact
          path="/graphiql"
          component={compose(
            authorized({ failure: UnauthorizedPage }),
          )(lazy(() => import('./containers/GraphQL')))}
        />
      )}
      <Redirect from="*" to="/" />
    </Switch>
  </Suspense>
);
