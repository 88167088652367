import * as constants from './constants';

export const searchCategorySelected = (payload) => (
  {
    type: constants.SEARCH_CATEGORY_SELECTED,
    payload,
  }
);

export const searchCategoryChanged = (value) => (
  (dispatch) => {
    dispatch(searchCategorySelected(value));
  }
);
