import AuthenticationContext from 'adal-angular';

// window.Logging.level = 3;
// window.Logging.log = message => console.log(message);

const adalConfig = {
  tenant: process.env.ADAL_TENANT_ID,
  clientId: process.env.ADAL_CLIENT_ID,
  cacheLocation: 'sessionStorage',
  expireOffsetSeconds: 300,
};

export default new AuthenticationContext(adalConfig);
