import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import queryString from 'query-string';
import Accordion from '../../components/Accordion';
import Button from '../../components/Button';
import Grid from '../../components/Grid';
import Icon from '../../components/Icon';
import Segment from '../../components/Segment';
import Form from '../../components/Form';
import Checkbox from '../../components/Checkbox';
import Dropdown from '../../components/Dropdown';
import styles from '../AdvancedSearch/styles.css';
import messages from './messages';
import SearchField from '../../components/SearchField';
import stateOptions, { stateOptionsShort } from './state-options';

const institutionTypeOptions = [
  { text: 'All', value: '' },
  { text: 'High School', value: 'HS' },
  { text: 'Higher Education', value: 'HE' },
];
const collegeTypeOptions = [
  { text: 'All', value: '' },
  { text: 'Public', value: 'PUBLIC' },
  { text: 'Private', value: 'PRIVATE' },
];

class AdvancedPeopleSearchBox extends React.PureComponent {
  static propTypes = {
    keyword: PropTypes.string,
    advanced: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { countyOptions: {}, ...pick(this.props, 'keyword', 'advanced') };
  }

  componentDidMount = () => {
    import('./county-options').then((options) => {
      this.setState({ countyOptions: options.default });
    });
  };

  onChange = (e, { type, value, name } = {}) => {
    const { advanced } = this.state;
    value = value || e.target.value;
    name = name || e.target.name;
    type = type || e.target.type;
    const nextAdvanced = { ...advanced, [name]: value };
    if (name === 'keyword') {
      this.setState({ keyword: value });
    } else {
      if (type === 'checkbox') {
        nextAdvanced[name] = e.target.checked;
      }
      if (name === 'state' && value !== advanced.state) {
        nextAdvanced.county = '';
      }
      this.setState({ advanced: pickBy(nextAdvanced) });
    }
  };

  handleCountyOptions = (territory) => {
    const options = this.state.countyOptions;
    if (territory && options[territory]) {
      return [{ text: 'All', value: '' }].concat(options[territory].map((county) => ({ value: county, text: county })));
    } else {
      return null;
    }
  };

  handlePlaceholder = (territory) => {
    if (territory) {
      return `Select a county in ${territory}`;
    } else {
      return 'Select a state to begin';
    }
  };

  search = (e) => {
    const { history } = this.props;
    const { advanced, keyword } = this.state;
    const territory = advanced.county || advanced.state;
    if (territory) {
      advanced.field_population_served_name = territory;
    } else {
      delete advanced.field_population_served_name;
    }
    history.push({
      pathname: '/search-results/people',
      search: queryString.stringify({
        keyword,
        advanced: JSON.stringify(advanced),
      }),
    });
  };

  render() {
    const { keyword, advanced } = this.state;
    return (
      <Grid.Column as={Form} onSubmit={this.search} styleName="searchBoxContainer">
        <Segment styleName="searchBoxSegment">
          <div styleName="keywordContainer">
            <SearchField
              message={messages.keyword}
              name="keyword"
              onChange={this.onChange}
              value={keyword}
              inputID="keyword"
            />
          </div>
          <Accordion
            exclusive={false}
            panels={[
              {
                key: 'general',
                title: {
                  className: styles.categoryTitle,
                  children: (
                    <span>
                      <FormattedMessage {...messages.general} />
                      <Icon name="chevron left" />
                    </span>
                  ),
                },
                content: {
                  className: styles.keywordContainer,
                  children: (
                    <div>
                      <SearchField
                        message={messages.firstName}
                        name="field_first_name"
                        onChange={this.onChange}
                        value={advanced.field_first_name}
                        inputID="firstName"
                      />
                      <SearchField
                        message={messages.lastName}
                        name="field_last_name"
                        onChange={this.onChange}
                        value={advanced.field_last_name}
                        inputID="lastName"
                      />
                      <SearchField
                        message={messages.email}
                        name="mail"
                        onChange={this.onChange}
                        value={advanced.mail}
                        inputID="email"
                      />
                      <SearchField
                        message={messages.almaMater}
                        name="field_alma_mater"
                        onChange={this.onChange}
                        value={advanced.field_alma_mater}
                        inputID="almaMater"
                      />
                    </div>
                  ),
                },
              },
              {
                key: 'work',
                title: {
                  className: styles.categoryTitle,
                  children: (
                    <span>
                      <FormattedMessage {...messages.work} />
                      <Icon name="chevron left" />
                    </span>
                  ),
                },
                content: {
                  className: styles.keywordContainer,
                  children: (
                    <div>
                      <SearchField
                        message={messages.institutionTitle}
                        name="field_user_institution_title"
                        onChange={this.onChange}
                        value={advanced.field_user_institution_title}
                        inputID="institutionTitle"
                      />
                      <div styleName="dropdownContainer">
                        <div styleName="dropdownLabel"><FormattedMessage {...messages.institutionType} /></div>
                        <div styleName="dropdownInput"><Dropdown
                          placeholder="All"
                          id="field_institution_type_name"
                          name="field_institution_type_name"
                          onChange={this.onChange}
                          value={advanced.field_institution_type_name}
                          fluid
                          selection
                          options={institutionTypeOptions}
                        />
                        </div>
                        {advanced.field_institution_type_name === 'HE' && (
                        <div>
                          <div styleName="dropdownLabel"><FormattedMessage {...messages.collegeType} /></div>
                          <div styleName="dropdownInput"><Dropdown
                            placeholder="All"
                            id="field_college_type_name"
                            name="field_college_type_name"
                            onChange={this.onChange}
                            value={advanced.field_college_type_name}
                            fluid
                            selection
                            options={collegeTypeOptions}
                          />
                          </div>
                        </div>
                        )}
                      </div>
                      <SearchField
                        message={messages.position}
                        name="field_job_position"
                        onChange={this.onChange}
                        value={advanced.field_job_position}
                        inputID="position"
                      />
                      <div styleName="dropdownContainer">
                        <div styleName="dropdownLabel"><FormattedMessage {...messages.institutionState} /></div>
                        <div styleName="dropdownInput"><Dropdown
                          placeholder="Select State"
                          id="institutionState"
                          name="field_user_institution_state"
                          onChange={this.onChange}
                          value={advanced.field_user_institution_state}
                          fluid
                          selection
                          options={stateOptionsShort}
                        />
                        </div>
                      </div>
                      <SearchField
                        message={messages.institutionZipcode}
                        name="field_user_institution_zip_code"
                        onChange={this.onChange}
                        value={advanced.field_user_institution_zip_code}
                        inputID="institutionZipcode"
                      />
                    </div>
                  ),
                },
              },
              {
                key: 'population-served',
                title: {
                  className: styles.categoryTitle,
                  children: (
                    <span>
                      <FormattedMessage {...messages.populationServed} />
                      <Icon name="chevron left" />
                    </span>
                  ),
                },
                content: {
                  className: styles.keywordContainer,
                  children: (
                    <div styleName="dropdownContainer">
                      <div styleName="dropdownLabel"><FormattedMessage {...messages.stateServed} /></div>
                      <div styleName="dropdownInput"><Dropdown
                        placeholder="Select State"
                        id="field_population_served_name_STATE"
                        name="state"
                        onChange={this.onChange}
                        value={advanced.state}
                        fluid
                        selection
                        options={stateOptions}
                      />
                      </div>
                      <div styleName="dropdownLabel"><FormattedMessage {...messages.countyServed} /></div>
                      <div styleName="dropdownInput"><Dropdown
                        placeholder={this.handlePlaceholder(advanced.state)}
                        id="field_population_served_name_COUNTY"
                        name="county"
                        onChange={this.onChange}
                        value={advanced.county}
                        fluid
                        selection
                        disabled={!advanced.state}
                        options={this.handleCountyOptions(advanced.state)}
                      />
                      </div>
                      <Checkbox
                        id="advise_students"
                        name="field_advise_students"
                        onChange={this.onChange}
                        value="true"
                        checked={advanced.field_advise_students || false}
                        label={{
                          htmlFor: 'advise_students',
                          className: styles.typeCheckbox,
                          children: (<FormattedMessage {...messages.adviseStudents} />),
                        }}
                      />
                      <Checkbox
                        id="college_visits"
                        name="field_college_visits"
                        onChange={this.onChange}
                        value="true"
                        checked={advanced.field_college_visits || false}
                        label={{
                          htmlFor: 'college_visits',
                          className: styles.typeCheckbox,
                          children: (<FormattedMessage {...messages.collegeVisits} />),
                        }}
                      />
                    </div>
                  ),
                },
              },
            ]}
          />
        </Segment>
        <Button color="teal" type="submit" data-cy="advanced-search-update-search-button">
          <FormattedMessage {...messages.updateSearch} />
        </Button>
      </Grid.Column>
    );
  }
}

export default withRouter(AdvancedPeopleSearchBox);
