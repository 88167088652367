import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Input from '../Input';
import './styles.css';

const SearchField = (props) => {
  const { message, value, name, inputID, onChange } = props;
  return (
    <div>
      <label styleName="searchFieldLabel" htmlFor={inputID}><FormattedMessage {...message} /></label>
      <Input styleName="searchFieldInput" onChange={onChange} type="text" name={name} fluid>
        <input id={inputID} value={value || ''} />
      </Input>
    </div>
  );
};

SearchField.propTypes = {
  message: PropTypes.object.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  inputID: PropTypes.string,
};

export default SearchField;
