import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import messages from './messages';
import Item from '../../components/Item';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';
import Feed from '../../components/Feed';

import './styles.css';

class RepvisitsAppointments extends React.Component {
  static propTypes = {
    viewer: PropTypes.object,
    loadMore: PropTypes.func.isRequired,
    repVisitsAppointments: PropTypes.object,
  };

  state = {
    loading: false,
  };

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore('node.repVisitsAppointments').then(() => this.setState({ loading: false }));
  }

  prepareFeedItems = (appointment) =>
    appointment.attendees.filter((attendee) => attendee.contact).map((attendee) => (
      <Feed.Event key={attendee.contact.id}>
        <Feed.Label data-cy={`repvisits-appointments-avatar-${attendee.contact.id}`}>
          <Avatar icon="user" image={attendee.contact.field_profile_picture_file_url} />
        </Feed.Label>
        <Feed.Content>
          <Feed.Date data-cy={`repvisits-appointments-time-${attendee.contact.id}`}>
            {`${appointment.startTime} - ${appointment.endTime}`}
          </Feed.Date>
          <Feed.Summary data-cy={`repvisits-appointments-attendee-${attendee.contact.id}`}>
            {
              attendee.contact.communityId &&
              (
                <Link
                  data-cy={`repvisits-appointments-community-attendee-${attendee.contact.communityId}`}
                  to={`/counselor-community/profile/${attendee.contact.communityId}`}
                >
                  {`${get(attendee, 'contact.name', '')}`}
                </Link>
              )
            }
            {
              !attendee.contact.communityId && `${get(attendee, 'contact.name', '')}`
            }
            <span> From </span>
            <Link
              data-cy={`repvisits-appointments-institution-${attendee.contact.institutionId}`}
              to={`/counselor-community/institution-id/${get(attendee, 'contact.institutionId')}`}
            >
              {`${get(attendee, 'contact.institution.name', '')}`}
            </Link>
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    ));

  prepareAppointment = (appointments) =>
    appointments
      .filter((elem) => elem.currentColleges > 0)
      .map((appointment) => (
        this.prepareFeedItems(appointment)
      ));

  prepareCards = (visits) => {
    const arr = [];
    visits.forEach(({ node: visit }) => {
      arr.push(
        <Card key={visit.id}>
          <Card.Content data-cy={`repvisits-appointments-card-${visit.id}`}>
            <Card.Header>{ visit.date }</Card.Header>
            <Feed styleName="itemContainer">
              { this.prepareAppointment(visit.appointments) }
            </Feed>
          </Card.Content>
        </Card>,
      );
    });

    return arr;
  }

  render() {
    const { repVisitsAppointments } = this.props;
    if (!get(repVisitsAppointments, 'edges.length')) {
      return (
        <Item.Group>
          <Item styleName="itemContainer">
            <Item.Content>
              <Item.Header styleName="itemHeader"><FormattedMessage {...messages.noResults} /></Item.Header>
            </Item.Content>
          </Item>
        </Item.Group>
      );
    } else {
      return (
        <div>
          <Item.Group>
            <Card.Group>
              {this.prepareCards(repVisitsAppointments.edges)}
            </Card.Group>
          </Item.Group>
          {get(repVisitsAppointments, 'pageInfo.hasNextPage') ? (
            <Container textAlign="center">
              <Loader inline active={this.state.loading} />
              <Button data-cy="repvisits-appointments-loadmore-button" size="small" color="teal" onClick={this.loadMore}>
                <FormattedMessage {...messages.moreResults} />
              </Button>
            </Container>
          ) : null}
        </div>
      );
    }
  }
}

export default RepvisitsAppointments;
