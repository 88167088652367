import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { loadMorePaginationProp, SimpleLoadingComponent } from '../../utils/apollo';
import AdvancedPeopleResults from './advanced-people-results';

const query = gql`
  query AdvancedPeopleResults($filter: String!, $first: Int!, $after: String, $advanced: AdvancedPeopleFilter!) {
    viewer {
      id
      advancedPeopleSearch(filter: $filter, first: $first, after: $after, advanced: $advanced) {
        edges {
          node {
            id
            communityId
            name
            field_job_position
            field_user_institution_title
            field_profile_picture_file_url
            field_user_institution
            field_general_description
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ filter, advanced }) => ({
    variables: {
      first: 5,
      filter,
      advanced,
    },
  }),
  props: loadMorePaginationProp(query),
});

export default SimpleLoadingComponent(graphqlData)(AdvancedPeopleResults);
