import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form from '../../components/Form';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import Label from '../../components/Label';
import stateOptionsLong from '../AdvancedPeopleSearchBox/state-options';
import countyOptions from '../AdvancedPeopleSearchBox/county-options';
import sectorOptions from '../AdvancedPeopleSearchBox/sector-options';
import { messages } from './constants';

import './styles.css';

const stateOptions = stateOptionsLong.filter((st) => st.value);

const getCountyOptions = (territory) => {
  /* istanbul ignore else */
  if (territory && countyOptions[territory]) {
    return countyOptions[territory].map((county) => ({ value: county, text: `${county} County` }));
  }
  /* istanbul ignore next */
  return [];
};

const Component = ({ id, label, error, ...rest }) => (
  <Form.Field>
    <label styleName="form-label-big" htmlFor={id}>
      {label}
    </label>
    <Dropdown
      id={id}
      fluid
      selection
      search
      icon={<Icon name="chevron down" styleName={rest.multiple ? 'dropdown-icon' : 'rounded-dropdown-icon'} />}
      className={rest.multiple ? 'custom-dropdown' : 'custom-rounded-dropdown'}
      {...rest}
    />
    {error && (
    <Label role="tooltip" basic color="red" pointing>
      {error}
    </Label>
    )}
  </Form.Field>
);
Component.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

export const StatesComponent = ({ error, ...rest }) => (
  <Component
    options={stateOptions}
    error={error && <FormattedMessage {...messages.selectStates} />}
    {...rest}
    openOnFocus={!rest.value}
  />
);
StatesComponent.propTypes = {
  error: PropTypes.bool,
};

export const CountiesComponent = ({ state, ...rest }) => (
  <Component
    options={getCountyOptions(state)}
    openOnFocus={!rest.value}
    {...rest}
  />
);
CountiesComponent.propTypes = {
  state: PropTypes.string,
};

export const SectorsComponent = ({ error, ...rest }) => (
  <Component
    options={sectorOptions}
    error={error && <FormattedMessage {...messages.selectSectors} />}
    openOnFocus={!rest.value}
    {...rest}
  />
);

SectorsComponent.propTypes = {
  error: PropTypes.bool,
};
