export const SEARCH_ALL = 'ALL';
export const SEARCH_HE_ACCOUNTS = 'HE_ACCOUNTS';
export const SEARCH_HS_ACCOUNTS = 'HS_ACCOUNTS';
export const SEARCH_USERS = 'USERS';
export const SEARCH_COLLEGE_CORE = 'COLLEGE_CORE';
export const SEARCH_INSTITUTIONS = 'INSTITUTIONS';
export const SEARCH_COMMUNITY_GROUPS = 'COMMUNITY_GROUPS';
export const SEARCH_COMMUNITY_PEOPLE = 'COMMUNITY_PEOPLE';

export const SEARCH_CATEGORY_SELECTED = 'SEARCH_CATEGORY_SELECTED';
