import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { SimpleLoadingComponent } from '../../utils/apollo';
import { withEnabledFeatures } from '../../utils/features';
import updateHEAccountMutation from '../../mutations/HEAccount/updateHEAccount';

import HEAccountDetails from './he-account-details';

const query = gql`
  query HEAccountDetails($id: ID!) {
    node(id: $id) {
      id
      ...on HEAccount {
        scid
        name
        freemium
        connectId
        primaryUser {
          id
          firstName
          lastName
          email
          role
          active
        }
        collegeCore {
          address {
            street
            street2
            city
            state
            postalCode
          }
        }
        moduleSubscriptions {
          name
          status
          quoteChargeId
          startDate
          endDate
          lastModifiedBy
          lastModifiedDate
          manuallyInactivated
        }
        radiusId
        scholarshipsMatchStartDate
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
});

const heAccountMutation = graphql(updateHEAccountMutation, {
  name: 'mutateHEAccount',
  options: {
    refetchQueries: ['HEAccountDetails'],
  },
});

export default withRouter(SimpleLoadingComponent(graphqlData, heAccountMutation)(withEnabledFeatures(HEAccountDetails)));
