import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

import Item from '../../components/Item';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';

import './styles.css';

const messages = defineMessages({
  noResults: {
    id: 'search-results.noneFound',
    defaultMessage: 'No results found.',
  },
  tryAgain: {
    id: 'search-results.tryAgain',
    defaultMessage: 'Please try a new search.',
  },
  moreResults: {
    id: 'search-results.moreResults',
    defaultMessage: 'More Results',
  },
});

const avatarURL = 'https://static.naviance.com/libraries/college-logos/';

function getHighSchoolId(hs) {
  return get(hs.naviance, 'field_hs_na_nguid') || get(hs.general, 'field_hs_na_nces_id');
}

class AdvancedInstitutionResults extends React.PureComponent {
  static propTypes = {
    viewer: PropTypes.object,
    variables: PropTypes.shape({
      first: PropTypes.number,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  state = { loading: false };

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore().then(() => this.setState({ loading: false }));
  };

  render() {
    const { viewer, variables } = this.props;
    const { loading } = this.state;

    if (!get(viewer, 'searchByCategory.length')) {
      return (
        <Item.Group>
          <Item styleName="itemContainer">
            <Item.Content>
              <Item.Header styleName="itemHeader"><FormattedMessage {...messages.noResults} /></Item.Header>
              <Item.Description styleName="itemDescription"><FormattedMessage {...messages.tryAgain} /></Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      );
    } else {
      return (
        <div>
          <Item.Group>
            {get(viewer, 'searchByCategory[0].results').map((row, index) => (
              <Item styleName="itemContainer" key={row.id}>
                <Avatar big icon="university" image={row.field_he_institution_id ? `${avatarURL}${row.field_he_institution_id}.gif` : ''} />
                <Item.Content styleName="itemContent">
                  <Item.Header styleName="itemHeader">
                    {row.field_he_institution_id ?
                      <Link to={`/counselor-community/institution-id/${row.field_he_institution_id}`}>{row.name}</Link> :
                      <Link to={`/counselor-community/institution-hs-id/${getHighSchoolId(row)}`}>{row.name}</Link>}
                  </Item.Header>
                  <Item.Description styleName="itemDescription">
                    {`
                      ${row.field_city || get(row, 'address.field_hs_elna_city') || ''},
                      ${row.field_state_short_name || get(row, 'address.field_hs_elna_state_latest') || ''}
                      ${row.field_postalcode || get(row, 'address.field_hs_eln_zip') || ''}
                    `}
                  </Item.Description>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
          {get(viewer, 'searchByCategory[0].results.length') === variables.first || loading ? (
            <Container textAlign="center">
              <Loader inline active={loading} />
              <Button size="small" color="teal" onClick={this.loadMore}>
                <FormattedMessage {...messages.moreResults} />
              </Button>
            </Container>
          ) : null}
        </div>
      );
    }
  }
}

export default AdvancedInstitutionResults;
