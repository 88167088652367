import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import 'isomorphic-fetch';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Message from '../../components/Message';

import * as constants from './constants';

export const { HS_APP_URL, HE_APP_URL } = constants;

export const messages = defineMessages({
  error: {
    id: 'primary-user.impersonate-user.error',
    defaultMessage: 'Unable to login as this user',
  },
  impersonateUser: {
    id: 'primary-user.impersonate-user.header',
    defaultMessage: 'Login as User',
  },
  impersonateUserText: {
    id: 'primary-user.impersonate-user.text',
    defaultMessage: 'Would you like to login as {userName}?',
  },
});

export class ImpersonateUserComponent extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    node: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    current: PropTypes.object,
    redirectTo: PropTypes.string.isRequired,
  };

  state = {
    hasError: false,
    requesting: true,
    impersonatorToken: null,
  };

  componentDidMount() {
    this._requestImpersonation();
  }

  _handleClose = () => {
    this.setState({ requesting: false });
    this.props.onClose();
  };

  _handleYes = () => {
    // this closes the previous impersonation tabs
    // only with window.open we'll get access to the impersonation tab
    // also we ensure that all the previous data is removed
    window.open(null, constants.IMPERSONATE_TAB).close();
    this._handleClose();
  };

  _requestImpersonation = () => {
    const { user, node, current } = this.props;
    const uri = `${constants.API_URL}/impersonate`;
    const body = JSON.stringify({
      username: user.id,
      institutionName: node.name,
    });
    fetch(uri, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${current.viewer.jwtToken}`,
      },
    }).then((res) => {
      if (res.status === 500) {
        this.setState({ hasError: true, requesting: false });
      } else {
        res.json().then((data) => this.setState({ impersonatorToken: data.token }));
        this.setState({ hasError: false, requesting: false });
      }
    }, () => {
      this.setState({ hasError: true, requesting: false });
    });
  };

  render() {
    const { user } = this.props;
    const { hasError, requesting, impersonatorToken } = this.state;
    const userName = `${user.firstName} ${user.lastName}`;
    const { redirectTo } = this.props;
    const impersonationLink = `${redirectTo}#${impersonatorToken}`;
    const errorDetails = hasError ? (
      <Message role="alert" negative>
        <Message.Header><FormattedMessage {...messages.error} /></Message.Header>
      </Message>
    ) : '';

    return (
      <Modal open onClose={this._handleClose}>
        <Header data-cy="impersonate-modal-header-title">
          <FormattedMessage {...messages.impersonateUser} />
        </Header>
        <Modal.Content>
          <p data-cy="impersonate-modal-content-message">
            <FormattedMessage
              {...messages.impersonateUserText}
              values={{
                userName,
              }}
            />
          </p>
          {errorDetails}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this._handleClose} data-cy="impersonate-modal-no-button">
            <Icon name="remove" />
            <FormattedMessage id="common.button.no" defaultMessage="No" />
          </Button>
          <Button
            primary
            as="a"
            href={impersonationLink}
            target={constants.IMPERSONATE_TAB}
            onClick={this._handleYes}
            data-cy="impersonate-modal-yes-button"
            loading={requesting}
            disabled={!impersonatorToken}
          >
            <Icon name="checkmark" />
            <FormattedMessage id="common.button.yes" defaultMessage="Yes" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  current: state.current,
});
export default connect(mapStateToProps)(ImpersonateUserComponent);
