export const prepareQueryParamObj = (queryText) => queryText.substring(1).split('&').reduce((acc, x) => {
  acc[x.split('=')[0]] = x.split('=')[1];
  return acc;
}, {});

export const setCounselorCommunityPath = () => {
  const counselorCommunityUrl = process.env.COUNSELOR_COMMUNITY_URL;
  if (!global.CounselorCommunity?.default?.default) {
    const { origin } = new URL(counselorCommunityUrl);
    global.__webpack_counscomm_public_path__ = origin;
  }
  return counselorCommunityUrl;
};
