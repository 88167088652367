/* eslint-disable prefer-destructuring */
/* process.env can not be destructured because that's not how the EnvironmentPlugin replaces process.env */
export const IMPERSONATE_MAX_TRIES = process.env.IMPERSONATE_MAX_TRIES;
export const IMPERSONATE_TIMEOUT = process.env.IMPERSONATE_TIMEOUT;
export const IMPERSONATE_INTERVAL = process.env.IMPERSONATE_INTERVAL;
export const IMPERSONATE_TAB = process.env.IMPERSONATE_TAB;

export const API_URL = process.env.API_URL;
export const HS_APP_URL = process.env.HS_APP_URL;
export const HE_APP_URL = process.env.HE_APP_URL;
