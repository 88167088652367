export const ADDED_USER_ACTION = 'added user to';
export const UPDATED_USER_ACTION = 'updated user';
export const CREATED_USER_ACTION = 'created user';
export const ENABLED_USER_ACTION = 'enabled user';
export const DISABLED_USER_ACTION = 'disabled user';
export const ADDED_PRIMARY_USER_ACTION = 'added primary user';
export const CREATED_PRIMARY_USER_ACTION = 'created primary user';
export const UPDATE_PRIMARY_USER_ACTION = 'updated primary user';
export const MARKED_PRIMARY_USER_ACTION = 'marked primary user';
export const UPDATED_MODULE_SUBSCRIPTIONS_ACTION = 'updated module subscriptions';
