import gql from 'graphql-tag';

export default gql`
  fragment AMSubscriptions on HEAccount {
    amSubscriptions(visible: true) {
      edges {
        node {
          id
          version
          institution
          type
          majors
          connection
          setupBy
          startDate
          endDate
          lastModifiedBy
          lastModifiedDate
          visible
          audience {
            diversity
            zipCode {
              zipCode
              radius
            }
            state
            county {
              state
              county
            }
            sector
          }
          messages {
            competitors {
              scid
              name
              state
            }
          }
        }
      }
    }
  }
`;
