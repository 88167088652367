import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import ContentHeader from './ContentHeader';
import ContentBody from './ContentBody';
import Message from '../../components/Message';
import styles from './styles.css';
import SubscriptionModal from '../HESubscriptionModal';
import getSubscriptionsList from '../HESubscriptionModal/utils';

class HESubscriptions extends React.Component {
  static propTypes = {
    isUserAdmin: PropTypes.bool,
    canEdit: PropTypes.bool,
    heAccount: PropTypes.shape({
      id: PropTypes.string,
      scid: PropTypes.string,
      name: PropTypes.string,
      primaryUser: PropTypes.object,
      collegeCore: PropTypes.object,
      moduleSubscriptions: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      amSubscriptions: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired).isRequired,
      }).isRequired,
    }),
    removeSubscription: PropTypes.func,
    subscriptions: PropTypes.array,
    enabledFeatures: PropTypes.array,
  };

  static defaultProps = {
    subscriptions: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showSubscriptionModal: false,
      editSubscription: null,
      removeSubscriptionID: '',
      removeErrorMessage: '',
    };
  }

  onRemoveSuccess = () => {
    this.handleDeleteModalClose();
  };

  onRemoveFailure = (error) => {
    /* istanbul ignore else */
    if (error && error.message) {
      this.setState({ removeErrorMessage: error.message });
    }
  };

  handleAddNewSubscription = () => {
    this.setState({
      showSubscriptionModal: true,
      editSubscription: null,
    });
  }

  handleSubscriptionModalClose = () => {
    this.setState({
      showSubscriptionModal: false,
    });
  }

  handleEditSubscription = (e, sub) => {
    this.setState({
      showSubscriptionModal: true,
      editSubscription: sub,
    });
  }

  handleDeleteModalOpen = (e, id) => {
    this.setState({
      showDeleteModal: true,
      removeSubscriptionID: id,
    });
  }

  handleDeleteModalClose = () => {
    this.setState({
      showDeleteModal: false,
      removeSubscriptionID: '',
    });
  }

  handleDeleteClick = () => {
    const { removeSubscriptionID } = this.state;
    const { heAccount, removeSubscription } = this.props;
    const edge = heAccount.amSubscriptions.edges.find(({ node }) => node.id === removeSubscriptionID);

    /* istanbul ignore else */
    if (edge) {
      const { node: { version } } = edge;
      const { onRemoveSuccess, onRemoveFailure } = this;
      const amSubscription = {
        id: removeSubscriptionID,
        visible: false,
        version,
      };
      removeSubscription({ variables: {
        input: {
          amSubscription,
          heAccountId: heAccount.id,
        },
      } })
        .then(onRemoveSuccess, onRemoveFailure);
    }
  };

  render() {
    const { heAccount, subscriptions, isUserAdmin, canEdit, enabledFeatures } = this.props;
    const { removeErrorMessage } = this.state;

    if (heAccount) {
      const subscriptionList = getSubscriptionsList(subscriptions);
      const activeSubscriptions = heAccount.moduleSubscriptions.filter((module) => module.status === 'active').map((a) => a.name);
      const { id } = heAccount;
      const { showDeleteModal, showSubscriptionModal, editSubscription } = this.state;
      const itsLink = `/he-account/${id}/subscriptions/`;
      return (
        <div styleName="container">
          <div styleName="page-menu">
            <Menu stackable as="ul" pointing secondary size="huge" styleName="menu">
              <Menu.Item as="li" link styleName="menu-item">
                <NavLink
                  to={itsLink}
                  styleName="menu-link"
                  activeClassName={styles.active}
                >
                  Overview
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>
          <div styleName="page-content">
            <ContentHeader heAccount={heAccount} />
            <ContentBody
              isUserAdmin={isUserAdmin}
              canEdit={canEdit}
              heAccount={heAccount}
              subscriptions={subscriptions}
              onClickDelete={this.handleDeleteModalOpen}
              onAddNewSubscription={this.handleAddNewSubscription}
              onLocationClick={this.handleEditSubscription}
              activeSubscriptions={activeSubscriptions}
              enabledFeatures={enabledFeatures}
            />
          </div>

          <SubscriptionModal
            heAccountId={heAccount.id}
            currentScid={heAccount.scid}
            name={heAccount.name}
            open={showSubscriptionModal}
            subscription={editSubscription}
            onClose={this.handleSubscriptionModalClose}
            subscriptionList={subscriptionList}
            moduleSubscriptions={heAccount.moduleSubscriptions}
          />

          <Modal open={showDeleteModal}>
            <Modal.Header>Are you sure you want to delete</Modal.Header>
            <Modal.Content>
              {showDeleteModal && removeErrorMessage && (
                <Message error>
                  <Message.Content>
                    {removeErrorMessage}
                  </Message.Content>
                </Message>
              )}
              <Modal.Description>
                <p>Are you sure you want to delete this/these subscriptions?</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button styleName="buttonlink" color="teal" onClick={this.handleDeleteModalClose}>
                Cancel
              </Button>
              <Button
                basic
                styleName="buttonlink"
                onClick={this.handleDeleteClick}
                color="teal"
              >
                Delete
              </Button>
            </Modal.Actions>
          </Modal>

        </div>
      );
    }
    return null;
  }
}

export default HESubscriptions;
