import React from 'react';
import PropTypes from 'prop-types';

import MainMenu from '../MainMenu';
import SecondaryMenu from '../SecondaryMenu';
import './styles.css';

const Nav = ({ className, idPrefix }) => (
  <nav className={className} styleName="sidebar">
    <MainMenu idPrefix={idPrefix} />
    <div styleName="spacer" role="separator" className="hidden-mobile" />
    <SecondaryMenu idPrefix={idPrefix} />
  </nav>
);
Nav.defaultProps = {
  idPrefix: 'main-nav',
};
Nav.propTypes = {
  idPrefix: PropTypes.string,
  className: PropTypes.string,
};

export default Nav;
