import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';
import get from 'lodash/get';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Dimmer from '../../components/Dimmer';
import Message from '../../components/Message';
import { SimpleGraphQLComponent } from '../../utils/apollo';
import SetNavianceHighSchoolMutation from './set-naviance-high-school-mutation';
import SetNonNavianceHighSchoolMutation from './set-non-naviance-high-school-mutation';
import './styles.css';

const messages = defineMessages({
  error: {
    id: 'toggle-naviance-modal.error',
    defaultMessage: 'Unable to {navianceLabel} school',
  },
  toggleHeader: {
    id: 'toggle-naviance-modal.naviance.header',
    defaultMessage: 'Turn into a {navianceLabel} School?',
  },
  noButton: {
    id: 'toggle-naviance-modal.button.no',
    defaultMessage: 'Go Back',
  },
  yesButton: {
    id: 'toggle-naviance-modal.button.yes',
    defaultMessage: 'Turn into {navianceLabel} School',
  },
  warningMessage: {
    id: 'toggle-naviance-modal.warning.text',
    defaultMessage: 'Warning! You\'re about to flag this high school in Intersect as a {navianceLabel} school. You won\'t be able to undo this.',
  },
  secondWarningMessage: {
    id: 'toggle-naviance-modal.secondWarning.text',
    defaultMessage: 'Continue ONLY if you can verify this client has a valid {navianceLabel} subscription.',
  },
  secondWarningRenewMessage: {
    id: 'toggle-naviance-modal.secondWarningRenew.text',
    defaultMessage: 'Continue ONLY if you can verify this client doesn\'t plan to renew their expired Naviance subscription.',
  },
  askForHelp: {
    id: 'toggle-naviance-modal.askForHelp.text',
    defaultMessage: 'For additional help, please contact your manager.',
  },
  askForHelpNaviance: {
    id: 'toggle-naviance-modal.askForHelpNaviance.text',
    defaultMessage: 'If you\'re unsure, please contact your manager.',
  },
  navianceCRM: {
    id: 'toggle-naviance-modal.navianceCRM.name',
    defaultMessage: 'Naviance CRM',
  },
  salesforce: {
    id: 'toggle-naviance-modal.salesforce.name',
    defaultMessage: 'Salesforce',
  },
  denavianceInstructions: {
    id: 'toggle-naviance-modal.denaviance.instructions',
    defaultMessage: 'Before you continue, find this client\'s Account Owner in {salesForceLink} to check the health of the account.',
  },
  navianceInstructions: {
    id: 'toggle-naviance-modal.naviance.instructions',
    defaultMessage: 'Before you continue, check this client\'s subscription status in {navianceCRMLink} and find this client\'s Account Owner in {salesForceLink}.',
  },
});

export class ToggleNavianceModalComponent extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object.isRequired,
    navianceLabel: PropTypes.string.isRequired,
    navianceSubscriptionStatus: PropTypes.string.isRequired,
    mutateSetNavianceHighSchool: PropTypes.func.isRequired,
    mutateSetNonNavianceHighSchool: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    isError: false,
    requesting: false,
  };

  _handleClose = (success) => {
    this.setState({ requesting: false });
    this.props.onClose(success);
  };

  _handleGoBack = () => {
    this.setState({ requesting: false });
    this.props.onClose(false);
  };

  _handleProceed = () => {
    const { mutateSetNavianceHighSchool, mutateSetNonNavianceHighSchool, node, navianceLabel } = this.props;
    this.setState({ isError: false, requesting: true });
    const payload = {
      variables: {
        input: {
          hsAccountId: node.id,
        },
      },
    };
    const mutation = navianceLabel === 'Naviance' ? mutateSetNavianceHighSchool(payload) : mutateSetNonNavianceHighSchool(payload);
    mutation.then(() => this._handleClose(true), () => this.setState({ isError: true, requesting: false }));
  };

  render() {
    const { navianceLabel, node, navianceSubscriptionStatus } = this.props;
    const { isError, requesting } = this.state;
    const errorDetails = isError ? (
      <Message role="alert" error>
        <Message.Header><FormattedMessage {...messages.error} values={{ navianceLabel }} /></Message.Header>
      </Message>
    ) : '';
    const nid = get(node, 'highSchool.naviance.field_hs_na_nid');
    const navianceCRMUrl = `https://insite.naviance.com/highschools/highschools.php?viewHS=1&nid=${nid}`;
    const navianceCRMLink = (
      <Link to={navianceCRMUrl} target="_blank">
        <FormattedMessage {...messages.navianceCRM} />
      </Link>
    );
    const salesForceLink = (
      <Link
        to="https://login.salesforce.com/"
        target="_blank"
      >
        <FormattedMessage {...messages.salesforce} />
      </Link>
    );

    return (
      <Dimmer.Dimmable as={Modal} open size="small" onClose={this._handleGoBack}>
        <Dimmer
          role="progressbar"
          aria-describedby={`Turning High School into a ${navianceLabel} School...`}
          aria-busy={requesting}
          inverted
          active={requesting}
        >
          <Loader active={requesting} />
        </Dimmer>
        <Header styleName="toggleNavianceHeader">
          <FormattedMessage {...messages.toggleHeader} values={{ navianceLabel }} />
        </Header>
        <Modal.Content>
          {errorDetails}
          <p styleName="toggleBold">
            <FormattedMessage {...messages.warningMessage} values={{ navianceLabel }} />
          </p>
          <p>
            {navianceLabel === 'Naviance' &&
              <FormattedMessage {...messages.navianceInstructions} values={{ navianceCRMLink, salesForceLink }} /> }
            {navianceLabel === 'Non-Naviance' &&
              <FormattedMessage {...messages.denavianceInstructions} values={{ salesForceLink }} /> }
          </p>
          <p styleName="toggleBold">
            <Icon name="exclamation triangle" color="red" />
            { navianceSubscriptionStatus === 'EXPIRED' && <FormattedMessage {...messages.secondWarningRenewMessage} /> }
            { navianceSubscriptionStatus !== 'EXPIRED' && <FormattedMessage {...messages.secondWarningMessage} values={{ navianceLabel }} /> }
          </p>
          <p>
            {navianceLabel === 'Naviance' &&
            <FormattedMessage {...messages.askForHelpNaviance} values={{ navianceLabel }} /> }
            {navianceLabel === 'Non-Naviance' &&
            <FormattedMessage {...messages.askForHelp} values={{ navianceLabel }} /> }
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button id="toggle-naviance-goback" onClick={this._handleGoBack}>
            <FormattedMessage {...messages.noButton} values={{ navianceLabel }} />
          </Button>
          <Button id="toggle-naviance-go" onClick={this._handleProceed} negative>
            <FormattedMessage {...messages.yesButton} values={{ navianceLabel }} />
          </Button>
        </Modal.Actions>
      </Dimmer.Dimmable>
    );
  }
}
const setNavianceHighSchool = graphql(SetNavianceHighSchoolMutation, {
  name: 'mutateSetNavianceHighSchool',
});
const setNonNavianceHighSchool = graphql(SetNonNavianceHighSchoolMutation, {
  name: 'mutateSetNonNavianceHighSchool',
});
export default SimpleGraphQLComponent(setNavianceHighSchool, setNonNavianceHighSchool)(ToggleNavianceModalComponent);
