import some from 'lodash/some';
import get from 'lodash/get';
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  setDisplayName,
} from 'react-recompose';
import { connect } from 'react-redux';
import * as constants from './constants';

export const isUserAdmin = (viewer) =>
  viewer.profile && viewer.profile.roles && constants.ADMIN_ROLES.some((role) => viewer.profile.roles.includes(role));

export const isUserSuperAdmin = (viewer) =>
  viewer.profile && viewer.profile.roles && viewer.profile.roles.includes(constants.SUPER_ADMIN_ROLE);

export const isUserSupport = (viewer) =>
  viewer.profile && viewer.profile.roles && viewer.profile.roles.includes(constants.SUPPORT_ROLE);

export const isUserSalesOps = (viewer) =>
  viewer.profile && viewer.profile.roles && viewer.profile.roles.includes(constants.SALES_OPS_ROLE);

export const isUserCommunity = (viewer) =>
  viewer.profile && viewer.profile.roles && viewer.profile.roles.includes(constants.COMMUNITY_ROLE);

export const isUserCommunityManager = (viewer) =>
  viewer.profile && viewer.profile.roles && viewer.profile.roles.includes(constants.COMMUNITY_MANAGER_ROLE);

export const isUserAuthenticated = (state) => get(state, 'current.viewer.isAuthenticated');

export const authorized = (options = {}) =>
  compose(
    connect((state) => ({ viewer: state.current.viewer })),
    setDisplayName('authorized'),
    branch(
      ({ viewer }) => !viewer.isAuthenticated || !some(get(viewer, 'profile.roles') || [], (each) => constants.APP_ROLES.includes(each)),
      renderComponent(options.failure || renderNothing()),
    ),
  );

export const authorizedWithRole = (roles, options = {}) =>
  compose(
    connect((state) => ({ viewer: state.current.viewer })),
    setDisplayName('authorizedWithRole'),
    branch(
      ({ viewer }) => !viewer.isAuthenticated || !some(get(viewer, 'profile.roles') || [], (each) => [].concat(roles)
        .includes(each)),
      renderComponent(options.failure || renderNothing()),
    ),
  );
