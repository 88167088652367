import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import pick from 'lodash/pick';
import Message from '../../components/Message';
import Modal from '../../components/Modal';
import Icon from '../../components/Icon';
import { messages, subscriptionTypes } from './constants';
import SubscriptionTypesComponent from './subscription-types';
import SubscriptionForm from './subscription-form';

import './styles.css';

class SubscriptionModal extends React.PureComponent {
  static propTypes = {
    heAccountId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subscription: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    createSubscription: PropTypes.func.isRequired,
    updateSubscription: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    subscriptionList: PropTypes.arrayOf(PropTypes.object),
    mutateHEAccount: PropTypes.func,
    moduleSubscriptions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      startDate: PropTypes.number.isRequired,
      endDate: PropTypes.number.isRequired,
      quoteChargeId: PropTypes.string,
      manuallyInactivated: PropTypes.bool,
    })),
    currentScid: PropTypes.string,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open && nextProps.subscription && prevState.subscriptionType !== nextProps.subscription.type) {
      return {
        subscriptionType: subscriptionTypes[nextProps.subscription.type.toUpperCase()],
        errorMessage: '',
      };
    } else if (!nextProps.open) {
      return {
        subscriptionType: subscriptionTypes.UNSELECTED,
        errorMessage: '',
      };
    }
    return prevState;
  }

  state = {
    subscriptionType: subscriptionTypes.UNSELECTED,
    errorMessage: '',
  }

  onSuccess = () => {
    this.handleClose();
  };

  onFailure = (error) => {
    /* istanbul ignore else */
    if (error && error.message) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleBack = () => {
    if (this.props.subscription) {
      this.handleClose();
    } else {
      this.setState({
        subscriptionType: subscriptionTypes.UNSELECTED,
        errorMessage: '',
      });
    }
  }

  handleClose = () => {
    this.setState({
      subscriptionType: subscriptionTypes.UNSELECTED,
      errorMessage: '',
    });
    this.props.onClose();
  }

  handleChangeType = (subscriptionType) => {
    this.setState({ subscriptionType });
  }

  saveSubscription = (amSubscription, updateAdvAwarenessModuleExpiration, updateConnectionModuleExpiration) => {
    const {
      heAccountId,
      subscription,
      createSubscription,
      updateSubscription,
    } = this.props;
    const { onFailure } = this;

    const afterSubscriptionSave = () => {
      this.updateModuleEndDate(amSubscription.endDate, updateAdvAwarenessModuleExpiration, updateConnectionModuleExpiration);
    };

    if (subscription) {
      updateSubscription({ variables: {
        input: { heAccountId, amSubscription },
      } })
        .then(afterSubscriptionSave, onFailure);
    } else {
      createSubscription({ variables: {
        input: { heAccountId, amSubscription },
      } })
        .then(afterSubscriptionSave, onFailure);
    }
  };

  findModule = (name) => {
    const { moduleSubscriptions = [] } = this.props;
    return moduleSubscriptions.find((module) => module.name === name);
  };

  updateModuleEndDate(endDate, updateAdvAwarenessModuleExpiration, updateConnectionModuleExpiration) {
    const {
      heAccountId,
      mutateHEAccount,
    } = this.props;
    const { onSuccess, onFailure } = this;

    const updatingModuleSubscriptions = [];

    const updateModuleSub = (moduleName) => {
      const moduleSub = this.findModule(moduleName);
      updatingModuleSubscriptions.push({
        ...pick(moduleSub, 'name', 'quoteChargeId', 'startDate', 'manuallyInactivated'),
        endDate,
      });
    };
    if (updateAdvAwarenessModuleExpiration) updateModuleSub('ADVANCEDAWARENESS');
    if (updateConnectionModuleExpiration) updateModuleSub('CONNECTION');

    if (updatingModuleSubscriptions.length) {
      mutateHEAccount({
        variables: {
          input: {
            heAccount: {
              id: heAccountId,
              moduleSubscriptions: updatingModuleSubscriptions,
            },
          },
        },
      }).then(onSuccess, onFailure);
    } else {
      onSuccess();
    }
  }

  renderHeader() {
    const { subscription, intl } = this.props;
    const { subscriptionType } = this.state;
    if (subscription) {
      return (
        <FormattedMessage
          {...messages.editSubscription}
          values={{ type: intl.formatMessage(messages[subscription.type]) }}
        />
      );
    } else if (subscriptionType === subscriptionTypes.UNSELECTED) {
      return <FormattedMessage {...messages.addNew} />;
    } else {
      return <FormattedMessage {...messages.addNewSubscription} values={{ type: intl.formatMessage(messages[subscriptionType]) }} />;
    }
  }

  render() {
    const { subscriptionType, errorMessage } = this.state;
    const { name, open, subscription, subscriptionList, currentScid } = this.props;
    const advAwarenessModuleSub = this.findModule('ADVANCEDAWARENESS');
    const connectionModuleSub = this.findModule('CONNECTION');
    const advAwarenessEndDate = advAwarenessModuleSub && advAwarenessModuleSub.status === 'active' ?
      advAwarenessModuleSub.endDate : undefined;
    const connectionEndDate = connectionModuleSub && connectionModuleSub.status === 'active' ?
      connectionModuleSub.endDate : undefined;

    return (
      <Modal
        styleName="subscription-modal"
        open={open}
        id="subscription-modal"
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        closeIcon={<Icon name="close" color="black" styleName="subscription-modal-close" />}
        dimmer
      >
        <Modal.Header as="h1">
          {
            this.renderHeader()
          }
          {` - ${name}`}
        </Modal.Header>
        <Modal.Content>
          {errorMessage && (
            <Message error>
              <Message.Content>
                {errorMessage}
              </Message.Content>
            </Message>
          )}
          {
            subscriptionType === subscriptionTypes.UNSELECTED ?
              <SubscriptionTypesComponent onTypeChange={this.handleChangeType} /> : (
                <SubscriptionForm
                  subscription={subscription}
                  subscriptionType={subscriptionType}
                  onBack={this.handleBack}
                  onFinish={this.saveSubscription}
                  subscriptionList={subscriptionList}
                  advAwarenessEndDate={advAwarenessEndDate}
                  connectionEndDate={connectionEndDate}
                  currentScid={currentScid}
                />
              )
          }
        </Modal.Content>
      </Modal>
    );
  }
}

export default injectIntl(SubscriptionModal);
