import compareAsc from 'date-fns/compareAsc';

export const checkIsActiveSubscription = (moduleSubscriptions = [], activeModule = '') =>
  moduleSubscriptions.find((sub) => sub.name === activeModule && sub.status === 'active');

export const getCurrentAcademicYearDates = () => {
  const today = new Date();
  const currentDate = today.getDate();
  const currentMonth = today.getMonth() + 1;
  let currentYear = today.getFullYear();
  if (currentMonth < 7 || (currentMonth === 7 && currentDate < 15)) {
    currentYear -= 1;
  }
  const nextYear = currentYear + 1;

  return { fromDate: `${currentYear}-07-15`, toDate: `${nextYear}-07-14` };
};

export const compareDeadlineDates = (a, b) =>
  compareAsc(new Date(a.formatedDate), new Date(b.formatedDate));

export default { getCurrentAcademicYearDates };
