import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate, FormattedTime, defineMessages, injectIntl } from 'react-intl';
import pick from 'lodash/pick';
import isMatch from 'lodash/isMatch';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import PageTitle from '../../components/PageTitle';
import Grid from '../../components/Grid';
import Dimmer from '../../components/Dimmer';
import Table from '../../components/Table';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import SearchControls from '../../components/SearchControl';
import Icon from '../../components/Icon';
import Header from '../../components/Header';
import './styles.css';

export const messages = defineMessages({
  loading: {
    id: 'navianceSyncLog.loading',
    defaultMessage: 'Loading...',
  },
});

class SyncLogHistory extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      navianceSyncLogs: PropTypes.array.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        nid: PropTypes.string.isRequired,
        timeframe: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    node: PropTypes.object,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search);
    this.state = { loading: false, ...query, ...props.match.params };
  }

  onChange = (changedValues) => {
    const query = {};
    const customQueryNames = ['from', 'to'];
    /* istanbul ignore next */
    const timeframe = changedValues.timeframe || this.state.timeframe;
    if (timeframe === 'custom') {
      Object.assign(query, pick(this.state, ...customQueryNames), pick(changedValues, ...customQueryNames));
    }
    /* istanbul ignore else */
    if (!isMatch(this.state, query) || timeframe !== this.state.timeframe) {
      this.pullLogs(timeframe, queryString.stringify(query));
      this.setState({ loading: true, ...changedValues });
    }
  };

  pullLogs(timeframe, search) {
    const { match, history } = this.props;
    const location = {
      state: {},
      pathname: `/naviance-sync-log/${match.params.id}/${match.params.nid}/${timeframe}/`,
      search,
    };
    history.push(location);
  }

  render() {
    const { viewer, intl, match, node = {} } = this.props;
    const { loading } = this.state;
    const logHistory = viewer.navianceSyncLogs;

    return (
      <div>
        <PageTitle title="Naviance Sync Log" category="Support" />
        <Link to={`/hs-account/${match.params.id}/`}>
          <Icon name="angle left" />
          <FormattedMessage id="navianceSyncLog.back" defaultMessage="Back" />
        </Link>
        <Header styleName="header" as="h1" data-cy="hs-sync-log-title-name">
          <FormattedMessage
            id="navianceSyncLog.header"
            defaultMessage="Client: {name} Naviance Sync Log"
            values={{
              name: get(node, 'name'),
            }}
          />
        </Header>
        <Grid columns={4} stackable doubling>
          <SearchControls {...this.state} hideSearch onChange={this.onChange} />
        </Grid>
        {!logHistory.length ? (
          <Message info data-cy="hs-sync-log-no-data-message">
            <FormattedMessage id="navianceSyncLog.noResults" defaultMessage="No Data" />
          </Message>
        ): (
          <Dimmer.Dimmable>
            <Dimmer
              role="progressbar"
              aria-describedby={intl.formatMessage(messages.loading)}
              aria-busy={loading}
              inverted
              active={loading}
            >
              <Loader active={loading} />
            </Dimmer>
            <Table>
              <caption><FormattedMessage id="navianceSyncLog.caption" defaultMessage="Sync Log Results" /></caption>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="navianceSyncLog.header.action" defaultMessage="Action" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="navianceSyncLog.header.scid" defaultMessage="SCID" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="navianceSyncLog.header.visitDate" defaultMessage="Visit Date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="navianceSyncLog.header.processResult" defaultMessage="Processing Result" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="navianceSyncLog.header.representative" defaultMessage="Representative" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="navianceSyncLog.header.processedDate" defaultMessage="Processed Date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="navianceSyncLog.header.externalId" defaultMessage="External Id" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {logHistory.map((logEntry) =>
                  (
                    <Table.Row key={logEntry.id}>
                      <Table.Cell>
                        {logEntry.eventType}
                      </Table.Cell>
                      <Table.Cell>
                        {logEntry.scid}
                      </Table.Cell>
                      <Table.Cell>
                        {logEntry.visitDate && (
                          <span>
                            <FormattedDate value={logEntry.visitDate} /> &nbsp;
                            <FormattedTime hour="numeric" minute="numeric" second="numeric" value={logEntry.visitDate} />
                          </span>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {logEntry.result}
                      </Table.Cell>
                      <Table.Cell>
                        {logEntry.representative}
                      </Table.Cell>
                      <Table.Cell>
                        <FormattedDate value={logEntry.processedDate} />&nbsp;
                        <FormattedTime hour="numeric" minute="numeric" second="numeric" value={logEntry.processedDate} />
                      </Table.Cell>
                      <Table.Cell>
                        {logEntry.externalId}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Dimmer.Dimmable>
        )}
      </div>
    );
  }
}

export default injectIntl(SyncLogHistory);
