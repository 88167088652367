import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import { FormattedMessage, defineMessages } from 'react-intl';
import { SimpleGraphQLComponent } from '../../utils/apollo';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Dimmer from '../../components/Dimmer';
import Message from '../../components/Message';
import updateHEUserMutation from '../../mutations/HEAccount/updateHEUser';

const messages = defineMessages({
  error: {
    id: 'unlock.user.error',
    defaultMessage: 'Unable to unlock user',
  },
  unlockUser: {
    id: 'unlock.user.header',
    defaultMessage: 'Unlock User',
  },
  unlockUserText: {
    id: 'unlock.user.text',
    defaultMessage: 'Would you like to unlock {userName} for {accountName}?',
  },
  noButton: {
    id: 'common.button.no',
    defaultMessage: 'No',
  },
  yesButton: {
    id: 'common.button.yes',
    defaultMessage: 'Yes',
  },
});

export class UnlockUserComponent extends React.PureComponent {
  static propTypes = {
    heAccount: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    mutateUpdateHEUser: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    isError: false,
    requesting: false,
  };

  _handleClose = () => {
    this.setState({ requesting: false });
    this.props.onClose();
  };

  _handleSave = () => {
    const { user, heAccount } = this.props;

    this.setState({ isError: false, requesting: true });
    this.props.mutateUpdateHEUser({
      variables: {
        input: {
          heAccount: {
            id: heAccount.id,
            user: {
              id: user.id,
              lockStatus: JSON.stringify({
                locked: false,
                invalidAttempts: [],
              }),
            },
          },
        },
      },
    }).then(this._handleClose, () => {
      this.setState({ isError: true, requesting: false });
    });
  };

  render() {
    const { user, heAccount } = this.props;
    const { isError, requesting } = this.state;
    const userName = `${user.firstName} ${user.lastName}`;
    const errorDetails = isError ? (
      <Message role="alert" negative>
        <Message.Header><FormattedMessage {...messages.error} /></Message.Header>
      </Message>
    ) : '';

    return (
      <Dimmer.Dimmable as={Modal} open onClose={this._handleClose}>
        <Dimmer
          role="progressbar"
          aria-describedby="Unlocking User..."
          aria-busy={requesting}
          inverted
          active={requesting}
        >
          <Loader active={requesting} />
        </Dimmer>
        <Header data-cy="header-unlock-user-label">
          <FormattedMessage {...messages.unlockUser} />
        </Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              {...messages.unlockUserText}
              values={{
                userName,
                accountName: heAccount.name,
              }}
            />
          </p>
          {errorDetails}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this._handleClose} data-cy="he-account-unlock-user-no-button">
            <Icon name="remove" />
            <FormattedMessage {...messages.noButton} />
          </Button>
          <Button positive onClick={this._handleSave} data-cy="he-account-unlock-user-yes-button">
            <Icon name="checkmark" />
            <FormattedMessage {...messages.yesButton} />
          </Button>
        </Modal.Actions>
      </Dimmer.Dimmable>
    );
  }
}

const updateHEUser = graphql(updateHEUserMutation, {
  name: 'mutateUpdateHEUser',
});

export default SimpleGraphQLComponent(updateHEUser)(UnlockUserComponent);
