import React from 'react';
import './styles.css';
import Grid from '../Grid';

const Footer = () => (
  <Grid verticalAlign="middle" textAlign="center" styleName="container">
    <Grid.Row only="computer">
      <Grid.Column width={5} textAlign="left" />
      <Grid.Column width={5}>&copy; {new Date().getFullYear()} PowerSchool Group LLC. All Rights Reserved.</Grid.Column>
      <Grid.Column width={3} textAlign="right">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://static.intersect.hobsons.com/terms.html"
        >Terms of Service
        </a>
      </Grid.Column>
      <Grid.Column width={3}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.powerschool.com/privacy/"
        >Privacy Statement
        </a>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default Footer;
