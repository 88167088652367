import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../components/PageTitle';
import Segment from '../../components/Segment';
import Header from '../../components/Header';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Grid from '../../components/Grid';
import RadioRenderer from '../UserExportTool/RadioRenderer';

import DownloadClients from './DownloadClients';

import messages from './messages';
import './styles.css';

export const fields = [
  {
    name: 'institution-type',
    label: 'Institution Type',
    defaultValue: 'higher-ed',
    options: [
      {
        name: 'high-school',
        label: 'High School',
        disabled: true,
      },
      {
        name: 'higher-ed',
        label: 'Higher Ed',
      },
    ],
    renderer: RadioRenderer,
  },
];

class ClientExportTool extends React.PureComponent {
  static propTypes = {
    token: PropTypes.string,
  };

  state = {
    fetching: false,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      fetching: true,
    });
  }

  handleInputChange = ({ name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  handleDownloadClose = () => {
    this.setState({
      fetching: false,
    });
  }

  renderForm() {
    return fields.map((field) => {
      const { name, renderer: Renderer } = field;
      const value = _get(this.state, name);
      return (
        <Renderer
          key={name}
          {...field}
          onChange={this.handleInputChange}
          value={value}
        />
      );
    });
  }

  render() {
    const { fetching } = this.state;
    return (
      <Segment>
        <PageTitle title="Home" category="AdminDashboard" />
        <Grid styleName="client-export-tool">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2" data-cy="client-export-title-text">Intersect Client Export Tool</Header>
              <p>Download HE or HS client accounts.</p>

              <Form onSubmit={this.handleSubmit}>
                { this.renderForm() }
                <Button primary type="submit" styleName="btn-submit" data-cy="client-export-download-button">
                  <FormattedMessage {...messages.downloadButton} />
                </Button>
              </Form>
              <DownloadClients
                doFetch={fetching}
                onClose={this.handleDownloadClose}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default ClientExportTool;
