import * as constants from './constants';
import { createReducer } from '../../utils';

const initialState = {
  searchCategory: constants.SEARCH_ALL,
};

export default createReducer(initialState, {
  [constants.SEARCH_CATEGORY_SELECTED]: (state, payload) => ({
    ...state, searchCategory: payload,
  }),
});
