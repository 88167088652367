import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { FormattedMessage } from 'react-intl';
import Table from '../../components/Table';
import messages from './messages';

const RepvisitsAccounts = ({ name, repVisitsAccounts }) => (
  <React.Fragment>
    <h3>
      <FormattedMessage
        id="hs-account.repvisits-accounts.caption"
        defaultMessage="{name}'s Repvisit Accounts"
        values={{
          name,
        }}
      />
    </h3>
    <Table>
      <Table.Header>
        <Table.Row data-cy="repvisits-accounts-table-headers">
          <Table.HeaderCell>
            <FormattedMessage {...messages.nguid} />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage {...messages.ncesId} />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage {...messages.nid} />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage {...messages.naviance} />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage {...messages.setupComplete} />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage {...messages.availabilityVisible} />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage {...messages.schoolYear} />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { repVisitsAccounts ?
          repVisitsAccounts.map((repVisitsAcc) => (
            <RepVisitAccountRow
              key={repVisitsAcc.id}
              account={repVisitsAcc}
            />
          )) :
          null}
      </Table.Body>
    </Table>
  </React.Fragment>
);

const repVisitAccountPropType = PropTypes.shape({
  id: PropTypes.string,
  ncesId: PropTypes.string,
  nguid: PropTypes.string,
  nid: PropTypes.string,
  availabilityVisible: PropTypes.bool,
  schoolYear: PropTypes.string,
  firstVisitDay: PropTypes.string,
  lastVisitDay: PropTypes.string,
  naviance: PropTypes.bool,
  setupComplete: PropTypes.bool,
});

RepvisitsAccounts.propTypes = {
  repVisitsAccounts: PropTypes.arrayOf(repVisitAccountPropType),
  name: PropTypes.string,
};

export const RepVisitAccountRow = ({ account }) => (

  <Table.Row data-cy="repvisits-accounts-table-row">
    <Table.Cell>{account.nguid ? account.nguid : '-'}</Table.Cell>
    <Table.Cell>{account.ncesId ? account.ncesId : '-'}</Table.Cell>
    <Table.Cell>{account.nid ? account.nid : '-'}</Table.Cell>
    <Table.Cell>{account.naviance ? 'Yes': 'No'}</Table.Cell>
    <Table.Cell>{account.setupComplete ? 'Yes': 'No'}</Table.Cell>
    <Table.Cell>{account.availabilityVisible ? 'Yes': 'No'}</Table.Cell>
    <Table.Cell>{account.firstVisitDay} - {account.lastVisitDay}</Table.Cell>
  </Table.Row>
);

RepVisitAccountRow.propTypes = {
  account: repVisitAccountPropType,
};

export default RepvisitsAccounts;
