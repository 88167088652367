import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import updateHSUserMutation from '../../mutations/HSAccount/updateHSUser';
import { SimpleLoadingComponent } from '../../utils/apollo';
import UpdatePrimaryUserPage from './update-primary-user-page';
import hsUserDetails from '../../queries/hsUserDetails';

const query = gql`
  query HSAccountDetails($id: ID!) {
    node(id: $id) {
      id
      ...on HSAccount {
        id
        name
        primaryUser {
          ...HSUserDetails
        }
      }
    }
  }
  ${hsUserDetails}
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
});
const updateHSUser = graphql(updateHSUserMutation, {
  name: 'mutateUpdateHSUser',
});

export default withRouter(SimpleLoadingComponent(graphqlData, updateHSUser)(UpdatePrimaryUserPage));
