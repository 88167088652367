import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import Form from '../../components/Form';
import Radio from '../../components/Radio';
import Header from '../../components/Header';

class RadioRenderer extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    onChange: _noop,
  };

  handleChange = (e, props) => {
    e.preventDefault();
    const { name } = props;
    const { name: oName, onChange } = this.props;
    onChange({
      name: oName,
      value: name,
    });
  }

  render() {
    const { label: fieldLabel, options, value: fieldValue, defaultValue } = this.props;
    const value = fieldValue || defaultValue;
    return (
      <React.Fragment>
        <Header as="h3">{fieldLabel}</Header>
        { options.map((option) => {
          const { name, label, disabled } = option;
          const rId = `${fieldLabel}-${name}`;
          return (
            <Form.Field key={name}>
              <Radio
                id={rId}
                data-cy={`${fieldLabel}-${name}`}
                key={rId}
                name={name}
                label={label}
                onChange={this.handleChange}
                checked={name === value}
                disabled={disabled}
              />
            </Form.Field>
          );
        })}
      </React.Fragment>
    );
  }
}

export default RadioRenderer;
