/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';

function getFieldToCompare(a) {
  const { state } = a;
  if (state) {
    return state.toLowerCase();
  }
  const { county } = a;
  if (county) {
    return (county.state + county.county).toLowerCase();
  }
  const { zipCode } = a;
  if (zipCode) {
    return (zipCode + String(zipCode.radius)).toLowerCase();
  }
  return a.sector.toLowerCase();
}

function sortSubscriptionsCompare(a, b) {
  const startDateA = get(a, 'node.startDate');
  const startDateB = get(b, 'node.startDate');

  if (startDateA < startDateB) {
    return 1;
  } else if (startDateA > startDateB) {
    return -1;
  }

  const audienceA = get(a, 'node.audience');
  const audienceB = get(b, 'node.audience');

  const targetA = getFieldToCompare(audienceA);
  const targetB = getFieldToCompare(audienceB);
  if (targetA < targetB) return -1;
  else if (targetA > targetB) {
    return 1;
  }
  return 0;
}

export function sortSubscriptions(list) {
  return list.sort(sortSubscriptionsCompare);
}
/* eslint-enable import/prefer-default-export */
