import React from 'react';
import PageTitle from '../../components/PageTitle';

const Home = () => (
  <div>
    <PageTitle title="Home" category="Intersect" />
    <p>Welcome to the Support Application</p>
  </div>
);

export default Home;
