import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import Accordion from '../../components/Accordion';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';

import styles from '../AdvancedSearch/styles.css';
import stateOptions from '../AdvancedPeopleSearchBox/state-options';

const messages = defineMessages({
  name: {
    id: 'search-results.highered.name',
    defaultMessage: 'Name',
  },
  location: {
    id: 'search-results.highschool.locationGroup',
    defaultMessage: 'Location',
  },
  city: {
    id: 'search-results.highered.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'search-results.highered.state',
    defaultMessage: 'State',
  },
  postalCode: {
    id: 'search-results.highered.postalCode',
    defaultMessage: 'Postal Code',
  },
  collegeType: {
    id: 'search-results.collegeType',
    defaultMessage: 'College Type',
  },
  controlType: {
    id: 'search-results.highered.controlType',
    defaultMessage: 'Public or Private',
  },
  degree: {
    id: 'search-results.highered.degree',
    defaultMessage: 'Major', // switched to use majors from the CMS college_full index
  },
});

const collegeTypeOptions = [
  { text: 'All', value: '' },
  { text: 'Vocational/Technical', value: 'VOCATIONAL' },
  { text: 'Community', value: 'COMMUNITY' },
  { text: 'Standard (4 Year)', value: 'STANDARD' },
];

const controlTypeOptions = [
  { text: 'All', value: '' },
  { text: 'Public', value: 'PUBLIC' },
  { text: 'Private For Profit', value: 'PRIVATE_FORPROFIT' },
  { text: 'Private Non Profit', value: 'PRIVATE_NONPROFIT' },
];

function isLocationActive(advanced) {
  return advanced.city || advanced.state || advanced.postalCode;
}

class AdvancedHigherEdSearchBox extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    advanced: PropTypes.shape({
      name: PropTypes.string,
      degree: PropTypes.string,
      type: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      control: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { onChange, advanced } = this.props;
    return (
      <div>
        <div styleName="heContainer">
          <label htmlFor="name"><FormattedMessage {...messages.name} /></label>
          <Input styleName="keywordInput" type="text" name="name" fluid onChange={onChange}>
            <input id="name" name="name" value={advanced.name} />
          </Input>
          <div styleName="dropdownContainer">
            <div styleName="dropdownLabel"><FormattedMessage {...messages.collegeType} /></div>
            <div styleName="dropdownInput"><Dropdown
              id="he-type"
              placeholder="All"
              name="type"
              value={advanced.type}
              onChange={onChange}
              fluid
              selection
              options={collegeTypeOptions}
            />
            </div>
            <div styleName="dropdownLabel"><FormattedMessage {...messages.controlType} /></div>
            <div styleName="dropdownInput"><Dropdown
              id="he-control"
              placeholder="All"
              name="control"
              value={advanced.control}
              onChange={onChange}
              fluid
              selection
              options={controlTypeOptions}
            />
            </div>
          </div>
          <label htmlFor="he-degree"><FormattedMessage {...messages.degree} /></label>
          <Input styleName="keywordInput" type="text" fluid onChange={onChange}>
            <input id="he-degree" name="degree" value={advanced.degree} />
          </Input>
        </div>
        <Accordion
          defaultActiveIndex={isLocationActive(advanced) ? 0 : -1}
          data-cy="he-location-accordion"
          panels={[{
            key: 'location',
            title: {
              className: styles.categoryTitle,
              children: (
                <span>
                  <FormattedMessage {...messages.location} />
                  <Icon name="chevron left" />
                </span>
              ),
            },
            content: {
              className: styles.institutionContainer,
              children: (
                <div>
                  <label htmlFor="he-city"><FormattedMessage {...messages.city} /></label>
                  <Input styleName="keywordInput" type="text" fluid onChange={onChange}>
                    <input id="he-city" name="city" value={advanced.city} />
                  </Input>
                  <div styleName="dropdownContainer">
                    <div styleName="dropdownLabel"><FormattedMessage {...messages.state} /></div>
                    <div styleName="dropdownInput"><Dropdown
                      placeholder="Select State"
                      id="he-state"
                      name="state"
                      onChange={onChange}
                      value={advanced.state}
                      fluid
                      selection
                      options={stateOptions}
                    />
                    </div>
                  </div>
                  <label htmlFor="he-postalCode"><FormattedMessage {...messages.postalCode} /></label>
                  <Input styleName="lastFieldInput" type="text" fluid onChange={onChange}>
                    <input id="he-postalCode" name="postalCode" value={advanced.postalCode} />
                  </Input>
                </div>
              ),
            },
          }]}
        />
      </div>
    );
  }
}

export default AdvancedHigherEdSearchBox;
