import gql from 'graphql-tag';

export default gql`
  mutation RemoveFeatureFlag($input: removeFeatureFlagInput!) {
    removeFeatureFlag(input: $input) {
      viewer {
        id
        enabledFeatures
      }
    }
  }
`;
