import gql from 'graphql-tag';

export default gql`
  fragment HEUserDetails on HEUser {
    id
    uuid
    firstName
    lastName
    role
    email
    lastLogin
    active
    created
    institution
    lockStatus
    userStatus
    communityProfiles {
      id
      communityId
      field_first_name
      field_last_name
      isActive
      institutionId
      field_institution_type_name
      field_user_institution_hs_na_nces_id
      field_user_institution_uuid
      profileComplete
      mail
    }
  }
`;
