import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const CommunityInstIds = ({ communityProfile }) => (
  <div>
    <div styleName="nowrap">{communityProfile.field_institution_type_name}</div>
    {
      communityProfile.institutionId &&
      <div styleName="nowrap"><b>SCID</b>: {communityProfile.institutionId}</div>
    }
    {
      communityProfile.field_user_institution_hs_na_nces_id &&
      <div styleName="nowrap"><b>NCES</b>: {communityProfile.field_user_institution_hs_na_nces_id}</div>
    }
    {
      communityProfile.field_user_institution_uuid &&
      communityProfile.field_user_institution_uuid !== communityProfile.field_user_institution_hs_na_nces_id &&
      <div styleName="nowrap"><b>UUID</b>: {communityProfile.field_user_institution_uuid}</div>
    }
  </div>
);

CommunityInstIds.propTypes = {
  communityProfile: PropTypes.shape({
    institutionId: PropTypes.string,
    field_user_institution_hs_na_nces_id: PropTypes.string,
    field_user_institution_uuid: PropTypes.string,
    field_institution_type_name: PropTypes.string,
  }).isRequired,
};

export default CommunityInstIds;
