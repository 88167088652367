import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Immutable from 'immutable';
import get from 'lodash/get';

import Grid from '../../components/Grid';
import List from '../../components/List';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { isUserAdmin, isUserSupport, isUserSalesOps } from '../Authorization';
import * as appRoles from '../Authorization/constants';

import './styles.css';

import ModuleSubscriptionsTable from '../ModuleSubscriptionsTable';
import ActionsGridColumn from './he-account-actions-grid-column';
import { FeatureFlags } from '../../utils/features';

class HEAccountDetails extends React.PureComponent {
  static propTypes = {
    current: PropTypes.object.isRequired,
    node: PropTypes.object,
    mutateHEAccount: PropTypes.func.isRequired,
    linkBack: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  };

  constructor(props) {
    super(props);
    const { viewer } = props.current;
    this.state = {
      heAccount: Immutable.fromJS(this.props.node),
      canEdit: isUserAdmin(viewer) || isUserSupport(viewer) || isUserSalesOps(viewer),
      failure: null,
      saving: false,
      editing: false,
    };
    this.originalHeAccount = this.state.heAccount;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ node }) {
    this.originalHeAccount = Immutable.fromJS(node);
    this.setState({
      heAccount: this.originalHeAccount,
    });
  }

  updateHEAccount(changes) {
    const { mutateHEAccount } = this.props;

    return mutateHEAccount({
      variables: {
        input: {
          heAccount: changes,
        },
      },
    });
  }

  saveChanges = () => {
    const { originalHeAccount } = this;
    const originalModuleNodes = originalHeAccount.get('moduleSubscriptions');
    const { heAccount } = this.state;
    const moduleSubscriptions = heAccount.get('moduleSubscriptions');
    this.setState({ saving: true });

    const modules2update = moduleSubscriptions.reduce((results, moduleNode, index) => {
      if (!Immutable.is(moduleNode, originalModuleNodes.get(index))) {
        const originalStatus = originalModuleNodes.get(index).get('status');
        const statusChange = moduleNode.get('status');
        let manuallyInactivated = moduleNode.get('manuallyInactivated') !== undefined ? moduleNode.get('manuallyInactivated') : false;
        manuallyInactivated = statusChange && statusChange !== originalStatus ? statusChange === 'inactive' : manuallyInactivated;
        // Create object with just the valid input properties for the mutation
        results.push({
          name: moduleNode.get('name'),
          quoteChargeId: moduleNode.get('quoteChargeId'),
          startDate: moduleNode.get('startDate'),
          endDate: moduleNode.get('endDate'),
          manuallyInactivated,
        });
      }
      return results;
    }, []);
    const changes = { id: heAccount.get('id') };
    if (modules2update.length > 0) {
      changes.moduleSubscriptions = modules2update;
    }
    if (!Immutable.is(heAccount.get('radiusId'), originalHeAccount.get('radiusId'))) {
      changes.radiusId = heAccount.get('radiusId');
    }
    if (!Immutable.is(heAccount.get('connectId'), originalHeAccount.get('connectId'))) {
      changes.connectId = heAccount.get('connectId');
    }
    this.updateHEAccount(changes).then(() => {
      this.setState({ failure: null, saving: false, editing: false });
    }).catch((error) => {
      const failure = (
        <FormattedMessage
          id="modules-table.failed-to-save-changes"
          description="Failure Message"
          defaultMessage="Failed to save changes, please try again later."
        />
      );
      this.setState({ failure, saving: false });
    });
  };

  // Changes to a moduleSubscription are stored in the 'changes' property of the state object.
  handleModuleSubscriptionsChange = (moduleNode, moduleChanges) => {
    const { heAccount } = this.state;
    const moduleSubscriptions = heAccount.get('moduleSubscriptions');
    const index = moduleSubscriptions.indexOf(moduleNode);
    this.setState({
      failure: null,
      heAccount: heAccount.set('moduleSubscriptions', moduleSubscriptions.mergeIn([index], moduleChanges)),
    });
  };

  // Changes to a moduleSubscription are stored in the 'changes' property of the state object.
  handleAccountChange = (accountChanges) => {
    const { heAccount } = this.state;
    this.setState({
      failure: null,
      heAccount: heAccount.merge(accountChanges),
    });
  };

  cancelChanges = () => {
    this.setState({ failure: null, editing: false, heAccount: this.originalHeAccount });
  };

  startEditing = () => {
    this.setState({ editing: true });
  };

  handleCreateUser = () => {
    const { linkBack } = this.props;
    const { heAccount } = this.state;
    this.props.history.push({ pathname: `/he-account/${heAccount.get('id')}/create-primary-user`, state: { linkBack } });
  };

  handleEditUser = () => {
    const { linkBack } = this.props;
    const { heAccount } = this.state;
    this.props.history.push({ pathname: `/he-account/${heAccount.get('id')}/update-primary-user`, state: { linkBack } });
  };

  render() {
    const { heAccount, canEdit, failure, saving, editing } = this.state;
    const { linkBack, current, enabledFeatures } = this.props;
    const { originalHeAccount } = this;
    if (!heAccount || !heAccount.get('id')) {
      return (
        <p>
          <FormattedMessage id="he-account.notFound" defaultMessage="No Higher Ed Account Found" />
        </p>
      );
    } else {
      const collegeCore = heAccount.get('collegeCore');
      const address = collegeCore && collegeCore.get('address');
      const addressObj = address ? address.toJS() : {};
      const primaryUser = heAccount.get('primaryUser');
      const firstName = primaryUser ? primaryUser.get('firstName') : null;
      const lastName = primaryUser ? primaryUser.get('lastName') : null;
      const email = primaryUser ? primaryUser.get('email') : null;
      const moduleNodes = heAccount.get('moduleSubscriptions');
      const savedModuleNodes = this.originalHeAccount.get('moduleSubscriptions');
      const errorDetails = failure ? (
        <Message compact role="alert" negative>
          {failure}
        </Message>
      ) : '';
      const role = get(current, 'viewer.profile.roles[0]');
      const allowCommunity = role === appRoles.COMMUNITY_ROLE && (enabledFeatures || []).includes(FeatureFlags.HEALTH_REPORT);
      const enableActions = allowCommunity || appRoles.NON_VIEW_ONLY_ROLES.includes(role);

      return (
        <div>
          <Header as="h1">Client: {heAccount.get('name')}</Header>
          <Grid columns="equal" stackable>
            <Grid.Row centered>
              <Grid.Column as="section" data-cy="college-details-section">
                <Header as="h2">
                  <FormattedMessage id="he-account.details.header" defaultMessage="Details" />
                </Header>
                <div className="vcard">
                  <div className="fn org">{heAccount.get('name')}</div>
                  <div className="adr">
                    <div className="street-address">{get(addressObj, 'street', '')}</div>
                    <div className="extended-address">{get(addressObj, 'street2', '')}</div>
                    <span className="locality">{get(addressObj, 'city', '')}</span>,&nbsp;
                    <span className="region">{get(addressObj, 'state', '')}</span>&nbsp;
                    <span className="postal-code">{get(addressObj, 'postalCode', '')}</span>
                    <div>
                      <div className="connect-id">{heAccount.get('connectId')}</div>
                      <div className="radius-id">{heAccount.get('radiusId')}</div>
                    </div>
                  </div>
                </div>
                <div className="scid">SCID: {heAccount.get('scid')}</div>
              </Grid.Column>
              <Grid.Column as="section" data-cy="primary-user-details-section">
                <Header as="h2">
                  <FormattedMessage id="he-account.primaryUserDetails.header" defaultMessage="Primary User Details" />
                  {primaryUser && canEdit ? (
                    <Button floated="right" onClick={this.handleEditUser}>
                      <FormattedMessage
                        id="modules-table.button.editPrimary"
                        description="Button to edit primary user details"
                        defaultMessage="Edit"
                      />
                    </Button>
                  ) : ''}
                  {!primaryUser && canEdit ? (
                    <Button floated="right" onClick={this.handleCreateUser} data-cy="he-account-create-primary-user-button">
                      <FormattedMessage
                        id="modules-table.button.createPrimary"
                        description="Button to create primary user"
                        defaultMessage="Create"
                      />
                    </Button>
                  ) : ''}
                </Header>
                { primaryUser ? (
                  <List as="dl">
                    <List.Item data-cy="primary-user-name" as="dt" icon="user" content={`${firstName} ${lastName}`} />
                    <List.Item data-cy="primary-email" as="dt" icon="mail" content={<a href={`mailto:${email}`}>{email}</a>} />
                    <List.Item data-cy="primary-role" as="dt" icon="key" content={primaryUser.get('role')} />
                  </List>
                ): <FormattedMessage id="he-account.primaryUser.notFound" defaultMessage="No Primary User Found" />}
              </Grid.Column>
              { enableActions && <ActionsGridColumn node={heAccount} linkBack={linkBack} /> }
            </Grid.Row>
          </Grid>
          <hr />

          <ModuleSubscriptionsTable
            moduleNodes={moduleNodes}
            savedModuleNodes={savedModuleNodes}
            onChange={this.handleModuleSubscriptionsChange}
            canEdit={canEdit}
            heAccountId={heAccount.get('id')}
            scholarshipsMatchStartDate={heAccount.get('scholarshipsMatchStartDate')}
          />
          { (!Immutable.is(originalHeAccount, heAccount) || editing || errorDetails) && (
          <div styleName="buttons-div">
            {errorDetails}
            <Loader inline size="small" active={saving} styleName="loader">Saving...</Loader>
            { !Immutable.is(originalHeAccount, heAccount) && (
            <Button data-cy="he-module-save-button" primary floated="right" disabled={saving} onClick={this.saveChanges}>
              <FormattedMessage
                id="modules-table.button.saveChanges"
                description="Button to save changes"
                defaultMessage="Save Changes"
              />
            </Button>
            )}
            <Button className="js-button-cancel" floated="right" disabled={saving} onClick={this.cancelChanges}>
              <FormattedMessage
                id="modules-table.button.cancelChanges"
                description="Button to cancel changes"
                defaultMessage="Cancel Changes"
              />
            </Button>
          </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  current: state.current,
});

export default withRouter(connect(mapStateToProps)(HEAccountDetails));
