import React from 'react';
import PropTypes from 'prop-types';
import Table from '../Table';
import getSubscriptionsList, {
  getLocation,
  getDiversity,
  getCompetitors,
} from '../../containers/HESubscriptionModal/utils';
import SubscriptionRow from './SubscriptionRow';

import './styles.css';

const SubscriptionsTable = (props) => {
  const { onClickDelete, subscriptions, onLocationClick, activeSubscriptions, isUserAdmin, canEdit, removeDiversityFilters } = props;

  const list = getSubscriptionsList(subscriptions);

  const handleLocationClick = (e, id) => {
    const subscription = list.find((sub) => sub.id === id);

    /* istanbul ignore else */
    if (subscription) {
      onLocationClick(e, subscription);
    }
  };

  function renderRows() {
    return list.map((sub) => {
      const {
        id,
        majors,
        startDate,
        endDate,
        diversity,
        connection,
        competitors,
        lastModifiedDate,
        lastModifiedBy,
      } = sub;

      const rowProps = {
        onLocationClick: handleLocationClick,
        location: getLocation(sub),
        diversity: getDiversity(diversity),
        competitors: getCompetitors(competitors),
        onClickDelete,
        id,
        majors,
        startDate,
        endDate,
        connection,
        lastModifiedDate,
        lastModifiedBy,
        activeSubscriptions,
        isUserAdmin,
        canEdit,
        removeDiversityFilters,
      };

      return <SubscriptionRow key={id} {...rowProps} />;
    });
  }
  return (
    <Table className="subscriptions-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Location</Table.HeaderCell>
          {!removeDiversityFilters && <Table.HeaderCell>Diversity</Table.HeaderCell>}
          <Table.HeaderCell>Competitors</Table.HeaderCell>
          <Table.HeaderCell>Majors</Table.HeaderCell>
          <Table.HeaderCell>Connect</Table.HeaderCell>
          <Table.HeaderCell>Start Date </Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
          <Table.HeaderCell>Last Modified</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { renderRows() }
      </Table.Body>
    </Table>
  );
};

SubscriptionsTable.propTypes = {
  isUserAdmin: PropTypes.bool,
  canEdit: PropTypes.bool,
  onClickDelete: PropTypes.func,
  subscriptions: PropTypes.array,
  onLocationClick: PropTypes.func,
  activeSubscriptions: PropTypes.array,
  removeDiversityFilters: PropTypes.bool,
};

SubscriptionsTable.defaultProps = {
  activeSubscriptions: [],
};

export default SubscriptionsTable;
