import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

export default class YearMonthForm extends React.Component {
  static propTypes = {
    date: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    localeUtils: PropTypes.instanceOf(Object),
  };

  handleChange = (e) => {
    const { year, month } = e.target.form;
    this.props.onChange(new Date(year.value, month.value));
  };

  render() {
    const { date, localeUtils } = this.props;
    const months = localeUtils.getMonths();
    const years = [];
    const currentYear = (new Date()).getFullYear();
    const fromMonth = new Date(currentYear - 1, 0, 1, 0, 0);
    const toMonth = new Date(currentYear + 12, 11, 31, 23, 59);

    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    return (
      <form className="DayPicker-Caption">
        <select id="month-select" name="month" onChange={this.handleChange} value={date.getMonth()} styleName="datePickerSelect">
          { months.map((month, i) => <option key={month} value={i}>{month}</option>) }
        </select>
        <select id="year-select" name="year" onChange={this.handleChange} value={date.getFullYear()} styleName="datePickerSelect">
          { years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  }
}
