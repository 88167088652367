import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import './styles.css';

const linkableCategories = ['people', 'institutions', 'posts', 'groups'];

const getCategoryRenderer = (searchFilter, isDisabledLegacyCommunity) => {
  const categoryRenderer = ({ name }) => {
    const lowerName = name.toLowerCase();
    const pathname = isDisabledLegacyCommunity ? `/counselorcommunity/search/${lowerName}` : `/search-results/${lowerName}`;
    if (linkableCategories.includes(lowerName)) {
      return (
        <div>
          <div data-cy={`GlobalSearch-Category-${name}`} className="name">{name}</div>
          <Link
            to={{ pathname, search: queryString.stringify({ keyword: searchFilter }) }}
            onClick={() => window.dispatchEvent(new Event('counselor-community-serverless-update-route'))}
          >
            More...
          </Link>
        </div>
      );
    }
    return (<div data-cy={`GlobalSearch-Category-${name}`} className="name">{name}</div>);
  };

  categoryRenderer.propTypes = {
    name: PropTypes.string,
  };
  return categoryRenderer;
};

export default getCategoryRenderer;
