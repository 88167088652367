import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { defineMessages, FormattedMessage } from 'react-intl';

import List from '../../components/List';
import SearchResult from './search-result';

import './styles.css';

export const messages = defineMessages({
  noResults: {
    id: 'search.college.byNameOrSCID.noResults',
    defaultMessage: 'No results found for \'{searchText}\'',
  },
  searchFor: {
    id: 'search.college.byNameOrSCID.searchFor',
    defaultMessage: 'Search for \'{searchText}\'',
  },
});

export const SEARCH_RESULTS_ID = 'search-college-results';

export default class SearchResults extends React.PureComponent {
  static propTypes = {
    searchText: PropTypes.string,
    viewer: PropTypes.object,
    onItemClick: PropTypes.func,
    selectedIndex: PropTypes.number,
  };

  render() {
    const { onItemClick, selectedIndex, searchText, viewer } = this.props;
    const searchResults = get(viewer, 'searchByCategory', []);

    return (
      <List
        id={SEARCH_RESULTS_ID}
        styleName="search-college-results"
        verticalAlign="middle"
        divided
      >
        {searchResults.length > 0 ? (
          <React.Fragment>
            <List.Item
              key="search-college"
            >
              <FormattedMessage {...messages.searchFor} values={{ searchText }} />
            </List.Item>
            {searchResults[0].results.map((college, index) => (
              <SearchResult
                key={college.id}
                college={college}
                onClick={onItemClick}
                highlighted={index === selectedIndex}
              />
            ))}
          </React.Fragment>
        ) : (
          <List.Item key="search-college-no-results">
            <FormattedMessage {...messages.noResults} values={{ searchText }} />
          </List.Item>
        )}
      </List>
    );
  }
}
