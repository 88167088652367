import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import get from 'lodash/get';

import SelectedPill from './selected-pill';
import SearchCollege from '../SearchCollegeByNameOrSCID';
import { SEARCH_RESULTS_ID } from '../SearchCollegeByNameOrSCID/search-results';
import { formattedCollegeName } from '../SearchCollegeByNameOrSCID/search-result';

import './styles.css';

const getNodeParents = (elem) => {
  let { target } = elem;
  const parents = [];
  while (target) {
    parents.unshift(target);
    target = target.parentNode;
  }
  return parents;
};

class CompetitorsField extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    competitors: PropTypes.array,
    currentScid: PropTypes.string,
  };

  setSearchName = debounce((value) => {
    this.setState({ searchName: value });
  }, 500);

  constructor(props) {
    super(props);

    const initialState = this.getInitialState();
    if (Array.isArray(props.competitors) && props.competitors.length > 0) {
      this.state = {
        ...initialState,
        competitors: props.competitors.map((college) => ({ scid: college.scid, name: formattedCollegeName(college) })),
      };
    } else {
      this.state = initialState;
    }
  }

  getInitialState = () => ({
    competitors: [],
    displayResults: false,
    value: '',
    searchName: '',
    selectedIndex: -1,
  });

  componentDidMount() {
    // hide when clicking outside the search results area
    document.addEventListener('click', this.hideResults);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideResults);
    if (this.containerRef) {
      this.containerRef.removeEventListener('click', this.handleContainerClick);
    }
  }

  onItemClick = (college) => {
    const { competitors } = this.state;

    if (competitors.find((competitor) => competitor.scid === college.scid)) return;

    const added = [
      ...competitors,
      college,
    ];

    this.setState({
      competitors: added,
      value: '',
      selectedIndex: -1,
    }, () => this.input.focus());
    this.props.onChange(added);
  };

  handleChange = (e) => {
    const value = get(e, 'target.value');
    this.setState({ value, displayResults: true });
    this.setSearchName(value);
  };

  displayResults = () => {
    this.setState({ displayResults: true });
  };

  hideResults = (e) => {
    if (
      this.state.displayResults &&
      e.target.id !== this.props.id &&
      !some(getNodeParents(e), { id: SEARCH_RESULTS_ID })
    ) {
      this.setState({
        displayResults: false,
        searchName: '',
        selectedIndex: -1,
      });
    }
  };

  removeCompetitor = (competitor) => {
    const { competitors } = this.state;
    const removed = competitors.filter((item) => item.scid !== competitor.scid);

    this.setState({
      competitors: removed,
    });
    this.props.onChange(removed);
  };

  handleContainerRef = (e) => {
    this.containerRef = e;

    /* istanbul ignore else */
    if (this.containerRef) {
      this.containerRef.addEventListener('click', this.handleContainerClick);
    }
  }

  handleContainerClick = () => {
    this.input = this.input || this.containerRef.querySelector('input');
    this.input.focus();
  }

  handleInputKeyNav = (e) => {
    const { competitors, value, displayResults, searchName, selectedIndex } = this.state;

    if (!value && competitors.length) {
      /* istanbul ignore else */
      if (e.key === 'Backspace') {
        e.preventDefault();
        const last = competitors[competitors.length-1];
        this.removeCompetitor(last);
      }
    }

    /* istanbul ignore next */
    if (!displayResults || !searchName) return;

    const list = document.getElementById(SEARCH_RESULTS_ID);

    /* istanbul ignore next */
    if (!list) return;
    /* istanbul ignore next */
    if (list.childNodes.length === 0) return;

    /* istanbul ignore else */
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      /* istanbul ignore else */
      if (e.key === 'ArrowDown' && selectedIndex < list.childNodes.length) {
        this.setState({
          selectedIndex: selectedIndex + 1,
        });
      } else if (e.key === 'ArrowUp' && selectedIndex > 0) {
        this.setState({
          selectedIndex: selectedIndex - 1,
        });
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();

      if (selectedIndex === -1) return;
      const selected = list.childNodes[selectedIndex+1];
      this.onItemClick({
        scid: selected.getAttribute('dataid'),
        name: selected.textContent,
      });
    }
  }

  render() {
    const { currentScid } = this.props;
    const {
      competitors,
      displayResults,
      value,
      searchName,
      selectedIndex,
    } = this.state;
    return (
      <div styleName="competitors-container" tabIndex="0" onFocus={this.displayResults} ref={this.handleContainerRef}>
        <div styleName="select-field">
          <span>
            {
              competitors.map((competitor) =>
                (
                  <SelectedPill
                    key={competitor.scid}
                    data={competitor}
                    onRemoveClick={this.removeCompetitor}
                  />
                ))
            }
          </span>
          <input
            id={this.props.id}
            aria-label="Search by College Name"
            styleName="competitors-search-input"
            onChange={this.handleChange}
            value={value}
            onKeyDown={this.handleInputKeyNav}
          />
        </div>
        {displayResults &&
          searchName && (
          <SearchCollege
            searchText={searchName}
            onItemClick={this.onItemClick}
            selectedIndex={selectedIndex}
            excludeScids={[currentScid]}
          />
        )}
      </div>
    );
  }
}

export default CompetitorsField;
