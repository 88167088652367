/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux';

// use this import if you need to combine multiple reducers under the same key.
import authorizationReducer from './containers/Authorization/reducer';
import searchBarReducer from './containers/SearchBar/reducer';
import notificationsReducer from './containers/Notifications/reducer';
import languageProviderReducer from './providers/LanguageProvider/reducer';

export default combineReducers({
  current: authorizationReducer,
  language: languageProviderReducer,
  notifications: notificationsReducer,
  globalSearch: searchBarReducer,
});
