import { purgeStoredState } from 'redux-persist';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import adalService from '../../adal/adal-service';
import persistConfig from '../../persistConfig';
import communityLogout from '../Community/logout';
import * as constants from './constants';

export const loginUserRequest = () => (
  {
    type: constants.LOGIN_USER_REQUEST,
  }
);

export const loginUserSuccess = (payload) => (
  {
    type: constants.LOGIN_USER_SUCCESS,
    payload,
  }
);

export const logout = () => (
  {
    type: constants.LOGOUT_USER,
  }
);

function setupSentryUserContext(viewer) {
  if (window.Sentry) {
    const profile = viewer.profile || {};
    window.Sentry.setUser({
      id: profile.oid,
      username: profile.unique_name,
    });
    window.Sentry.setExtra('roles', profile.roles);
  }
}

export const signOut = (viewer) => (
  (dispatch) => {
    dispatch(logout());
    if (get(viewer, 'profile.oid')) {
      communityLogout(get(viewer, 'profile.oid'));
    }
    return purgeStoredState(persistConfig).then(() => {
      adalService.logOut();
    });
  }
);

export const signInSuccess = (jwkToken) => (
  (dispatch) => {
    const userData = {
      profile: jwtDecode(jwkToken),
    };
    userData.userName = userData.profile.upn;
    userData.jwtToken = jwkToken;
    userData.isAuthenticated = true;
    setupSentryUserContext(userData);
    dispatch(loginUserSuccess(userData));
    // eslint-disable-next-line no-use-before-define
    setupTokenRefreshTimer(dispatch, userData);
  }
);

function setupTokenRefreshTimer(dispatch, userData) {
  // adal by default will set cached token to null within 5 min of expiration,
  // so we need to setup a timer that would force renew right after that but before
  // the real expiration.
  let timeout = (1000 * userData.profile.exp) - Date.now() - ((adalService.config.expireOffsetSeconds - 10) * 1000);
  if (timeout < 0) {
    timeout = 0;
  }
  /* istanbul ignore if */
  if (process.env.NODE_ENV !== 'production') {
    console.log('Will renew token in %d seconds', timeout/1000); // eslint-disable-line no-console
  }
  setTimeout(() => {
    adalService.acquireToken(adalService.config.clientId, (err, jwtToken) => {
      if (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err); // eslint-disable-line no-console
        }
        dispatch(signOut(userData));
      } else {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Token renewed'); // eslint-disable-line no-console
        }
        dispatch(signInSuccess(jwtToken));
      }
    });
  }, Math.min(timeout, 0x7FFFFFFF));
}
