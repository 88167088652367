import { defineMessages } from 'react-intl';

const messages = defineMessages({
  downloadingData: {
    id: 'export-clients.downloading-data',
    defaultMessage: 'Downloading...',
  },
  downloadReady: {
    id: 'export-clients.download-ready',
    defaultMessage: 'Download ready',
  },
  cancelButton: {
    id: 'export-clients.cancel-button',
    defaultMessage: 'Cancel',
  },
  generateButton: {
    id: 'export-clients.generate-list-button',
    defaultMessage: 'Generate List',
  },
  downloadButton: {
    id: 'export-clients.download-button',
    defaultMessage: 'Download',
  },
});

export default messages;
