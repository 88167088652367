import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import { Link } from 'react-router-dom';
import { fromGlobalId } from 'graphql-relay';
import striptags from 'striptags';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import Icon from '../../components/Icon';
import Item from '../../components/Item';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Loader from '../../components/Loader';

import './styles.css';

const messages = defineMessages({
  noResults: {
    id: 'search-results.noneFound',
    defaultMessage: 'No results found.',
  },
  tryAgain: {
    id: 'search-results.tryAgain',
    defaultMessage: 'Please try a new search.',
  },
  moreResults: {
    id: 'search-results.moreGroups',
    defaultMessage: 'More Groups',
  },
  join: {
    id: 'search-results.join',
    defaultMessage: 'Join',
  },
  private: {
    id: 'search-results.private',
    defaultMessage: 'Private',
  },
  public: {
    id: 'search-results.public',
    defaultMessage: 'Public',
  },
});

class AdvancedGroupResults extends React.PureComponent {
  static propTypes = {
    viewer: PropTypes.object,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  state = { loading: false };

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore('viewer.advancedGroupSearch').then(() => this.setState({ loading: false }));
  };

  render() {
    const { viewer } = this.props;

    if (!get(viewer, 'advancedGroupSearch.edges.length')) {
      return (
        <Item.Group>
          <Item styleName="itemContainer">
            <Item.Content>
              <Item.Header styleName="itemHeader"><FormattedMessage {...messages.noResults} /></Item.Header>
              <Item.Description styleName="itemDescription"><FormattedMessage {...messages.tryAgain} /></Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      );
    } else {
      const { formatMessage } = this.props.intl;
      const rows = viewer.advancedGroupSearch.edges.map((group) => group.node);
      return (
        <div>
          <Item.Group>
            {rows.map((row, index) => (
              <Item styleName="itemContainer" key={row.id}>
                <Item.Content>
                  <Item.Header styleName="itemHeader">
                    {row.name} {`(${row.group_access ? formatMessage(messages.private) : formatMessage(messages.public)})`}
                  </Item.Header>
                  <Item.Description styleName="itemDescription">{truncate(striptags(row.body_value), { length: 100 })}
                  </Item.Description>
                  <Button
                    styleName="joinButton"
                    as={Link}
                    to={`/counselor-community/groups/${fromGlobalId(row.id).id}`}
                    size="small"
                  >
                    <Icon.Group>
                      <Icon name="user plus" />
                    </Icon.Group>
                    <FormattedMessage {...messages.join} />
                  </Button>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
          {get(viewer, 'advancedGroupSearch.pageInfo.hasNextPage') ? (
            <Container textAlign="center">
              <Loader inline active={this.state.loading} />
              <Button size="small" color="teal" onClick={this.loadMore} data-cy="advanced-search-more-groups-button">
                <FormattedMessage {...messages.moreResults} />
              </Button>
            </Container>
          ) : null}
        </div>
      );
    }
  }
}

export default injectIntl(AdvancedGroupResults);
