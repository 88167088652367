import React from 'react';
import PropTypes from 'prop-types';

import Nav from '../Nav';
import Sidebar from '../Sidebar';

function focusOnFirstAnchor() {
  const element = document.querySelector('a');
  if (element) {
    element.focus();
  }
}
class NavSidebar extends React.Component {
  static propTypes = {
    animation: PropTypes.string,
    className: PropTypes.string,
    visible: PropTypes.bool,
    closeSidebar: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        document.addEventListener('keydown', this.closeOnEscape);
        focusOnFirstAnchor();
      } else {
        document.removeEventListener('keydown', this.closeOnEscape);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape);
  }

  closeOnEscape = (e) => {
    if (e.keyCode === 27 && this.props.closeSidebar) {
      e.preventDefault();
      this.props.closeSidebar();
    }
  };

  render() {
    const { animation, className, visible } = this.props;
    return (
      <Sidebar as={Nav} idPrefix="main-sidebar-nav" className={className} animation={animation} visible={visible} />
    );
  }
}

export default NavSidebar;
