import gql from 'graphql-tag';

export default gql`
  mutation saveAMCaminoMessage($input: updateAMMessageCaminoInput!) {
    updateAMMessageCamino(input: $input) {
      messages {
        version
        camino {
          defaultMessage
        }
      }
    }
  }
`;
