import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

import Item from '../../components/Item';
import Container from '../../components/Container';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';

import '../AdvancedInstitutionSearchResults/styles.css';

const messages = defineMessages({
  noResults: {
    id: 'search-results.noneFound',
    defaultMessage: 'No results found.',
  },
  tryAgain: {
    id: 'search-results.tryAgain',
    defaultMessage: 'Please try a new search.',
  },
  moreResults: {
    id: 'search-results.moreResults',
    defaultMessage: 'More Results',
  },
});

const avatarURL = 'https://static.naviance.com/libraries/college-logos/';

class AdvancedHigherEdResults extends React.PureComponent {
  static propTypes = {
    viewer: PropTypes.object,
    loadMore: PropTypes.func.isRequired,
  };

  state = { loading: false };

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore('viewer.advancedHigherEdSearch').then(() => this.setState({ loading: false }));
  };

  render() {
    const { viewer } = this.props;

    if (!get(viewer, 'advancedHigherEdSearch.edges.length')) {
      return (
        <Item.Group>
          <Item styleName="itemContainer">
            <Item.Content>
              <Item.Header styleName="itemHeader"><FormattedMessage {...messages.noResults} /></Item.Header>
              <Item.Description styleName="itemDescription"><FormattedMessage {...messages.tryAgain} /></Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      );
    } else {
      const rows = viewer.advancedHigherEdSearch.edges.map((higherEd) => higherEd.node);
      return (
        <div>
          <Item.Group>
            {rows.map((row, index) => (
              <Item styleName="itemContainer" key={row.id}>
                <Avatar big icon="university" image={row.field_he_institution_id ? `${avatarURL}${row.field_he_institution_id}.gif` : ''} />
                <Item.Content styleName="itemContent">
                  <Item.Header styleName="itemHeader">
                    <Link to={`/counselor-community/institution-id/${row.field_he_institution_id}`}>{row.name}</Link>
                  </Item.Header>
                  <Item.Description styleName="itemDescription">
                    {`${row.field_city}, ${row.field_state_short_name} ${row.field_postalcode}`}
                  </Item.Description>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
          {get(viewer, 'advancedHigherEdSearch.pageInfo.hasNextPage') ? (
            <Container textAlign="center">
              <Loader inline active={this.state.loading} />
              <Button size="small" color="teal" onClick={this.loadMore} data-cy="he-institutions-load-more-button">
                <FormattedMessage {...messages.moreResults} />
              </Button>
            </Container>
          ) : null}
        </div>
      );
    }
  }
}

export default AdvancedHigherEdResults;
