import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import omit from 'lodash/omit';
import Button from '../Button';
import Input from '../Input';
import Container from '../Container';
import Loader from '../Loader';
import Dimmer from '../Dimmer';
import Segment from '../Segment';
import Form from '../Form';
import Label from '../Label';
import Message from '../Message';
import Header from '../Header';
import Icon from '../Icon';

const messages = defineMessages({
  generalError: {
    id: 'primary-user.update.error.general',
    defaultMessage: 'Unable to update primary user',
  },
  userExists: {
    id: 'primary-user.update.error.user_exists',
    defaultMessage: 'User with provided email already exists!',
  },
});

const missingValueLabel = (
  <Label role="tooltip" tabIndex={-1} basic color="red" pointing>
    <FormattedMessage id="common.label.missingValue" defaultMessage="Please enter a value" />
  </Label>
);

export const elementIds = {
  firstNameId: 'create-primary-user-first-name',
  lastNameId: 'create-primary-user-last-name',
  emailId: 'create-primary-user-email',
};
function validateForm(formData) {
  return {
    missingFirstName: !formData.firstName,
    missingLastName: !formData.lastName,
    missingEmail: !formData.email,
  };
}

class UpdatePrimaryUser extends React.Component {
  static propTypes = {
    accountName: PropTypes.string.isRequired,
    primaryUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    requesting: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    updateError: PropTypes.shape({
      generalError: PropTypes.bool,
      userExists: PropTypes.bool,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { primaryUser } = props;
    this.state = {
      errors: {},
      firstName: primaryUser.firstName || '',
      lastName: primaryUser.lastName || '',
      email: primaryUser.email || '',
    };
  }

  getModifiedFormFields = (formData) => {
    const { primaryUser } = this.props;
    const modifiedFormFields = {};
    const existingLastName = primaryUser.lastName || '';
    const existingFirstName = primaryUser.firstName || '';
    const existingEmail = primaryUser.email || '';
    if (formData.firstName.trim() !== existingFirstName.trim()) {
      modifiedFormFields.firstName = formData.firstName.trim();
    }
    if (formData.lastName.trim() !== existingLastName.trim()) {
      modifiedFormFields.lastName = formData.lastName.trim();
    }
    if (formData.email.trim() !== existingEmail.trim()) {
      modifiedFormFields.email = formData.email.trim();
    }
    return modifiedFormFields;
  };

  _onChange = (e, { value, name } = {}) => {
    value = value || e.target.value;
    name = name || e.target.name;
    this.setState({ [name]: value });
  };

  _onSubmit = (e) => {
    const formData = omit(this.state, 'errors');
    const errors = validateForm(formData);
    if (!Object.keys(errors).some((key) => errors[key])) {
      const modifiedFormFields = this.getModifiedFormFields(formData);
      if (Object.getOwnPropertyNames(modifiedFormFields).length === 0) {
        // no changes
        this._onCancel();
      } else {
        this.props.onSave(modifiedFormFields);
      }
    }
    this.setState({ errors });
  };

  _onCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { errors, firstName, lastName, email } = this.state;
    const { accountName, updateError, requesting } = this.props;

    const missingFirstNameLabel = errors.missingFirstName ? missingValueLabel : '';
    const missingLastNameLabel = errors.missingLastName ? missingValueLabel : '';
    const missingEmailLabel = errors.missingEmail ? missingValueLabel : '';

    const errorDetails = updateError && Object.keys(updateError).length === 1 ? (
      <Message role="alert" negative>
        <Message.Header>
          <FormattedMessage {...messages[Object.keys(updateError)[0]]} />
        </Message.Header>
      </Message>
    ) : '';

    return (
      <Container text className="updatePrimaryUserPanel">
        <Header as="h1">
          <FormattedMessage id="primary-user.update.header" defaultMessage="Update Primary User for" /> {accountName}
        </Header>
        <Dimmer.Dimmable as={Form} id="form-update-primary-user" onSubmit={this._onSubmit} size="large">
          <Dimmer
            tabIndex={-1}
            role="progressbar"
            aria-describedby="Updating Primary User..."
            aria-busy={requesting}
            inverted
            active={requesting}
          >
            <Loader active={requesting} />
          </Dimmer>
          <Segment>
            <Form.Field>
              <label form="form-update-primary-user" htmlFor={elementIds.firstNameId}>
                <FormattedMessage id="primary-user.firstName.label" defaultMessage="First Name" />
              </label>
              <Input type="text" name="firstName" value={firstName} onChange={this._onChange}>
                <input id={elementIds.firstNameId} aria-required aria-invalid={!!missingFirstNameLabel} />
              </Input>
              {missingFirstNameLabel}
            </Form.Field>
            <Form.Field>
              <label form="form-update-primary-user" htmlFor={elementIds.lastNameId}>
                <FormattedMessage id="primary-user.lastName.label" defaultMessage="Last Name" />
              </label>
              <Input type="text" name="lastName" value={lastName} onChange={this._onChange}>
                <input id={elementIds.lastNameId} aria-required aria-invalid={!!missingLastNameLabel} />
              </Input>
              {missingLastNameLabel}
            </Form.Field>
            <Form.Field>
              <label form="form-update-primary-user" htmlFor={elementIds.emailId}>
                <FormattedMessage id="primary-user.email.label" defaultMessage="Email" />
              </label>
              <Input
                type="email"
                name="email"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$"
                value={email}
                onChange={this._onChange}
              >
                <input id={elementIds.emailId} aria-required aria-invalid={!!missingEmailLabel} />
              </Input>
              {missingEmailLabel}
            </Form.Field>
            <Button type="button" onClick={this._onCancel}>
              <Icon name="remove" />
              <FormattedMessage id="common.button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button primary type="submit">
              <Icon name="checkmark" />
              <FormattedMessage id="common.button.save" defaultMessage="Save" />
            </Button>
          </Segment>
        </Dimmer.Dimmable>
        {errorDetails}
      </Container>
    );
  }
}

export default UpdatePrimaryUser;
