import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../Button';
import { signOut } from '../../containers/Authorization/actions';

export class SignOutComponent extends React.PureComponent {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    current: PropTypes.object.isRequired,
  };

  render() {
    return <Button onClick={() => this.props.signOut(this.props.current.viewer)}>Sign Out</Button>;
  }
}
const mapStateToProps = (state) => ({
  current: state.current,
});
export default connect(mapStateToProps, { signOut })(SignOutComponent);
