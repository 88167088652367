import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Button from '../../components/Button';
import Radio from '../../components/Radio';
import Divider from '../../components/Divider';
import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';
import Form from '../../components/Form';
import Message from '../../components/Message';
import Icon from '../../components/Icon';
import Label from '../../components/Label';
import './styles.css';

const options = [
  { key: 'he', text: 'HE', value: 'he' },
  { key: 'hs-naviance', text: 'HS - Naviance', value: 'hs-naviance' },
  { key: 'hs', text: 'HS - Non Naviance', value: 'hs' },
];

export const messages = defineMessages({
  validationError: {
    id: 'support.add.announcement.error.validation',
    defaultMessage: 'Something went wrong',
  },
  validationErrorMessage: {
    id: 'support.add.announcement.error.validationMessage',
    defaultMessage: 'The fields highlighted below need your attention',
  },
  pageTitleAddNew: {
    id: 'support.add.announcement.pagetitle.addnew',
    defaultMessage: 'Add New',
  },
  pageTitleEdit: {
    id: 'support.add.announcement.pagetitle.edit',
    defaultMessage: 'Edit',
  },
});

export const elementIds = {
  title: 'create-announcement-title',
  content: 'create-announcement-content',
  audience: 'create-announcement-audience',
  status: 'create-announcement-status',
};

class CreateOrEditAnnouncementsPage extends React.PureComponent {
  static propTypes = {
    mutateEditAnnouncement: PropTypes.func.isRequired,
    mutateNewAnnouncement: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    node: PropTypes.object,
    viewer: PropTypes.object,
  };

  statusValidation = (
    <Label role="tooltip" basic color="red" pointing styleName="tooltip">
      <FormattedMessage id="support.add.announcement.status.validationError" defaultMessage="You can only have 1 announcement per audience published at a time. Save as 'unpublished' to edit later." />
    </Label>
  );

  contentValidationMessage = (
    <Label role="tooltip" basic color="red" pointing>
      <FormattedMessage id="support.add.announcement.status.contentValidationError" defaultMessage="Field is required" />
    </Label>
  );

  errorMessage = (
    <Message icon color="red" styleName="error-message">
      <Icon name="remove circle" styleName="error-message-icon" />
      <Message.Content>
        <Message.Header styleName="error-message-header">
          <FormattedMessage {...messages.validationError} />
        </Message.Header>
        <FormattedMessage {...messages.validationErrorMessage} />
      </Message.Content>
    </Message>
  );

  constructor(props) {
    super(props);

    this.isEditMode = false;
    this.pageTitle = messages.pageTitleAddNew;
    this.announcementToEdit = null;

    if (props.node) {
      this.isEditMode = true;
      this.pageTitle = messages.pageTitleEdit;
      this.announcementToEdit = props.node;
      this.state = { ...this.announcementToEdit, loading: false, createError: {} };
    } else {
      this.state = {
        loading: false,
        title: '',
        content: '',
        audience: [],
        status: 'Unpublished',
        createError: {
        },
      };
    }
  }

  _onChange = (e, { value, name } = {}) => {
    value = value || e.target.value;
    name = name || e.target.name;
    this.setState({ [name]: value });
    if (name === 'status') {
      this.setState({ createError: { alreadyPublished: false } });
    }
    this.setState({ createError: { [name]: '' }, errorMessage: '' });
  };

  _onSubmit = () => {
    const onSuccess = () => {
      this.props.history.push({
        pathname: '/admin/announcements/details',
        state: {
          success: true,
          isEditMode: this.isEditMode,
        },
      });
    };
    const onFailure = (error) => {
      if (error.message.includes('You can only have 1 announcement published at a time')) {
        this.setState({ loading: false, createError: { alreadyPublished: true }, errorMessage: this.errorMessage });
      }
    };

    const validate = (text, name, errorsObject) => {
      if (!text || text.length === 0) {
        errorsObject[name] = this.contentValidationMessage;
        return false;
      }
      return true;
    };

    const { title, content, audience, status } = this.state;
    const errorsObject = {};
    const contentValidate = validate(content.trim(), 'content', errorsObject);
    const audienceValidate = validate(audience, 'audience', errorsObject);
    if (!contentValidate || !audienceValidate) {
      this.setState({ createError: errorsObject, errorMessage: this.errorMessage });
      return false;
    }

    const mutationBody = {
      variables: {
        input: {
          announcement: {
            title,
            content,
            audience,
            status,
          },
        },
      },
    };
    this.setState({ loading: true, createError: {} });
    if (this.isEditMode) {
      mutationBody.variables.input.announcement.id = this.announcementToEdit.id;
      this.props.mutateEditAnnouncement(mutationBody).then(onSuccess, onFailure);
    } else {
      mutationBody.variables.first = 25;
      this.props.mutateNewAnnouncement(mutationBody).then(onSuccess, onFailure);
    }
    return true;
  };

  render() {
    const { createError, errorMessage, title, content, audience, status, loading } = this.state;
    const statusValidationError = createError.alreadyPublished ? this.statusValidation : '';

    return (
      <div>
        <div styleName="more-padding-left more-padding-top inline">
          <div styleName="product-title"><FormattedMessage {...this.pageTitle} /></div>
          { errorMessage }
        </div>
        <Form id="form-create-announcement" onSubmit={this._onSubmit} size="large">
          <label styleName="form-label more-padding-left more-padding-top" form="form-create-announcement" htmlFor={elementIds.title}>
            <FormattedMessage id="support.add.announcement.label.title" defaultMessage="Title" />
          </label>
          <label htmlFor={elementIds.title} styleName="title-info-message">
            <FormattedMessage id="support.add.announcement.label.title.infoMessage" defaultMessage="The title field is formatted in bold and shows before the content field." />
          </label>
          <div styleName="more-padding form-field">
            <Form.Field>
              <Input type="text" name="title">
                <input id={elementIds.title} maxLength="30" onChange={this._onChange} value={title} />
              </Input>
              <div styleName="title-limit-message">
                <FormattedMessage id="support.add.announcement.label.title.limit" defaultMessage="30 character limit" />
              </div>
              { createError.title }
            </Form.Field>
            <Form.Field>
              <label styleName="form-label form-label-mandatory" form="form-create-announcement" htmlFor={elementIds.content}>
                <FormattedMessage id="support.add.announcement.label.content" defaultMessage="Content" />
              </label>
              <Form.TextArea
                id={elementIds.content}
                name="content"
                onChange={this._onChange}
                value={content}
              />
              { createError.content }
            </Form.Field>
            <Form.Field>
              <label styleName="form-label form-label-mandatory" form="form-create-announcement" htmlFor={elementIds.audience}>
                <FormattedMessage id="support.add.announcement.label.audience" defaultMessage="Audience" />
              </label>
              <Dropdown
                name="audience"
                id="announcement-audience-dropdown"
                styleName="dropdown-multiselect"
                placeholder="Select all that apply"
                fluid
                multiple
                selection
                options={options}
                onChange={this._onChange}
                value={audience}
              />
              { createError.audience }
            </Form.Field>
            <Form.Field>
              <label styleName="form-label form-label-mandatory" form="form-create-announcement" htmlFor="status">
                <FormattedMessage id="support.add.announcement.label.status" defaultMessage="Status" />
              </label>
            </Form.Field>
            <Form.Field>
              <Radio
                data-cy="product-announcements-radio-unpublished"
                styleName="radio-status"
                label="Unpublished"
                name="status"
                value="Unpublished"
                checked={status === 'Unpublished'}
                onChange={this._onChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                data-cy="product-announcements-radio-published"
                styleName="radio-status"
                label="Published"
                name="status"
                value="Published"
                checked={status === 'Published'}
                onChange={this._onChange}
              />
            </Form.Field>
            { statusValidationError }
          </div>
          <Divider styleName="form-divider" />
          <Form.Field styleName="submit-form-field">
            <Button loading={loading} styleName="save-btn" primary type="submit">
              <FormattedMessage id="common.button.save" defaultMessage="Save" />
            </Button>
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateOrEditAnnouncementsPage);
