import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import filesaver from 'file-saver';

import Button from '../../components/Button';
import Progress from '../../components/Progress';
import Modal from '../../components/Modal';
import { exportHEInstitutionUsersToCSV } from './utils';
import { SimpleGraphQLComponent, withSetVariablesProp } from '../../utils/apollo';
import messages from './messages';

const DOWNLOAD_LIMIT = 25;
const CSV_FILENAME = 'he-users.csv';

export class DownloadUsersComponent extends React.PureComponent {
  static propTypes = {
    doFetch: PropTypes.bool,
    viewer: PropTypes.object,
    onClose: PropTypes.func,
    setVariables: PropTypes.func,
  }

  static getDerivedStateFromProps(props, state) {
    const { setVariables } = props;
    const next = get(props, 'viewer.heInstitutionUsers');
    const { prevHeInstitutionUsers: current, heInstitutionUsers } = state;

    if (next && (!current || next.pagination.page !== current.pagination.page)) {
      const { hasNextPage } = next.pagination;
      const nextHeInstitutionUsers = heInstitutionUsers.concat(next.results);
      if (hasNextPage) {
        setVariables({ page: next.pagination.page + 1 });
      }
      return {
        requestProgress: ((next.pagination.page * DOWNLOAD_LIMIT) / next.pagination.totalCount) * 100,
        heInstitutionUsers: nextHeInstitutionUsers,
        prevHeInstitutionUsers: next,
        downloadReady: !hasNextPage,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.state = {
      downloadReady: false,
      heInstitutionUsers: [],
      requestProgress: 0,
      prevHeInstitutionUsers: null,
    };
  }

  handleCancelClick = () => {
    this.finishDownload();
  };

  finishDownload = () => {
    const { onClose } = this.props;
    this.setState({
      downloadReady: false,
      heInstitutionUsers: [],
      prevHeInstitutionUsers: null,
      requestProgress: 0,
    });
    onClose();
  };

  handleDownloadFileClick = () => {
    const { heInstitutionUsers } = this.state;
    const csvBlob = exportHEInstitutionUsersToCSV(heInstitutionUsers);
    filesaver.saveAs(csvBlob, CSV_FILENAME);
    this.finishDownload();
  };

  render() {
    const { doFetch } = this.props;
    const { downloadReady, requestProgress } = this.state;

    return (
      <Modal
        open={doFetch}
      >
        <Modal.Content data-cy="download-users-content">
          <Progress percent={requestProgress} color="teal">
            <FormattedMessage {...(downloadReady ? messages.downloadReady : messages.downloadingData)} />
          </Progress>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancelClick} data-cy="download-users-cancel-button">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            disabled={!downloadReady}
            onClick={this.handleDownloadFileClick}
            data-cy="download-users-generate-list-button"
          >
            <FormattedMessage {...messages.generateButton} />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

/* eslint-disable graphql/template-strings */
export const query = gql`
  query queryHEAccountsInInstitution(
    $roles: [String], $subscriptions: [String], $isPrimary: Boolean, $page: Int!, $limit: Int!
  ) {
    viewer {
      id
      heInstitutionUsers(
        roles: $roles, subscriptions: $subscriptions, isPrimary: $isPrimary, page: $page, limit: $limit
      ) {
        results {
          heUsers {
            id
            firstName
            lastName
            email
            role
            institution
          }
          heAccount {
            scid
            name
            collegeCore {
              address {
                state
              }
              type
              forProfit
            }
            primaryUser {
              id
              firstName
              lastName
              email
              role
              institution
            }
            moduleSubscriptions {
              name
              startDate
              endDate
            }
          }
        }
        pagination {
          page
          limit
          hasNextPage
          totalCount
        }
      }
    }
  }
`;
/* eslint-enable graphql/template-strings */

const queryData= {
  initialVariables: {
    page: 1,
    limit: DOWNLOAD_LIMIT,
  },
};

const graphqlData = graphql(query, {
  props: withSetVariablesProp,
  skip: (props) => !props.doFetch,
  options: ({ roles, subscriptions, isPrimary }) => {
    const variables = { ...queryData.initialVariables };
    return {
      variables: { ...variables, roles, subscriptions, isPrimary },
      fetchPolicy: 'no-cache',
    };
  },
});

export default SimpleGraphQLComponent(graphqlData)(DownloadUsersComponent);
