import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, withApollo } from '@apollo/react-hoc';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import 'isomorphic-fetch';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Message from '../../components/Message';
import Search from '../../components/Search';
import { SimpleGraphQLComponent } from '../../utils/apollo';
import moveUserToHEAccountMutation from './move-he-user-to-heaccount-mutation';
import searchQuery from './search-query';

export const messages = defineMessages({
  error: {
    id: 'he-account.move-user-to-he-account.error',
    defaultMessage: 'Unable to move user to he account',
  },
  header: {
    id: 'he-account.move-user-to-he-account.header',
    defaultMessage: 'Move {user} to HE Account',
  },
  placeholder: {
    id: 'he-account.move-user-to-he-account.placeholder',
    defaultMessage: 'Search by SCID or Name',
  },
  move: {
    id: 'he-account.move',
    defaultMessage: 'Move User To {name}',
  },
});

export class MoveUserToHeAccountComponent extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    client: PropTypes.shape({
      query: PropTypes.func.isRequired,
    }).isRequired,
    mutateMoveUserToHEAccount: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      institution: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.requestSearchDebounce = debounce(this.requestSearch, 400);
  }

  state = {
    errors: false,
    destination: null,
    results: null,
    searching: false,
    moving: false,
  };

  handleSearchChange = (e, { value }) => {
    if (value.length < 2) {
      this.resetComponent();
    } else {
      this.setState({ searching: true });
      this.requestSearchDebounce(e, value);
    }
  };

  resetComponent() {
    this.setState({ searching: false, results: [] });
    this.requestSearchDebounce.cancel();
  }

  _handleClose = () => {
    this.props.onClose();
  };

  _handleMove = () => {
    const { mutateMoveUserToHEAccount, user, onSuccess } = this.props;
    const { destination } = this.state;
    this.setState({ errors: null, moving: true });
    mutateMoveUserToHEAccount({
      variables: {
        input: {
          userId: user.id,
          destinationId: destination.key,
        },
      },
    }).then(() => onSuccess({
      title: 'Success!',
      description: `${user.firstName} ${user.lastName} has been moved to ${destination.title}`,
    }))
      .catch((e) => {
        this.setState({ errors: e.graphQLErrors, moving: false });
      });
  };

  requestSearch = async (e, value) => {
    const { client, user } = this.props;
    const response = await client.query({
      query: searchQuery,
      variables: {
        filter: value,
      },
    });
    const results = (get(response, 'data.viewer.searchByCategory[0].results') || []).map((result) => ({
      key: result.id,
      image: result.field_he_institution_id ?
        `https://static.naviance.com/libraries/college-logos/${result.field_he_institution_id}.gif` : null,
      description: result.scid,
      title: result.name,
    })).filter((entry) => entry.description !== user.institution);
    this.setState({ results, searching: false });
  };

  handleOnChange = (e, { result }) => {
    this.setState({ destination: result });
  };

  render() {
    const { user, intl } = this.props;
    const { errors, moving, destination, results, searching } = this.state;
    const userName = `${user.firstName} ${user.lastName}`;
    const errorDetails = errors ? (
      <Message role="alert" negative>
        <Message.Header><FormattedMessage {...messages.error} /></Message.Header>
        {errors.map((error) => (<p>{error.message}</p>))}
      </Message>
    ) : '';

    const placeholderText = intl.formatMessage(messages.placeholder);
    return (
      <Modal open onClose={this._handleClose}>
        <Header data-cy="move-user-to-he-account-label">
          <FormattedMessage {...messages.header} values={{ user: userName }} />
        </Header>
        <Modal.Content>
          <Search
            id="move-user-search-box"
            fluid
            placeholder={placeholderText}
            onSearchChange={this.handleSearchChange}
            onResultSelect={this.handleOnChange}
            input={{
              'id': 'move-user-search-box-input',
              'aria-label': placeholderText,
            }}
            results={results}
            loading={searching}
            showNoResults={!searching}
            minCharacters={2}
            icon="search"
          />
          {errorDetails}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this._handleClose} data-cy="move-user-cancel-button">
            <Icon name="remove" />
            <FormattedMessage id="common.button.cancel" defaultMessage="Cancel" />
          </Button>
          <Button primary onClick={this._handleMove} loading={moving} disabled={!destination} data-cy="move-user-to-button">
            <Icon name="forward" />
            <FormattedMessage
              {...messages.move}
              values={{
                name: destination && destination.title,
              }}
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const moveUserToHEAccount = graphql(moveUserToHEAccountMutation, {
  name: 'mutateMoveUserToHEAccount',
});

export default SimpleGraphQLComponent(moveUserToHEAccount)(withApollo(injectIntl(MoveUserToHeAccountComponent)));
