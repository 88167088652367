import gql from 'graphql-tag';

import HEUserDetails from '../../queries/heUserDetails';

export default gql`
  mutation moveUserToHEAccount($input: moveUserToHeAccountInput!) {
    moveUserToHEAccount(input: $input) {
      source {
        id
        primaryUser {
          ...HEUserDetails
        }
        userList {
          ...HEUserDetails
        }
      }
      destination {
        id
        userList {
          ...HEUserDetails
        }
      }
    }
  }
  ${HEUserDetails}
`;
