import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import HEAccountUsersTable from '../HEAccountUsersTable';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import HasRole from '../../components/HasRole';
import * as appRoles from '../Authorization/constants';
import CounselorCommunityProfile from '../CounselorCommunityServerless/profile'; // eslint-disable-line import/no-named-as-default
import { FEATURE_VIEW_EDIT_CC_PROFILE } from '../../featureFlags';

class HEAccountUsersPage extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    viewer: PropTypes.shape({
      enabledFeatures: PropTypes.array,
    }),
  };

  state = {
    showDisabledUsers: false,
    viewCommunityProfileId: '',
  };

  toggleShowDisabledUsers = () => {
    this.setState((prevState) => ({ showDisabledUsers: !prevState.showDisabledUsers }));
  };

  setViewCommunityProfileId = (viewCommunityProfileId) => {
    this.setState({ viewCommunityProfileId });
  };

  render() {
    const { node, viewer: { enabledFeatures } } = this.props;
    const { userList } = node;
    const { showDisabledUsers, viewCommunityProfileId } = this.state;
    const disabledUsersExist = userList && userList.some((u) => !u.active);

    const buttonDataCy = showDisabledUsers ? 'he-users-show-active-users-button' : 'he-users-show-disabled-users-button';
    const isViewEditCCProfileButtonEnabled = enabledFeatures.includes(FEATURE_VIEW_EDIT_CC_PROFILE);

    return (
      <div>
        <PageTitle title="Users" category="Support" />
        <Link to={`/he-account/${node.id}/`} data-cy="he-account-users-back-link">
          <Icon name="angle left" />
          <FormattedMessage id="he-account.users-page.back" defaultMessage="Back" />
        </Link>
        <Header as="h1" data-cy="he-account-user-page-title-name">
          <FormattedMessage
            id="he-account.users-table.caption"
            defaultMessage="{name}'s User List"
            values={{
              name: node.name,
            }}
          />
        </Header>
        <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
          <Button as={Link} to={`/he-account/${node.id}/create-user`}>
            <FormattedMessage id="he-account.users-page.createNew" defaultMessage="Create New User" />
          </Button>
          {disabledUsersExist && (
            <Button onClick={this.toggleShowDisabledUsers} data-cy={buttonDataCy}>
              {
                showDisabledUsers ?
                  <FormattedMessage id="he-account.users-page.hideDisabledUsers" defaultMessage="Show Active Users" /> :
                  <FormattedMessage id="he-account.users-page.showDisabledUsers" defaultMessage="Show Disabled Users" />
              }
            </Button>
          )}
        </HasRole>
        <HEAccountUsersTable
          node={node}
          showDisabledUsers={showDisabledUsers}
          handleViewCCProfile={this.setViewCommunityProfileId}
          isViewEditCCProfileButtonEnabled={isViewEditCCProfileButtonEnabled}
        />
        {viewCommunityProfileId && (
          <CounselorCommunityProfile
            userId={viewCommunityProfileId}
            onClose={this.setViewCommunityProfileId}
          />
        )}
      </div>
    );
  }
}

HEAccountUsersPage.getFragments = () => [
  HEAccountUsersTable.fragment,
];

export default HEAccountUsersPage;
