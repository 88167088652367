import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

import Avatar from '../../components/Avatar';
import Icon from '../../components/Icon';
import Item from '../../components/Item';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Container from '../../components/Container';
import './styles.css';

const messages = defineMessages({
  noResults: {
    id: 'search-results.noneFound',
    defaultMessage: 'No results found.',
  },
  tryAgain: {
    id: 'search-results.tryAgain',
    defaultMessage: 'Please try a new search.',
  },
  moreResults: {
    id: 'search-results.morePeople',
    defaultMessage: 'More People',
  },
  view: {
    id: 'search-results.view',
    defaultMessage: 'view',
  },
});

class AdvancedPeopleResults extends React.PureComponent {
  static propTypes = {
    viewer: PropTypes.object,
    loadMore: PropTypes.func.isRequired,
  };

  state = { loading: false };

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore('viewer.advancedPeopleSearch').then(() => this.setState({ loading: false }));
  };

  render() {
    const { viewer } = this.props;

    if (!get(viewer, 'advancedPeopleSearch.edges.length')) {
      return (
        <Item.Group>
          <Item styleName="noResultsContainer">
            <Item.Content>
              <Item.Header styleName="noResultsHeader"><FormattedMessage {...messages.noResults} /></Item.Header>
              <Item.Description styleName="noResultsDescription"><FormattedMessage {...messages.tryAgain} /></Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      );
    } else {
      const rows = viewer.advancedPeopleSearch.edges.map((People) => People.node);
      return (
        <div>
          <Item.Group>
            {rows.map((row, index) => (
              <Item key={row.id} styleName="itemContainer">
                <Avatar big icon="user" image={row.field_profile_picture_file_url} />
                <Item.Content styleName="itemContent">
                  <Item.Header styleName="itemHeader">{row.name}</Item.Header>
                  <Item.Description styleName="itemDescription">
                    {row.field_job_position && `${row.field_job_position} at `}
                    {row.field_user_institution_title &&
                      <Link to={`/counselor-community/institution/${row.field_user_institution}`}>{row.field_user_institution_title}</Link>}
                  </Item.Description>
                  <Item.Description styleName="itemDescription">
                    {row.field_general_description}
                  </Item.Description>
                  <Button
                    styleName="viewButton"
                    as={Link}
                    to={`/counselor-community/profile/${row.communityId}`}
                    size="small"
                  >
                    <Icon.Group>
                      <Icon name="user plus" />
                    </Icon.Group>
                    <FormattedMessage {...messages.view} />
                  </Button>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
          {get(viewer, 'advancedPeopleSearch.pageInfo.hasNextPage') ? (
            <Container textAlign="center">
              <Loader inline active={this.state.loading} />
              <Button size="small" color="teal" onClick={this.loadMore} data-cy="advanced-search-more-people-button">
                <FormattedMessage {...messages.moreResults} />
              </Button>
            </Container>
          ) : null}
        </div>
      );
    }
  }
}

export default AdvancedPeopleResults;
