import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Immutable from 'immutable';
import get from 'lodash/get';

import Grid from '../../components/Grid';
import Divider from '../../components/Divider';
import List from '../../components/List';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { isUserAdmin, isUserSupport, isUserSalesOps } from '../Authorization';
import * as appRoles from '../Authorization/constants';

import './styles.css';

import ActionsGridColumn from './hs-account-actions-grid-column';
import HasRole from '../../components/HasRole';

class HSAccountDetails extends React.PureComponent {
  static propTypes = {
    current: PropTypes.object.isRequired,
    node: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    linkBack: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { node } = props;
    this.state = {
      hsAccount: Immutable.fromJS(node),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.node !== this.props.node) {
      this.setState({ hsAccount: Immutable.fromJS(nextProps.node) });
    }
  }

  handleCreateUser = () => {
    const { linkBack } = this.props;
    const { hsAccount } = this.state;
    this.props.history.push({ pathname: `/hs-account/${hsAccount.get('id')}/create-primary-user`, state: { linkBack } });
  };

  handleEditUser = () => {
    const { linkBack } = this.props;
    const { hsAccount } = this.state;
    this.props.history.push({ pathname: `/hs-account/${hsAccount.get('id')}/update-primary-user`, state: { linkBack } });
  };

  render() {
    const { hsAccount } = this.state;
    const { linkBack, current: { viewer }, node } = this.props;
    const highSchool = get(node, 'highSchool');
    const hasEditRole = isUserAdmin(viewer) || isUserSupport(viewer) || isUserSalesOps(viewer);
    const isActiveNavianceSchool = get(highSchool, 'isActiveNavianceSchool', false);
    const deNavianceRequired = get(node, 'deNavianceRequired', false);
    if (!hsAccount || !hsAccount.get('id')) {
      return (
        <p>
          <FormattedMessage id="hs-account.notFound" defaultMessage="No High School Account Found" />
        </p>
      );
    }
    const ncesId = get(highSchool, 'general.field_hs_na_nces_id');
    const nguid = get(highSchool, 'naviance.field_hs_na_nguid');
    const nid = get(highSchool, 'naviance.field_hs_na_nid');
    const address = get(highSchool, 'address');
    const canEdit = hasEditRole && !isActiveNavianceSchool && !deNavianceRequired && ncesId;
    const primaryUser = hsAccount.get('primaryUser');
    const firstName = primaryUser ? primaryUser.get('firstName') : null;
    const lastName = primaryUser ? primaryUser.get('lastName') : null;
    const email = primaryUser ? primaryUser.get('email') : null;
    return (
      <div>
        <Header as="h1">Client: {hsAccount.get('name')}</Header>
        <Grid columns="equal" stackable>
          <Grid.Row centered>
            <Grid.Column as="section">
              <Header as="h2">
                <FormattedMessage id="hs-account.details.header" defaultMessage="Details" />
              </Header>
              <div className="vcard" data-cy="hs-details-vcard">
                <div className="fn org">{hsAccount.get('name')}</div>
                <div className="adr">
                  <div className="street-address">{get(address, 'field_hs_elna_address_line1')}</div>
                  <span className="locality">{get(address, 'field_hs_elna_city')}</span>,&nbsp;
                  <span className="region">{get(address, 'field_hs_elna_state_latest')}</span>&nbsp;
                  <span className="postal-code">{get(address, 'field_hs_eln_zip')}</span>
                </div>
              </div>
              <Divider />
              <div className="nces-id">
                NCES ID: {ncesId}
                {(deNavianceRequired || !isActiveNavianceSchool) && !ncesId && <span styleName="error">MISSING NCES ID</span>}
              </div>
              {nguid && <div>NGUID: {nguid}</div>}
              {nid && <div>NID: {nid}</div>}
            </Grid.Column>
            <Grid.Column as="section" data-cy="hs-primary-user-details">
              <Header as="h2">
                <FormattedMessage id="hs-account.primaryUserDetails.header" defaultMessage="Primary User Details" />
                {primaryUser && canEdit ? (
                  <Button
                    data-cy="hs-edit-primary-user-button"
                    floated="right"
                    onClick={this.handleEditUser}
                  >
                    <FormattedMessage
                      id="modules-table.button.editPrimary"
                      description="Button to edit primary user details"
                      defaultMessage="Edit"
                    />
                  </Button>
                ) : ''}
                {!primaryUser && canEdit ? (
                  <Button
                    data-cy="hs-create-primary-user-button"
                    floated="right"
                    onClick={this.handleCreateUser}
                  >
                    <FormattedMessage
                      id="modules-table.button.createPrimary"
                      description="Button to create primary user"
                      defaultMessage="Create"
                    />
                  </Button>
                ) : ''}
              </Header>
              {
                primaryUser ? (
                  <List as="dl">
                    <List.Item as="dt" icon="user" content={`${firstName} ${lastName}`} data-cy="hs-primary-user-details-name" />
                    <List.Item as="dt" icon="mail" content={<a href={`mailto:${email}`}>{email}</a>} data-cy="hs-primary-user-details-email" />
                    <List.Item as="dt" icon="key" content={primaryUser.get('role')} data-cy="hs-primary-user-details-role" />
                  </List>
                ): <FormattedMessage id="he-account.primaryUser.notFound" defaultMessage="No Primary User Found" />
              }
            </Grid.Column>
            <HasRole anyOf={appRoles.NON_VIEW_ONLY_ROLES}>
              <ActionsGridColumn node={hsAccount} linkBack={linkBack} />
            </HasRole>
          </Grid.Row>
        </Grid>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  current: state.current,
});

export default withRouter(connect(mapStateToProps)(HSAccountDetails));
