import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { loadMorePaginationProp, SimpleLoadingComponent } from '../../utils/apollo';
import AdvancedHighSchoolResults from './advanced-highschool-results';

const query = gql`
  query AdvancedHighSchoolResults($filter: String!, $first: Int!, $after: String, $advanced: AdvancedHighSchoolFilter!) {
    viewer {
      id
      advancedHighSchoolSearch(filter: $filter, first: $first, after: $after, advanced: $advanced) {
        edges {
          node {
            id
            name
            general {
              field_hs_na_nces_id
            }
            naviance {
              field_hs_na_nguid
            }
            address {
              field_hs_elna_state_latest
              field_hs_elna_city
              field_hs_eln_zip
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ filter, advanced }) => ({
    variables: {
      first: 5,
      filter,
      advanced,
    },
  }),
  props: loadMorePaginationProp(query),
});

export default SimpleLoadingComponent(graphqlData)(AdvancedHighSchoolResults);
