import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import get from 'lodash/get';
import set from 'lodash/set';
import update from 'immutability-helper';
import { SimpleLoadingComponent } from '../../utils/apollo';

import AdvancedInstitutionResults from './advanced-institution-results';

const query = gql`
  query AdvancedInstitutionResults($filter: String!, $first: Int!) {
    viewer {
      id
      searchByCategory(category: INSTITUTIONS, filter: $filter, maxSize: $first) {
        results {
          id
          name
          ... on HigherEd {
            field_he_institution_id
            field_city
            field_state_short_name
            field_postalcode
          }
          ... on HighSchool {
            general {
              field_hs_na_nces_id
            }
            naviance {
              field_hs_na_nguid
            }
            address {
              field_hs_elna_state_latest
              field_hs_elna_city
              field_hs_eln_zip
            }
          }
        }
      }
    }
  }
`;

const paginationAmount = 5;

const graphqlData = graphql(query, {
  options: ({ filter }) => ({
    variables: {
      first: paginationAmount,
      filter,
    },
  }),
  props({ data }) {
    const { loading, variables, error, fetchMore } = data;
    return {
      loading,
      error,
      data,
      loadMore: () => fetchMore({
        variables: { ...variables, first: get(data, 'viewer.searchByCategory[0].results.length') + paginationAmount },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newResults = get(fetchMoreResult, 'viewer.searchByCategory[0].results');
          return update(previousResult, set({}, 'viewer.searchByCategory[0]', {
            results: { $set: newResults },
          }));
        },
      }),
    };
  },
});

export default SimpleLoadingComponent(graphqlData)(AdvancedInstitutionResults);
