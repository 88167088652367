import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import format from 'date-fns/format';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import DateRangeInput from '../../components/DateRangeInput';
import Label from '../../components/Label';
import styles from './styles.css';

export const messages = defineMessages({
  header: {
    id: 'scholarship.modal.header',
    defaultMessage: 'Scholarship Matching',
  },
  dateInputLabel: {
    id: 'scholarship.modal.dateInputLabel',
    defaultMessage: 'Matching Start Date',
  },
  validDate: {
    id: 'scholarship.modal.validDate',
    defaultMessage: 'Matching Start Date is required.',
  },
  notBeyond: {
    id: 'scholarship.modal.notBeyond',
    defaultMessage: 'Matching Start Date cannot be beyond the Scholarship subscription end date.',
  },
});

function ScholarshipModal(params) {
  const { open, onCancel, intl, value = '', endDate = '', heAccountId, mutateHEAccount, canEdit } = params;
  const initialDate = value && format(new Date(value), 'yyyy-MM-dd');
  const [selectedDate, setDate] = React.useState(initialDate);
  const [error, setError] = React.useState();

  return (
    <Modal open={open} size="tiny" data-cy="scholarship-matching-modal">
      <Modal.Header>{intl.formatMessage(messages.header)}</Modal.Header>
      <Modal.Content>
        <div>
          <span className={styles.label}>
            {intl.formatMessage(messages.dateInputLabel)} :
          </span>
          <DateRangeInput
            value={selectedDate}
            from={new Date()}
            to={new Date(endDate)}
            initialMonth={new Date()}
            id="matching-start-date"
            label={intl.formatMessage(messages.dateInputLabel)}
            canEdit={canEdit}
            onChange={(newDate) /* istanbul ignore next */ => {
              setDate(newDate);
              setError();
            }}
          />
        </div>
        {error ?
          (
            <Label role="tooltip" tabIndex={-1} basic color="red" pointing data-cy="scholarship-matching-modal-error">
              {intl.formatMessage(messages[error])}
            </Label>
          ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          onClick={() => {
            setDate(initialDate);
            onCancel();
            setError();
          }}
        >
          <FormattedMessage id="common.button.cancel" defaultMessage="Cancel" />
        </Button>
        {canEdit && (
          <Button
            primary
            onClick={async () => {
              if (!selectedDate) {
                setError('validDate');
              } else if (new Date(selectedDate).getTime() > endDate) {
                setError('notBeyond');
              } else {
                // save data
                await mutateHEAccount({
                  variables: {
                    input: {
                      heAccount: {
                        id: heAccountId,
                        scholarshipsMatchStartDate: new Date(selectedDate).getTime(),
                      },
                    },
                  },
                });
                onCancel();
              }
            }}
          >
            <FormattedMessage id="common.button.save" defaultMessage="Save" />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

ScholarshipModal.propTypes = {
  open: PropTypes.bool,
  intl: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  mutateHEAccount: PropTypes.func.isRequired,
  value: PropTypes.number,
  endDate: PropTypes.number,
  heAccountId: PropTypes.string,
  canEdit: PropTypes.bool,
};

export default injectIntl(ScholarshipModal);
