import 'isomorphic-fetch';
import Cookie from 'js-cookie';

const removeAuthCookie = () => {
  const secure = window.location.protocol === 'https:';
  const domain = window.location.hostname.split('.').slice(-2).join('.');
  Cookie.remove('auth', { domain, secure });
};

export default (userId) => {
  removeAuthCookie();
  const endpoint = `${process.env.COMMUNITY_URL}cp-api/logout/${userId}`;
  fetch(endpoint, { method: 'POST', mode: 'no-cors' });
};
