import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import withCounselorCommunityMicro from './withCounselorCommunityMicro';
import { useRedirectToReferrer } from './useRedirectTo';

export function EditCounselorCommunityProfile(props) {
  const { userId } = useParams();
  const { EditUserProfilePage } = props;

  const onClose = useRedirectToReferrer();
  return (
    <div className="edit-cc-profile-container" data-cy="edit-cc-profile-container">
      <PageTitle title="Edit CC Profile" category="SUPPORT" />
      <EditUserProfilePage
        userId={userId}
        onClose={onClose}
        isSupportAdmin
      />
    </div>
  );
}
EditCounselorCommunityProfile.propTypes = {
  EditUserProfilePage: PropTypes.func,
};

export default withCounselorCommunityMicro(EditCounselorCommunityProfile);
