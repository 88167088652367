import gql from 'graphql-tag';

import HEUserDetails from '../../queries/heUserDetails';

export default gql`
  mutation createHEUser($input: createHEUserInput!) {
    createHEUser(input: $input) {
      heAccount {
        id
        userList {
          ...HEUserDetails
        }
      }
    }
  }
  ${HEUserDetails}
`;
