import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { ModuleNames } from '@purple/match-graphql-schema/lib/schema/common/types/SubscriptionModuleNameType/type';
import Header from '../../components/Header';
import Grid from '../../components/Grid';
import Card from '../../components/Card';
import './styles.css';
import messages from './messages';
import { checkIsActiveSubscription } from './utils';

const HeHealthReportScholarships = ({ scholarships, moduleSubscriptions }) => {
  const isActiveSubscription = checkIsActiveSubscription(moduleSubscriptions, ModuleNames.SCHOLARSHIPS);
  return (
    <Grid columns="equal" stackable styleName="health-report-section-container">
      <Grid.Row>
        <Grid.Column>
          <Header as="h2" data-cy="health-report-scholarships-header">
            <FormattedMessage {...messages.scholarships} />
          </Header>
          <Card fluid>
            <Card.Content styleName="health-report-card-content">
              <Header as="h3" styleName="health-report-count-header" data-cy="health-report-scholarships-count-header">
                <FormattedMessage {...messages.scholarshipsEntered} />
              </Header>
              <div data-cy="health-report-scholarships-count" styleName="health-report-count">
                {isActiveSubscription ? get(scholarships, 'meritBasedScholarships.length', 0) : 'N/A'}
              </div>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column />
      </Grid.Row>
    </Grid>
  );
};

HeHealthReportScholarships.propTypes = {
  scholarships: PropTypes.shape({
    meritBasedScholarships: PropTypes.array,
  }),
  moduleSubscriptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  })),
};

export default HeHealthReportScholarships;
