import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Form from '../../components/Form';
import Label from '../../components/Label';
import Button from '../../components/Button';
import DatePickerNavigation from '../../components/DayPicker/date-picker-navigation';
import InteractivePopup from '../../components/InteractivePopup';
import { messages } from './constants';
import { MSPERDAY } from '../../utils/date-time';

import './styles.css';

export class DateField extends React.PureComponent {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    dateLabel: PropTypes.object,
    ariaLabel: PropTypes.string,
    error: PropTypes.object,
    selectedDays: PropTypes.object,
    startDate: PropTypes.object,
  };

  onDayClick = (day, { disabled }) => {
    const { fieldName, onChange } = this.props;

    /* istanbul ignore else */
    if (!disabled) {
      onChange({ name: fieldName, value: day.getTime() });
    }
  };

  render() {
    const {
      id,
      dateLabel,
      ariaLabel,
      error,
      selectedDays,
    } = this.props;

    return (
      <Form.Field>
        <InteractivePopup
          trigger={(
            <div styleName="date-container">
              <Button
                id={id}
                icon="calendar alternate outline"
                type="button"
                basic
                role="tooltip"
                aria-label={ariaLabel}
                styleName="date-button"
                content={dateLabel}
              />
              {error}
            </div>
)}
          on="click"
          position="right center"
        >
          <DatePickerNavigation
            labels={{ nextMonth: 'Next Month', previousMonth: 'Previous Month' }}
            selectedDays={selectedDays}
            onDayClick={this.onDayClick}
            initialMonth={selectedDays ? new Date(selectedDays) : new Date(Date.now() + MSPERDAY)}
          />
        </InteractivePopup>
      </Form.Field>
    );
  }
}

const DateRange = ({
  ids,
  startDate,
  endDate,
  errors,
  onChange,
}) => {
  const start = startDate ? new Date(startDate) : null;
  const startInEndField = startDate ? new Date(startDate + MSPERDAY) : null;
  const end = endDate ? new Date(endDate) : null;

  const formattedStartLabel = start ? <FormattedDate value={start} month="short" day="numeric" year="numeric" /> : <FormattedMessage {...messages.selectDate} />;
  const formattedEndLabel = end ? <FormattedDate value={end} month="short" day="numeric" year="numeric" /> : <FormattedMessage {...messages.selectDate} />;

  const formattedErrorMessage = (
    <Label role="tooltip" basic color="red" pointing>
      <FormattedMessage {...messages.enterValue} />
    </Label>
  );
  const startErrorMessage = startDate < Date.now() ? messages.invalidStartDate : messages.enterValue;
  const formattedStartErrorMessage = (
    <Label role="tooltip" basic color="red" pointing>
      <FormattedMessage {...startErrorMessage} />
    </Label>
  );

  const handelChange = ({ name, value }) => {
    if (name === 'startDate') {
      onChange({ startDate: value, endDate: (!endDate || endDate < value ? value + MSPERDAY: endDate) });
    } else {
      onChange({ startDate, endDate: value });
    }
  };

  return (
    <Form.Group>
      <DateField
        id={ids.start}
        fieldName="startDate"
        dateLabel={formattedStartLabel}
        ariaLabel="Start Date Calendar"
        error={errors.startDate ? formattedStartErrorMessage : null}
        selectedDays={start}
        onChange={handelChange}
      />
      <span styleName="date-hyphen">&#45;</span>
      <DateField
        id={ids.end}
        fieldName="endDate"
        dateLabel={formattedEndLabel}
        ariaLabel="End Date Calendar"
        error={errors.endDate ? formattedErrorMessage : null}
        selectedDays={end}
        onChange={handelChange}
        startDate={startInEndField}
      />
    </Form.Group>
  );
};

DateRange.propTypes = {
  ids: PropTypes.object,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  errors: PropTypes.object,
  messages: PropTypes.object,
  onChange: PropTypes.func,
};

export default DateRange;
