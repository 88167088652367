import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import filesaver from 'file-saver';

import Button from '../../components/Button';
import Progress from '../../components/Progress';
import Modal from '../../components/Modal';
import { exportHEInstitutionClientsToCSV } from './utils';
import { SimpleGraphQLComponent } from '../../utils/apollo';
import messages from './messages';

const DOWNLOAD_LIMIT = 5000;
const CSV_FILENAME = 'he-clients.csv';

export class DownloadClientsComponent extends React.PureComponent {
  static propTypes = {
    doFetch: PropTypes.bool,
    searchByCategory: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
  };

  static getDerivedStateFromProps(props, state) {
    const next = props.searchByCategory;
    const { downloadReady } = state;

    if (next && !downloadReady) {
      return {
        requestProgress: 100,
        heInstitutionClients: next,
        downloadReady: true,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.state = {
      downloadReady: false,
      heInstitutionClients: [],
      requestProgress: 0,
    };
  }

  handleCancelClick = () => {
    this.finishDownload();
  };

  finishDownload = () => {
    const { onClose } = this.props;
    this.setState({
      downloadReady: false,
      heInstitutionClients: [],
      requestProgress: 0,
    });
    onClose();
  };

  handleDownloadFileClick = () => {
    const { heInstitutionClients } = this.state;
    const csvBlob = exportHEInstitutionClientsToCSV(heInstitutionClients);
    filesaver.saveAs(csvBlob, CSV_FILENAME);
    this.finishDownload();
  };

  render() {
    const { doFetch } = this.props;
    const { downloadReady, requestProgress } = this.state;

    return (
      <Modal
        open={doFetch}
      >
        <Modal.Content data-cy="download-clients-content">
          <Progress percent={requestProgress} color="teal">
            <FormattedMessage {...(downloadReady ? messages.downloadReady : messages.downloadingData)} />
          </Progress>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancelClick} data-cy="download-clients-cancel-button">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            disabled={!downloadReady}
            onClick={this.handleDownloadFileClick}
            data-cy="download-clients-generate-list-button"
          >
            <FormattedMessage {...messages.generateButton} />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

/* eslint-disable graphql/template-strings */
export const query = gql`
  query searchByCategory($limit: Int!) {
    viewer {
      id
      searchByCategory(
        category: HE_ACCOUNTS
        filter: ""
        exclude: []
        maxSize: $limit
      ) {
        category
        results {
          ... on Searchable {
            name
            id
            ... on HEAccount {
              scid
              collegeCore {
                address {
                  state
                  city
                }
              }
            }
          }
        }
      }
    }
  }
`;
/* eslint-enable graphql/template-strings */

const queryData = {
  initialVariables: {
    limit: DOWNLOAD_LIMIT,
  },
};

const graphqlData = graphql(query, {
  props: ({ data }) => {
    if (data.loading) return null;
    return ({
      searchByCategory: get(data, 'viewer.searchByCategory[0].results', []),
    });
  },
  skip: (props) => !props.doFetch,
  options: () => {
    const variables = { ...queryData.initialVariables };
    return {
      variables: {
        ...variables,
      },
      fetchPolicy: 'no-cache',
    };
  },
});

export default SimpleGraphQLComponent(graphqlData)(DownloadClientsComponent);
