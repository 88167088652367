import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { loadMorePaginationProp, SimpleLoadingComponent } from '../../utils/apollo';
import AdvancedHigherEdResults from './advanced-highered-results';

const query = gql`
  query AdvancedHigherEdResults($filter: String!, $first: Int!, $after: String, $advanced: AdvancedHigherEdFilter!) {
    viewer {
      id
      advancedHigherEdSearch(filter: $filter, first: $first, after: $after, advanced: $advanced) {
        edges {
          node {
            id
            field_he_institution_id
            name
            field_city
            field_state_short_name
            field_postalcode
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ filter, advanced }) => ({
    variables: {
      first: 5,
      filter,
      advanced,
    },
  }),
  props: loadMorePaginationProp(query),
});

export default SimpleLoadingComponent(graphqlData)(AdvancedHigherEdResults);
