import React from 'react';
import PropTypes from 'prop-types';
import SelectedPill from './selected-pill';

import './styles.css';

class ZipField extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    inputValue: '',
    isActive: false,
  }

  onTextChange = (e) => {
    const { value } = e.target;
    const { value: values, onChange } = this.props;
    if (!value) this.setState({ inputValue: value });

    if (/^\d+$/.test(value)) {
      /* istanbul ignore else */
      if (value.length < 5) {
        this.setState({ inputValue: value });
      } else if (!values.includes(value)) {
        this.setState({ inputValue: '' });
        onChange(values.concat(value.substring(0, 5)));
      }
    }
  }

  removeZip = (data) => {
    const { name: zip } = data;
    const { value: values, onChange } = this.props;
    onChange(values.filter((z) => z !== zip));
  }

  handleKeyDown = (e) => {
    const { inputValue } = this.state;
    const { value: values, onChange } = this.props;
    /* istanbul ignore else */
    if (e.key === 'Enter') {
      /* istanbul ignore else */
      if (inputValue.length === 5) {
        onChange(values.concat(inputValue));
        this.setState({ inputValue: '' });
      }
      e.preventDefault();
    } else if (e.key === 'Backspace') {
      if (!inputValue) {
        if (values.length > 0) {
          const zip = values.pop();
          this.setState({
            inputValue: zip,
          });
          onChange(values);
          e.preventDefault();
        }
      }
    }
  }

  handleInputRef = (ref) => {
    this.inputRef = ref;
  }

  focusInput = (e) => {
    /* istanbul ignore else */
    if (this.inputRef) {
      this.inputRef.focus();
      this.setState({
        isActive: true,
      });
    }
  }

  blurInput = (e) => {
    /* istanbul ignore else */
    if (this.inputRef) {
      this.inputRef.blur();
      this.setState({
        isActive: false,
      });
    }
  }

  render() {
    const { value } = this.props;
    const { isActive, inputValue } = this.state;
    return (
      <React.Fragment>
        <div
          styleName={isActive ? 'select-field active': 'select-field'}
          onFocus={this.focusInput}
          onBlur={this.blurInput}
          role="presentation"
          tabIndex="0"
        >
          <span>
            {
              value.map((zip) => (
                <SelectedPill
                  key={zip}
                  data={{ name: zip }}
                  onRemoveClick={this.removeZip}
                />
              ))
            }
          </span>
          <input
            id={this.props.id}
            value={inputValue}
            type="text"
            onChange={this.onTextChange}
            onKeyDown={this.handleKeyDown}
            onFocus={this.focusInput}
            onBlur={this.handleBlur}
            ref={this.handleInputRef}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ZipField;
