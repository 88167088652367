import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { SimpleLoadingComponent } from '../../utils/apollo';

import HSAccountPage from './hs-account-page';

const query = gql`
  query HEAccountPage($id: ID!) {
    node(id: $id) {
      id
      ...on HSAccount {
        ...NavDeNav
        ...HSAccountDetails
      }
    }
  }
  ${HSAccountPage.getFragments()[0]}
  ${HSAccountPage.getFragments()[1]}
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
});

export default withRouter(SimpleLoadingComponent(graphqlData)(HSAccountPage));
