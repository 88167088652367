import React from 'react';
import PropTypes from 'prop-types';
import { DateUtils } from 'react-day-picker';
import { injectIntl, defineMessages } from 'react-intl';

import Input from '../Input';
import Button from '../Button';
import InteractivePopup from '../InteractivePopup';
import DayPicker from '../DayPicker';
import './styles.css';

const displayDateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
const isoDateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;

function getDate(value) {
  return new Date(Date.parse(value));
}

const messages = defineMessages({
  datePickerButton: {
    id: 'common.datePickerButton',
    defaultMessage: 'Open Date Picker',
  },
});

class DateRangeInput extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatDate: PropTypes.func.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    initialMonth: PropTypes.instanceOf(Date).isRequired,
    from: PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.instanceOf(Date).isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    canEdit: PropTypes.bool,
  };

  onDateChange = (value) => {
    this.props.onChange(value.toISOString().split('T')[0]);
  };

  onInputChange = (e) => {
    if (isoDateRegex.exec(e.target.value) || displayDateRegex.exec(e.target.value)) {
      const value = getDate(e.target.value);
      if (!Number.isNaN(value.getTime())) {
        this.props.onChange(value.toISOString().split('T')[0]);
      }
    }
  };

  render() {
    const { intl, id, label, initialMonth, from, to, value, canEdit } = this.props;
    const popupTrigger = <Button id={id} title={intl.formatMessage(messages.datePickerButton)} color="teal" icon="calendar alternate outline" />;
    return (
      <Input action>
        <input
          type="date"
          name={id}
          className="no-clear"
          styleName="dateInput"
          aria-label={label}
          onChange={this.onInputChange}
          value={value}
        />
        {canEdit && (
          <InteractivePopup hoverable on="click" trigger={popupTrigger}>
            <DayPicker
              labels={{ nextMonth: 'Next Month', previousMonth: 'Previous Month' }}
              initialMonth={initialMonth}
              selectedDays={(day) => DateUtils.isDayInRange(day, { from, to })}
              disabledDays={(day) => DateUtils.isFutureDay(day)}
              onDayClick={this.onDateChange}
            />
          </InteractivePopup>
        )}
      </Input>
    );
  }
}

export default injectIntl(DateRangeInput);
