import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import createHSUserMutation from './create-hs-user-mutation';
import { SimpleLoadingComponent } from '../../utils/apollo';
import CreateSecondaryUserPage from './create-secondary-user-page';

const query = gql`
  query HSAccountDetails($id: ID!) {
    node(id: $id) {
      id
      ...on HSAccount {
        id
        name
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
});
const createHSUser = graphql(createHSUserMutation, {
  name: 'mutateCreateHSUser',
});

export default withRouter(SimpleLoadingComponent(graphqlData, createHSUser)(CreateSecondaryUserPage));
