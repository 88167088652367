import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { SimpleLoadingComponent } from '../../utils/apollo';
import HSAccountUsersPage from './hs-account-users-page';

const query = gql`
  query HSAccountDetails($id: ID!) {
    node(id: $id) {
      id
      ...on HSAccount {
        id
        name
        deNavianceRequired
        highSchool {
          id
          general {
            field_hs_na_nces_id
          }
          isActiveNavianceSchool
        }
        ...HSAccountUsersTable
      }
    }
    viewer {
      id
      enabledFeatures
    }
  }
  ${HSAccountUsersPage.getFragments()[0]}
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
});

export default withRouter(SimpleLoadingComponent(graphqlData)(HSAccountUsersPage));
