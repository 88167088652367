import React from 'react';
import PropTypes from 'prop-types';
import JSONMarkup from 'json-markup';
import 'json-markup/style.css';
import { defineMessages, FormattedDate, FormattedMessage, FormattedTime, injectIntl } from 'react-intl';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Message from '../../components/Message';
import Table from '../../components/Table';
import GraphQLLoader from '../../components/GraphQLLoader';
import * as util from './log-history-formatter';

export const messages = defineMessages({
  loggedInAs: {
    id: 'he-account.logHistory.loggedInAs',
    defaultMessage: 'logged in as',
  },
});

class LogHistoryTable extends React.PureComponent {
  static propTypes = {
    hasNextPage: PropTypes.bool,
    logHistory: PropTypes.array,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  static defaultProps = {
    logHistory: [],
    hasNextPage: false,
  };

  state = {
    rawLogs: false,
  };

  switchRawLogs = () => {
    const { rawLogs } = this.state;
    this.setState({ rawLogs: !rawLogs });
  };

  render() {
    const { logHistory, hasNextPage, intl, loadMore } = this.props;
    const { rawLogs } = this.state;
    if (!logHistory.length) {
      return (
        <Message info data-cy="he-account-logHistory-no-results-message">
          <FormattedMessage id="he-account.logHistory.noResults" defaultMessage="No Results" />
        </Message>
      );
    } else {
      return (
        <Table>
          <caption><FormattedMessage id="he-account.logHistory.logResults.caption" defaultMessage="Log Results" /></caption>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="he-account.logHistory.logResults.header.timestamp" defaultMessage="Timestamp" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="he-account.logHistory.logResults.header.action" defaultMessage="Action" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="he-account.logHistory.logResults.header.changes" defaultMessage="Changes" />
                <Icon name="code" onClick={this.switchRawLogs} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {logHistory.map((logEntry) => {
              let actorString = `${logEntry.actor.firstName} ${logEntry.actor.lastName || ''}`;
              if (logEntry.actor.impersonator) {
                actorString = `${logEntry.actor.impersonator} ${intl.formatMessage(messages.loggedInAs)} ${actorString}`;
              }
              const { target } = logEntry;
              const targetName = util.formatTargetName(target);
              const changeFieldDisplay = !rawLogs && util.formatChangeFieldDisplay(logEntry);
              return (
                <Table.Row key={logEntry.id}>
                  <Table.Cell>
                    <FormattedDate value={logEntry.timestamp} />&nbsp;
                    <FormattedTime hour="numeric" minute="numeric" second="numeric" value={logEntry.timestamp} />
                  </Table.Cell>
                  <Table.Cell>
                    {actorString} {logEntry.action} {targetName}
                  </Table.Cell>
                  <Table.Cell>
                    {
                      changeFieldDisplay ||
                      // eslint-disable-next-line react/no-danger
                      <div dangerouslySetInnerHTML={{ __html: JSONMarkup(JSON.parse(logEntry.changed)) }} />
                    }
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          {hasNextPage && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="4">
                <Button onClick={loadMore}>
                  <FormattedMessage
                    id="he-account.logHistory.loadMore"
                    description="Button to trigger loading more log entries"
                    defaultMessage="More Log Entries"
                  />
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
          )}
        </Table>
      );
    }
  }
}
export default GraphQLLoader(injectIntl(LogHistoryTable));
