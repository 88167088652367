import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { ModuleNames } from '@purple/match-graphql-schema/lib/schema/common/types/SubscriptionModuleNameType/type';
import Header from '../../components/Header';
import Grid from '../../components/Grid';
import Card from '../../components/Card';
import './styles.css';
import messages from './messages';
import { checkIsActiveSubscription } from './utils';

const HeHealthReportRVandEvents = ({ collegeEvents, repVisits, moduleSubscriptions }) => {
  const eventsCount = get(collegeEvents, 'edges.length', 0);
  const repVisitsCount = get(repVisits, 'length', 0);
  const isActiveSubscription = checkIsActiveSubscription(
    moduleSubscriptions,
    ModuleNames.REPVISITS,
  );
  return (
    <Grid columns="equal" stackable styleName="health-report-section-container">
      <Grid.Row>
        <Grid.Column>
          <Header as="h2" data-cy="health-report-rep-visits-header">
            <FormattedMessage {...messages.repVisits} />
          </Header>
          <Card fluid>
            <Card.Content styleName="health-report-card-content">
              <Header as="h3" styleName="health-report-count-header" data-cy="health-report-rep-visits-count-header">
                <FormattedMessage {...messages.repVisitsCount} />
              </Header>
              <div data-cy="health-report-rep-visits-count" styleName="health-report-count">
                {repVisitsCount}
              </div>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <Header as="h2" data-cy="health-report-events-header">
            <FormattedMessage {...messages.events} />
          </Header>
          <Card fluid>
            <Card.Content styleName="health-report-card-content">
              <Header as="h3" styleName="health-report-count-header" data-cy="health-report-events-count-header">
                <FormattedMessage {...messages.eventsCount} />
              </Header>
              <div data-cy="health-report-events-count" styleName="health-report-count">
                {isActiveSubscription ? eventsCount : 'N/A'}
              </div>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

HeHealthReportRVandEvents.propTypes = {
  collegeEvents: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    ),
  }),
  repVisits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  moduleSubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
};

export default HeHealthReportRVandEvents;
