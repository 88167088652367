import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import { FormattedMessage, defineMessages } from 'react-intl';
import Table from '../../components/Table';
import HSAccountUsersTableRow from './hs-account-users-table-row';
import './styles.css';

const messages = defineMessages({
  status: {
    id: 'hs-account.users-table.status',
    defaultMessage: 'Status',
  },
  community: {
    id: 'hs-account.users-table.community',
    defaultMessage: 'Community',
  },
  role: {
    id: 'hs-account.users-table.role',
    defaultMessage: 'Role',
  },
  firstName: {
    id: 'hs-account.users-table.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'hs-account.users-table.lastName',
    defaultMessage: 'Last Name',
  },
  login: {
    id: 'hs-account.users-table.login',
    defaultMessage: 'Login',
  },
  actions: {
    id: 'hs-account.users-table.actions',
    defaultMessage: 'Actions',
  },
});

class HSAccountUsersTable extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    showDisabledUsers: PropTypes.bool,
    handleViewCCProfile: PropTypes.func,
    isViewEditCCProfileButtonEnabled: PropTypes.bool,
  };

  render() {
    const { node, showDisabledUsers, handleViewCCProfile, isViewEditCCProfileButtonEnabled } = this.props;
    let { userList } = node;

    if (showDisabledUsers) {
      userList = userList.filter((user) => !user.active);
    } else {
      userList = userList.filter((user) => user.active);
    }

    if (userList.length > 0) {
      userList = [...userList];
      const primaryUserId = get(node, 'primaryUser.id');
      const primaryUser = userList.find((user) => user.id === primaryUserId);
      const userListNoPrimary = userList.filter((user) => user.id !== primaryUserId);
      userList = userListNoPrimary.sort((a, b) =>
        (lowerCase(a.lastName) < lowerCase(b.lastName) ? -1 : 1));
      if (primaryUser) userList.unshift(primaryUser);
    }

    return (
      <Table>
        <caption styleName="caption">
          <FormattedMessage
            id="hs-account.users-table.caption"
            defaultMessage="{name}'s User List"
            values={{
              name: node.name,
            }}
          />
        </caption>
        <Table.Header>
          <Table.Row>
            {!node.highSchool.isActiveNavianceSchool && (
              <Table.HeaderCell>
                <FormattedMessage {...messages.status} />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              <FormattedMessage {...messages.firstName} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage {...messages.lastName} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage {...messages.role} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage {...messages.community} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage {...messages.login} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage {...messages.actions} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {userList.map((user) => (
            <HSAccountUsersTableRow
              user={user}
              key={user.id}
              hsAccount={node}
              handleViewCCProfile={handleViewCCProfile}
              isViewEditCCProfileButtonEnabled={isViewEditCCProfileButtonEnabled}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default HSAccountUsersTable;
