import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import Form from '../../components/Form';
import Header from '../../components/Header';

class SimpleCheckboxRenderer extends React.PureComponent {
  static propTypes = {
    value: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
  };

  static defaultProps = {
    onChange: _noop,
    options: [],
  };

  handleChange = (e, { name, checked }) => {
    const { onChange, name: fieldName } = this.props;
    onChange({
      name: fieldName,
      value: checked,
    });
  }

  render() {
    const { options, name: fieldName, label: fieldLabel, value } = this.props;
    const [option] = options;
    if (option) {
      const { name, label } = option;
      return (
        <React.Fragment>
          <Header as="h3">{fieldLabel}</Header>
          <Form.Field key={fieldName}>
            <Checkbox
              id={name}
              name={name}
              label={label}
              onChange={this.handleChange}
              checked={value}
            />
          </Form.Field>
        </React.Fragment>
      );
    }
    return null;
  }
}

export default SimpleCheckboxRenderer;
