import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Footer from '../../components/Footer';
import NavSidebar from '../../components/NavSidebar';
import Nav from '../../components/Nav';
import Message from '../../components/Message';
import SiteHeader from '../../components/SiteHeader';
import Sidebar from '../../components/Sidebar';
import IdleTimeout from '../IdleTimeout';
import './styles.css';

function closest(target, nodeName) {
  return target && ((target.nodeName === nodeName && target) || closest(target.parentNode, nodeName));
}

class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired,
    }).isRequired,
    idleTimeout: PropTypes.number,
  };

  state = { sidebarVisible: false, hasError: false };

  componentDidMount() {
    this.routerListener = this.props.history.listen(() => this.setState({ hasError: false }));
  }

  componentWillUnmount() {
    this.routerListener();
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    if (window.Sentry) {
      window.Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        window.Sentry.captureException(error);
      });
    }
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({ sidebarVisible: !prevState.sidebarVisible }));
  };

  navClickHandler = (event) => {
    const { target } = event;
    if (this.state.sidebarVisible && closest(target, 'A')) {
      this.setState({ sidebarVisible: false });
    }
  };

  pusherClick = () => {
    if (this.state.sidebarVisible) {
      this.setState({ sidebarVisible: false });
    }
  };

  render() {
    const { children, idleTimeout } = this.props;
    const { hasError, sidebarVisible } = this.state;
    return (
      <Sidebar.Pushable styleName="wrapper" onClick={this.navClickHandler}>
        <NavSidebar
          styleName="hidden"
          animation="push"
          className="menu visible-mobile-flex visible-tablet-flex"
          closeSidebar={this.toggleSidebar}
          visible={sidebarVisible}
        />
        <IdleTimeout timeout={(idleTimeout || 1800) * 1000} />
        <Nav className="hidden-mobile hidden-tablet" />
        <Sidebar.Pusher dimmed={sidebarVisible} styleName="main" onClick={this.pusherClick}>
          <header styleName="header" role="banner">
            <SiteHeader onToggleSidebar={this.toggleSidebar} />
          </header>
          <main styleName="content">
            {hasError ? <Message error header="Oops, something went wrong" /> : children }
          </main>
          <footer styleName="footer">
            <Footer />
          </footer>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default withRouter(App);
