import gql from 'graphql-tag';

export default gql`
  mutation updateHEAccount($input: updateHEAccountInput!) {
    updateHEAccount(input: $input) {
      heAccount {
        id
        connectId
        freemium
        moduleSubscriptions {
          name
          status
          quoteChargeId
          startDate
          endDate
          lastModifiedBy
          lastModifiedDate
        }
        radiusId
        scholarshipsMatchStartDate
      }
    }
  }
`;
