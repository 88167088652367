import { GraphQLEnumType } from 'graphql';
import unionBy from 'lodash/unionBy';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import find from 'lodash/find';
import intersectionBy from 'lodash/intersectionBy';
import cloneDeep from 'lodash/cloneDeep';

export const ModuleNames = {
  HUBPAGE: 'HUBPAGE',
  COMMUNITY: 'COMMUNITY',
  MATCHING: 'MATCHING',
  REPVISITS: 'REPVISITS',
  AMEVENTS: 'AMEVENTS',
  AMPLUS: 'AMPLUS',
  ADVANCEDAWARENESS: 'ADVANCEDAWARENESS',
  CONNECTION: 'CONNECTION',
  CAMINO: 'CAMINO',
  SCHOLARSHIPS: 'SCHOLARSHIPS',
};

const moduleNameType = new GraphQLEnumType({
  name: 'ModuleName',
  description: 'An enumeration of Module names',
  values: {
    [ModuleNames.HUBPAGE]: { value: ModuleNames.HUBPAGE, description: 'Legacy: Hub Page management' },
    [ModuleNames.COMMUNITY]: { value: ModuleNames.COMMUNITY, description: 'Legacy: Community' },
    [ModuleNames.MATCHING]: { value: ModuleNames.MATCHING, description: 'Intersect Awareness Subscription' },
    [ModuleNames.REPVISITS]: { value: ModuleNames.REPVISITS, description: 'Intersect Presence Subscription' },
    [ModuleNames.AMEVENTS]: { value: ModuleNames.AMEVENTS, description: 'ActiveMatch Events Subscription' },
    [ModuleNames.AMPLUS]: { value: ModuleNames.AMPLUS, description: 'ActiveMatch by Naviance Subscription' },
    [ModuleNames.ADVANCEDAWARENESS]: { value: ModuleNames.ADVANCEDAWARENESS, description: 'Advanced Awareness Subscription' },
    [ModuleNames.CONNECTION]: { value: ModuleNames.CONNECTION, description: 'Connection Subscription' },
    [ModuleNames.CAMINO]: { value: ModuleNames.CAMINO, description: 'Camino Subscription' },
    [ModuleNames.SCHOLARSHIPS]: { value: ModuleNames.SCHOLARSHIPS, description: 'Scholarships Subscription' },
  },
});

// featureFlag : moduleName
const moduleFeatureFlags = {
  AMEVENTS: ModuleNames.AMEVENTS,
  CAMINO_PROVISION: ModuleNames.CAMINO,
  SCHOLARSHIPS_PROVISION: ModuleNames.SCHOLARSHIPS,
};

const defaultAllModuleSubscriptions = moduleNameType.getValues().map((each) => ({ name: each.value }));

const featureFlaggedSubscriptions = Object.keys(moduleFeatureFlags).join(',');

function expandVisibleModuleSubscriptions(heAccount, ctx) {
  // exclude subscription modules whose feature flags are not set
  const nonEnabledSubscriptions = difference(Object.keys(moduleFeatureFlags), ctx.enabledFeatures).map((each) => ({
    name: moduleFeatureFlags[each],
  }));
  const visibleSubscriptions = filter(defaultAllModuleSubscriptions, (o) => !find(nonEnabledSubscriptions, o));

  // expand all
  const moduleSubscriptions = unionBy(heAccount.moduleSubscriptions, defaultAllModuleSubscriptions, 'name');

  // trim to what you can see
  heAccount.moduleSubscriptions = cloneDeep(intersectionBy(moduleSubscriptions, visibleSubscriptions, 'name'));
}

export { defaultAllModuleSubscriptions, expandVisibleModuleSubscriptions, featureFlaggedSubscriptions };
export default moduleNameType;
