import gql from 'graphql-tag';

export default gql`
  query searchByCategory($category: SearchCategory!, $filter: String!) {
    viewer {
      id
      searchByCategory(category: $category, filter: $filter, maxSize: 5) {
        category,
        results {
          ... on Searchable {
            name
            id
            ... on HEUser {
              institution
            }
            ... on HEAccount {
              scid
            }
            ... on CollegeCore {
              codes {
                SCID
              }
            }
            ... on HigherEd {
              field_he_institution_id
              field_city
              field_state_short_name
              field_he_institution_id
            }
            ... on HighSchool {
              general {
                field_hs_na_nces_id
              }
              naviance {
                field_hs_na_nguid
              }
              address {
                field_hs_elna_state_latest
                field_hs_elna_city
              }
            }
            ... on CommunityPerson {
              field_profile_picture_file_url
              field_user_institution_title
              communityId
            }
            ... on CommunityGroup {
              body_value
            }
            ... on CommunityPost {
              title
            }
          }
        }
      }
    }
  }
`;
