import gql from 'graphql-tag';

export default gql`
  mutation createAMSubscription($input: createAMSubscriptionInput!) {
    createAMSubscription(input: $input) {
      amSubscriptions {
        edges {
          node {
            id
            version
            institution
            type
            majors
            connection
            startDate
            endDate
            audience {
              diversity
            }
            visible
          }
        }
      }
    }
  }
`;
