import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link, withRouter } from 'react-router-dom';
import CreateUser from '../../components/CreateUser';
import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon';

class CreatePrimaryUserPageComponent extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        linkBack: PropTypes.object,
      }),
    }),
    node: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    mutateUpdateHEUser: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    createError: {},
    requesting: false,
  };

  getLinkBack() {
    return get(this.props, 'location.state.linkBack', null);
  }

  navigateToCaller() {
    const path = this.getLinkBack() || `/he-account/${this.props.node.id}/`;
    this.props.history.push(path);
  }

  _handleCancel = () => {
    this.navigateToCaller();
  };

  _handleSave = ({ firstName, lastName, email }) => {
    const { node } = this.props;

    this.setState({ requesting: true, createError: {} });
    this.props.mutateUpdateHEUser({
      variables: {
        input: {
          heAccount: {
            id: node.id,
            user: { firstName, lastName, email },
          },
        },
      },
    }).then(() => this.navigateToCaller(), (error) => {
      const state = {
        requesting: false,
      };
      if (!error) {
        state.createError = { generalError: true };
      } else if (error.message.includes('User account already exists')) {
        state.createError = { userExists: true };
      } else if (error.message.includes('Primary User already exists')) {
        state.createError = { primaryExists: true };
      } else {
        state.createError = { generalError: true };
      }
      this.setState(state);
    });
  };

  render() {
    const { createError, requesting } = this.state;
    const linkBack = this.getLinkBack();
    return (
      <div>
        <PageTitle title="User" category="Support" />
        {
          linkBack &&
          (<Link to={linkBack}><Icon name="angle left" />Back</Link>)
        }
        <CreateUser
          primary
          accountName={this.props.node.name}
          onCancel={this._handleCancel}
          onSave={this._handleSave}
          requesting={requesting}
          createError={createError}
        />
      </div>
    );
  }
}

export default withRouter(CreatePrimaryUserPageComponent);
