import gql from 'graphql-tag';

export default gql`
  fragment AnnouncementDetails on Announcement {
    id
    title
    content
    audience
    status
    createdDate
    createdFullName
  }
`;
