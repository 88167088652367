import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link, withRouter } from 'react-router-dom';
import UpdatePrimaryUser from '../../components/UpdatePrimaryUser';
import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon';

class HSAccountUpdatePrimaryUserPageComponent extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        linkBack: PropTypes.object,
      }),
    }),
    node: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      primaryUser: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
    }).isRequired,
    mutateUpdateHSUser: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    updateError: {},
    requesting: false,
  };

  componentDidMount() {
    if (!this.props.node.primaryUser) {
      this.navigateToCaller();
    }
  }

  getLinkBack() {
    return get(this.props, 'location.state.linkBack');
  }

  navigateToCaller() {
    const path = this.getLinkBack() || `/hs-account/${this.props.node.id}/`;
    this.props.history.push(path);
  }

  _handleCancel = () => {
    this.navigateToCaller();
  };

  _handleSave = (updatedUser) => {
    const { node } = this.props;

    this.setState({ requesting: true });
    this.props.mutateUpdateHSUser({
      variables: {
        input: {
          hsAccount: {
            id: node.id,
            user: { ...updatedUser, id: node.primaryUser.id },
          },
        },
      },
    }).then(() => this.navigateToCaller(), (error) => {
      const state = {
        requesting: false,
      };
      if (!error) {
        state.updateError = { generalError: true };
      } else if (error.message.includes('User account already exists')) {
        state.updateError = { userExists: true };
      } else {
        state.updateError = { generalError: true };
      }
      this.setState(state);
    });
  };

  render() {
    const { node } = this.props;
    const { requesting, updateError } = this.state;
    const linkBack = this.getLinkBack();
    return (
      <div>
        <PageTitle title="User" category="Support" />
        {
          linkBack &&
          (<Link to={linkBack}><Icon name="angle left" />Back</Link>)
        }
        { node.primaryUser && (
        <UpdatePrimaryUser
          accountName={node.name}
          primaryUser={node.primaryUser}
          onCancel={this._handleCancel}
          onSave={this._handleSave}
          requesting={requesting}
          updateError={updateError}
        />
        ) }
      </div>
    );
  }
}

export default withRouter(HSAccountUpdatePrimaryUserPageComponent);
