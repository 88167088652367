import gql from 'graphql-tag';

import HSUserDetails from '../../queries/hsUserDetails';

export default gql`
  mutation updateHSUser($input: updateHSAccountInput!) {
    updateHSAccount(input: $input) {
      hsAccount {
        id
        primaryUser {
          ...HSUserDetails
        }
        userList {
          ...HSUserDetails
        }
      }
    }
  }
  ${HSUserDetails}
`;
