import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import {
  compose,
  branch,
  renderNothing,
  renderComponent,
  withProps,
  setDisplayName,
} from 'react-recompose';
import { authorized } from '../containers/Authorization';
import GraphQLLoader from '../components/GraphQLLoader';
import GraphQLError from '../components/GraphQLError';

export const FeatureFlags = {
  INTERSECT_HOMEPAGE: 'INTERSECT_HOMEPAGE',
  NAVIANCE_SYNC_LOG: 'NAVIANCESYNCLOG',
  HEALTH_REPORT: 'HEALTH_REPORT',
  COMMUNITY_SERVERLESS: 'COMMUNITYSERVERLESS',
  DISABLE_LEGACY_COMMUNITY: 'DISABLE_LEGACY_COMMUNITY',
};

const EnabledFeaturesQuery = gql`
  query enabledFeatures {
    viewer {
      id
      enabledFeatures
    }
  }
`;

const withEnabledFeatures = compose(
  authorized(),
  setDisplayName('withEnabledFeatures'),
  graphql(EnabledFeaturesQuery),
  GraphQLLoader,
  GraphQLError,
  withProps(({ data: { viewer } }) => ({
    enabledFeatures: viewer && viewer.enabledFeatures,
  })),
);

function hasFeature(features, options, enabled) {
  return compose(
    setDisplayName(enabled ? 'onFeatureEnabled' : 'onFeatureDisabled'),
    withEnabledFeatures,
    branch(
      ({ enabledFeatures }) => [].concat(features)
        .every((feature) => enabledFeatures.includes(feature) !== enabled),
      renderComponent(options.failure || renderNothing()),
    ),
  );
}

const onFeatureEnabled = (features, options) => hasFeature(features, options || {}, true);
const onFeatureDisabled = (features, options) => hasFeature(features, options || {}, false);

export { EnabledFeaturesQuery, withEnabledFeatures, onFeatureEnabled, onFeatureDisabled };
