import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { withRouter } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import queryString from 'query-string';
import Button from '../../components/Button';
import Segment from '../../components/Segment';
import Form from '../../components/Form';
import SearchField from '../../components/SearchField';
import '../AdvancedSearch/styles.css';

const messages = defineMessages({
  keyword: {
    id: 'search-results.keyword',
    defaultMessage: 'Keyword',
  },
  updateSearch: {
    id: 'search-results.updateSearch',
    defaultMessage: 'Update Search',
  },
  general: {
    id: 'search-results.general',
    defaultMessage: 'GENERAL',
  },
  name: {
    id: 'search-results.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'search-results.description',
    defaultMessage: 'Description',
  },
  type: {
    id: 'search-results.type',
    defaultMessage: 'Type',
  },
  all: {
    id: 'search-results.all',
    defaultMessage: 'All',
  },
  public: {
    id: 'search-results.public',
    defaultMessage: 'Public',
  },
  private: {
    id: 'search-results.private',
    defaultMessage: 'Private',
  },
});

class AdvancedGroupSearchBox extends React.PureComponent {
  static propTypes = {
    keyword: PropTypes.string,
    advanced: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = pick(this.props, 'keyword', 'advanced');
  }

  onChange = (e, { value, name } = {}) => {
    const { advanced } = this.state;
    value = value || e.target.value;
    name = name || e.target.name;
    const nextAdvanced = { ...advanced, [name]: value };
    if (name === 'keyword') {
      this.setState({ keyword: value });
    } else {
      this.setState({ advanced: pickBy(nextAdvanced) });
    }
  };

  search = (e) => {
    const { history } = this.props;
    const { advanced, keyword } = this.state;
    history.push({
      pathname: '/search-results/groups',
      search: queryString.stringify({
        keyword,
        advanced: JSON.stringify(advanced),
      }),
    });
  };

  render() {
    const { advanced, keyword } = this.state;
    return (
      <Form onSubmit={this.search} styleName="searchBoxContainer">
        <Segment styleName="searchBoxSegment">
          <div styleName="keywordContainer">
            <SearchField
              message={messages.keyword}
              name="keyword"
              onChange={this.onChange}
              value={keyword}
              inputID="keyword"
            />
          </div>
          <div styleName="categoryTitle"><FormattedMessage {...messages.general} /></div>
          <div styleName="keywordContainer">
            <SearchField
              message={messages.name}
              name="name"
              onChange={this.onChange}
              value={advanced.name}
              inputID="name"
            />
            <SearchField
              message={messages.description}
              name="description"
              onChange={this.onChange}
              value={advanced.description}
              inputID="description"
            />
            <fieldset aria-label="Group Type" styleName="fieldSet" role="radiogroup">
              <legend>
                <FormattedMessage {...messages.type} />
              </legend>
              <div>
                <input
                  id="all"
                  type="radio"
                  name="type"
                  value=""
                  checked={!advanced.type}
                  onChange={this.onChange}
                />
                <label htmlFor="all" styleName="typeRadio">
                  <FormattedMessage {...messages.all} />
                </label>
              </div>
              <div>
                <input
                  id="public"
                  type="radio"
                  name="type"
                  value="PUBLIC"
                  checked={advanced.type === 'PUBLIC'}
                  onChange={this.onChange}
                />
                <label htmlFor="public" styleName="typeRadio">
                  <FormattedMessage {...messages.public} />
                </label>
              </div>
              <div>
                <input
                  id="private"
                  type="radio"
                  name="type"
                  value="PRIVATE"
                  checked={advanced.type === 'PRIVATE'}
                  onChange={this.onChange}
                />
                <label htmlFor="private" styleName="typeRadio">
                  <FormattedMessage {...messages.private} />
                </label>
              </div>
            </fieldset>
          </div>
        </Segment>
        <Button color="teal" type="submit" data-cy="groups-update-search-button">
          <FormattedMessage {...messages.updateSearch} />
        </Button>
      </Form>
    );
  }
}

export default withRouter(AdvancedGroupSearchBox);
