import _get from 'lodash/get';
import { formatSchoolType, generateHeader, renderColumn } from '../UserExportTool/export-commons';

export const columns = [
  'name',
  'city',
  'state',
  'schoolType',
  'scid',
];

export function exportHEInstitutionClientsToCSV(heInstitutionClients) {
  const header = generateHeader(columns);

  const data = heInstitutionClients
    .map((item) => {
      const values = {
        name: item.name,
        city: _get(item, 'collegeCore.address.city', ''),
        state: _get(item, 'collegeCore.address.state', ''),
        schoolType: formatSchoolType(item),
        scid: item.scid,
      };
      return columns
        .map((col) => renderColumn(col, values))
        .join(',');
    })
    .join('\n');
  return new Blob([header, '\n', data], { type: 'text/csv;charset=utf-8;' });
}
