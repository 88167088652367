import React from 'react';
import PropTypes from 'prop-types';
import TextArea from '../TextArea';
import styles from './styles.css';

export default class TextAreaWithCounter extends React.PureComponent {
  static propTypes = {
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    rows: PropTypes.number,
    value: PropTypes.string,
  };

  static defaultProps = {
    maxLength: 280,
    rows: 4,
  };

  handleChange = (e, props) => {
    const { value } = props;
    const { onChange, maxLength } = this.props;
    if (value.length < maxLength) {
      onChange(e, props);
    } else {
      onChange(e, {
        ...props,
        value: value.substring(0, maxLength),
      });
    }
  };

  render() {
    const { value, rows, maxLength, ...rest } = this.props;
    return (
      <div className={styles.container}>
        <TextArea
          {...rest}
          onChange={this.handleChange}
          value={value}
          rows={rows}
        />
        <p className={styles.counter}>{value.length}/{maxLength} Characters</p>
      </div>
    );
  }
}
