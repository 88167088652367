import format from 'date-fns/format';

/* eslint-disable */
export const MSPERDAY = 86400000;
/* eslint-enable */

/**
 * Calculates ordinal day for provided date.
 * @param {*} date
 */
export const toOrdinalDate = (date) => parseInt(format(date, 'yyyyDDDD'), 10);
