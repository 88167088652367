import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import AnnouncementDetail from '../../components/AnnouncementDetail';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import Toast from '../../components/Toast';
import './styles.css';

const messages = defineMessages({
  noAnnouncements: {
    id: 'product-announcements.noAnnouncements',
    defaultMessage: 'You currently have no announcements.',
  },
  showMore: {
    id: 'product-announcements.showMore',
    defaultMessage: 'Show More',
  },
});

export default class ProductAnnouncementsDetails extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    viewer: PropTypes.shape({
      announcements: PropTypes.shape({
        pageInfo: PropTypes.shape({
          hasNextPage: PropTypes.bool.isRequired,
        }).isRequired,
        edges: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  successNotificationForNewAnnouncement =
    <Toast
      active
      title=""
      kind="success"
      description="New announcement added"
    />;

  successNotificationForUpdatedAnnouncement =
    <Toast
      active
      title=""
      kind="success"
      description="Changes saved"
    />;

  componentDidMount() {
    if (get(this.props, 'location.state.success')) {
      const isEditMode = !!get(this.props, 'location.state.isEditMode');
      const notification = isEditMode ? this.successNotificationForUpdatedAnnouncement : this.successNotificationForNewAnnouncement;
      this.setState({ notification });
      this.props.location.state.success = false;
    }
  }

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore('viewer.announcements').then(() => this.setState({ loading: false }));
  };

  render() {
    const { viewer } = this.props;
    const { notification, loading } = this.state;
    let announcementsArea = '';
    const announcements = get(viewer, 'announcements.edges');
    const hasNextPage = get(viewer, 'announcements.pageInfo.hasNextPage');
    if (!get(viewer, 'announcements.edges.length')) {
      announcementsArea = (
        <div id="no-notifications-msg" styleName="no-notifications-message">
          <FormattedMessage {...messages.noAnnouncements} />
        </div>
      );
    } else {
      announcementsArea = (
        <div>
          {announcements.map((announcement) => (
            <AnnouncementDetail
              key={announcement.node.id}
              announcement={announcement.node}
            />
          ))}
          {hasNextPage && (
            <div styleName="show-more-container">
              <Button loading={loading} styleName="show-more-button" id="show-more-announcements" onClick={this.loadMore}>
                <FormattedMessage {...messages.showMore} />
              </Button>
            </div>
          )}
        </div>
      );
    }

    return (
      <Grid styleName="product-announcements">
        { notification }
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h2">Product Announcements</Header>
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <Button as={Link} to="/admin/announcements/add" styleName="add-button">Add New</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row styleName="announcement-details">
          {announcementsArea}
        </Grid.Row>
      </Grid>
    );
  }
}
