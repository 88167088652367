import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

import './styles.css';
import Menu from '../../components/Menu';
import HasRole from '../../components/HasRole';
import ProductAnnouncements from '../ProductAnnouncements';
import { authorizedWithRole } from '../Authorization';
import { SUPER_ADMIN_ROLE } from '../Authorization/constants';
import UnauthorizedPage from '../UnauthorizedPage';
import UserExportTool from '../UserExportTool';
import ClientExportTool from '../ClientExportTool';

const messages = defineMessages({
  announcements: {
    id: 'support.admin.announcements',
    defaultMessage: 'Product Announcements',
  },
  userExportTool: {
    id: 'support.admin.userExportTool',
    defaultMessage: 'User Export Tool',
  },
  clientExportTool: {
    id: 'support.admin.clientExportTool',
    defaultMessage: 'Client Export Tool',
  },
  admin: {
    id: 'support.admin.dashboard',
    defaultMessage: 'Admin Dashboard',
  },
});

export class AdminDashboardPage extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { match } = this.props;
    return (
      <div>
        <h1 styleName="container-title"><FormattedMessage {...messages.admin} /></h1>
        <div styleName="pageContainer">
          <Menu as="ul" vertical className="third">
            <Menu.Item as="li">
              <NavLink
                to={`${match.path}/announcements`}
                id="admin-dashboard-announcements"
                className="menu-link"
                activeClassName="active"
              >
                <FormattedMessage {...messages.announcements} />
              </NavLink>
              <HasRole anyOf={SUPER_ADMIN_ROLE}>
                <NavLink
                  to={`${match.path}/user-export`}
                  id="admin-dashboard-user-export"
                  className="menu-link"
                  activeClassName="active"
                >
                  <FormattedMessage {...messages.userExportTool} />
                </NavLink>
              </HasRole>
              <HasRole anyOf={SUPER_ADMIN_ROLE}>
                <NavLink
                  to={`${match.path}/client-export`}
                  id="admin-dashboard-client-export"
                  className="menu-link"
                  activeClassName="active"
                >
                  <FormattedMessage {...messages.clientExportTool} />
                </NavLink>
              </HasRole>
            </Menu.Item>
          </Menu>
          <div styleName="pageContent">
            <Switch>
              <Route
                path={`${match.path}/announcements`}
                component={ProductAnnouncements}
              />
              <Route
                path={`${match.path}/user-export`}
                component={
                  authorizedWithRole(SUPER_ADMIN_ROLE, {
                    failure: UnauthorizedPage,
                  })(UserExportTool)
                }
              />
              <Route
                path={`${match.path}/client-export`}
                component={
                  authorizedWithRole(SUPER_ADMIN_ROLE, {
                    failure: UnauthorizedPage,
                  })(ClientExportTool)
                }
              />
              <Redirect from="*" to={`${match.path}/announcements`} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminDashboardPage);
