import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import './styles.css';
import { ModuleNames } from '@purple/match-graphql-schema/lib/schema/common/types/SubscriptionModuleNameType/type';
import * as heRoles from '@purple/match-graphql-schema/lib/schema/common/types/HERole/roles';

import messages from './messages';
import PageTitle from '../../components/PageTitle';
import Segment from '../../components/Segment';
import Header from '../../components/Header';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Grid from '../../components/Grid';

import CheckboxRenderer from './CheckboxRenderer';
import RadioRenderer from './RadioRenderer';
import SimpleCheckboxRenderer from './SimpleCheckboxRenderer';

import DownloadUsers from './DownloadUsers';

export const fields = [
  {
    name: 'institution-type',
    label: 'Institution Type',
    defaultValue: 'higher-ed',
    options: [
      {
        name: 'high-school',
        label: 'High School',
        disabled: true,
      },
      {
        name: 'higher-ed',
        label: 'Higher Ed',
      },
    ],
    renderer: RadioRenderer,
  },
  {
    name: 'subscriptions',
    label: 'Subscriptions',
    options: [
      {
        name: ModuleNames.HUBPAGE,
        label: 'Legacy: Hubs page management',
      },
      {
        name: ModuleNames.COMMUNITY,
        label: 'Legacy: Community',
      },
      {
        name: ModuleNames.MATCHING,
        label: 'Intersect Awareness Subscription',
      },
      {
        name: ModuleNames.REPVISITS,
        label: 'Intersect Presence Subscription',
      },
      {
        name: ModuleNames.AMEVENTS,
        label: 'Legacy: ActiveMatch Events',
      },
      {
        name: ModuleNames.AMPLUS,
        label: 'ActiveMatch Plus',
      },
      {
        name: ModuleNames.ADVANCEDAWARENESS,
        label: 'Advanced Awareness',
      },
      {
        name: ModuleNames.CONNECTION,
        label: 'Connection',
      },
      {
        name: ModuleNames.CAMINO,
        label: 'Camino HE Pilot',
      },
      {
        name: ModuleNames.SCHOLARSHIPS,
        label: 'Scholarships',
      },
    ],
    renderer: CheckboxRenderer,
  },
  {
    name: 'roles',
    label: 'Role',
    options: [
      {
        name: heRoles.ADMIN_ROLE,
        label: 'Administrator',
      },
      {
        name: heRoles.PUBLISHING_ROLE,
        label: 'Publishing',
      },
      {
        name: heRoles.COMMUNITY_ROLE,
        label: 'Community',
      },
    ],
    renderer: CheckboxRenderer,
  },
  {
    name: 'isPrimary',
    label: 'User Type',
    options: [
      {
        name: 'primary',
        label: 'Primary',

      },
    ],
    renderer: SimpleCheckboxRenderer,
  },
];

class UserExportTool extends React.PureComponent {
  static propTypes = {
    token: PropTypes.string,
  };

  state = {
    fetching: false,
    isPrimary: false,
    roles: [],
    subscriptions: [],
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      fetching: true,
    });
  }

  handleInputChange = ({ name, value }) => {
    this.setState({
      [name]: value,
    });
  }

  handleDownloadClose = () => {
    this.setState({
      fetching: false,
    });
  }

  renderForm() {
    return fields.map((field) => {
      const { name, renderer: Renderer } = field;
      const value = _get(this.state, name);
      return (
        <Renderer
          key={name}
          {...field}
          onChange={this.handleInputChange}
          value={value}
        />
      );
    });
  }

  render() {
    const { fetching, roles, subscriptions, isPrimary } = this.state;
    return (
      <Segment>
        <PageTitle title="Home" category="AdminDashboard" />
        <Grid styleName="user-export-tool">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2" data-cy="user-export-title-text">Intersect User Export Tool</Header>
              <p>Choose user traits to build a list of users.</p>

              <Form onSubmit={this.handleSubmit}>
                { this.renderForm() }
                <Button primary type="submit" styleName="btn-submit" data-cy="user-export-download-button">
                  <FormattedMessage {...messages.downloadButton} />
                </Button>
              </Form>
              <DownloadUsers
                roles={roles}
                subscriptions={subscriptions}
                isPrimary={isPrimary}
                doFetch={fetching}
                onClose={this.handleDownloadClose}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default UserExportTool;
