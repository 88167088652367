import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const LocationLink = (props) => {
  const { location, id, onLocationClick } = props;

  const onLinkClick = (e) => {
    e.preventDefault();
    onLocationClick(e, id);
  };

  return (
    <a data-cy={`Subscription-Link-${location}`} href="/" onClick={onLinkClick}>
      {location}
    </a>
  );
};
LocationLink.propTypes = {
  id: PropTypes.string,
  location: PropTypes.string,
  onLocationClick: PropTypes.func,
};

export default LocationLink;
