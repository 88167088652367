import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import Icon from '../Icon';
import Popup from '../Popup';

const messages = defineMessages({
  primary: {
    id: 'he-account.users-table.status.primary',
    defaultMessage: 'Primary',
  },
  nonPrimary: {
    id: 'he-account.users-table.status.non-primary',
    defaultMessage: 'Non Primary',
  },
  inactive: {
    id: 'he-account.users-table.status.inactive',
    defaultMessage: 'Inactive',
  },
  locked: {
    id: 'he-account.users-table.status.locked',
    defaultMessage: 'Locked',
  },
});

class UserStatusIcon extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    primaryUserId: PropTypes.string,
  };

  render() {
    const { user, intl, primaryUserId } = this.props;
    const lockStatus = JSON.parse(user.lockStatus);

    if (user.id === primaryUserId) {
      const primaryUserIcon = (
        <Popup
          trigger={<Icon name="star" color="yellow" />}
          content={intl.formatMessage(messages.primary)}
          on="hover"
        />
      );

      if (lockStatus && lockStatus.locked) {
        return (
          <div>
            <Popup trigger={<Icon name="lock" />} content={intl.formatMessage(messages.locked)} on="hover" />
            {primaryUserIcon}
          </div>
        );
      }

      return primaryUserIcon;
    }
    if (lockStatus && lockStatus.locked) {
      return (<Popup trigger={<Icon name="lock" />} content={intl.formatMessage(messages.locked)} on="hover" />);
    }
    return user.active ?
      (<Popup trigger={<Icon name="outline star" />} content={intl.formatMessage(messages.nonPrimary)} on="hover" />) :
      (<Popup trigger={<Icon name="ban" />} content={intl.formatMessage(messages.inactive)} on="hover" />);
  }
}

export default injectIntl(UserStatusIcon);
