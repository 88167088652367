import React, { useMemo } from 'react';
import format from 'date-fns/format';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModuleNames } from '@purple/match-graphql-schema/lib/schema/common/types/SubscriptionModuleNameType/type';
import List from '../../components/List';
import Grid from '../../components/Grid';
import Card from '../../components/Card';
import styles from './styles.css';
import Header from '../../components/Header';
import messages from './messages';
import { compareDeadlineDates, checkIsActiveSubscription } from './utils';

const HeHealthReportCollegeProfile = ({ collegeDetails = {}, moduleSubscriptions = [] }) => {
  const isActiveSubscription = useMemo(
    () => checkIsActiveSubscription(moduleSubscriptions, ModuleNames.MATCHING),
    [moduleSubscriptions],
  );

  const data = useMemo(
    () => ({
      ...collegeDetails,
      webAssets: (get(collegeDetails, 'hubsWebTour.campusMedia.assets') || []).length,
      webLinks: (get(collegeDetails, 'hubsWebLinks.premiumLinks') || []).length,
      deadlines: (get(collegeDetails, 'deadlines') || [])
        .map((item, key) => ({
          ...item,
          label: item.type === 'Other' && item.description !== null ? item.description : item.type,
          itemKey: key,
          formatedDate: format(new Date(null, item.deadlineMonth - 1, item.deadlineDay), 'MMMM d'),
        }))
        .sort(compareDeadlineDates),
      spotlights: (get(collegeDetails, 'hubsProfile.customProfiles') || []).length,
    }),
    [collegeDetails],
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={16}>
          <Header as="h2" className={styles.sectionTitle}>
            <FormattedMessage {...messages.profileTitle} />
          </Header>
        </Grid.Column>
        <Grid.Column computer={16}>
          <Card fluid>
            <Card.Content className={styles.cardContent}>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={4}>
                    <Header as="h3" data-cy="health-report-gpa-header">
                      <FormattedMessage {...messages.gpa} />
                    </Header>
                    <List horizontal className={styles.list}>
                      <List.Item>
                        <List.Header className={styles.listHeader}>
                          <FormattedMessage {...messages.gpa25th} />
                        </List.Header>
                        <List.Content data-cy="report-gpa-25th" className={styles.listContent}>
                          {data.gpa25Percentile}
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Header className={styles.listHeader}>
                          <FormattedMessage {...messages.average} />
                        </List.Header>
                        <List.Content data-cy="report-gpa-average" className={styles.listContent}>
                          {data.gpaAveragePercentile}
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Header className={styles.listHeader}>
                          <FormattedMessage {...messages.gpa75th} />
                        </List.Header>
                        <List.Content data-cy="report-gpa-75th" className={styles.listContent}>
                          {data.gpa75Percentile}
                        </List.Content>
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column computer={4}>
                    <Header as="h3">
                      <FormattedMessage {...messages.tuition} />
                    </Header>
                    <List horizontal className={styles.list}>
                      <List.Item>
                        <List.Header className={styles.listHeader}>
                          <FormattedMessage {...messages.inState} />
                        </List.Header>
                        <List.Content className={styles.listContent}>
                          ${data.tuitionInState}
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Header className={styles.listHeader}>
                          <FormattedMessage {...messages.outOfState} />
                        </List.Header>
                        <List.Content className={styles.listContent}>
                          ${data.tuitionOutState}
                        </List.Content>
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column computer={4}>
                    <Header as="h3" data-cy="report-deadlines">
                      <FormattedMessage {...messages.deadlines} />
                    </Header>
                    <List>
                      {data.deadlines.map((item) => (
                        <List.Item key={item.itemKey} className={styles.listItemHorizontal}>
                          <List.Header className={styles.listItemHorizontalHeader}>
                            {item.label}:
                          </List.Header>
                          <List.Content>{item.formatedDate}</List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </Grid.Column>
                  <Grid.Column computer={4}>
                    <Header as="h3">
                      <FormattedMessage {...messages.usage} />
                    </Header>
                    {isActiveSubscription ? (
                      <List>
                        <List.Item className={styles.listItemHorizontal}>
                          <List.Header className={styles.listItemHorizontalHeader}>
                            <FormattedMessage {...messages.numberOfImages} />
                          </List.Header>
                          <List.Content>{data.webAssets}</List.Content>
                        </List.Item>
                        <List.Item className={styles.listItemHorizontal}>
                          <List.Header className={styles.listItemHorizontalHeader}>
                            <FormattedMessage {...messages.numberOfLinks} />
                          </List.Header>
                          <List.Content>{data.webLinks}</List.Content>
                        </List.Item>
                        <List.Item className={styles.listItemHorizontal}>
                          <List.Header className={styles.listItemHorizontalHeader}>
                            <FormattedMessage {...messages.numberOfSpotLights} />
                          </List.Header>
                          <List.Content>{data.spotlights}</List.Content>
                        </List.Item>
                      </List>
                    ) : (
                      'N/A'
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

HeHealthReportCollegeProfile.propTypes = {
  collegeDetails: PropTypes.shape({
    gpa25Percentile: PropTypes.number,
    gpa75Percentile: PropTypes.number,
    gpaAveragePercentile: PropTypes.number,
    tuitionOutState: PropTypes.number,
    tuitionInState: PropTypes.number,
    deadlines: PropTypes.arrayOf(
      PropTypes.shape({
        deadlineDay: PropTypes.number,
        type: PropTypes.string,
        deadlineMonth: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
    hubsWebLinks: PropTypes.object,
    hubsWebTour: PropTypes.object,
    hubsProfile: PropTypes.object,
  }),
  moduleSubscriptions: PropTypes.arrayOf(PropTypes.object),
};

export default HeHealthReportCollegeProfile;
