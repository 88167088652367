import { fromGlobalId } from 'graphql-relay';
import React from 'react';
import Icon from '../Icon';
import Popup from '../Popup';
import TooManyProfiles from './too-many-profiles';
import Mismatch from './mismatch';
import CommunityInstIds from './community-inst-ids';
import { generateIcon, isMatchInstitition } from './utils';
import propTypes from './propTypes';
import './styles.css';

function isNavianceDenaviance(user, communityUser) {
  return (user.email === communityUser.mail &&
    (user.institution === communityUser.field_user_institution_uuid ||
      user.institution === communityUser.field_user_institution_hs_na_nces_id));
}

function isMatch(user, communityUser) {
  return (user.uuid === fromGlobalId(communityUser.id).id || isNavianceDenaviance(user, communityUser)) &&
    user.email === communityUser.mail &&
    user.firstName === communityUser.field_first_name &&
    user.lastName === communityUser.field_last_name &&
    isMatchInstitition(user, communityUser) &&
    (user.isNaviance || user.active === communityUser.isActive);
}

class CommunityStatusIcon extends React.PureComponent {
  static propTypes = propTypes;

  render() {
    const { user, institutionId } = this.props;
    const communityProfile = user.communityProfiles[0];

    if (institutionId !== user.institution) {
      return (
        <Popup hoverable trigger={<Icon name="warning sign" color="red" />}>
          <h3>Institution Id Mismatch!</h3>
          {!user.isNaviance && <div styleName="nowrap"><b>CognitoId</b>: {fromGlobalId(user.id).id}</div>}
          <div styleName="nowrap"><b>UUID</b>: {user.uuid}</div>
          <div styleName="nowrap mismatch">InstitutionId: {user.institution} vs {institutionId}</div>
        </Popup>
      );
    } else if (!communityProfile) {
      return <Icon name="cancel" />;
    } else if (user.communityProfiles.length > 1) {
      return (<TooManyProfiles user={user} />);
    } else if (!isMatch(user, communityProfile)) {
      return (<Mismatch user={user} />);
    } else {
      const color = communityProfile.profileComplete ? 'teal' : 'yellow';
      return (
        <Popup hoverable trigger={generateIcon(communityProfile.communityId, 'check', color)}>
          {!user.isNaviance && <div styleName="nowrap"><b>CognitoId</b>: {fromGlobalId(user.id).id}</div>}
          <div styleName="nowrap"><b>UUID</b>: {user.uuid}</div>
          <div styleName="nowrap"><b>Community</b>: {fromGlobalId(communityProfile.id).id}</div>
          <CommunityInstIds communityProfile={communityProfile} />
          <div styleName="nowrap"><b>Complete?</b> {communityProfile.profileComplete ? 'Yes' : 'No'}</div>
        </Popup>
      );
    }
  }
}

export default CommunityStatusIcon;
