import gql from 'graphql-tag';

export default gql`
  query searchByCategory($filter: String!) {
    viewer {
      id
      searchByCategory(category: HE_ACCOUNTS, filter: $filter, maxSize: 5) {
        results {
          ... on Searchable {
            name
            id
            ... on HEAccount {
              scid
            }
          }
        }
      }
    }
  }
`;
