import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import find from 'lodash/find';
import { fromGlobalId, toGlobalId } from 'graphql-relay';
import debounce from 'lodash/debounce';
import striptags from 'striptags';
import noop from 'lodash/noop';

import createService from '../CounselorCommunityServerless/embedService';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import Search from '../../components/Search';
import getCategoryRenderer from './search-category-renderer';
import { searchCategoryChanged } from './actions';
import searchQuery from './search-query';
import SearchResultRenderer from './search-result-renderer';
import * as appRoles from '../Authorization/constants';
import * as constants from './constants';
import * as viewParams from '../SearchResultsPage/constants';
import { withEnabledFeatures, FeatureFlags } from '../../utils/features';
import './styles.css';

const legacySearchOptionsConfig = [
  {
    icon: 'comments outline',
    text: 'Groups',
    value: constants.SEARCH_COMMUNITY_GROUPS,
    placeholder: 'Search by groups',
  },
];
const baseSearchOptionsConfig = [
  {
    icon: 'search',
    selectIcon: 'content',
    text: 'All',
    value: constants.SEARCH_ALL,
    placeholder: 'Search for accounts, people and more...',
  },
  {
    icon: 'university',
    text: 'HE Accounts',
    value: constants.SEARCH_HE_ACCOUNTS,
    placeholder: 'Search for accounts',
  },
  {
    icon: 'university',
    text: 'HS Accounts',
    value: constants.SEARCH_HS_ACCOUNTS,
    placeholder: 'Search for non naviance high schools',
  },
  {
    icon: 'graduation',
    text: 'College Core',
    value: constants.SEARCH_COLLEGE_CORE,
    placeholder: 'Search by SCID',
  },
  {
    icon: 'university',
    text: 'Institutions',
    value: constants.SEARCH_INSTITUTIONS,
    placeholder: 'Search for institutions',
  },
  {
    icon: 'user',
    text: 'Users',
    value: constants.SEARCH_USERS,
    placeholder: 'Search for he users',
  },
  {
    icon: 'user',
    text: 'People',
    value: constants.SEARCH_COMMUNITY_PEOPLE,
    placeholder: 'Search for community people',
  },
];

export const searchOptionsConfig = (isDisabledLegacyCommunity) => [
  ...baseSearchOptionsConfig,
  ...(isDisabledLegacyCommunity ? [] : legacySearchOptionsConfig),
];

const searchIcon = (searchCategory, isDisabledLegacyCommunity) => {
  const anOption = searchOptionsConfig(isDisabledLegacyCommunity).find((each) => each.value === searchCategory) || {};
  return anOption.selectIcon || anOption.icon;
};

const searchDescription = (item, category) => {
  if (category === constants.SEARCH_COMMUNITY_PEOPLE) {
    return item.field_user_institution_title;
  } else if (category === constants.SEARCH_INSTITUTIONS) {
    const state = startCase((item.field_state_short_name || get(item, 'address.field_hs_elna_state_latest') || '').toLowerCase());
    const city = startCase((item.field_city || get(item, 'address.field_hs_elna_city') || '').toLowerCase());
    return `${city}, ${state}`;
  } else if (category === constants.SEARCH_COMMUNITY_GROUPS) {
    return striptags(item.body_value);
  } else if (category === constants.SEARCH_HE_ACCOUNTS) {
    return striptags(item.scid);
  } else if (category === constants.SEARCH_HS_ACCOUNTS) {
    const ncesId = item.general.field_hs_na_nces_id;
    return striptags(ncesId);
  } else if (category === constants.SEARCH_COLLEGE_CORE) {
    return striptags(item.codes.SCID);
  }
  return '';
};

export const constructSearchResultsRoutePath = (searchCategory, searchFilter, isDisabledLegacyCommunity) => {
  let viewParam;
  switch (searchCategory) {
    case constants.SEARCH_ALL:
    case constants.SEARCH_COMMUNITY_PEOPLE: {
      viewParam = viewParams.COMMUNITY_PEOPLE_ROUTE_VIEW_PARAM;
      break;
    }
    case constants.SEARCH_INSTITUTIONS: {
      viewParam = viewParams.COMMUNITY_INSTITUTIONS_ROUTE_VIEW_PARAM;
      break;
    }
    case constants.SEARCH_COMMUNITY_GROUPS: {
      viewParam = viewParams.COMMUNITY_GROUPS_ROUTE_VIEW_PARAM;
      break;
    }
  }
  return viewParam && !isDisabledLegacyCommunity ? `/search-results/${viewParam}?keyword=${searchFilter}` : '';
};

export const getAvatarURL = (searchCategory, item) => {
  if (searchCategory === constants.SEARCH_INSTITUTIONS) {
    return item.field_he_institution_id ?
      `https://static.naviance.com/libraries/college-logos/${item.field_he_institution_id}.gif` :
      null;
  } else if (searchCategory === constants.SEARCH_COMMUNITY_PEOPLE) {
    return item.field_profile_picture_file_url || null;
  }
  return null;
};

const searchPlaceholder = (searchCategory, isDisabledLegacyCommunity) => (
  get(searchOptionsConfig(isDisabledLegacyCommunity).find((each) => each.value === searchCategory), 'placeholder', '')
);

const searchCategoryText = (searchCategory, isDisabledLegacyCommunity) => (
  get(searchOptionsConfig(isDisabledLegacyCommunity).find((each) => each.value === searchCategory), 'text', '')
);

export const salesSearchOptions = (isDisabledLegacyCommunity) => searchOptionsConfig(isDisabledLegacyCommunity)
  .filter((item) => item.value === constants.SEARCH_ALL || item.value === constants.SEARCH_HE_ACCOUNTS ||
    item.value === constants.SEARCH_HS_ACCOUNTS ||
    item.value === constants.SEARCH_USERS || item.value === constants.SEARCH_COLLEGE_CORE);

export const communitySearchOptions = (isDisabledLegacyCommunity) => searchOptionsConfig(isDisabledLegacyCommunity)
  .filter((item) => item.value === constants.SEARCH_ALL || item.value === constants.SEARCH_COMMUNITY_GROUPS ||
    item.value === constants.SEARCH_INSTITUTIONS || item.value === constants.SEARCH_COMMUNITY_PEOPLE);

export const extendedCommunitySearchOptions = (isDisabledLegacyCommunity) => searchOptionsConfig(isDisabledLegacyCommunity)
  .filter((item) => item.value === constants.SEARCH_ALL || item.value === constants.SEARCH_COMMUNITY_GROUPS ||
    item.value === constants.SEARCH_INSTITUTIONS || item.value === constants.SEARCH_COMMUNITY_PEOPLE ||
    item.value === constants.SEARCH_USERS || item.value === constants.SEARCH_HE_ACCOUNTS);

class SearchBar extends React.PureComponent {
  static propTypes = {
    searchBoxId: PropTypes.string,
    searchCategory: PropTypes.string.isRequired,
    searchCategoryChanged: PropTypes.func.isRequired,
    client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    enabledFeatures: PropTypes.arrayOf(PropTypes.string),
    current: PropTypes.shape({
      viewer: PropTypes.shape({
        userName: PropTypes.string.isRequired,
        profile: PropTypes.shape({
          roles: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    searchBoxId: 'global-search-box',
  };

  constructor(props) {
    super(props);
    this.requestSearchDebounce = debounce(this.requestSearch, 400);
    this.state = { isLoading: false, searchFilter: '' };
  }

  componentWillUnmount() {
    this.requestSearchDebounce.cancel();
  }

  handleSearchChange = (e, { value }) => {
    if (value.length < 2) {
      this.resetComponent();
    } else {
      this.setState({ isLoading: true });
      this.requestSearchDebounce(e, value);
    }
  };

  resetComponent() {
    this.setState({ isLoading: false, results: [], originalResults: [], searchFilter: '' });
    this.requestSearchDebounce.cancel();
  }

  handleSearchCategoryChanged = (e, { name, value }) => {
    this.props.searchCategoryChanged(value);
    this.handleSearchChange(e, { value: this.state.searchFilter });
  };

  pushGoogleAnalyticsEvent = (options) => {
    window.dataLayer.push({
      event: 'trackEvent',
      eventCategory: 'User Engagement',
      userId: this.props.current.viewer.userName,
      ...options,
    });
  }

  handleOnChange = (e, { result }) => {
    e.stopPropagation();
    this.pushGoogleAnalyticsEvent({
      'eventAction': 'search-result-selection',
      'eventLabel': this.state.searchFilter,
      'selected-result-key': result.key,
      'selected-result-title': result.title,
    });
    const { history } = this.props;
    const isDisabledLegacyCommunity = this.props.enabledFeatures.includes(appRoles.DISABLE_LEGACY_COMMUNITY);
    const communityUrl = isDisabledLegacyCommunity ? 'counselorcommunity' : 'counselor-community';
    const originalResult = find(this.state.originalResults, { id: result.key });
    const isCollegeResult = !!originalResult.field_he_institution_id;
    const isHighschoolResult = get(originalResult.general, 'field_hs_na_nces_id') || get(originalResult.naviance, 'field_hs_na_nguid');
    const resultCategory = findKey(this.state.results, (item) => (item.results.includes(result)));
    const { id, type } = fromGlobalId(result.key);

    const userId = originalResult.communityId;
    const collegeId = originalResult.field_he_institution_id;
    const highschoolId = isDisabledLegacyCommunity ?
      get(originalResult.general, 'field_hs_na_nces_id') || get(originalResult.naviance, 'field_hs_na_nguid') :
      get(originalResult.naviance, 'field_hs_na_nguid') || get(originalResult.general, 'field_hs_na_nces_id');
    const highschoolIdType = get(originalResult.general, 'field_hs_na_nces_id') ? 'NCES_ID' : 'NGUID';
    const idType = collegeId ? 'SCID' : highschoolIdType;

    const peopleEndpoint = isDisabledLegacyCommunity ?
      `/people/${originalResult.communityId}` :
      `/profile/${originalResult.communityId}`;
    const collegeEndpoint = isDisabledLegacyCommunity ?
      `/institutions/colleges/${originalResult.field_he_institution_id}` :
      `/institution-id/${originalResult.field_he_institution_id}`;
    const highschoolEndpoint = isDisabledLegacyCommunity ?
      `/institutions/highschools/${highschoolId}?idType=${idType}` :
      `/institution-hs-id/${highschoolId}`;

    if (!isDisabledLegacyCommunity && resultCategory === constants.SEARCH_COMMUNITY_GROUPS) {
      return history.push(`/${communityUrl}/groups/${id}`);
    }

    if (resultCategory === constants.SEARCH_COMMUNITY_PEOPLE) {
      if (!isDisabledLegacyCommunity) return history.push(`/${communityUrl}${peopleEndpoint}`);
      return this.openUserProfileModal({ userId });
    }

    if (resultCategory === constants.SEARCH_INSTITUTIONS && isCollegeResult) {
      if (!isDisabledLegacyCommunity) return history.push(`/${communityUrl}${collegeEndpoint}`);
      return this.openInstitutionProfileModal({ collegeId, highschoolId, idType });
    }

    if (resultCategory === constants.SEARCH_INSTITUTIONS && isHighschoolResult) {
      if (!isDisabledLegacyCommunity) return history.push(`/${communityUrl}${highschoolEndpoint}`);
      return this.openInstitutionProfileModal({ collegeId, highschoolId, idType });
    }

    if (resultCategory === constants.SEARCH_HE_ACCOUNTS) {
      return history.push(`/he-account/${result.key}/`);
    }

    if (resultCategory === constants.SEARCH_HS_ACCOUNTS) {
      const selectedResult = this.state.originalResults.find((each) => each.id === result.key);
      const institutionId = selectedResult.general.field_hs_na_nces_id || selectedResult.naviance.field_hs_na_nguid;
      return history.push(`/hs-account/${toGlobalId('HSAccount', institutionId)}/`);
    }

    if (resultCategory === constants.SEARCH_USERS && type === 'HEUser') {
      return history.push(`/he-account/${toGlobalId('HEAccount', originalResult.institution)}/users`);
    }

    /* istanbul ignore else */
    if (resultCategory === constants.SEARCH_COLLEGE_CORE) {
      const selectedResult = this.state.originalResults.find((each) => each.id === result.key);
      return history.push(`/he-account/${toGlobalId('HEAccount', selectedResult.codes.SCID)}/`);
    }
    /* istanbul ignore next */
    return null;
  };

  requestSearch = async (_, value) => {
    const { client, searchCategory } = this.props;
    this.setState({ searchFilter: value });
    const response = await client.query({
      query: searchQuery,
      variables: {
        category: searchCategory,
        filter: value,
      },
    });
    this.handleSearchResults(get(response, 'data.viewer.searchByCategory'));
  };

  searchByIDAndGotoSubscriptions = (e, scid) => {
    e.stopPropagation();
    this.handleSearchCategoryChanged(e, { value: constants.ALL });
    this.handleSearchChange(e, { value: scid });
    const interval = setInterval(() => {
      const { results } = this.state;
      const result = Object.entries(results)[0][1].results[0];

      if (result.description === scid) {
        clearInterval(interval);
        this.handleOnChange(e, { result });
      }
    }, 1000);
  }

  showAccountResults = (searchResults) => (
    searchResults.filter((item) => item.category === constants.SEARCH_HE_ACCOUNTS ||
      item.category === constants.SEARCH_HS_ACCOUNTS ||
      item.category === constants.SEARCH_USERS ||
      item.category === constants.SEARCH_COLLEGE_CORE)
  );

  showCommunityResults = (searchResults) => (
    searchResults.filter((item) => item.category === constants.SEARCH_COMMUNITY_GROUPS || item.category === constants.SEARCH_INSTITUTIONS ||
      item.category === constants.SEARCH_COMMUNITY_PEOPLE)
  );

  showExtendedCommunityResults = (searchResults) => (
    searchResults.filter((item) => item.category === constants.SEARCH_COMMUNITY_GROUPS || item.category === constants.SEARCH_INSTITUTIONS ||
      item.category === constants.SEARCH_COMMUNITY_PEOPLE || item.category === constants.SEARCH_USERS ||
      item.category === constants.SEARCH_HE_ACCOUNTS)
  );

  isAuthorizedForExtendedCommunity = (role) => {
    const enabledFeatures = get(this.props, 'enabledFeatures', []);
    return role === appRoles.COMMUNITY_ROLE && enabledFeatures.includes(FeatureFlags.HEALTH_REPORT);
  }

  handleSearchResults = (searchResults) => {
    const { viewer } = this.props.current;
    const isDisabledLegacyCommunity = this.props.enabledFeatures.includes(appRoles.DISABLE_LEGACY_COMMUNITY);

    if (!searchResults) searchResults = [];
    const { extendedCommunityRole, communityRole, salesRole } = this.getRoles(viewer);
    if (extendedCommunityRole) searchResults = this.showExtendedCommunityResults(searchResults);
    else if (communityRole) searchResults = this.showCommunityResults(searchResults);
    else if (salesRole) searchResults = this.showAccountResults(searchResults);

    this.pushGoogleAnalyticsEvent({
      eventAction: 'search',
      eventLabel: this.state.searchFilter,
      resultCategoryCount: searchResults.length,
      resultCount: searchResults.reduce((count, item) => count + item.results.length, 0),
    });

    const originalResults = [];
    const results = searchResults.reduce((obj, item) => {
      obj[item.category] = {
        name: searchCategoryText(item.category),
        results: item.results.map((each) => {
          originalResults.push(each);
          const searchByInstitutions = [constants.SEARCH_COLLEGE_CORE, constants.SEARCH_INSTITUTIONS].includes(item.category);
          const scid = each.field_he_institution_id ||
              (each.general ?
                each.general.field_hs_na_nces_id :
                undefined
              );
          return {
            renderer: SearchResultRenderer,
            key: each.id,
            icon: searchIcon(item.category, isDisabledLegacyCommunity),
            image: getAvatarURL(item.category, each),
            description: searchDescription(each, item.category),
            title: each.name,
            ...(searchByInstitutions ?
              { scid, gotosubscriptions: this.searchByIDAndGotoSubscriptions } : {}
            ),
          };
        }),
      };
      return obj;
    }, {});
    this.setState({ isLoading: false, results, originalResults });
  };

  handleKeyDown = (e) => {
    if (e.which === 13) {
      const { state, close } = this.searchDropdown || { state: { open: false, selectedIndex: -1 }, close: noop };
      /* istanbul ignore else */
      if ((state.selectedIndex < 0 && state.open) || !state.open) {
        close();
        this.handleHardSearch(e);
      }
    }
  };

  handleHardSearch = () => {
    const { searchFilter } = this.state;
    const { history, searchCategory } = this.props;
    if (searchFilter && searchFilter.length >= 2) {
      const isDisabledLegacyCommunity = this.props.enabledFeatures.includes(appRoles.DISABLE_LEGACY_COMMUNITY);
      const routePath = constructSearchResultsRoutePath(searchCategory, searchFilter, isDisabledLegacyCommunity);
      if (routePath) {
        this.setState({ isLoading: false });
        history.push(routePath);
      }
    }
  };

  getRoles = (viewer) => ({
    extendedCommunityRole: this.isAuthorizedForExtendedCommunity(viewer.profile.roles[0]),
    communityRole: appRoles.COMMUNITY_ONLY_ROLES.includes(viewer.profile.roles[0]),
    salesRole: viewer.profile.roles[0] === appRoles.SALES_OPS_ROLE,
  });

  getSearchOptionsForRole = (viewer, isDisabledLegacyCommunity) => {
    const { extendedCommunityRole, communityRole, salesRole } = this.getRoles(viewer);
    if (extendedCommunityRole) return extendedCommunitySearchOptions(isDisabledLegacyCommunity);
    if (communityRole) return communitySearchOptions(isDisabledLegacyCommunity);
    if (salesRole) return salesSearchOptions(isDisabledLegacyCommunity);
    return searchOptionsConfig(isDisabledLegacyCommunity);
  }

  displaySearchOptions = (viewer, isDisabledLegacyCommunity) => {
    const options = this.getSearchOptionsForRole(viewer, isDisabledLegacyCommunity);
    return options.map((each) => ({
      icon: each.icon,
      text: each.text,
      value: each.value,
    }));
  };

  handleViewStaff = (event, institutionName, institutionType, institutionSubType) => {
    event.preventDefault();
    this.props.history.push('/counselorcommunity/search/people?' +
      `viewStaffInstitutionName=${institutionName}&` +
      `viewStaffInstitutionType=${institutionType}&` +
      `viewStaffInstitutionSubType=${institutionSubType}`);
    window.dispatchEvent(new Event('counselor-community-serverless-update-route'));
  }

  closeModals = () => this.setState({
    showUserModal: null,
    showCollegeModal: null,
    showHighschoolModal: null,
    userId: null,
    collegeId: null,
    highschoolId: null,
    idType: null,
  });

  openUserProfileModal = ({ userId }) => {
    this.closeModals();
    this.setState({ showUserModal: true, userId });
  }

  openInstitutionProfileModal = ({ collegeId, highschoolId, idType }) => {
    this.closeModals();
    this.setState({
      showCollegeModal: !!collegeId,
      showHighschoolModal: !!highschoolId,
      collegeId,
      highschoolId,
      idType,
    });
  }

  renderUserProfileModal = () => {
    if (!this.state.showUserModal) return null;
    const UserProfileModal = global.CounselorCommunity?.default?.UserProfileModal;
    return !!UserProfileModal && (
      <UserProfileModal
        {...this.props}
        embedService={createService(this.props)}
        baseUrl={process.env.COUNSELOR_COMMUNITY_API}
        userId={this.state.userId}
        onInstitutionClick={this.openInstitutionProfileModal}
        onClose={this.closeModals}
      />
    );
  }

  renderHEInstitutionProfileModal = () => {
    if (!this.state.showCollegeModal) return null;
    const HEInstitutionProfileModal = global.CounselorCommunity?.default?.HEInstitutionProfileModal;
    return !!HEInstitutionProfileModal && (
      <HEInstitutionProfileModal
        {...this.props}
        embedService={createService(this.props)}
        baseUrl={process.env.COUNSELOR_COMMUNITY_API}
        collegeId={this.state.collegeId}
        idType={this.state.idType}
        handleViewStaff={this.handleViewStaff}
        onClose={this.closeModals}
      />
    );
  }

  renderHSInstitutionProfileModal = () => {
    if (!this.state.showHighschoolModal) return null;
    const HSInstitutionProfileModal = global.CounselorCommunity?.default?.HSInstitutionProfileModal;
    return !!HSInstitutionProfileModal && (
      <HSInstitutionProfileModal
        {...this.props}
        embedService={createService(this.props)}
        baseUrl={process.env.COUNSELOR_COMMUNITY_API}
        highschoolId={this.state.highschoolId}
        idType={this.state.idType}
        handleViewStaff={this.handleViewStaff}
        onClose={this.closeModals}
      />
    );
  }

  render() {
    const { searchFilter, results, isLoading } = this.state;
    const { searchBoxId, searchCategory, current: { viewer } } = this.props;
    const isDisabledLegacyCommunity = this.props.enabledFeatures.includes(appRoles.DISABLE_LEGACY_COMMUNITY);
    return (
      <div styleName="searchBarContainer">
        {this.renderUserProfileModal()}
        {this.renderHEInstitutionProfileModal()}
        {this.renderHSInstitutionProfileModal()}
        <Dropdown
          id={`${searchBoxId}-filter`}
          styleName="searchDropdown"
          aria-label="Search Category Selector"
          floating
          inline
          trigger={(
            <Icon
              aria-label={searchCategoryText(searchCategory)}
              name={searchIcon(searchCategory)}
            />
          )}
          options={this.displaySearchOptions(viewer, isDisabledLegacyCommunity)}
          onChange={this.handleSearchCategoryChanged}
        />
        <Search
          id={searchBoxId}
          className="labeled"
          category
          fluid
          placeholder={searchPlaceholder(searchCategory)}
          results={results}
          onSearchChange={this.handleSearchChange}
          onResultSelect={this.handleOnChange}
          loading={isLoading}
          showNoResults={!isLoading}
          minCharacters={2}
          categoryRenderer={getCategoryRenderer(searchFilter, isDisabledLegacyCommunity)}
          ref={(search) => { this.searchDropdown = search; }}
          onKeyDown={this.handleKeyDown}
          input={{
            'data-cy': 'search-box-input',
            'id': `${searchBoxId}-input`,
            'aria-label': searchPlaceholder(searchCategory),
          }}
          icon={{
            'data-cy': 'search-box-icon',
            'id': `${searchBoxId}-icon`,
            'name': 'search',
            'link': true,
            'onClick': this.handleHardSearch,
          }}
        />
        <Link
          to={isDisabledLegacyCommunity ? '/counselorcommunity/search/people' : '/search-results/people'}
          styleName="advancedSearchButton"
          data-cy="header-advanced-search-link"
        >
          {isDisabledLegacyCommunity ? 'Counselor Community Search' : 'Advanced Search'}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchCategory: get(state, 'globalSearch.searchCategory') || constants.SEARCH_ALL,
  current: state.current,
});

export const SearchBarComponent = withRouter(SearchBar);
export const SearchBarWithFeatures = withEnabledFeatures(SearchBarComponent);

export default connect(mapStateToProps, { searchCategoryChanged })(withApollo(SearchBarWithFeatures));
export const SearchBarWithApolloWithoutFeaturesComponent =
  connect(mapStateToProps, { searchCategoryChanged })(withApollo(SearchBarComponent));
