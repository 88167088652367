import React from 'react';
import PropTypes from 'prop-types';
import { pageTitleShape, injectPageTitle } from '../../providers/PageTitleProvider';
import './styles.css';

const PageCaption = ({ className, pageTitle }) => (
  <div styleName="pageCaption" className={className}>
    <div styleName="pageCategory">{pageTitle.category}</div>
    <div styleName="pageTitle">{pageTitle.title}</div>
  </div>
);
PageCaption.propTypes = {
  className: PropTypes.string,
  pageTitle: pageTitleShape,
};

export default injectPageTitle(PageCaption);
