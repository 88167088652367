import gql from 'graphql-tag';

export default gql`
  mutation ($input: changeAMSubscriptionStatusInput!) {
    changeAMSubscriptionStatus(input: $input) {
      amSubscription {
        version
        visible
      }
    }
  }
`;
