import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import TitleWithActions from '../../components/TitleWithActions';
import * as appRoles from '../Authorization/constants';
import './styles.css';
import HasRole from '../../components/HasRole';

const ContentHeader = (props) => {
  const { heAccount: { id, scid, name, primaryUser, collegeCore } } = props;

  const street = _get(collegeCore, 'address.street');
  const location = `${_get(collegeCore, 'address.city')},\
                    ${_get(collegeCore, 'address.state')}, \
                    ${_get(collegeCore, 'address.postalCode')}`;

  const userName = primaryUser ? `${primaryUser.firstName} ${primaryUser.lastName}`: 'No primary user found';
  const email = primaryUser && primaryUser.email;
  const role = primaryUser && primaryUser.role;
  const historyLogLink = `/he-account/${id}/log-history/today/`;
  const allUsersLink = `/he-account/${id}/users`;
  const createUserLink = `/he-account/${id}/create-user`;

  return (
    <div styleName="content-header">
      <TitleWithActions title={name}>
        <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
          <TitleWithActions.Action to={historyLogLink}>View History Log</TitleWithActions.Action>
        </HasRole>
        <HasRole anyOf={appRoles.NON_VIEW_ONLY_ROLES}>
          <TitleWithActions.Action to={allUsersLink}>See All Users</TitleWithActions.Action>
        </HasRole>
        <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
          <TitleWithActions.Action to={createUserLink}>Create User</TitleWithActions.Action>
        </HasRole>
      </TitleWithActions>

      <div styleName="data-list-container">
        <h3 styleName="light-title">Details</h3>
        <ul styleName="data-list">
          <li>
            Client Name
          </li>
          <li>
            {street}
          </li>
          <li>
            {location}
          </li>
          <li>
            { scid }
          </li>
        </ul>
      </div>
      <div styleName="data-list-container">
        <h3 styleName="light-title">Primary User</h3>
        <ul styleName="data-list">
          <li>
            { userName }
          </li>
          <li>
            { email }
          </li>
          <li>
            { role }
          </li>
        </ul>
      </div>
      <div style={{ clear: 'both' }} />
    </div>
  );
};

ContentHeader.propTypes = {
  heAccount: PropTypes.shape({
    id: PropTypes.string,
    scid: PropTypes.string,
    name: PropTypes.string,
    primaryUser: PropTypes.object,
    collegeCore: PropTypes.object,
  }),
};

export default ContentHeader;
