import * as React from 'react';
import ReactDOM from 'react-dom';

const withGlobals = (WrappedComponent) =>
  class extends React.Component {
    constructor(props) {
      super(props);
      global.React = React;
      global.ReactDOM = ReactDOM;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withGlobals;
