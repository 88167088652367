import gql from 'graphql-tag';
import HSUserDetails from '../../queries/hsUserDetails';

export default gql`
  mutation setNonNavianceHighSchool($input: setNonNavianceHighSchoolInput!) {
    setNonNavianceHighSchool(input: $input) {
      hsAccount {
        id
        deNavianceRequired
        navianceRequired
        primaryUser {
          ...HSUserDetails
        }
        userList {
          ...HSUserDetails
        }
      }
    }
  }
  ${HSUserDetails}
`;
