import React from 'react';
import PropTypes from 'prop-types';

import Label from '../../components/Label';
import Icon from '../../components/Icon';

import './styles.css';

function SelectedPill({ data, onRemoveClick }) {
  function handleRemoveClick() {
    onRemoveClick(data);
  }

  return (
    <Label styleName="selected-pill">
      {data.name}
      <Icon styleName="selection-label-icon" name="delete" onClick={handleRemoveClick} />
    </Label>
  );
}

SelectedPill.propTypes = {
  data: PropTypes.object,
  onRemoveClick: PropTypes.func,
};

export default SelectedPill;
