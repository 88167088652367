import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from './CustomButton';
import './styles.css';

const TitleWithActions = ({ title, tag, children }) => (
  <div styleName="title-with-actions">
    { React.createElement(tag, { className: 'light-title' }, title) }

    { React.Children.count(children) > 0 && (
      <div className="he-account-links" styleName="he-account-links">
        {children}
      </div>
    )}
  </div>
);
TitleWithActions.Action = CustomButton;
TitleWithActions.propTypes = {
  tag: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
};
TitleWithActions.defaultProps = {
  tag: 'h1',
};
export default TitleWithActions;
