import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import { injectIntl, defineMessages } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Cookie from 'js-cookie';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

import PageTitle from '../../components/PageTitle';
import { updateNotificationCount } from '../Notifications/actions';
import './styles.css';

const messages = defineMessages({
  frameTitle: {
    id: 'community.frame.title',
    defaultMessage: 'Community',
  },
});

function getCurrentDomain() {
  return window.location.hostname.split('.').slice(-2).join('.');
}

function getSplatFromRouter(match, location) {
  const splat = location.pathname.substring(match.path.length);
  return splat + location.search + location.hash;
}

function setAuthCookie(viewer) {
  const secure = window.location.protocol === 'https:';
  const domain = getCurrentDomain();
  Cookie.set('auth', viewer.jwtToken, { domain, secure });
}

/* eslint-disable class-methods-use-this */
class Community extends React.Component {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    current: PropTypes.shape({
      viewer: PropTypes.shape({
        jwtToken: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    updateNotificationCount: PropTypes.func.isRequired,
    notifications: PropTypes.shape({
      count: PropTypes.number.isRequired,
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
      hash: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    notifications: {
      count: 0,
    },
  };

  constructor(...args) {
    super(...args);
    const { match, location } = this.props;
    this.documentTitle = document.title;
    /* istanbul ignore if */
    if (window.self === window.top) { // without this, karma tests timeout..... huuuuhhh???
      try {
        document.domain = getCurrentDomain();
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    }
    const splatFromRouter = getSplatFromRouter(match, location);
    this.state = {
      routerSplat: splatFromRouter,
      iframeSplat: splatFromRouter,
    };
  }

  componentDidMount() {
    this.routerListener = this.props.history.listen((location) => {
      this.setState({
        routerSplat: getSplatFromRouter(this.props.match, location),
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.routerSplat !== nextState.iframeSplat;
  }

  componentWillUnmount() {
    document.title = this.documentTitle;
    if (this.iframeNode) {
      this.iframeNode.removeEventListener('load', this.onLoad);
    }
    this.routerListener();
  }

  onLoad = () => {
    const { history, match } = this.props;
    const { iframeSplat } = this.state;
    const iframeLocation = get(this.iframeNode, 'contentWindow.location');
    /* istanbul ignore if */
    if (!iframeLocation) {
      console.error('Unable to read iframe location, integration disabled'); // eslint-disable-line no-console
      return;
    }
    const currentIframeSplat = iframeLocation.pathname.slice(1) + iframeLocation.search + iframeLocation.hash;
    if (iframeSplat !== currentIframeSplat) {
      this.setState({ routerSplat: currentIframeSplat, iframeSplat: currentIframeSplat });
    }
    history.replace(`${match.path}${currentIframeSplat}`);
    document.title = this.iframeNode.contentDocument.title;
    this.updateNotificationCount(this.iframeNode.contentDocument);
    this._addEventListeners(this.iframeNode.contentDocument.body);
    this._patchAjax(this.iframeNode.contentWindow.XMLHttpRequest);
  };

  _patchAjax(request) {
    const originalSend = request.prototype.send;
    const self = this;
    request.prototype.send = function sendPatch(...args) {
      self._updateCookie();
      return originalSend.apply(this, args);
    };
  }

  _addEventListeners(element) {
    element.addEventListener('click', this._updateCookie);
    element.addEventListener('submit', this._updateCookie);
  }

  _updateCookie = () => {
    const { current } = this.props;
    setAuthCookie(current.viewer);
  };

  updateNotificationCount(doc) {
    const { notifications } = this.props;
    const meta = doc.querySelector('meta[name=notifications]');
    if (meta) {
      const count = parseInt(meta.getAttribute('content'), 10) || 0;
      if (count !== notifications.count) {
        this.props.updateNotificationCount(count);
      }
    }
  }

  iframeMounted = (ref) => {
    if (ref) {
      if (this.iframeNode) {
        this.iframeNode.removeEventListener('load', this.onLoad);
      }
      this.iframeNode = ref;
      ref.addEventListener('load', this.onLoad);
    }
  };

  render() {
    const { intl } = this.props;
    const { routerSplat } = this.state;
    this._updateCookie();
    return (
      <div styleName="wrapper">
        <PageTitle title="Counselor Community" category="Awareness" />
        {process.env.COMMUNITY_URL && (
          <iframe
            key={uniqueId('community_')}
            title={intl.formatMessage(messages.frameTitle)}
            frameBorder="0"
            styleName="iframe"
            src={`${process.env.COMMUNITY_URL}${routerSplat}`}
            ref={this.iframeMounted}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => pick(state, 'notifications', 'current');

export const CommunityComponent = withRouter(injectIntl(Community));
export default connect(mapStateToProps, { updateNotificationCount })(CommunityComponent);
