import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';
import './styles.css';

const SearchResultRenderer = ({
  title,
  id,
  active,
  image,
  icon,
  description,
  scid,
  gotosubscriptions,
}) => (
  <div
    role="option"
    aria-selected={active}
    id={`global-search-box-item-${active ? 'active' : id}`}
  >
    <Avatar icon={icon} image={image} />
    <div styleName="item">
      {title && (
      <div data-cy={`GlobalSearch-Result-${title}`} className="title">
        {title}
      </div>
      )}
      {description && <div className="description">{description}</div>}

      {scid && (
      <button
        type="button"
        styleName="search-by-scid-btn"
        onClick={(e) => gotosubscriptions(e, scid)}
        onKeyDown={(e) => {
          if (e.code === 13) {
            gotosubscriptions(e, scid);
          }
        }}
      >
        Subscriptions
      </button>
      )}
    </div>
  </div>
);

SearchResultRenderer.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  scid: PropTypes.string,
  gotosubscriptions: PropTypes.func,
};

export default SearchResultRenderer;
