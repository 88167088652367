import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import HEAccountDetails from '../HEAccountDetails';
import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon';
import './styles.css';

const HEAccountPage = ({ location }) => {
  const linkBackToThis = location && pick(location, 'pathname', 'search');
  const linkBackToParent = get(location, 'state.linkBack');
  return (
    <div>
      <PageTitle title="Account" category="Support" />
      {linkBackToParent && <div styleName="back-button"><Link to={linkBackToParent}><Icon name="angle left" />Back</Link></div>}
      <HEAccountDetails linkBack={linkBackToThis} />
    </div>
  );
};

HEAccountPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      linkBack: PropTypes.object,
    }),
  }),
};

export default HEAccountPage;
