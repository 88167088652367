import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { loadMorePaginationProp, SimpleLoadingComponent } from '../../utils/apollo';
import ProductAnnouncementsDetails from './product-announcements-details';
import announcementDetails from '../../queries/announcementDetails';

const query = gql`
  query ProductAnnouncementsDetails($first: Int, $after: String) {
    viewer {
      id
      announcements(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            ...AnnouncementDetails
          }
        }
      }
    }
  }
  ${announcementDetails}
`;

const graphqlData = graphql(query, {
  options: () => ({
    variables: {
      first: 25,
    },
  }),
  props: loadMorePaginationProp(query),
});

export default withRouter(SimpleLoadingComponent(graphqlData)(ProductAnnouncementsDetails));
