import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../../components/Grid';
import './styles.css';

import AdvancedGroupSearchBox from '../AdvancedGroupSearchBox';
import AdvancedPeopleSearchBox from '../AdvancedPeopleSearchBox';
import AdvancedInstitutionSearchBox from '../AdvancedInstitutionSearchBox';
import AdvancedSearchResults from '../AdvancedSearchResults';

class AdvancedSearch extends React.PureComponent {
  static propTypes = {
    view: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
    advanced: PropTypes.object,
  };

  render() {
    const { view, keyword, advanced } = this.props;

    const active = {
      groups: view === 'groups',
      people: view === 'people',
      institutions: view === 'institutions',
    };
    const key = JSON.stringify({ keyword, advanced });
    return (
      <Grid.Row styleName="advancedSearchContainer">
        <Grid.Column computer={5} mobile={16} tablet={5}>
          {active.groups && <AdvancedGroupSearchBox key={key} keyword={keyword} advanced={advanced} />}
          {active.people && <AdvancedPeopleSearchBox key={key} keyword={keyword} advanced={advanced} />}
          {active.institutions && <AdvancedInstitutionSearchBox key={key} keyword={keyword} advanced={advanced} />}
        </Grid.Column>
        <Grid.Column computer={11} mobile={16} tablet={11}>
          <AdvancedSearchResults {...this.props} />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default AdvancedSearch;
