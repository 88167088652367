import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Menu from '../Menu';
import Icon from '../Icon';
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import './styles.css';

const SecondaryMenu = ({ idPrefix, current }) => (
  <Menu as="dl" styleName="menu" secondary inverted>
    <Menu.Item as="dt" link styleName="menu-item">
      <a
        href={`${process.env.API_URL}/help/redirect?auth=${get(current, 'viewer.jwtToken')}`}
        target="_blank"
        id={`js-${idPrefix}-help-menu-link`}
        styleName="menu-link"
        rel="noopener noreferrer"
      >
        <Icon name="help circle" inverted />
        <FormattedMessage id="app.secondaryMenu.help" defaultMessage="Help" />
      </a>
    </Menu.Item>
    {process.env.LANGUAGE_SWITCHER && <Menu.Item as={LanguageSwitcher} />}
  </Menu>
);
SecondaryMenu.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  current: PropTypes.shape({
    viewer: PropTypes.shape({
      jwtToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  current: state.current,
});

export default connect(mapStateToProps)(SecondaryMenu);
