import React from 'react';
import format from 'date-fns/format';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModuleNames } from '@purple/match-graphql-schema/lib/schema/common/types/SubscriptionModuleNameType/type';
import Grid from '../../components/Grid';
import Card from '../../components/Card';
import Header from '../../components/Header';
import messages from './messages';
import { checkIsActiveSubscription } from './utils';
import './styles.css';

const isCompetitorMessage = (amMessages) => !!(
  get(amMessages, 'competitors') || []
).find((comp) => {
  const compMessages = get(comp, 'messages') || [];
  return comp.defaultMessage || (compMessages.find((msg) => !!msg.message));
});

const isMajorMessage = (amMessages) => !!(
  get(amMessages, 'majors') || []
).find((major) => {
  const majorMessages = get(major, 'messages') || [];
  return major.defaultMessage || (majorMessages.find((msg) => !!msg.message));
});

const isAcademicThreshold = (amSubscriptions) => !!(
  get(amSubscriptions, 'edges') || []
).find((edge) => !!get(edge, 'node.academicThreshold.enabled', false));

const HeHealthReportAAConnection = ({ amExportConfig, amSubscriptions, amMessages, amLastDownloadDate, moduleSubscriptions }) => {
  const isActiveAASubscription = checkIsActiveSubscription(moduleSubscriptions, ModuleNames.ADVANCEDAWARENESS);
  const isActiveConnectionSubscription = checkIsActiveSubscription(moduleSubscriptions, ModuleNames.CONNECTION);
  const lastDownloadDate = amLastDownloadDate ? format(new Date(amLastDownloadDate), 'MMM dd, yyyy') : null;
  const competitorsMsg = amMessages && isCompetitorMessage(amMessages) ? 'Yes' : 'No';
  const majorsMsg = amMessages && isMajorMessage(amMessages) ? 'Yes' : 'No';
  const academicThreshold = amSubscriptions && isAcademicThreshold(amSubscriptions) ? 'Yes' : 'No';
  const amSftpConfig = amExportConfig && (get(amExportConfig, 'schedule') || []).length > 0 ? 'Yes' : 'No';
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h2" data-cy="health-report-aa-connection-title">
            <FormattedMessage {...messages.aaConnectionTitle} />
          </Header>
          <Card fluid>
            <Card.Content>
              <Grid columns="equal" stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3" styleName="health-report-aa-connection-title" data-cy="health-report-aa-msg-for-major">
                      <FormattedMessage {...messages.msgForMajor} />
                    </Header>
                    <div styleName="health-report-aa-connection-value" data-cy="health-report-aa-msg-for-major-value">
                      {isActiveAASubscription ? majorsMsg : 'N/A'}
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as="h3" styleName="health-report-aa-connection-title" data-cy="health-report-aa-msg-for-comp">
                      <FormattedMessage {...messages.msgForComp} />
                    </Header>
                    <div styleName="health-report-aa-connection-value" data-cy="health-report-aa-msg-for-comp-value">
                      {isActiveAASubscription ? competitorsMsg : 'N/A'}
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as="h3" styleName="health-report-aa-connection-title" data-cy="health-report-aa-academic-threshold">
                      <FormattedMessage {...messages.academicThreshold} />
                    </Header>
                    <div styleName="health-report-aa-connection-value" data-cy="health-report-aa-academic-threshold-value">
                      {isActiveConnectionSubscription ? academicThreshold : 'N/A'}
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as="h3" styleName="health-report-aa-connection-title" data-cy="health-report-aa-download-date">
                      <FormattedMessage {...messages.lastManualDownloadDate} />
                    </Header>
                    <div styleName="health-report-aa-connection-value" data-cy="health-report-aa-download-date-value">
                      {isActiveConnectionSubscription && lastDownloadDate ? lastDownloadDate : 'N/A'}
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as="h3" styleName="health-report-aa-connection-title" data-cy="health-report-aa-sftp">
                      <FormattedMessage {...messages.sftpSetup} />
                    </Header>
                    <div styleName="health-report-aa-connection-value" data-cy="health-report-aa-sftp-value">
                      {isActiveConnectionSubscription ? amSftpConfig : 'N/A'}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

HeHealthReportAAConnection.propTypes = {
  amExportConfig: PropTypes.object,
  amLastDownloadDate: PropTypes.number,
  amSubscriptions: PropTypes.object,
  amMessages: PropTypes.object,
  moduleSubscriptions: PropTypes.arrayOf(PropTypes.object),
};

export default HeHealthReportAAConnection;
