import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import { signOut } from '../Authorization/actions';
import { _getItem, _saveItem } from '../../utils';

const localStorageKey = 'match-support-idle-lastactive';
function getLocalStorageTime() {
  const item = _getItem('localStorage', localStorageKey);
  try {
    return JSON.parse(item);
  } catch (e) {
    /* istanbul ignore next */
    console.log('corrupt local storage time', item); // eslint-disable-line no-console
    /* istanbul ignore next */
    return 0;
  }
}

class IdleTimeoutComponent extends React.PureComponent {
  static defaultState = {
    timerId: null,
  };

  static propTypes = {
    timeout: PropTypes.number.isRequired,
    current: PropTypes.shape({
      viewer: PropTypes.shape({
        loginTime: PropTypes.number,
      }).isRequired,
    }).isRequired,
    events: PropTypes.arrayOf(PropTypes.string),
    signOut: PropTypes.func.isRequired,
  };

  static defaultProps = {
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mouseWheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  };

  constructor(...args) {
    super(...args);
    this.state = IdleTimeoutComponent.defaultState;
    this._throttledHandleEvent = throttle(this._handleEvent, 1000);
  }

  componentDidMount() {
    this.props.events.forEach((e) => document.addEventListener(e, this._throttledHandleEvent));
    this._updateLastActiveTime();
    this._handleIdle();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timerId);
    this.props.events.forEach((e) => document.removeEventListener(e, this._throttledHandleEvent));
  }

  _updateLastActiveTime = () => {
    const now = Date.now();
    try {
      _saveItem('localStorage', localStorageKey, JSON.stringify(now));
    } catch (e) {
      // safari private browsing throws errors on setting local storage
      /* istanbul ignore next */
      console.log(`Storage Set Failed for key: ${localStorageKey}`, e); // eslint-disable-line no-console
    }
  };

  _handleIdle = () => {
    const { timeout, current } = this.props;
    const now = Date.now();
    let lastActive = getLocalStorageTime();
    if (lastActive) {
      lastActive = parseInt(lastActive, 10);
    } else {
      lastActive = 0;
    }
    if (lastActive > 0 && (now - lastActive) > timeout) {
      this.props.signOut(current.viewer);
    } else {
      // another tab has been active, refresh timeout to the appropriate time
      this.setState({
        timerId: setTimeout(this._handleIdle, timeout - (now - lastActive)),
      });
    }
  };

  _handleEvent = () => {
    const { timeout } = this.props;
    const { timerId } = this.state;
    this._updateLastActiveTime();
    clearTimeout(timerId);
    this.setState({
      timerId: setTimeout(this._handleIdle, timeout),
    });
  };

  /* eslint-disable class-methods-use-this */
  render() {
    return null;
  }
  /* eslint-enable class-methods-use-this */
}

const mapStateToProps = (state) => ({
  current: state.current,
});

export { localStorageKey, IdleTimeoutComponent };
export default connect(mapStateToProps, { signOut })(IdleTimeoutComponent);
