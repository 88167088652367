import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import Icon from '../Icon';
import './styles.css';

class Avatar extends React.PureComponent {
  static propTypes = {
    image: PropTypes.string,
    icon: PropTypes.string.isRequired,
    big: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      src: props.image,
    };
  }

  onImageError = () => {
    this.setState({
      src: '', // render icon instead
    });
  };

  render() {
    const { icon, big } = this.props;
    const { src } = this.state;
    const styleName = big ? 'largeAvatar' : 'avatar';
    const size = big ? 'big' : null;
    if (src) {
      return (<Image aria-hidden="true" src={src} shape="rounded" styleName={styleName} onError={this.onImageError} />);
    } else {
      return (<Icon circular size={size} name={icon} styleName={styleName} />);
    }
  }
}

export default Avatar;
