import gql from 'graphql-tag';

export default gql`
  fragment HSUserDetails on HSUser {
    id
    uuid
    firstName
    lastName
    role
    email
    lastLogin
    active
    created
    institution
    isNaviance
    lockStatus
    userStatus
    communityProfiles {
      id
      communityId
      field_first_name
      field_last_name
      field_user_institution_uuid
      field_user_institution_hs_na_nces_id
      isActive
      profileComplete
      mail
    }
  }
`;
