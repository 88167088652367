import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { formatSchoolType, generateHeader, renderColumn, subscriptionColumns } from './export-commons';

export const columns = [
  'schoolName',
  'schoolState',
  {
    name: 'scid',
    format: (data) => data.institution,
  },
  'schoolType',
  'email',
  'firstName',
  'lastName',
  {
    name: 'isPrimary',
    format: (data) => (_get(data, 'isPrimary') ? '1' : '0'),
  },
  'role',
  ...subscriptionColumns,
];

function formatUserData(users, institution) {
  const fields = ['uuid', 'firstName', 'lastName', 'email', 'role', 'institution'];
  if (Array.isArray(users)) {
    return users.map((user) => {
      const userData = _pick(user, fields);
      const institutionData = {
        schoolName: institution.name,
        schoolState: _get(institution, 'collegeCore.address.state', ''),
        schoolType: formatSchoolType(institution),
        subscriptions: _get(institution, 'moduleSubscriptions', []),
      };

      return {
        ...userData,
        ...institutionData,
        isPrimary: user.id === _get(institution, 'primaryUser.id'),
      };
    });
  }
  const user = _get(institution, 'primaryUser');
  if (user) {
    return [{
      ..._pick(user, fields),
      schoolName: institution.name,
      schoolState: _get(institution, 'collegeCore.address.state', ''),
      schoolType: _get(institution, 'collegeCore.type', ''),
      subscriptions: _get(institution, 'moduleSubscriptions', []),
      isPrimary: true,
    }];
  }
  return [];
}

export function exportHEInstitutionUsersToCSV(heInstitutionUsers) {
  const header = generateHeader(columns);

  const users = heInstitutionUsers.reduce((acc, iu) => {
    const { heAccount, heUsers } = iu;
    return acc.concat(formatUserData(heUsers, heAccount));
  }, []);

  const data = users
    .map((user) => {
      const row = columns
        .map((col) => renderColumn(col, user))
        .join(',');
      return row;
    })
    .join('\n');
  return new Blob([header, '\n', data], { type: 'text/csv;charset=utf-8;' });
}
