/* eslint-disable no-bitwise */
import ldGet from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import ldMergeWith from 'lodash/mergeWith';
import mapKeysDeep from 'map-keys-deep';
import snakeCase from 'lodash/snakeCase';
import crypto from 'crypto';
import stringify from 'fast-json-stable-stringify';
import { UserError } from 'graphql-errors';
import struct from 'python-struct';

// taken from https://stackoverflow.com/a/8076436
export const javaHashCode = (str) => {
  const length = str.length;
  let hash = 0;

  for (let i = 0; i < length; i += 1) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};
/* eslint-enable */

export const jsonHash = (obj) => crypto.createHash('MD5').update(stringify(obj)).digest('hex');

/**
 * use to check process.env environment variables that are intended to have boolean values
 * @param toCheck (accepts string or boolean)
 * @returns {boolean}
 */
export const stringToBoolean = (toCheck) => {
  if (toCheck) {
    if (typeof toCheck === 'string') {
      return toCheck.trim().toLowerCase() === 'true';
    } else if (typeof toCheck === 'boolean') {
      return toCheck;
    }
    return false;
  }
  return false;
};

export const stringToPositiveInt = (str) => {
  if (!/^\d+$/.test(str)) {
    return Number.NaN;
  }
  return parseInt(str, 10);
};

/**
 * Just like lodash's get(), but returns default value if resolved value is `null` or `undefined`.
 * @param obj The object to query.
 * @param path The path of the property to get.
 * @param defaultValue The value returned for undefined or null resolved values.
 * @returns {*} Returns the resolved value
 */
export function get(obj, path, defaultValue) {
  const result = ldGet(obj, path);
  if (result == null && arguments.length > 2) {
    return defaultValue;
  }
  return result;
}

// customizer function for merge(): replaces arrays instead of merging them
function replaceArrays(value, sourceValue) {
  return Array.isArray(sourceValue) ? sourceValue : undefined;
}

/**
 * Just like lodash's merge(), but does not deep merge arrays. Instead, arrays are simply being assigned thus replacing an
 * existing value.
 * @param {Object} object The merge target
 * @param {...Object} sources  One or more source objects
 * @returns {Object} the merge target
 */
export function merge(object, ...sources) {
  return ldMergeWith(object, ...sources, replaceArrays);
}

/**
 * Creates an enum-like object with a non-enumerable `parse(str)` function
 * out of an object literal.
 *
 * @param {Object} options
 * @returns {any}
 */
export function makeEnum(options) {
  const keysMap = new Map(Object.keys(options).map((k) => [options[k], k]));

  // return enum object enhanced with non-enumerable parse(str) method
  return Object.defineProperties(
    { ...options },
    {
      parse: {
        value: (str) => keysMap.get(str),
      },
    },
  );
}

export function toSnakeCaseKeys(obj) {
  return mapKeysDeep(obj, snakeCase);
}

export const escapeCsvFieldData = (val) => {
  if (val === undefined || val === null) return '""'; // we should let value 0 as it is,
  // surround all the data with double quotes and replace any existing double quotes with 2 consecutive double quotes
  return `"${String(val).replace(/"/g, '""')}"`;
};

export function userErrorWithCode(msg, code) {
  const err = new UserError(msg);
  err.code = code;
  return err;
}

export function errorWithCode(msg, code) {
  const err = new Error(msg);
  err.code = code;
  return err;
}

/**
 * Wraps non-array values as a single-item array. If "value" already
 * is an array, the value is returned unmodified.
 * `null` and `undefined` are normalized to an empty array.
 * If `emptyStringAsNull` is `true`, an emptry string value is also normalized to
 * an empty array - otherwise `[""]` is returned.
 *
 * @param value the value to wrap as an array
 * @param [emptyStringAsNull=false] whether
 * @return {(typeof value)[]}
 */
export function asArray(value, emptyStringAsNull = false) {
  if (value == null || (emptyStringAsNull && value === '')) return [];
  return [].concat(value);
}

/**
 * check if value is a positive number or not
 * @param value
 * @returns {boolean}
 */
export const isPositiveNumber = (value) => {
  const number = Number(value);
  return !isNaN(number) && number > 0;
};

export const hashStudentId = (userId, salt, algorithm) => {
  const packed = struct.pack('q', parseInt(userId, 10));
  const hash = crypto.createHash(algorithm);
  hash.update(packed);
  hash.update(salt);
  return hash.digest('hex');
};

export const setUserActivation = (communityUser, active) => {
  if (!isUndefined(active)) communityUser.status = active ? 1 : 0;
  return communityUser;
};
