import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

import Item from '../../components/Item';
import Container from '../../components/Container';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

import '../AdvancedInstitutionSearchResults/styles.css';

const messages = defineMessages({
  noResults: {
    id: 'search-results.noneFound',
    defaultMessage: 'No results found.',
  },
  tryAgain: {
    id: 'search-results.tryAgain',
    defaultMessage: 'Please try a new search.',
  },
  moreResults: {
    id: 'search-results.moreResults',
    defaultMessage: 'More Results',
  },
});

function getHighSchoolId(hs) {
  return get(hs.naviance, 'field_hs_na_nguid') || get(hs.general, 'field_hs_na_nces_id');
}

class AdvancedHighSchoolResults extends React.PureComponent {
  static propTypes = {
    viewer: PropTypes.object,
    loadMore: PropTypes.func.isRequired,
  };

  state = { loading: false };

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore('viewer.advancedHighSchoolSearch').then(() => this.setState({ loading: false }));
  };

  render() {
    const { viewer } = this.props;

    if (!get(viewer, 'advancedHighSchoolSearch.edges.length')) {
      return (
        <Item.Group>
          <Item>
            <Item.Content styleName="itemContainer">
              <Item.Header styleName="itemHeader"><FormattedMessage {...messages.noResults} /></Item.Header>
              <Item.Description styleName="itemDescription"><FormattedMessage {...messages.tryAgain} /></Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      );
    } else {
      const rows = viewer.advancedHighSchoolSearch.edges.map((highSchool) => highSchool.node);
      return (
        <div>
          <Item.Group>
            {rows.map((row, index) => (
              <Item styleName="itemContainer" key={row.id}>
                <Item.Content>
                  <Item.Header styleName="itemHeader">
                    <Link to={`/counselor-community/institution-hs-id/${getHighSchoolId(row)}`}>{row.name}</Link>
                  </Item.Header>
                  <Item.Description styleName="itemDescription">
                    {`
                      ${get(row, 'address.field_hs_elna_city') || ''},
                      ${get(row, 'address.field_hs_elna_state_latest') || ''}
                      ${get(row, 'address.field_hs_eln_zip') || ''}
                    `}
                  </Item.Description>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
          {get(viewer, 'advancedHighSchoolSearch.pageInfo.hasNextPage') ? (
            <Container textAlign="center">
              <Loader inline active={this.state.loading} />
              <Button size="small" color="teal" onClick={this.loadMore} data-cy="hs-institutions-load-more-button">
                <FormattedMessage {...messages.moreResults} />
              </Button>
            </Container>
          ) : null}
        </div>
      );
    }
  }
}

export default AdvancedHighSchoolResults;
