import { graphql } from '@apollo/react-hoc';
import { compose } from 'react-recompose';
import createAMSubscription from '../../mutations/HEAccount/createAMSubscription';
import updateAMSubscription from '../../mutations/HEAccount/updateAMSubscription';
import SubscriptionModal from './subscription-modal';
import updateHEAccountMutation from '../../mutations/HEAccount/updateHEAccount';

const createSubscriptionMutation = graphql(createAMSubscription, {
  name: 'createSubscription',
  options: {
    refetchQueries: ['HEAccountSubscriptions'],
  },
});

const updateSubscriptionMutation = graphql(updateAMSubscription, {
  name: 'updateSubscription',
  options: {
    refetchQueries: ['HEAccountSubscriptions'],
  },
});

const heAccountMutation = graphql(updateHEAccountMutation, {
  name: 'mutateHEAccount',
});

export default compose(
  createSubscriptionMutation,
  updateSubscriptionMutation,
  heAccountMutation,
)(SubscriptionModal);
