import gql from 'graphql-tag';
import AnnouncementDetails from '../../queries/announcementDetails';

export default gql`
  mutation createAnnouncement($input: createAnnouncementInput!, $first: Int) {
    createAnnouncement(input: $input) {
      viewer {
        id
        announcements(first: $first) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              ...AnnouncementDetails
            }
          }
        }
      }
    }
  }
  ${AnnouncementDetails}
`;
