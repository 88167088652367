import React from 'react';
import PropTypes from 'prop-types';
import pageTitleShape from './page-title-shape';
import injectPageTitle from './inject-page-title';

class PageTitleProvider extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = {
    pageTitle: pageTitleShape,
  };

  constructor(props) {
    super(props);
    this.listeners = [];
    this.childContext = {
      pageTitle: {
        title: '',
        category: '',
        set: this.set,
        subscribe: this.subscribe,
      },
    };
    this.eventIndex = 0;
  }

  getChildContext() {
    return this.childContext;
  }

  subscribe = (callback) => {
    this.listeners.push(callback);
    return () => {
      this.listeners = this.listeners.filter((item) => item !== callback);
    };
  };

  set = (title = '', category = '') => {
    if (title) {
      document.title = `Intersect - ${title}`;
    } else {
      document.title = 'Intersect';
    }
    Object.assign(this.childContext.pageTitle, { title, category });
    this.eventIndex += 1;
    this.listeners.forEach((listener) => listener(this.eventIndex));
  };

  render() {
    return React.Children.only(this.props.children);
  }
}
export { injectPageTitle, pageTitleShape };
export default PageTitleProvider;
