import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import List from '../../components/List';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import HasRole from '../../components/HasRole';
import FeatureEnabled from '../../components/FeatureEnabled';
import * as appRoles from '../Authorization/constants';
import { FeatureFlags } from '../../utils/features';

class ActionsGridColumn extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    linkBack: PropTypes.object,
  };

  render() {
    const { node, linkBack } = this.props;
    return (
      <Grid.Column as="section" data-cy="actions-section">
        <Header as="h2">
          <FormattedMessage id="he-account.actions.header" defaultMessage="Actions" />
        </Header>
        <List as="dl">
          <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
            <List.Item as="dt">
              <Link
                to={{ pathname: `/he-account/${node.get('id')}/log-history/today/`, state: { linkBack } }}
                data-cy="he-account-actions-log-history-link"
              >
                <FormattedMessage id="he-account.actions.logHistory" defaultMessage="View Log History" />
              </Link>
            </List.Item>
          </HasRole>
          <HasRole anyOf={appRoles.NON_VIEW_ONLY_ROLES}>
            <List.Item as="dt">
              <Link
                to={`/he-account/${node.get('id')}/users`}
                data-cy="he-account-actions-see-users-link"
              >
                <FormattedMessage id="he-account.actions.seeAllUsers" defaultMessage="See All Users" />
              </Link>
            </List.Item>
          </HasRole>
          <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
            <List.Item as="dt">
              <Link
                to={`/he-account/${node.get('id')}/create-user`}
                data-cy="he-account-actions-create-user-link"
              >
                <FormattedMessage id="he-account.actions.createUser" defaultMessage="Create User" />
              </Link>
            </List.Item>
          </HasRole>
          <FeatureEnabled allOf={FeatureFlags.HEALTH_REPORT}>
            <List.Item as="dt">
              <Link
                to={`/he-account/${node.get('id')}/health-report`}
                data-cy="he-account-actions-health-report-link"
              >
                <FormattedMessage id="he-account.actions.healthReport" defaultMessage="Health Report" />
              </Link>
            </List.Item>
          </FeatureEnabled>
        </List>
      </Grid.Column>
    );
  }
}

export default ActionsGridColumn;
