import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserInfo from '../../containers/UserInfo';
import Notifications from '../../containers/Notifications';
import Grid from '../Grid';
import SearchBar from '../../containers/SearchBar';
import { authorized } from '../../containers/Authorization';
import * as constants from '../../containers/Authorization/constants';
import FeatureDisabled from '../FeatureDisabled';
import SignOut from './signout';
import SearchToggleButton from '../SearchToggleButton';
import SidebarToggleButton from '../SidebarToggleButton';
import PageCaption from './page-caption';
import './styles.css';

export const SearchOnlyVisibleWhenAuthorized = authorized()(SearchBar);
export const SearchToggleButtonOnlyVisibleWhenAuthorized = authorized()(SearchToggleButton);
export const NotificationsOnlyVisibleWhenAuthorized = authorized()(Notifications);
export const AuthorizedUserInfoContainer = authorized({ failure: SignOut })(UserInfo);

class SiteHeader extends React.PureComponent {
  static propTypes = {
    onToggleSidebar: PropTypes.func.isRequired,
    current: PropTypes.object.isRequired,
  };

  state = { showSearch: false };

  toggleSearch = () => {
    this.setState((prevState) => ({ showSearch: !prevState.showSearch }));
  };

  render() {
    const { onToggleSidebar } = this.props;
    const { showSearch } = this.state;
    return (
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column computer={5} mobile={7} tablet={8}>
            <SidebarToggleButton className="hidden-computer" onClick={onToggleSidebar} />
            <PageCaption className="hidden-mobile hidden-tablet" />
          </Grid.Column>
          <Grid.Column width={7} only="computer" textAlign="right"><SearchOnlyVisibleWhenAuthorized /></Grid.Column>
          <Grid.Column computer={4} mobile={9} tablet={8} textAlign="right">
            <SearchToggleButtonOnlyVisibleWhenAuthorized className="hidden-computer" onClick={this.toggleSearch} />
            <FeatureDisabled anyOf={constants.DISABLE_LEGACY_COMMUNITY}>
              <NotificationsOnlyVisibleWhenAuthorized />
            </FeatureDisabled>
            <AuthorizedUserInfoContainer />
          </Grid.Column>
        </Grid.Row>
        { showSearch ? (
          <Grid.Row styleName="search-row" only="tablet mobile">
            <Grid.Column>
              <SearchOnlyVisibleWhenAuthorized searchBoxId="global-mobile-search-box" />
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  current: state.current,
});

export default connect(mapStateToProps)(SiteHeader);
