import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';
import withCounselorCommunityMicro from './withCounselorCommunityMicro';
import { useRedirectToWithReferrer } from './useRedirectTo';

export function CounselorCommunityProfile(props) {
  const {
    UserProfileModal,
    userId,
    onClose,
    ...rest
  } = props;

  const [showLoader, setShowLoader] = React.useState(true);
  const redirectToEditProfile = useRedirectToWithReferrer(`/edit-cc-profile/${userId}`, [userId]);

  return (
    <div data-cy="view-edit-cc-profile-container">
      {showLoader && <Loader active />}
      <UserProfileModal
        {...rest}
        userId={userId}
        onClose={() => onClose()}
        setShowLoader={setShowLoader}
        handleEditButton={redirectToEditProfile}
        isSupportAdmin
      />
    </div>
  );
}
CounselorCommunityProfile.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  UserProfileModal: PropTypes.func,
};

export default withCounselorCommunityMicro(CounselorCommunityProfile);
