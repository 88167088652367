import React from 'react';
import Loader from '../../components/Loader';
import useCounselorCommunityMicro from './useCounselorCommunityMicro';
import ScriptLoaderError from './script-loader-error';

/**
 * HOC that loads the CounselorCommunity micro bundle, and exposes the entry point component
 * as a prop on the wrapped component.
 * @param {React.Component} Component
 */
export default function withCounselorCommunityMicro(Component) {
  const WithCounselorCommunityMicro = (props) => {
    const {
      loading,
      error,
      url,
      ...exports
    } = useCounselorCommunityMicro();

    if (error) {
      return <ScriptLoaderError url={url} />;
    }
    if (loading) {
      return <Loader active />;
    }
    return <Component {...exports} {...props} />;
  };

  return WithCounselorCommunityMicro;
}
