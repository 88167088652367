import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { SimpleLoadingComponent } from '../../utils/apollo';
import CreateOrEditAnnouncementsComponent from './component';
import editAnnouncementMutation from './edit-announcement-mutation';
import newAnnouncementMutation from './new-announcement-mutation';
import announcementDetails from '../../queries/announcementDetails';

const query = gql`
  query Announcement($id: ID!) {
    node(id: $id) {
      id
      ...on Announcement {
        id
        ...AnnouncementDetails
      }
    }
  }
  ${announcementDetails}
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id = '' } = match.params;
    return {
      skip: !id,
      variables: {
        id,
      },
    };
  },
});

const editAnnouncement = graphql(editAnnouncementMutation, {
  name: 'mutateEditAnnouncement',
});
const newAnnouncement = graphql(newAnnouncementMutation, {
  name: 'mutateNewAnnouncement',
});

export default withRouter(SimpleLoadingComponent(graphqlData, editAnnouncement, newAnnouncement)(CreateOrEditAnnouncementsComponent));
