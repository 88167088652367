import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';
import queryString from 'query-string';

import PageTitle from '../../components/PageTitle';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import List from '../../components/List';
import './styles.css';

import AdvancedSearch from '../AdvancedSearch';

import {
  COMMUNITY_GROUPS_ROUTE_VIEW_PARAM,
  COMMUNITY_INSTITUTIONS_ROUTE_VIEW_PARAM,
  COMMUNITY_PEOPLE_ROUTE_VIEW_PARAM,
} from './constants';

const messages = defineMessages({
  searchResults: {
    id: 'search-results.searchResults',
    defaultMessage: 'Search Results:',
  },
  people: {
    id: 'search-results.people',
    defaultMessage: 'People',
  },
  institutions: {
    id: 'search-results.institutions',
    defaultMessage: 'institutions',
  },
  groups: {
    id: 'search-results.groups',
    defaultMessage: 'Groups',
  },
});

class SearchResultsPage extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        view: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  buildAdvanced = (query) => JSON.parse(query.advanced || '{}');

  render() {
    const { match, location } = this.props;
    const { view } = match.params;
    const query = queryString.parse(location.search);
    const { keyword = '' } = query;
    const advanced = this.buildAdvanced(query);
    const props = { keyword, advanced, view };
    const tabs = [
      COMMUNITY_PEOPLE_ROUTE_VIEW_PARAM,
      COMMUNITY_INSTITUTIONS_ROUTE_VIEW_PARAM,
      COMMUNITY_GROUPS_ROUTE_VIEW_PARAM,
    ];
    const active = {
      groups: view === COMMUNITY_GROUPS_ROUTE_VIEW_PARAM,
      institutions: view === COMMUNITY_INSTITUTIONS_ROUTE_VIEW_PARAM,
      people: view === COMMUNITY_PEOPLE_ROUTE_VIEW_PARAM,
    };

    return (
      <Grid columns={2} stackable>
        <Grid.Row>
          <PageTitle title="Search" category="Relationships" />
          <Header styleName="searchHeader" as="h1"><FormattedMessage {...messages.searchResults} /> { keyword } </Header>
        </Grid.Row>
        <Grid.Row styleName="listContainer">
          <Grid.Column computer={11} mobile={16} tablet={11} floated="right">
            <List as="dl" link horizontal size="big" styleName="listContainer" role="tablist">
              {tabs.map((tab, index) => (
                <List.Item key={tab} as="dt" active={active[tab]} styleName="listItem" role="tab" aria-selected={active[tab]}>
                  <Link
                    to={{ pathname: `/search-results/${tab}`, search: queryString.stringify({ keyword }) }}
                    id={`searchResultsTab${tab}`}
                  >
                    <FormattedMessage {...messages[tab]} />
                  </Link>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>
        <AdvancedSearch {...props} />
      </Grid>
    );
  }
}

export default withRouter(SearchResultsPage);
