import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { prepareQueryParamObj } from './utils';

export function useRedirectToWithReferrer(url, deps = []) {
  const history = useHistory();
  const location = useLocation();

  return useCallback(() => {
    const referrer = encodeURIComponent(location.pathname);
    history.push(`${url}?referrer=${referrer}`);
  }, deps);
}

export function useRedirectToReferrer() {
  const history = useHistory();
  const location = useLocation();

  return useCallback(() => {
    const { referrer } = prepareQueryParamObj(location.search);
    history.push(decodeURIComponent(referrer));
  }, []);
}
