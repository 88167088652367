import React from 'react';
import ScriptLoader from 'react-script-loader-hoc';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withGlobals from '../../components/Globals';
import PageTitle from '../../components/PageTitle';
import createService from './embedService';
import { signOut } from '../Authorization/actions';
import { setCounselorCommunityPath } from './utils';

const counselorCommunityUrl = setCounselorCommunityPath();

export class CounselorCommunityServerlessApp extends React.PureComponent {
  static propTypes = {
    signOut: PropTypes.func,
    current: PropTypes.shape({
      viewer: PropTypes.object,
    }),
  }

  signOut = () => {
    this.props.signOut(this.props.current.viewer);
  }

  render() {
    const CounselorCommunity = global.CounselorCommunity?.default?.default;
    return (
      <div className="counselor-community-serverless-container">
        <PageTitle title="Counselor Community" category="Awareness" />
        {CounselorCommunity ? (
          <CounselorCommunity
            embedService={createService(this.props)}
            baseUrl={process.env.COUNSELOR_COMMUNITY_API}
            signOut={this.signOut}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  current: state.current,
});

const CounselorCommunityServerlessAppLoaded = withGlobals(ScriptLoader(counselorCommunityUrl)(CounselorCommunityServerlessApp));
export default connect(mapStateToProps, { signOut })(CounselorCommunityServerlessAppLoaded);
