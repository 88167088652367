import React from 'react';
import PropTypes from 'prop-types';
import { createHTMLLabel } from 'semantic-ui-react/dist/commonjs/lib';
import '@purple/semantic-ui-purple-theme/components/checkbox.css';

function CheckBox({ label, children, ...inputProps }) {
  if (children) {
    return (<div className="ui checkbox">{children}</div>);
  } else {
    return (
      <div className="ui checkbox">
        <input {...inputProps} type="checkbox" />
        {createHTMLLabel(label)}
      </div>
    );
  }
}
CheckBox.propTypes = {
  children: PropTypes.node,
  label: PropTypes.shape({
    htmlFor: PropTypes.string.isRequired,
  }),
};
export default CheckBox;
