import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import get from 'lodash/get';
import { localeChanged } from '../../providers/LanguageProvider/actions';
import { appLocales } from '../../i18n';
import Dropdown from '../../components/Dropdown';

const messages = defineMessages({
  language: {
    id: 'app.language.switcherLabel',
    description: 'Dropdown to switch between different languages',
    defaultMessage: 'Language',
  },
});
const dropdownLocaleOptions = appLocales.map((locale) => ({
  role: 'menuitem',
  tabIndex: '-1',
  text: locale,
  value: locale,
}));

class LanguageSwitcher extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    localeChanged: PropTypes.func.isRequired,
  };

  handleLocaleChange = (e, { value }) => {
    this.props.localeChanged(value);
  };

  render() {
    const { locale = 'en', intl, className } = this.props;
    return (
      <Dropdown
        role="menu"
        tabIndex="0"
        selectOnBlur={false}
        selectOnNavigation={false}
        className={className}
        pointing="bottom"
        text={intl.formatMessage(messages.language)}
        options={dropdownLocaleOptions}
        defaultValue={locale.split('-')[0]}
        onChange={this.handleLocaleChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({ locale: get(state, 'language.locale') });

export default connect(mapStateToProps, { localeChanged })(injectIntl(LanguageSwitcher));
