import gql from 'graphql-tag';

export default gql`
  mutation updateAnnouncement($input: updateAnnouncementInput!) {
    updateAnnouncement(input: $input) {
      announcement {
        id
        title
        content
        audience
        status
      }
    }
  }
`;
