import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import Segment from '../../components/Segment';
import ProductAnnouncementsDetails from '../ProductAnnouncementsDetails';
import CreateOrEditAnnouncements from '../CreateOrEditAnnouncements';

const ProductAnnouncementsPage = ({ match }) => (
  <Segment>
    <PageTitle title="Home" category="Admin Dashboard" />
    <Switch>
      <Route
        exact
        path={`${match.path}/details`}
        component={ProductAnnouncementsDetails}
      />
      <Route
        exact
        path={`${match.path}/add`}
        component={CreateOrEditAnnouncements}
      />
      <Route
        exact
        path={`${match.path}/edit/:id`}
        component={CreateOrEditAnnouncements}
      />
      <Redirect from="*" to={`${match.path}/details`} />
    </Switch>
  </Segment>
);

ProductAnnouncementsPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default withRouter(ProductAnnouncementsPage);
