import * as constants from './constants';
import { createReducer } from '../../utils';

const initialState = {
  viewer: {
    isAuthenticated: false,
    userName: '',
    profile: {},
  },
  statusText: '',
};

export default createReducer(initialState, {
  [constants.LOGIN_USER_REQUEST]: (state, payload) => ({
    ...state, viewer: initialState.viewer, statusText: 'Login Request Received.',
  }),
  [constants.LOGIN_USER_SUCCESS]: (state, payload) => ({
    ...state, viewer: payload, statusText: 'You have been successfully logged in.',
  }),
  [constants.LOGIN_USER_FAILURE]: (state, payload) => ({
    ...state, viewer: initialState.viewer, statusText: 'Login Failure.',
  }),
});
