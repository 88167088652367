import format from 'date-fns/format';
import {
  subscriptionTypes,
  subscriptionTypesInput,
  diversityOptions,
} from './constants';
import { formattedCollegeName } from '../SearchCollegeByNameOrSCID/search-result';

const getSubscriptionsList = (subscriptions) => subscriptions.map(({ node }) => {
  const {
    id,
    type,
    version,
    majors,
    connection,
    startDate,
    endDate,
    audience,
    messages,
    lastModifiedDate,
    lastModifiedBy,
  } = node;

  // assign locals here in case audience is null
  let zipCode;
  let state;
  let county;
  let sector;
  let diversity;
  let competitors;
  /* istanbul ignore else */
  if (audience) {
    zipCode = audience.zipCode;
    state = audience.state;
    sector = audience.sector;
    county = audience.county;
    diversity = audience.diversity;
  }

  if (messages) {
    competitors = messages.competitors;
  }

  const subscription = {
    id,
    type: type === 'ZIP_CODE' ? 'zip' : type.toLowerCase(),
    version,
    majors,
    connection,
    startDate,
    endDate,
    lastModifiedDate,
    lastModifiedBy,
    state,
    sector,
    competitors: [],
    diversity,
  };

  /* istanbul ignore else */
  if (type === subscriptionTypesInput.zip && zipCode) {
    subscription.zipCode = zipCode.zipCode;
    subscription.radius = zipCode.radius;
  } else if (type === subscriptionTypesInput.county && county) {
    subscription.county = county.county;
    subscription.state = county.state;
  }

  /* istanbul ignore else */
  if (Array.isArray(competitors)) {
    // eslint-disable-next-line no-shadow
    subscription.competitors = competitors.map(({ scid, name, state }) => ({ scid, name, state }));
  }

  subscription.connection = connection;
  return subscription;
});

export const adjustDateRangeTimestamps = (startDate, endDate) => {
  if (startDate) {
    startDate = new Date(startDate).setHours(6, 1, 0, 0);
  }
  if (endDate) {
    endDate = new Date(endDate).setHours(23, 59, 0, 0);
  }
  return {
    startDate,
    endDate,
  };
};

const amSubscriptionInput = (
  subscriptionType,
  {
    diversity,
    majors,
    connection,
    startDate,
    endDate,
    competitors,
  },
) => {
  const adjustedDateRange = adjustDateRangeTimestamps(startDate, endDate);
  const amSubscription = {
    type: subscriptionTypesInput[subscriptionType],
    visible: true,
    diversity,
    majors,
    connection,
    startDate: adjustedDateRange.startDate,
    endDate: adjustedDateRange.endDate,
  };
  amSubscription.competitors = competitors.map(({ scid }) => ({ scid }));
  return amSubscription;
};

export const updateAMSubscriptionInput = (
  subscriptionType,
  values,
  subscription,
) => {
  const { state, county, zipCode, radius, sector } = values;
  const amSubscription = amSubscriptionInput(subscriptionType, values);
  if (subscriptionType === subscriptionTypes.ZIP) {
    amSubscription.zipCode = { zipCode: String(zipCode), radius: Number(radius) };
  } else if (subscriptionType === subscriptionTypes.COUNTY) {
    amSubscription.county = { county, state };
  } else if (subscriptionType === subscriptionTypes.SECTOR) {
    amSubscription.sector = sector;
  } else {
    amSubscription.state = state;
  }
  amSubscription.id = subscription.id;
  amSubscription.version = subscription.version;

  return amSubscription;
};

export const createAmSubscriptionInput = (
  subscriptionType,
  values,
) => {
  const { states, zips, radius, counties, sectors } = values;
  const amSubscription = amSubscriptionInput(subscriptionType, values);

  if (subscriptionType === subscriptionTypes.ZIP) {
    amSubscription.zipCodes = zips.map((zip) => ({ zipCode: String(zip), radius: Number(radius) }));
  } else if (subscriptionType === subscriptionTypes.SECTOR) {
    amSubscription.sectors = sectors.map((sector) => ({ sector }));
  } else {
    amSubscription.states = states.map((state) => ({ state }));

    if (subscriptionType === subscriptionTypes.COUNTY) {
      amSubscription.counties = [];

      Object.keys(counties).forEach((key) => {
        const countiesArray = counties[key];
        countiesArray.forEach((county) => amSubscription.counties.push({ state: key, county }));
      });
    }
  }

  return amSubscription;
};

export const getLocation = ({ type, zipCode, state, county, sector }) => {
  /* istanbul ignore else */
  if (type === subscriptionTypes.ZIP) {
    return zipCode;
  } else if (type === subscriptionTypes.STATE) {
    return state;
  } else if (type === subscriptionTypes.COUNTY && county) {
    return `${county} County, ${state}`;
  } else if (type === subscriptionTypes.SECTOR) {
    return sector;
  }

  return '';
};

export const getDiversity = (diversity) => {
  const diversityOption = diversityOptions.find(({ value }) => value === diversity);
  /* istanbul ignore else */
  if (diversityOption && diversityOption.text) {
    return diversityOption.text;
  }

  return '';
};

export const getCompetitors = (competitors) => {
  /* istanbul ignore else */
  if (Array.isArray(competitors)) {
    return competitors.map(({ scid, name, state }) => {
      if (name && state) {
        return formattedCollegeName({ scid, name, state });
      } else if (name) {
        return formattedCollegeName({ scid, name });
      }

      return scid;
    }).join('|');
  }

  return '';
};

export const formatToStandardUSADate = (date) => format(date, 'MM/dd/yyyy');

export default getSubscriptionsList;
