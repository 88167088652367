import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import find from 'lodash/find';

import timeframeConfig from './timeframes';
import Grid from '../Grid';
import DateRangeInput from '../DateRangeInput';
import Input from '../Input';
import Dropdown from '../Dropdown';
import Button from '../Button';
import './styles.css';

const timeframeOptions = timeframeConfig.map((each) => ({
  text: each.text,
  value: each.value,
  role: 'option',
  tabIndex: '-1',
}));
const messages = defineMessages({
  searchPlaceholder: {
    id: 'common.logHistory.searchPlaceholder',
    defaultMessage: 'Search...',
  },
  timeframeSwitcher: {
    id: 'common.logHistory.timeframeSwitcher',
    defaultMessage: 'Timeframe Switcher',
  },
  customStartDate: {
    id: 'common.logHistory.customStartDate',
    defaultMessage: 'Custom Start Date',
  },
  customEndDate: {
    id: 'common.logHistory.customEndDate',
    defaultMessage: 'Custom End Date',
  },
});

function formatDate(milli) {
  const date = new Date(milli);
  const pad = '00';
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${pad.substring(String(month).length)}${month}-${pad.substring(String(day).length)}${day}`;
}

function getNextTimeframe(timeframe, lastTimeframe) {
  if (timeframe === 'custom') {
    const timeInfo = find(timeframeConfig, { value: lastTimeframe }).range();
    return { timeframe, to: formatDate(timeInfo.to), from: formatDate(timeInfo.from) };
  } else {
    return { timeframe };
  }
}

class SearchControls extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    timeframe: PropTypes.string.isRequired,
    searchQuery: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    hideSearch: PropTypes.bool,
  };

  static defaultProps = {
    searchQuery: '',
  };

  onSearchQueryChange = (e) => {
    const searchQuery = e.target.value || '';
    this.props.onChange({ searchQuery }, true);
  };

  onTimeframeChange = (e, { value }) => {
    const { timeframe } = this.props;
    if (value !== timeframe) {
      const nextState = getNextTimeframe(value, timeframe);
      this.props.onChange(nextState);
    }
  };

  render() {
    const { timeframe, searchQuery, from, to, intl, onChange, hideSearch } = this.props;
    const fromDate = new Date(Date.parse(`${from}T12:00:00`));
    const toDate = new Date(Date.parse(`${to}T12:00:00`));
    return (
      <Grid.Row textAlign="center">
        {!hideSearch && (
        <Grid.Column>
          <Input action placeholder={intl.formatMessage(messages.searchPlaceholder)}>
            <input type="text" aria-label="Search Log History" value={searchQuery} onChange={this.onSearchQueryChange} data-cy="log-history-search-input" />
            <Button icon="search" color="grey" title={intl.formatMessage(messages.searchPlaceholder)} />
          </Input>
        </Grid.Column>
        )}
        <Grid.Column>
          <Dropdown
            compact
            styleName="timeframeSwitcher"
            aria-label={intl.formatMessage(messages.timeframeSwitcher)}
            selection
            value={timeframe}
            data-cy="log-history-timeframe-dropdown"
            options={timeframeOptions}
            onChange={this.onTimeframeChange}
          />
        </Grid.Column>
        <Grid.Column data-cy="he-account-logHistory-start-date-input">
          {timeframe !== 'custom' ? '' : (
            <DateRangeInput
              value={from}
              from={fromDate}
              to={toDate}
              initialMonth={fromDate}
              label={intl.formatMessage(messages.customStartDate)}
              id="start-date-input"
              onChange={(nextFrom) => onChange({ from: nextFrom })}
              canEdit
            />
          )}
        </Grid.Column>
        <Grid.Column data-cy="he-account-logHistory-end-date-input">
          {timeframe !== 'custom' ? '' : (
            <DateRangeInput
              value={to}
              from={fromDate}
              to={toDate}
              initialMonth={toDate}
              label={intl.formatMessage(messages.customEndDate)}
              id="end-date-input"
              onChange={(nextTo) => onChange({ to: nextTo })}
              canEdit
            />
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }
}
export default injectIntl(SearchControls);
