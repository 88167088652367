import { defineMessages } from 'react-intl';

const messages = defineMessages({
  noResults: {
    id: 'search-results.noneFound',
    defaultMessage: 'No results found.',
  },
  tryAgain: {
    id: 'search-results.tryAgain',
    defaultMessage: 'Please try a new search.',
  },
  moreResults: {
    id: 'search-results.moreResults',
    defaultMessage: 'More Results',
  },
  nguid: {
    id: 'hs-account.repvisits-accounts.nguid',
    defaultMessage: 'NGUID',
  },
  ncesId: {
    id: 'hs-account.repvisits-accounts.ncesId',
    defaultMessage: 'NCESID',
  },
  nid: {
    id: 'hs-account.repvisits-accounts.nid',
    defaultMessage: 'NID',
  },
  naviance: {
    id: 'hs-account.repvisits-accounts.naviance',
    defaultMessage: 'Naviance school',
  },
  setupComplete: {
    id: 'hs-account.repvisits-accounts.setupComplete',
    defaultMessage: 'Setup Complete',
  },
  availabilityVisible: {
    id: 'hs-account.repvisits-accounts.availabilityVisible',
    defaultMessage: 'Availability Visible',
  },
  schoolYear: {
    id: 'hs-account.repvisits-accounts.schoolYear',
    defaultMessage: 'School Year',
  },
});

export default messages;
