import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import HSAccountUsersTable from '../HSAccountUsersTable';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import * as appRoles from '../Authorization/constants';
import HasRole from '../../components/HasRole';
import CounselorCommunityProfileWrapped from '../CounselorCommunityServerless/profile';
import { FEATURE_VIEW_EDIT_CC_PROFILE } from '../../featureFlags';

class HSAccountUsersPage extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    viewer: PropTypes.shape({
      enabledFeatures: PropTypes.array,
    }),
  };

  state = {
    showDisabledUsers: false,
    viewCommunityProfileId: '',
  };

  toggleShowDisabledUsers = () => {
    this.setState((prevState) => ({ showDisabledUsers: !prevState.showDisabledUsers }));
  };

  setViewCommunityProfileId = (viewCommunityProfileId) => {
    this.setState({ viewCommunityProfileId });
  };

  render() {
    const { node, viewer: { enabledFeatures } } = this.props;
    const { userList } = node;
    const { showDisabledUsers, viewCommunityProfileId } = this.state;
    const disabledUsersExist = userList && userList.some((u) => !u.active);
    const isActiveNavianceSchool = get(node, 'highSchool.isActiveNavianceSchool', false);
    const ncesId = get(node, 'highSchool.general.field_hs_na_nces_id');
    const deNavianceRequired = get(node, 'deNavianceRequired', false);
    const canEdit = !isActiveNavianceSchool && !deNavianceRequired && ncesId;
    const buttonDataCy = showDisabledUsers ? 'hs-users-show-active-users-button' : 'hs-users-show-disabled-users-button';
    const isViewEditCCProfileButtonEnabled = enabledFeatures.includes(FEATURE_VIEW_EDIT_CC_PROFILE);

    return (
      <div>
        {viewCommunityProfileId && (
          <CounselorCommunityProfileWrapped
            userId={viewCommunityProfileId}
            onClose={this.setViewCommunityProfileId}
          />
        )}
        <PageTitle title="Users" category="Support" />
        <Link to={`/hs-account/${node.id}/`} data-cy="hs-users-list-back-link">
          <Icon name="angle left" />
          <FormattedMessage id="hs-account.users-page.back" defaultMessage="Back" />
        </Link>
        <Header as="h1">
          <FormattedMessage
            id="hs-account.users-table.caption"
            defaultMessage="{name}'s User List"
            values={{
              name: node.name,
            }}
          />
        </Header>
        {canEdit && (
          <HasRole anyOf={[...appRoles.ADMIN_ROLES, appRoles.SUPPORT_ROLE]}>
            <Button as={Link} to={`/hs-account/${node.id}/create-user`}>
              <FormattedMessage id="hs-account.users-page.createNew" defaultMessage="Create New User" />
            </Button>
            {disabledUsersExist && (
              <Button onClick={this.toggleShowDisabledUsers} data-cy={buttonDataCy}>
                {
                  showDisabledUsers ?
                    <FormattedMessage id="hs-account.users-page.showActiveUsers" defaultMessage="Show Active Users" /> :
                    <FormattedMessage id="hs-account.users-page.showDisabledUsers" defaultMessage="Show Disabled Users" />
                }
              </Button>
            )}
          </HasRole>
        )}
        <HSAccountUsersTable
          node={node}
          showDisabledUsers={isActiveNavianceSchool || showDisabledUsers} // for active naviance schools, the "active" field is null for all the users, so it is fine to show only disabled users as that is same as showing all the users
          handleViewCCProfile={this.setViewCommunityProfileId}
          isViewEditCCProfileButtonEnabled={isViewEditCCProfileButtonEnabled}
        />
      </div>
    );
  }
}

HSAccountUsersPage.getFragments = () => [
  HSAccountUsersTable.fragment,
];

export default HSAccountUsersPage;
