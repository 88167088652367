import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { compose, setDisplayName } from 'react-recompose';
import some from 'lodash/some';

const HasRoleComponent = ({ anyOf, viewer, children }) => {
  const myRoles = get(viewer, 'profile.roles') || [];
  if (viewer.isAuthenticated && some([].concat(anyOf), (each) => myRoles.includes(each))) {
    return children;
  } else {
    return null;
  }
};
const HasRole = compose(
  setDisplayName('HasRole'),
  connect((state) => ({ viewer: state.current.viewer })),
)(HasRoleComponent);
HasRole.propTypes = {
  anyOf: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
export default HasRole;
