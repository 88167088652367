import { defineMessages } from 'react-intl';

const messages = defineMessages({
  repVisits: {
    id: 'he-account.health-report.repvisits-header',
    defaultMessage: 'RepVisits',
  },
  events: {
    id: 'he-account.health-report.events-header',
    defaultMessage: 'Events',
  },
  repVisitsCount: {
    id: 'he-account.health-report.repvisits-count',
    defaultMessage: 'TOTAL VISITS SCHEDULED FOR CURRENT SCHOOL YEAR',
  },
  eventsCount: {
    id: 'he-account.health-report.events-count',
    defaultMessage: 'EVENTS PUBLISHED FOR CURRENT SCHOOL YEAR',
  },
  title: {
    id: 'he-account.health-report.header.details',
    defaultMessage: 'Details',
  },
  userDataTitle: {
    id: 'he-account.health-report.primaryUserDetails',
    defaultMessage: 'Primary User Details',
  },
  noUserContent: {
    id: 'he-account.health-report.primaryUser.notFound',
    defaultMessage: 'No Primary User Found',
  },
  profileTitle: {
    id: 'he-account.health-report.college-profile',
    defaultMessage: 'College Profile',
  },
  gpa: {
    id: 'he-account.health-report.college-profile.gpa',
    defaultMessage: 'GPA',
  },
  tuition: {
    id: 'he-account.health-report.college-profile.tuition-data',
    defaultMessage: 'Tuition Data',
  },
  inState: {
    id: 'he-account.health-report.college-profile.tuition-in-state',
    defaultMessage: 'IN-STATE',
  },
  outOfState: {
    id: 'he-account.health-report.college-profile.tuition-out-state',
    defaultMessage: 'OUT-OF-STATE',
  },
  deadlines: {
    id: 'he-account.health-report.college-profile.deadlines',
    defaultMessage: 'Deadlines',
  },
  numberOfImages: {
    id: 'he-account.health-report.college-profile.number-images',
    defaultMessage: 'Number of Images/Videos:',
  },
  numberOfLinks: {
    id: 'he-account.health-report.college-profile.number-links',
    defaultMessage: 'Number of Links:',
  },
  numberOfSpotLights: {
    id: 'he-account.health-report.college-profile.number-spotlights',
    defaultMessage: 'Number of Spotlights:',
  },
  usage: {
    id: 'he-account.health-report.college-profile.usage',
    defaultMessage: 'Usage',
  },
  gpa25th: {
    id: 'he-account.health-report.college-profile.gpa25th',
    defaultMessage: '25TH',
  },
  average: {
    id: 'he-account.health-report.college-profile.gpa-average',
    defaultMessage: 'AVERAGE',
  },
  gpa75th: {
    id: 'he-account.health-report.college-profile.gpa-75th',
    defaultMessage: '75TH',
  },
  scholarships: {
    id: 'he-account.health-report.scholarships',
    defaultMessage: 'Scholarships',
  },
  scholarshipsEntered: {
    id: 'he-account.health-report.scholarships-entered',
    defaultMessage: 'SCHOLARSHIPS ENTERED',
  },
  aaConnectionTitle: {
    id: 'he-account.health-report.aa-connection-title',
    defaultMessage: 'AA & Connection',
  },
  msgForMajor: {
    id: 'he-account.health-report.aa-connection-msg-major',
    defaultMessage: 'MESSAGES FOR MAJORS?',
  },
  msgForComp: {
    id: 'he-account.health-report.aa-connection-msg-comp',
    defaultMessage: 'MESSAGES FOR COMPETITORS?',
  },
  academicThreshold: {
    id: 'he-account.health-report.aa-connection-academic-threshold',
    defaultMessage: 'ACADEMIC THRESHOLDS?',
  },
  lastManualDownloadDate: {
    id: 'he-account.health-report.aa-connection-download-date',
    defaultMessage: 'LAST MANUAL CONNECTION DOWNLOAD DATE',
  },
  sftpSetup: {
    id: 'he-account.health-report.aa-connection-sftp-setup',
    defaultMessage: 'SFTP SET UP?',
  },
});

export default messages;
