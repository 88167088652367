import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { fromGlobalId } from 'graphql-relay';
import { withRouter } from 'react-router-dom';
import HEHealthReport from './he-health-report';
import { SimpleLoadingComponent } from '../../utils/apollo';
import { withEnabledFeatures } from '../../utils/features';
import { getCurrentAcademicYearDates } from './utils';

const query = gql`
  query HEAccountDetails(
    $id: ID!
    $fromDate: OrdinalDate!
    $toDate: OrdinalDate!
    $today: OrdinalDate!
    $scid: String!
  ) {
    heAccount: node(id: $id) {
      id
      ... on HEAccount {
        scid
        name
        moduleSubscriptions {
          name
          status
        }
        repVisits(fromDate: $fromDate, toDate: $toDate) {
          id
        }
        collegeEvents(fromDate: $fromDate, toDate: $toDate, status: PUBLISHED) {
          edges {
            node {
              id
            }
          }
        }
        scholarships {
          meritBasedScholarships {
            name
          }
        }
        primaryUser {
          id
          firstName
          lastName
          email
          role
          active
        }
        collegeCore {
          address {
            street
            street2
            city
            state
            postalCode
          }
        }
        activeMatchSubscriptions(fromDate: $today, visible: true) {
          edges {
            node {
              academicThreshold {
                enabled
              }
            }
          }
        }
        activeMatchMessages {
          competitors {
            defaultMessage
            messages {
              message
            }
          }
          majors {
            defaultMessage
            messages {
              message
            }
          }
        }
        activeMatchExportConfig {
          id
          schedule
        }
        activeMatchConnectionsLastDownloadedDatetime
      }
    }
    collegeData: collegeDetails(scid: $scid, extended: true) {
      hubsWebLinks {
        premiumLinks: premium_links {
          url
        }
      }
      hubsWebTour {
        campusMedia: campus_media {
          assets {
            mediaType: media_type
          }
        }
      }
      hubsProfile {
        customProfiles: custom_profiles {
          productId: product_id
        }
      }
      gpa25Percentile
      gpa75Percentile
      gpaAveragePercentile
      tuitionOutState
      tuitionInState
      deadlines {
        deadlineDay
        type
        deadlineMonth
        description
      }
    }
  }
`;

const accountData = graphql(query, {
  name: 'accountDetails',
  options: ({ match }) => {
    const { id } = match.params;
    const scid = fromGlobalId(id);
    const { fromDate, toDate } = getCurrentAcademicYearDates();
    const today = new Date();
    return {
      variables: {
        id,
        scid: scid?.id || '',
        fromDate,
        toDate,
        today: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
      },
    };
  },
});

export default withRouter(
  SimpleLoadingComponent(accountData)(
    withEnabledFeatures(HEHealthReport),
  ),
);
