import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import Form from '../../components/Form';
import Header from '../../components/Header';

class CheckboxRenderer extends React.PureComponent {
  static propTypes = {
    value: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
  };

  static defaultProps = {
    value: [],
    onChange: _noop,
    options: [],
  };

  handleChange = (e, { name, value }) => {
    const { name: fieldName, value: fieldValue } = this.props;

    const exists = fieldValue.includes(name);
    const newValue = exists ?
      fieldValue.filter((a) => a !== name) :
      fieldValue.concat([name]);

    const { onChange } = this.props;
    onChange({
      name: fieldName,
      value: newValue,
    });
  }

  render() {
    const { label: fieldLabel, options, value } = this.props;
    return (
      <React.Fragment>
        <Header as="h3">{fieldLabel}</Header>
        { options.map((option) => {
          const { name, label } = option;
          const includes = Array.isArray(value) && value.includes(name);
          const cId = `${fieldLabel}-${name}`;
          return (
            <Form.Field key={name}>
              <Checkbox
                id={cId}
                key={cId}
                data-cy={`${fieldLabel}-${name}`}
                name={name}
                label={label}
                onChange={this.handleChange}
                checked={includes}
              />

            </Form.Field>
          );
        })}
      </React.Fragment>
    );
  }
}

export default CheckboxRenderer;
