import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, defineMessages, injectIntl } from 'react-intl';
import get from 'lodash/get';
import connect from 'react-redux/es/connect/connect';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import UserStatusIcon from '../../components/UserStatusIcon';
import CommunityStatusIcon from '../../components/CommunityStatusIcon';
import './styles.css';
import MoveUserToHeAccount from '../MoveUserToHeAccount';
import SwitchPrimaryUser from '../SwitchPrimaryUser';
import ReInviteUser from '../ReInviteUser';
import ToggleActivationUser from '../ToggleActivationUser';
import UnlockUser from '../UnlockUser';
import ImpersonateUser, { HE_APP_URL } from '../ImpersonateUser';
import { isUserAdmin, isUserSupport, isUserSuperAdmin } from '../Authorization';
import * as appRoles from '../Authorization/constants';
import HasRole from '../../components/HasRole';

const messages = defineMessages({
  actions: {
    id: 'he-account.users-table.dropdown.actions',
    defaultMessage: 'Actions',
  },
  activate: {
    id: 'he-account.users-table.dropdown.activate',
    defaultMessage: 'Activate',
  },
  inactivate: {
    id: 'he-account.users-table.dropdown.inactivate',
    defaultMessage: 'Inactivate',
  },
  switch: {
    id: 'he-account.users-table.dropdown.switch',
    defaultMessage: 'Assign as Primary',
  },
  reInvite: {
    id: 'he-account.users-table.dropdown.reinvite',
    defaultMessage: 'Re-invite',
  },
  unlock: {
    id: 'he-account.users-table.dropdown.unlock',
    defaultMessage: 'Unlock',
  },
  loginAs: {
    id: 'he-account.users-table.dropdown.loginAs',
    defaultMessage: 'Login As',
  },
  viewEditCCProfile: {
    id: 'he-account.users-table.dropdown.viewEditCCProfile',
    defaultMessage: 'View/Edit CC Profile',
  },
  moveToHEAccount: {
    id: 'he-account.users-table.dropdown.moveToHEAccount',
    defaultMessage: 'Move User',
  },
});

const RE_INVITE_STATUS = 'FORCE_CHANGE_PASSWORD';
const trigger = (<Icon name="tasks" aria-label="Actions" data-cy="user-actions-menu" />);

function prepareDropdownOptions(props) {
  const { intl, heAccount, user, current, isViewEditCCProfileButtonEnabled } = props;
  const lockStatus = JSON.parse(user.lockStatus);
  const statusMessage = {
    textKey: user.active ? messages.inactivate : messages.activate,
    valueKey: user.active ? 'inactivate' : 'activate',
  };
  const userIsLocked = lockStatus && lockStatus.locked;
  const options = [];
  options.push({
    text: intl.formatMessage(messages.unlock),
    value: 'unlock',
    disabled: !userIsLocked,
  });
  options.push({
    'text': intl.formatMessage(statusMessage.textKey),
    'value': statusMessage.valueKey,
    'data-cy': `user-actions-menu-${statusMessage.valueKey}`,
  });
  if (get(heAccount, 'primaryUser.id') !== user.id && user.active) {
    options.push({
      text: intl.formatMessage(messages.switch),
      value: 'switch',
    });
  }
  if (RE_INVITE_STATUS === user.userStatus) {
    options.push({
      text: intl.formatMessage(messages.reInvite),
      value: 're-invite',
    });
  }
  if ((isUserAdmin(current.viewer) || isUserSupport(current.viewer)) && user.active && !userIsLocked) {
    options.push({
      text: intl.formatMessage(messages.loginAs),
      value: 'impersonate',
    });
  }
  if (isUserAdmin(current.viewer)) {
    options.push({
      text: intl.formatMessage(messages.moveToHEAccount),
      value: 'move-user',
    });
  }
  if (isViewEditCCProfileButtonEnabled && isUserSuperAdmin(current.viewer) && user.communityProfiles.length === 1) {
    options.push({
      text: intl.formatMessage(messages.viewEditCCProfile),
      value: 'view-edit-cc-profile',
    });
  }
  return options;
}

class HEAccountUsersTableRow extends React.PureComponent {
  static propTypes = {
    heAccount: PropTypes.shape({
      id: PropTypes.string.isRequired,
      scid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    onSuccess: PropTypes.func,
    current: PropTypes.shape({
      viewer: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    handleViewCCProfile: PropTypes.func,
    isViewEditCCProfileButtonEnabled: PropTypes.bool,
  };

  state = {
    actionClicked: null,
  };

  handleActionsClicked = (e, { value }, user) => {
    this.setState({
      actionClicked: value,
    });
    if (value === 'view-edit-cc-profile') {
      this.props.handleViewCCProfile(user.communityProfiles[0].communityId.toString());
    }
  };

  handleActionsClose = () => {
    this.setState({
      actionClicked: null,
    });
  };

  render() {
    const { user, heAccount, intl } = this.props;
    const { actionClicked } = this.state;
    const dropdownOptions = prepareDropdownOptions(this.props);
    const lastLogin = user.lastLogin ? new Date(user.lastLogin).toISOString() : null;
    const primaryUserId = get(heAccount, 'primaryUser.id');

    return (
      <Table.Row>
        <Table.Cell>
          <UserStatusIcon user={user} primaryUserId={primaryUserId} />
        </Table.Cell>
        <Table.Cell>{user.firstName}</Table.Cell>
        <Table.Cell>{user.lastName}</Table.Cell>
        <Table.Cell>{user.role}</Table.Cell>
        <Table.Cell><CommunityStatusIcon user={user} institutionId={heAccount.scid} /></Table.Cell>
        <Table.Cell><a href={`mailto:${user.email}`}>{user.email}</a><br />
          {lastLogin ?
            <FormattedDate value={lastLogin} year="numeric" month="short" day="numeric" /> :
            ''}
        </Table.Cell>
        <Table.Cell>
          { dropdownOptions.length > 0 && (
            <HasRole anyOf={appRoles.NON_VIEW_ONLY_ROLES}>
              <Dropdown
                value="none"
                selectOnBlur={false}
                selectOnNavigation={false}
                className="top right"
                floating
                options={dropdownOptions}
                onChange={(e, { value }) => this.handleActionsClicked(e, { value }, user)}
                aria-label={intl.formatMessage(messages.actions)}
                trigger={trigger}
              />
            </HasRole>
          )}
          {
            actionClicked === 'move-user' &&
            <MoveUserToHeAccount user={user} onSuccess={this.props.onSuccess} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'unlock' &&
            <UnlockUser heAccount={heAccount} user={user} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'activate' &&
            <ToggleActivationUser heAccount={heAccount} user={user} activate onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'inactivate' &&
            <ToggleActivationUser heAccount={heAccount} user={user} activate={false} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'switch' &&
            <SwitchPrimaryUser heAccount={heAccount} user={user} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 're-invite' &&
            <ReInviteUser heAccount={heAccount} user={user} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'impersonate' &&
            <ImpersonateUser node={heAccount} user={user} onClose={this.handleActionsClose} redirectTo={HE_APP_URL} />
          }
        </Table.Cell>
      </Table.Row>
    );
  }
}
const mapStateToProps = (state) => ({
  current: state.current,
});

export default injectIntl(connect(mapStateToProps)(HEAccountUsersTableRow));
