import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import List from '../../components/List';

import './styles.css';

export const formattedCollegeName = ({ scid, name, state, collegeCore }) => {
  if (_get(collegeCore, 'address.state', null)) {
    return `${name}, ${_get(collegeCore, 'address.state')} (${scid})`;
  } else if (state) {
    return `${name}, ${state} (${scid})`;
  } else {
    return `${name} (${scid})`;
  }
};

export default class SearchResult extends React.PureComponent {
  static propTypes = {
    college: PropTypes.object,
    onClick: PropTypes.func,
    highlighted: PropTypes.bool,
    collegeCore: PropTypes.object,
  };

  handleClick = () => {
    const { college, onClick } = this.props;
    onClick({
      scid: college.scid,
      name: formattedCollegeName(college),
    });
  };

  render() {
    const { college, highlighted } = this.props;
    return (
      <List.Item
        styleName={`search-college-result${highlighted ? ' active' : ''}`}
        key={college.scid}
        onClick={this.handleClick}
        tabIndex="0"
        dataid={college.scid}
      >
        <List.Content styleName="search-college-college-link">
          {formattedCollegeName(college)}
        </List.Content>
      </List.Item>
    );
  }
}
