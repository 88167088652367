import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import Table from '../Table';
import Button from '../Button';
import Icon from '../Icon';
import LocationLink from './LocationLink';

import './styles.css';

function setInactiveStatus(isSubscriptionEnabled, content) {
  if (isSubscriptionEnabled) {
    return content;
  }
  return <span>Inactive</span>;
}
const SubscriptionRow = (props) => {
  const { isUserAdmin, activeSubscriptions, canEdit, removeDiversityFilters } = props;
  const showRemoveButton = (isUserAdmin || props.startDate > Date.now()) && canEdit;
  const handleDeleteClick = (e) => {
    props.onClickDelete(e, props.id);
  };

  return (
    <Table.Row>
      <Table.Cell>
        {canEdit ? (
          <LocationLink
            id={props.id}
            location={props.location}
            onLocationClick={props.onLocationClick}
          />
        ) : (
          <div data-cy={`Subscription-link-text-location-${props.location}`}>{props.location}</div>
        )}
      </Table.Cell>
      {!removeDiversityFilters && <Table.Cell>{props.diversity}</Table.Cell>}
      <Table.Cell>
        { setInactiveStatus(
          activeSubscriptions.includes('ADVANCEDAWARENESS'),
          props.competitors.split('|').map((item) => (
            <div key={item}>{item}</div>
          )),
        )}
      </Table.Cell>
      <Table.Cell>
        {
          setInactiveStatus(
            activeSubscriptions.includes('ADVANCEDAWARENESS'),
            <Icon name={props.majors ? 'checkmark': 'minus'} />,
          )
        }
      </Table.Cell>
      <Table.Cell>
        {
          setInactiveStatus(
            activeSubscriptions.includes('CONNECTION'),
            <Icon name={props.connection ? 'checkmark': 'minus'} />,
          )
        }
      </Table.Cell>
      <Table.Cell>
        <FormattedDate
          year="numeric"
          month="short"
          day="2-digit"
          value={new Date(props.startDate)}
        />
        &nbsp;
        <Icon size="large" name="calendar alternate outline" />
      </Table.Cell>
      <Table.Cell>
        <FormattedDate
          year="numeric"
          month="short"
          day="2-digit"
          value={new Date(props.endDate)}
        />
        &nbsp;
        <Icon size="large" name="calendar alternate outline" />
      </Table.Cell>
      <Table.Cell>
        <FormattedDate
          year="numeric"
          month="short"
          day="2-digit"
          value={new Date(props.lastModifiedDate)}
        />
        <p>{props.lastModifiedBy}</p>
      </Table.Cell>
      <Table.Cell>{ showRemoveButton && <Button data-cy="remove-subscription-btn" onClick={handleDeleteClick}>remove</Button> }</Table.Cell>
    </Table.Row>
  );
};

SubscriptionRow.propTypes = {
  isUserAdmin: PropTypes.bool,
  canEdit: PropTypes.bool,
  onClickDelete: PropTypes.func,
  onLocationClick: PropTypes.func,
  id: PropTypes.string,
  location: PropTypes.string,
  diversity: PropTypes.string,
  competitors: PropTypes.string,
  majors: PropTypes.bool,
  connection: PropTypes.bool,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  lastModifiedDate: PropTypes.number,
  lastModifiedBy: PropTypes.string,
  activeSubscriptions: PropTypes.array,
  removeDiversityFilters: PropTypes.bool,
};

export default SubscriptionRow;
