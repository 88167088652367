import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import defaults from 'lodash/defaults';
import InputRange from '../../components/InputRange';
import Accordion from '../../components/Accordion';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import styles from '../AdvancedSearch/styles.css';
import stateOptions from '../AdvancedPeopleSearchBox/state-options';

const messages = defineMessages({
  name: {
    id: 'search-results.highschool.name',
    defaultMessage: 'Name',
  },
  location: {
    id: 'search-results.highschool.locationGroup',
    defaultMessage: 'Location',
  },
  academics: {
    id: 'search-results.highschool.academicsGroup',
    defaultMessage: 'Academics',
  },
  type: {
    id: 'search-results.highschool.type',
    defaultMessage: 'Type',
  },
  city: {
    id: 'search-results.highschool.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'search-results.highschool.state',
    defaultMessage: 'State',
  },
  postalCode: {
    id: 'search-results.highschool.postalCode',
    defaultMessage: 'Postal Code',
  },
  charterSchool: {
    id: 'search-results.highschool.charterSchool',
    defaultMessage: 'Charter School',
  },
  titleIEligible: {
    id: 'search-results.highschool.titleIEligible',
    defaultMessage: 'Title I Eligible',
  },
  collegeGoingRate: {
    id: 'search-results.highschool.collegeGoingRate',
    defaultMessage: 'College Going Percent',
  },
});
const publicPrivateTypeOptions = [
  { text: 'All', value: '' },
  { text: 'Public', value: 'PUBLIC' },
  { text: 'Private', value: 'PRIVATE' },
];

const yesNoUnknownOptions = [
  { text: 'All', value: '' },
  { text: 'Yes', value: 'YES' },
  { text: 'No', value: 'NO' },
  { text: 'Unknown', value: 'UNKNOWN' },
];

function isLocationActive(advanced) {
  return advanced.city || advanced.state || advanced.postalCode;
}

function isAcademicsActive(advanced) {
  return advanced.charterSchool || advanced.collegeGoingRate || advanced.titleIEligible;
}

class AdvancedHighSchoolSearchBox extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    advanced: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf(['', 'PUBLIC', 'PRIVATE']),
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
      charterSchool: PropTypes.oneOf(['', 'YES', 'NO']),
      titleIEligible: PropTypes.oneOf(['', 'YES', 'NO', 'MISSING']),
      collegeGoingRate: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
      }),
    }).isRequired,
  };

  onCollegeGoingRateChange = (val) => {
    this.props.onChange(null, {
      name: 'collegeGoingRate',
      value: {
        from: val.min,
        to: val.max,
      },
      type: 'input',
    });
  };

  render() {
    const { onChange, advanced } = this.props;
    const collegeGoingRate = defaults({}, advanced.collegeGoingRate, { from: 0, to: 100 });
    return (
      <div>
        <div styleName="hsContainer">
          <label htmlFor="hs-name"><FormattedMessage {...messages.name} /></label>
          <Input styleName="keywordInput" type="text" fluid onChange={onChange}>
            <input id="hs-name" name="name" value={advanced.name || ''} />
          </Input>
          <div styleName="dropdownContainer">
            <div styleName="dropdownLabel"><FormattedMessage {...messages.type} /></div>
            <div styleName="dropdownInput"><Dropdown
              id="hs-type"
              placeholder="All"
              name="type"
              value={advanced.type}
              onChange={onChange}
              fluid
              selection
              options={publicPrivateTypeOptions}
            />
            </div>
          </div>
        </div>
        <Accordion
          defaultActiveIndex={isLocationActive(advanced) ? 0 : -1}
          data-cy="hs-location-accordion"
          panels={[{
            key: 'location',
            title: {
              className: styles.categoryTitle,
              children: (
                <span>
                  <FormattedMessage {...messages.location} />
                  <Icon name="chevron left" />
                </span>
              ),
            },
            content: {
              className: styles.institutionContainer,
              children: (
                <div>
                  <label htmlFor="hs-city"><FormattedMessage {...messages.city} /></label>
                  <Input styleName="keywordInput" type="text" fluid onChange={onChange}>
                    <input id="hs-city" name="city" value={advanced.city} />
                  </Input>
                  <div styleName="dropdownContainer">
                    <div styleName="dropdownLabel"><FormattedMessage {...messages.state} /></div>
                    <div styleName="dropdownInput"><Dropdown
                      placeholder="Select State"
                      id="hs-state"
                      name="state"
                      onChange={onChange}
                      value={advanced.state}
                      fluid
                      selection
                      options={stateOptions}
                    />
                    </div>
                  </div>
                  <label htmlFor="hs-postalCode"><FormattedMessage {...messages.postalCode} /></label>
                  <Input styleName="lastFieldInput" type="text" fluid onChange={onChange}>
                    <input id="hs-postalCode" name="postalCode" value={advanced.postalCode} />
                  </Input>
                </div>
              ),
            },
          }]}
        />
        <Accordion
          defaultActiveIndex={isAcademicsActive(advanced) ? 0 : -1}
          panels={[{
            key: 'academics',
            title: {
              className: styles.categoryTitle,
              children: (
                <span>
                  <FormattedMessage {...messages.academics} />
                  <Icon name="chevron left" />
                </span>
              ),
            },
            content: {
              className: styles.institutionContainer,
              children: (
                <div>
                  <div styleName="dropdownContainer">
                    <div styleName="dropdownLabel"><FormattedMessage {...messages.charterSchool} /></div>
                    <div styleName="dropdownInput"><Dropdown
                      id="hs-charter-school"
                      placeholder="All"
                      name="charterSchool"
                      value={advanced.charterSchool}
                      onChange={onChange}
                      fluid
                      selection
                      options={yesNoUnknownOptions}
                    />
                    </div>
                    <div styleName="dropdownLabel"><FormattedMessage {...messages.titleIEligible} /></div>
                    <div styleName="dropdownInput"><Dropdown
                      id="hs-title-i-eligible"
                      placeholder="All"
                      name="titleIEligible"
                      value={advanced.titleIEligible}
                      onChange={onChange}
                      fluid
                      selection
                      options={yesNoUnknownOptions}
                    />
                    </div>
                    <div id="hs-college-going-rate" styleName="dropdownLabel"><FormattedMessage {...messages.collegeGoingRate} /></div>
                    <div styleName="input-range-margin">
                      <InputRange
                        id="hs-college-going-rate"
                        name="collegeGoingRate"
                        ariaLabelledby="hs-college-going-rate"
                        minValue={0}
                        maxValue={100}
                        value={{ min: collegeGoingRate.from, max: collegeGoingRate.to }}
                        onChange={this.onCollegeGoingRateChange}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
          }]}
        />
      </div>
    );
  }
}

export default AdvancedHighSchoolSearchBox;
