import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedDate, FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import get from 'lodash/get';
import Toast from '../../components/Toast';
import Message from '../../components/Message';
import Icon from '../../components/Icon';
import HasRole from '../../components/HasRole';
import ToggleNavianceModal from './toggle-naviance-modal';
import { ADMIN_ROLES } from '../Authorization/constants';
import './styles.css';

const messages = defineMessages({
  warning: {
    id: 'hs-account-page.warning',
    defaultMessage: 'Warning!',
  },
  denavianceRequiredExpired: {
    id: 'hs-account-page.denavianceRequiredExpired',
    defaultMessage: 'This high school\'s Naviance Subscription expired on {date} and the account can\'t be managed until the subscription status is updated in Intersect.',
  },
  denavianceRequiredInactive: {
    id: 'hs-account-page.denavianceRequiredInactive',
    defaultMessage: 'This high school\'s Naviance Subscription is inactive and the account can\'t be managed until the subscription status is updated in Intersect.',
  },
  denavianceRequiredPending: {
    id: 'hs-account-page.denavianceRequiredPending',
    defaultMessage: 'This high school\'s Naviance Subscription will start on {date} and the account can\'t be managed until that time or the subscription status is updated in Intersect.',
  },
  denavianceAction: {
    id: 'hs-account-page.denavianceAction',
    defaultMessage: 'Turn Into a Non-Naviance School',
  },
  ncesIdMissing: {
    id: 'hs-account-page.ncesIdMissing',
    defaultMessage: 'Unable to De-Naviance without NCES ID',
  },
  navianceRequired: {
    id: 'hs-account-page.navianceRequired',
    defaultMessage: 'This high school\'s Naviance Subscription started on {date}. The subscription status needs to be updated in Intersect.',
  },
  navianceAction: {
    id: 'hs-account-page.navianceAction',
    defaultMessage: 'Turn Into a Naviance School',
  },
});

class NavDeNavComponent extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object.isRequired,
  };

  state = {
    openModal: false,
    navianceLabel: '',
    success: false,
  };

  onNavianceClick = (event) => {
    this.setState({ openModal: true, navianceLabel: 'Naviance' });
    event.preventDefault();
  };

  onNonNavianceClick = (event) => {
    this.setState({ openModal: true, navianceLabel: 'Non-Naviance' });
    event.preventDefault();
  };

  onModalClose = (success) => {
    this.setState({ openModal: false, success });
  };

  render() {
    const { node } = this.props;
    const { openModal, navianceLabel, success } = this.state;
    const { deNavianceRequired, navianceRequired } = node;
    const { navianceSubscriptionStartDate, navianceSubscriptionExpirationDate, navianceSubscriptionStatus } = node.highSchool;
    const ncesId = get(node, 'highSchool.general.field_hs_na_nces_id');
    const startDate = navianceSubscriptionStartDate &&
      <FormattedDate value={navianceSubscriptionStartDate} weekday="short" month="short" day="numeric" year="numeric" />;
    const expirationDate = navianceSubscriptionExpirationDate &&
      <FormattedDate value={navianceSubscriptionExpirationDate} weekday="short" month="short" day="numeric" year="numeric" />;
    return (
      <div styleName="nav-denav-wrapper">
        {success && <Toast active kind="success" title="Done" description={`This high school is now a ${navianceLabel} school.`} />}
        {openModal && (
        <ToggleNavianceModal
          node={node}
          navianceLabel={navianceLabel}
          navianceSubscriptionStatus={navianceSubscriptionStatus}
          onClose={this.onModalClose}
        />
        )}
        {deNavianceRequired && (
        <Message icon warning>
          <Icon name="warning circle" />
          <Message.Content>
            <Message.Header><FormattedMessage {...messages.warning} /></Message.Header>
            <p>
              {navianceSubscriptionStatus === 'EXPIRED' && <FormattedMessage {...messages.denavianceRequiredExpired} values={{ date: expirationDate }} />}
              {navianceSubscriptionStatus === 'INACTIVE' && <FormattedMessage {...messages.denavianceRequiredInactive} />}
              {navianceSubscriptionStatus === 'PENDING' && <FormattedMessage {...messages.denavianceRequiredPending} values={{ date: startDate }} />}
            </p>
            <p>
              <HasRole anyOf={ADMIN_ROLES}>
                <span>
                  {!ncesId && <span styleName="error"><FormattedMessage {...messages.ncesIdMissing} /></span>}
                  {ncesId && (
                  <a id="denaviance-school" styleName="banner-link" href="/" onClick={this.onNonNavianceClick}>
                    <FormattedMessage {...messages.denavianceAction} />
                  </a>
                  )}
                </span>
              </HasRole>
            </p>
          </Message.Content>
        </Message>
        )}
        {navianceRequired && (
        <Message icon warning>
          <Icon name="warning circle" />
          <Message.Content>
            <Message.Header><FormattedMessage {...messages.warning} /></Message.Header>
            <p><FormattedMessage {...messages.navianceRequired} values={{ date: startDate }} /></p>
            <HasRole anyOf={ADMIN_ROLES}>
              <a id="naviance-school" styleName="banner-link" href="/" onClick={this.onNavianceClick}>
                <FormattedMessage {...messages.navianceAction} />
              </a>
            </HasRole>
          </Message.Content>
        </Message>
        )}
      </div>
    );
  }
}

NavDeNavComponent.fragment = gql`
  fragment NavDeNav on HSAccount {
    id
    deNavianceRequired
    navianceRequired
    highSchool {
      navianceSubscriptionExpirationDate
      navianceSubscriptionStartDate
      navianceSubscriptionStatus
      general {
        field_hs_na_nces_id
      }
      naviance {
        field_hs_na_nid
      }
    }
  }
`;

export default NavDeNavComponent;
