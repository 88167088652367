import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import Portal from 'semantic-ui-react/dist/commonjs/addons/Portal';

import Icon from '../Icon';
import Message from '../Message';
import Button from '../Button';
import styles from './styles.css';

const ToastKind = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

const Timeouts = {
  enter: 200,
  exit: 700,
  show: 5000,
};

export default class Toast extends PureComponent {
  static propTypes = {
    mountNode: PropTypes.any,
    active: PropTypes.bool,
    title: PropTypes.any,
    description: PropTypes.string,
    kind: PropTypes.oneOf(Object.values(ToastKind)),
    onDeactivate: PropTypes.func,
  }

  static defaultProps = {
    kind: 'success',
  }

  constructor(props) {
    super(props);

    this.activeTimeoutId = 0;

    this.state = {
      open: props.active,
      show: props.active,
    };

    if (props.active) {
      this.activate();
    }
  }

  componentWillUnmount() {
    this.clearActiveTimeout();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ active }) {
    /* istanbul ignore else */
    if (active !== this.state.open) {
      this.setState({ open: active, show: active });

      if (active) {
        this.activate();
      } else {
        this.clearActiveTimeout();
      }
    }
  }

  onDismiss = () => {
    this.setState({ open: false });
    this.clearActiveTimeout();
  }

  get iconName() {
    switch (this.props.kind) {
      case ToastKind.error:
        return 'remove circle';
      case ToastKind.warning:
        return 'warning circle';
      case ToastKind.info:
        return 'info circle';
      case ToastKind.success:
      default:
        return 'check circle';
    }
  }

  get messageProps() {
    const { kind } = this.props;
    return {
      success: kind === ToastKind.success,
      error: kind === ToastKind.error,
      warning: kind === ToastKind.warning,
      info: kind === ToastKind.info,
    };
  }

  clearActiveTimeout() {
    /* istanbul ignore else */
    if (this.activeTimeoutId !== 0) {
      clearTimeout(this.activeTimeoutId);
      this.activeTimeoutId = 0;
    }
  }

  activate() {
    const { onDeactivate } = this.props;
    /* istanbul ignore else */
    if (this.activeTimeoutId === 0) {
      this.activeTimeoutId = setTimeout(() => {
        this.setState({ show: false });
        this.activeTimeoutId = setTimeout(() => {
          if (onDeactivate) {
            // use props to deactivate, useful when the parent component
            // has to re render when this is displayed
            onDeactivate();
          } else {
            this.setState({ open: false });
          }
          this.activeTimeoutId = 0;
        }, Timeouts.exit);
      }, Timeouts.show);
    }
  }

  render() {
    const { title, description, mountNode } = this.props;
    const { open, show } = this.state;

    return (
      <Portal
        closeOnEscape={false}
        closeOnDocumentClick={false}
        open={open}
        mountNode={mountNode}
      >
        <CSSTransition
          in={show}
          appear
          classNames={styles}
          enter={false}
          timeout={Timeouts}
        >
          <div styleName="toast">
            <Message
              {...this.messageProps}
              size="small"
              className="toast"
              icon
            >
              <Button basic icon color="black" className="close" onClick={this.onDismiss}>
                <Icon name="close" />
              </Button>
              <Icon name={this.iconName} />
              <Message.Content>
                <Message.Header>{title}</Message.Header>
                <p>{description}</p>
              </Message.Content>
            </Message>
          </div>
        </CSSTransition>
      </Portal>
    );
  }
}
