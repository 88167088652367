import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { compose, flattenProp } from 'react-recompose';
import queryString from 'query-string';

import LogHistory from './log-history';
import { ranges } from '../../components/SearchControl/timeframes';
import { loadMorePaginationProp } from '../../utils/apollo';

const query = gql`
  query LogHistory($id: ID!, $searchQuery: String, $first: Int, $after: String, $from: DateTime!, $to: DateTime!) {
    node(id: $id) {
      id
      ...on HEAccount {
        name
        logHistory(first: $first, after: $after, from: $from, to: $to, searchQuery: $searchQuery) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ... on LogEntry {
                id
                action
                actor {
                  ... on HEUser {
                    # Please be aware of adding 'id' field here.
                    # There was a graphql/Appollo cache issue which had caused MATCH-7048 bug
                    firstName
                    lastName
                    email
                  }
                  ... on SupportUser {
                    # Please be aware of adding 'id' field here.
                    # There was a graphql/Appollo cache issue which had caused MATCH-7048 bug
                    firstName
                    lastName
                    email
                  }
                  ... on HSUser {
                    # Please be aware of adding 'id' field here.
                    # There was a graphql/Appollo cache issue which had caused MATCH-7048 bug
                    firstName
                    lastName
                    email
                  }
                  impersonator
                }
                target {
                  ... on HEUser {
                    id
                    firstName
                    lastName
                    email
                  }
                  ... on CommunityPerson {
                    field_first_name
                    field_last_name
                    mail
                  }
                  ... on RepVisitsDay {
                    id
                    date
                  }
                  ... on RepVisitsAccount {
                    id
                  }
                  ... on CollegeFair {
                    id
                    name
                  }
                  ... on RepVisitsHEContact {
                    id
                    field_first_name
                    field_last_name
                  }
                  ... on RepVisitsRatingSummary {
                    id
                    schoolYear
                    communityPerson:contact {
                      name
                    }
                  }
                  ... on RepVisitsRatingDetail {
                    id
                    reviewDate
                  }
                  ... on RepVisitsContact {
                    id
                  }
                  ... on HEAccount {
                    id
                    scid
                    name
                  }
                  ... on AMExportConfig {
                    id
                  }
                }
                changed
                timestamp
              }
            }
          }
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ match, location }) => {
    const urlQuery = queryString.parse(location.search);
    const { searchQuery = '' } = urlQuery;
    const { id, timeframe } = match.params;
    const range = ranges[timeframe];
    const { from, to } = (range && range(urlQuery)) || {};
    return {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        id,
        first: 20,
        from,
        to,
        searchQuery,
      },
    };
  },
  props: loadMorePaginationProp(query),
});

export default withRouter(compose(graphqlData, flattenProp('data'))(LogHistory));
