import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from './CustomButton';
import Radio from '../Radio';
import './styles.css';

const filterTypes = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'active',
    label: 'Active',
  },
  {
    key: 'inactive',
    label: 'Inactive',
  },
];

const TitleWithActionsAndFilters = ({ title, tag, children, filterType: filterTypeSelected, handleFilterTypeChange }) => (
  <React.Fragment>
    <div styleName="only-actions">
      { React.Children.count(children) > 0 && (
        <div data-cy="he-account-links" className="he-account-links" styleName="he-account-links">
          {children}
        </div>
      )}
    </div>
    <div styleName="title-with-filters">
      { React.createElement(tag, { 'className': 'light-title', 'data-cy': 'he-account-title' }, title) }
      <div data-cy="he-account-filters" className="he-account-filters" styleName="he-account-filters">
        <span>Show:</span>
        {filterTypes.map((filterType) => (
          <Radio
            key={filterType.key}
            value={filterType.key}
            id={`filter-radio-${filterType.key}`}
            name="filterTypes"
            className="filter-radio"
            styleName="filter-radio"
            label={filterType.label}
            checked={filterTypeSelected === filterType.key}
            onChange={() => handleFilterTypeChange(filterType.key)}
          />
        ))}
      </div>
    </div>
  </React.Fragment>
);
TitleWithActionsAndFilters.Action = CustomButton;
TitleWithActionsAndFilters.propTypes = {
  tag: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  filterType: PropTypes.string,
  handleFilterTypeChange: PropTypes.func,
};
TitleWithActionsAndFilters.defaultProps = {
  tag: 'h1',
};
export default TitleWithActionsAndFilters;
