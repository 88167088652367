import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Menu from '../Menu';
import Divider from '../Divider';
import HasRole from '../HasRole';
import styles from './styles.css';
import * as constants from '../../containers/Authorization/constants';
import FeatureEnabled from '../FeatureEnabled';
import FeatureDisabled from '../FeatureDisabled';
import { FeatureFlags } from '../../utils/features';

const MainMenu = ({ idPrefix }) => (
  <div>
    <Menu as="dl" styleName="menu" vertical inverted size="huge">
      <Menu.Header as="dt" styleName="menu-header">
        <img
          src="https://static.intersect.hobsons.com/svg/intersect-by-powerschool.svg"
          width="95%"
          height="100%"
          alt="Intersect Logo"
        />
      </Menu.Header>
      <HasRole anyOf={constants.NON_VIEW_ONLY_ROLES}>
        <Menu.Item as="dt" link styleName="menu-item">
          <NavLink aria-label="Home" to="/" exact id={`js-${idPrefix}-home-menu-link`} styleName="menu-link" activeClassName={styles.active}>
            Home
          </NavLink>
        </Menu.Item>
      </HasRole>
      <HasRole anyOf={constants.ADMIN_ROLES}>
        <Menu.Item as="dt" link styleName="menu-item">
          <NavLink aria-label="Admin Dashboard" to="/admin/announcements" id={`js-${idPrefix}-admin-menu-link`} styleName="menu-link" activeClassName={styles.active}>
            Admin Dashboard
          </NavLink>
        </Menu.Item>
      </HasRole>
    </Menu>
    {process.env.COMMUNITY_URL && (
      <React.Fragment>
        <FeatureDisabled anyOf={FeatureFlags.DISABLE_LEGACY_COMMUNITY}>
          <HasRole anyOf={constants.COMMUNITY_VIEW_ROLES}>
            <Menu as="dl" styleName="menu" vertical inverted size="huge">
              <Divider as="dt" inverted styleName="menu-divider" />
              <Menu.Header as="dt" styleName="menu-sub-header">
                <FormattedMessage id="app.mainMenu.subtitle.awareness" defaultMessage="Awareness" />
              </Menu.Header>
              <Menu.Item as="dt" link styleName="menu-item">
                <NavLink
                  data-cy="legacy-counselor-community-link"
                  aria-label="Counselor Community"
                  to="/counselor-community/"
                  id={`js-${idPrefix}-counselor-community-menu-link`}
                  activeClassName={styles.active}
                  styleName="menu-link"
                >
                  <FormattedMessage id="app.mainMenu.link.counselorcommunity" defaultMessage="Counselor Community" />
                </NavLink>
              </Menu.Item>
            </Menu>
          </HasRole>
        </FeatureDisabled>
      </React.Fragment>
    )}
    {process.env.COUNSELOR_COMMUNITY_URL && (
      <React.Fragment>
        <FeatureEnabled allOf={FeatureFlags.COMMUNITY_SERVERLESS}>
          <HasRole anyOf={constants.COMMUNITY_VIEW_ROLES}>
            <Menu as="dl" styleName="menu" vertical inverted size="huge">
              <Divider as="dt" inverted styleName="menu-divider" />
              <Menu.Item as="dt" link styleName="menu-item">
                <NavLink
                  data-cy="serverless-counselor-community-link"
                  aria-label="Counselor Community"
                  to="/counselorcommunity/"
                  id={`js-${idPrefix}-counselor-community-serverless-menu-link`}
                  activeClassName={styles.active}
                  styleName="menu-link"
                >
                  <FormattedMessage id="app.mainMenu.link.counselorcommunityserverless" defaultMessage="Counselor Community" />
                </NavLink>
              </Menu.Item>
            </Menu>
          </HasRole>
        </FeatureEnabled>
      </React.Fragment>
    )}
    <HasRole anyOf={constants.SCHOOL_CORE_LOOKUP_ROLES}>
      <Menu as="dl" styleName="menu" vertical inverted size="huge">
        <Divider as="dt" inverted styleName="menu-divider" />
        <Menu.Item as="dt" link styleName="menu-item">
          <NavLink
            aria-label="School Core Lookup"
            to="/school-core-lookup/"
            id={`js-${idPrefix}-school-core-lookup-menu-link`}
            activeClassName={styles.active}
            styleName="menu-link"
          >
            <FormattedMessage id="app.mainMenu.link.schoolcore" defaultMessage="School Core Lookup" />
          </NavLink>
        </Menu.Item>
      </Menu>
    </HasRole>
    {process.env.GRAPHIQL && (
    <Menu as="dl" styleName="menu" vertical inverted size="huge">
      <Menu.Item as="dt" link styleName="menu-item">
        <NavLink aria-label="GraphiQL" to="/graphiql" id={`js-${idPrefix}-graphiql-menu-link`} styleName="menu-link" activeClassName={styles.active}>
          GraphiQL
        </NavLink>
      </Menu.Item>
    </Menu>
    )}
  </div>
);
MainMenu.propTypes = {
  idPrefix: PropTypes.string.isRequired,
};
export default MainMenu;
