import PropTypes from 'prop-types';

export default {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    institution: PropTypes.string.isRequired,
    isNaviance: PropTypes.bool,
    active: PropTypes.bool,
    email: PropTypes.string.isRequired,
    communityProfiles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      communityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      field_first_name: PropTypes.string,
      field_last_name: PropTypes.string,
      field_institution_type_name: PropTypes.string,
      field_user_institution_uuid: PropTypes.string.isRequired,
      field_user_institution_hs_na_nces_id: PropTypes.string,
      institutionId: PropTypes.string,
      isActive: PropTypes.bool.isRequired,
      profileComplete: PropTypes.bool.isRequired,
      mail: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};
