import React from 'react';
import PropTypes from 'prop-types';
import Message from '../../components/Message';

function ScriptLoaderError({ url }) {
  return (
    <Message
      error
      header="Oops, something went wrong"
      content={`A required resource failed to load: ${url}. Please try again.`}
    />
  );
}

ScriptLoaderError.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ScriptLoaderError;
