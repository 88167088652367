const dayMilli = 1000 * 60 * 60 * 24;

function floorDate(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

const ranges = {
  today: () => {
    const now = new Date();
    const to = now.getTime();
    return {
      from: floorDate(now).getTime(),
      to,
    };
  },
  yesterday: () => {
    const now = new Date();
    const yesterday = floorDate(now).getTime();
    return {
      from: yesterday - dayMilli,
      to: yesterday - 1,
    };
  },
  sevendays: () => {
    const now = new Date();
    const to = now.getTime();
    return {
      from: floorDate(now).getTime() - (6 * dayMilli),
      to,
    };
  },
  thirtydays: () => {
    const now = new Date();
    const to = now.getTime();
    return {
      from: floorDate(now) - (29 * dayMilli),
      to,
    };
  },
  lastweek: () => {
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() - nowDate.getDay());
    const now = floorDate(nowDate).getTime();
    return {
      from: now - (7 * dayMilli),
      to: now - 1,
    };
  },
  custom: ({ from, to }) => {
    const fromParts = from.split('-');
    const toParts = to.split('-');
    const fromDate = new Date(fromParts[0], fromParts[1]-1, fromParts[2]);
    const toDate = new Date(toParts[0], toParts[1]-1, toParts[2]);
    return {
      from: fromDate.getTime(),
      to: (toDate.getTime() + dayMilli) - 1,
    };
  },
  lastmonth: () => {
    const now = floorDate(new Date());
    now.setDate(1);
    const to = now.getTime() - 1;
    now.setMonth(now.getMonth() - 1);
    now.setDate(1);
    return {
      from: now.getTime(),
      to,
    };
  },
};
const timeframeConfig = [
  {
    text: 'Today',
    value: 'today',
    range: ranges.today,
  },
  {
    text: 'Yesterday',
    value: 'yesterday',
    range: ranges.yesterday,
  },
  {
    text: 'Last 7 days',
    value: 'sevendays',
    range: ranges.sevendays,
  },
  {
    text: 'Last 30 days',
    value: 'thirtydays',
    range: ranges.thirtydays,
  },
  {
    text: 'Custom',
    value: 'custom',
  },
  {
    text: 'Last Week',
    value: 'lastweek',
    range: ranges.lastweek,
  },
  {
    text: 'Last Month',
    value: 'lastmonth',
    range: ranges.lastmonth,
  },
];
export default timeframeConfig;
export { ranges };
