import { fromGlobalId } from 'graphql-relay';
import React from 'react';
import Popup from '../Popup';
import Table from '../Table';
import CommunityInstIds from './community-inst-ids';
import { generateIcon, isMatchInstitition } from './utils';
import propTypes from './propTypes';
import './styles.css';

const Mismatch = ({ user }) => {
  const communityProfile = user.communityProfiles[0];
  return (
    <Popup hoverable trigger={generateIcon(communityProfile.communityId, 'warning sign', 'yellow')}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{user.isNaviance ? 'Naviance' : 'Cognito'}</Table.HeaderCell>
            <Table.HeaderCell>Community</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row styleName={user.uuid !== fromGlobalId(communityProfile.id).id ? 'mismatch' : ''}>
            <Table.Cell>{user.uuid}</Table.Cell>
            <Table.Cell>{fromGlobalId(communityProfile.id).id}</Table.Cell>
          </Table.Row>
          <Table.Row styleName={user.email !== communityProfile.mail ? 'mismatch' : ''}>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{communityProfile.mail}</Table.Cell>
          </Table.Row>
          <Table.Row styleName={user.firstName !== communityProfile.field_first_name ? 'mismatch' : ''}>
            <Table.Cell>{user.firstName}</Table.Cell>
            <Table.Cell>{communityProfile.field_first_name}</Table.Cell>
          </Table.Row>
          <Table.Row styleName={user.lastName !== communityProfile.field_last_name ? 'mismatch' : ''}>
            <Table.Cell>{user.lastName}</Table.Cell>
            <Table.Cell>{communityProfile.field_last_name}</Table.Cell>
          </Table.Row>
          <Table.Row styleName={isMatchInstitition(user, communityProfile) ? '': 'mismatch'}>
            <Table.Cell>{user.institution}</Table.Cell>
            <Table.Cell><CommunityInstIds communityProfile={communityProfile} /></Table.Cell>
          </Table.Row>
          {!user.isNaviance && (
          <Table.Row styleName={user.active !== communityProfile.isActive ? 'mismatch' : ''}>
            <Table.Cell>{user.active ? 'Active' : 'Inactive'}</Table.Cell>
            <Table.Cell>{communityProfile.isActive ? 'Active' : 'Inactive'}</Table.Cell>
          </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Popup>
  );
};

Mismatch.propTypes = propTypes;

export default Mismatch;
