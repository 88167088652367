import gql from 'graphql-tag';

import HSAccountDetails from './hs-account-details';

HSAccountDetails.fragment = gql`
  fragment HSAccountDetails on HSAccount {
    id
    name
    deNavianceRequired
    highSchool {
      id
      general {
        field_hs_na_nces_id
      }
      address {
        field_hs_elna_address_line1
        field_hs_el_location_zip4plus
        field_hs_elna_city
        field_hs_elna_state_latest
        field_hs_el_county_name
        field_hs_eln_zip
      }
      naviance {
        field_hs_na_nguid
        field_hs_na_nid
      }
      isActiveNavianceSchool
    }
    primaryUser {
      firstName
      lastName
      email
      role
      active
    }
  }
`;

export default HSAccountDetails;
