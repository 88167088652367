import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from './index';
import YearMonthForm from './year-month-form';

class DatePickerNavigation extends React.Component {
  static propTypes = {
    labels: PropTypes.shape({
      nextMonth: PropTypes.string.isRequired,
      previousMonth: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
    initialMonth: PropTypes.instanceOf(Date),
    fromMonth: PropTypes.instanceOf(Date),
    selectedDays: PropTypes.instanceOf(Date),
    disabledDays: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]),
    onDayClick: PropTypes.func,
    nextMonth: PropTypes.string,
    previousMonth: PropTypes.string,
  };

  state = {
    yearMonthChanged: this.props.initialMonth,
  };

  handleYearMonthChange = (yearMonthChanged) => {
    this.setState({ yearMonthChanged });
  };

  render() {
    const { labels, className, initialMonth, selectedDays, disabledDays, onDayClick } = this.props;
    const currentYear = (new Date()).getFullYear();
    const fromMonth = new Date(currentYear - 1, 0, 1, 0, 0);
    const toMonth = new Date(currentYear + 12, 11, 31, 23, 59);
    return (
      <DayPicker
        labels={labels}
        className={className}
        initialMonth={initialMonth}
        selectedDays={selectedDays}
        disabledDays={disabledDays}
        onDayClick={onDayClick}
        fromMonth={fromMonth}
        toMonth={toMonth}
        month={this.state.yearMonthChanged}
        captionElement={
          <YearMonthForm onChange={this.handleYearMonthChange} />
        }
      />
    );
  }
}

export default DatePickerNavigation;
