import gql from 'graphql-tag';

import HEAccountUsersTable from './he-account-users-table';
import HEUserDetails from '../../queries/heUserDetails';

HEAccountUsersTable.fragment = gql`
  fragment HEAccountUsersTable on HEAccount {
    id
    name
    freemium
    scid
    primaryUser {
      id
    }
    userList {
      ...HEUserDetails
    }
  }
  ${HEUserDetails}
`;
export default HEAccountUsersTable;
