import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

import { isUserAdmin, isUserSupport, isUserSalesOps } from '../Authorization';
import { SimpleLoadingComponent } from '../../utils/apollo';
import AMSubscriptions from '../../queries/amSubscriptions';
import changeAmSubscriptionStatus from '../../mutations/HEAccount/changeAMSubscriptionStatus';
import HESubscriptions from './HESubscriptions';
import { sortSubscriptions } from './utils';

export const query = gql`
  query HEAccountSubscriptions($id: ID!) {
    node(id: $id) {
      id
      ...on HEAccount {
        scid
        name
        moduleSubscriptions {
          name
          status
          startDate
          endDate
          quoteChargeId
          manuallyInactivated
        }
        primaryUser {
          id
          firstName
          lastName
          email
          role
          active
        }
        collegeCore {
          address {
            street
            street2
            city
            state
            postalCode
          }
        }
        ...AMSubscriptions
      }
    }
    viewer {
      id,
      enabledFeatures
    }
  }
  ${AMSubscriptions}
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
  props: ({ data, ownProps }) => ({
    ...ownProps,
    heAccount: data.node,
    enabledFeatures: data.viewer ? data.viewer.enabledFeatures : [],
    subscriptions: sortSubscriptions([].concat(get(data.node, 'amSubscriptions.edges', []))),
  }),
});

const removeSubscriptionMutation = graphql(changeAmSubscriptionStatus, {
  name: 'removeSubscription',
  options: {
    refetchQueries: ['HEAccountSubscriptions'],
  },
});

const Connected = SimpleLoadingComponent(graphqlData, removeSubscriptionMutation)(HESubscriptions);
const mapStateToProps = (state) => ({
  isUserAdmin: isUserAdmin(state.current.viewer),
  canEdit: isUserAdmin(state.current.viewer) || isUserSupport(state.current.viewer) || isUserSalesOps(state.current.viewer),
});
export default withRouter(connect(mapStateToProps)(Connected));
