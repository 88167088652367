import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/react-hoc';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import lowerFirst from 'lodash/lowerFirst';
import get from 'lodash/get';
import throttle from 'lodash/throttle';

import Avatar from '../../components/Avatar/index';
import Dropdown from '../../components/Dropdown';
import Feed from '../../components/Feed';
import Icon from '../../components/Icon';
import { updateNotificationCount } from './actions';
import './styles.css';

const messages = defineMessages({
  notificationMenu: {
    id: 'notifications.menu',
    defaultMessage: 'Notifications',
  },
});

const notificationCountQuery = gql`
  query notificationCount {
    viewer {
      id
      unreadCommunityNotificationsCount
    }
  }
`;

function getNotificationContent(notification, unread) {
  let text = notification.notificationText.split(` at ${notification.groupName} `).join(' ');
  if (notification.userName) {
    text = lowerFirst(text);
  }
  return (
    <Feed>
      <Feed.Event>
        <Feed.Label aria-hidden="true">
          <Avatar image={notification.userPicture} icon="user" />
        </Feed.Label>
        <Feed.Content styleName="notificationContent">
          <Feed.Summary styleName="notificationSummary">
            {unread && <Icon name="exclamation" color="red" />}
            <Feed.User as={Link} to={`/counselor-community/${notification.userProfile}`}>
              {notification.userName}
            </Feed.User> {text}
          </Feed.Summary>
          {notification.groupName && <Feed.Extra text>{notification.groupName}</Feed.Extra>}
        </Feed.Content>
      </Feed.Event>
    </Feed>
  );
}

function mapNotificationsToDropdown(notifications, numUnread) {
  if (!notifications.length) {
    return [{
      key: 'none',
      text: 'No Notifications',
    }];
  } else {
    return notifications.map((item, index) => ({
      key: item.id,
      text: item.notificationText,
      content: getNotificationContent(item, index < numUnread),
      value: item.path,
    }));
  }
}
class Notifications extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    notifications: PropTypes.shape({
      count: PropTypes.number.isRequired,
    }),
    client: PropTypes.shape({
      query: PropTypes.func.isRequired,
    }).isRequired,
    updateNotificationCount: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      listen: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    notifications: {
      count: 0,
    },
  };

  constructor(props) {
    super(props);
    this._updateNotificationCount = throttle(async () => {
      const response = await this.props.client.query({
        fetchPolicy: 'no-cache',
        query: notificationCountQuery,
      });
      const count = get(response, 'data.viewer.unreadCommunityNotificationsCount');
      /* istanbul ignore else */
      if (get(this.props.notifications, 'count') !== count) {
        this.props.updateNotificationCount(count);
      }
    }, 30 * 1000, { leading: true, trailing: false });
  }

  state = { notificationList: [], loading: false, open: false };

  componentDidMount() {
    this._updateNotificationCount();
    this.routerListener = this.props.history.listen(() => this._updateNotificationCount());
  }

  componentWillUnmount() {
    this.routerListener();
  }

  onChange = (e, { value }) => {
    const { history } = this.props;
    if (value && e.target.nodeName !== 'A') {
      history.push(`/counselor-community/${value}`);
    }
  };

  handleOpen = async () => {
    this.setState({ loading: true });
    const response = await this.props.client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query notificationResults {
          viewer {
            id
            communityNotifications(limit:10) {
              id
              message
              groupName
              notificationText
              notificationType
              path
              userName
              userPicture
              userProfile
            }
          }
        }
      `,
    });
    this.setState({ notificationList: get(response, 'data.viewer.communityNotifications'), loading: false, open: true });
  };

  handleClose = () => {
    if (this.props.notifications.count > 0) {
      this.props.updateNotificationCount(0);
    }
    this.setState({ loading: false, open: false });
  };

  render() {
    const { intl, notifications } = this.props;
    const { loading, open, notificationList } = this.state;

    const notificationAlert = !notifications.count ? null : (
      <Icon name="circle" color="red" size="large" styleName="alertBubble">
        <span styleName="alertBubbleText">{Math.min(notifications.count, 99)}</span>
      </Icon>
    );
    const iconClass = notifications.count ? 'activeGlobe' : 'globe';
    return (
      <Dropdown
        value="none"
        aria-label={intl.formatMessage(messages.notificationMenu)}
        selectOnBlur={false}
        selectOnNavigation={false}
        id="notifications"
        onChange={this.onChange}
        className="top right"
        floating
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        icon={notificationAlert}
        options={mapNotificationsToDropdown(notificationList, notifications.count)}
        trigger={<Icon loading={loading} name={loading ? 'spinner' : 'globe'} styleName={iconClass} size="big" />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

export const NotificationsComponent = withRouter(injectIntl(Notifications));
export default connect(mapStateToProps, { updateNotificationCount })(withApollo(NotificationsComponent));
