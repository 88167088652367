import { graphql } from '@apollo/react-hoc';
import { compose } from 'react-recompose';
import updateHEAccountMutation from '../../mutations/HEAccount/updateHEAccount';
import ScholarshipModal from './scholarship-modal';

const heAccountMutation = graphql(updateHEAccountMutation, {
  name: 'mutateHEAccount',
});

export default compose(
  heAccountMutation,
)(ScholarshipModal);
