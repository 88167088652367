import gql from 'graphql-tag';

import HSAccountUsersTable from './hs-account-users-table';
import HSUserDetails from '../../queries/hsUserDetails';

HSAccountUsersTable.fragment = gql`
  fragment HSAccountUsersTable on HSAccount {
    id
    name
    deNavianceRequired
    navianceRequired
    highSchool {
      isActiveNavianceSchool
      general {
        field_hs_na_nces_id
      }
      naviance {
        field_hs_na_nguid
      }
    }
    primaryUser {
      id
    }
    userList {
      ...HSUserDetails
    }
  }
  ${HSUserDetails}
`;

export default HSAccountUsersTable;
