import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import isMatch from 'lodash/isMatch';
import queryString from 'query-string';

import PageTitle from '../../components/PageTitle';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import Icon from '../../components/Icon';
import Message from '../../components/Message';
import SearchControls from '../../components/SearchControl';
import LogHistoryTable from './log-history-table';
import './styles.css';

class LogHistory extends React.Component {
  static propTypes = {
    loadMore: PropTypes.func.isRequired,
    node: PropTypes.shape({
      name: PropTypes.string.isRequired,
      logHistory: PropTypes.object.isRequired,
    }),
    data: PropTypes.object,
    error: PropTypes.shape({
      graphQLErrors: PropTypes.array.isRequired,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        timeframe: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        linkBack: PropTypes.object,
      }),
      search: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.goDebounce = debounce(this.go, 400);
    const query = queryString.parse(props.location.search);
    this.state = { ...query, ...props.match.params };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const query = queryString.parse(nextProps.location.search);
    /* istanbul ignore next */
    return nextProps.node !== this.props.node ||
      !isEqual(nextState, this.state) ||
      !isMatch(this.state, query) ||
      !isMatch(this.state, nextProps.match.params) ||
      nextProps.data.loading !== this.props.data.loading;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ location, match }) {
    const query = queryString.parse(location.search);
    const state = { ...query, ...match.params };
    this.setState(state);
  }

  onChange = (changedValues, debounced) => {
    const query = {};
    const queryNames = ['searchQuery'];
    const customQueryNames = ['searchQuery', 'from', 'to'];
    const timeframe = changedValues.timeframe || this.state.timeframe;
    if (timeframe === 'custom') {
      Object.assign(query, pick(this.state, ...customQueryNames), pick(changedValues, ...customQueryNames));
    } else {
      Object.assign(query, pick(this.state, ...queryNames), pick(changedValues, ...queryNames));
    }
    /* istanbul ignore else */
    if (!isMatch(this.state, query) || timeframe !== this.state.timeframe) {
      this[debounced ? 'goDebounce' : 'go'](timeframe, queryString.stringify(query));
      this.setState({ ...changedValues });
    }
  };

  getLinkBack() {
    return get(this.props, 'location.state.linkBack');
  }

  loadMore = () => {
    this.props.loadMore('node.logHistory');
  };

  go(timeframe, search) {
    const { match, history } = this.props;
    const location = {
      state: { linkBack: this.getLinkBack() },
      pathname: `/hs-account/${match.params.id}/log-history/${timeframe}/`,
      search,
    };
    history.push(location);
  }

  render() {
    const { node={}, error, data } = this.props;
    const linkBack = this.getLinkBack();
    const logHistory = compact(get(node, 'logHistory.edges', []).map((logEntry) => logEntry.node));
    return (
      <div>
        <PageTitle title="Log History" category="Support" />
        {linkBack && (
          <Link to={linkBack}>
            <Icon name="angle left" /><FormattedMessage id="hs-account.logHistory.back" defaultMessage="Back" />
          </Link>
        )}
        <Header styleName="header" as="h1" data-cy="hs-account-logHistory-header">
          <FormattedMessage
            id="hs-account.logHistory.header"
            defaultMessage="Client: {name} Log History"
            values={{
              name: get(node, 'name'),
            }}
          />
        </Header>
        <Grid columns={4} stackable doubling>
          <SearchControls {...this.state} onChange={this.onChange} />
        </Grid>
        {error && <Message error>{error.graphQLErrors.map(({ message, path }) => (<div key={path.join('.')}>{message}</div>))}</Message>}
        <LogHistoryTable
          logHistory={logHistory}
          data={data}
          loadMore={this.loadMore}
          hasNextPage={get(node, 'logHistory.pageInfo.hasNextPage')}
        />
      </div>
    );
  }
}

export default withRouter(LogHistory);
