import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';
import CommunityStatusIcon from '../../components/CommunityStatusIcon';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import UserStatusIcon from '../../components/UserStatusIcon';
import './styles.css';
import HSAccountSwitchPrimaryUser from '../HSAccountSwitchPrimaryUser';
import HSAccountReInviteUser from '../HSAccountReInviteUser';
import HSAccountToggleActivationUser from '../HSAccountToggleActivationUser';
import HSAccountUnlockUser from '../HSAccountUnlockUser';
import ImpersonateUser, { HS_APP_URL } from '../ImpersonateUser';
import { isUserAdmin, isUserSuperAdmin, isUserSupport } from '../Authorization';
import * as appRoles from '../Authorization/constants';
import HasRole from '../../components/HasRole';

const messages = defineMessages({
  actions: {
    id: 'hs-account.users-table.dropdown.actions',
    defaultMessage: 'Actions',
  },
  activate: {
    id: 'hs-account.users-table.dropdown.activate',
    defaultMessage: 'Activate',
  },
  inactivate: {
    id: 'hs-account.users-table.dropdown.inactivate',
    defaultMessage: 'Inactivate',
  },
  switch: {
    id: 'hs-account.users-table.dropdown.switch',
    defaultMessage: 'Assign as Primary',
  },
  reInvite: {
    id: 'hs-account.users-table.dropdown.reinvite',
    defaultMessage: 'Re-invite',
  },
  unlock: {
    id: 'hs-account.users-table.dropdown.unlock',
    defaultMessage: 'Unlock',
  },
  loginAs: {
    id: 'he-account.users-table.dropdown.loginAs',
    defaultMessage: 'Login As',
  },
  viewEditCCProfile: {
    id: 'he-account.users-table.dropdown.viewEditCCProfile',
    defaultMessage: 'View/Edit CC Profile',
  },
});

const RE_INVITE_STATUS = 'FORCE_CHANGE_PASSWORD';
const trigger = (<Icon name="tasks" aria-label="Actions" data-cy="user-actions-menu" />);

function prepareNonNavianceUserOptions(props) {
  const { intl, hsAccount, user, current, isViewEditCCProfileButtonEnabled } = props;
  const lockStatus = JSON.parse(user.lockStatus);
  const statusMessage = {
    textKey: user.active ? messages.inactivate : messages.activate,
    valueKey: user.active ? 'inactivate' : 'activate',
  };
  const userIsLocked = lockStatus && lockStatus.locked;

  const options = [];
  options.push({
    text: intl.formatMessage(messages.unlock),
    value: 'unlock',
    disabled: !userIsLocked,
  });
  options.push({
    'text': intl.formatMessage(statusMessage.textKey),
    'value': statusMessage.valueKey,
    'data-cy': `user-actions-menu-${statusMessage.valueKey}`,
  });
  if (get(hsAccount, 'primaryUser.id') !== user.id && user.active) {
    options.push({
      text: intl.formatMessage(messages.switch),
      value: 'switch',
    });
  }
  if (RE_INVITE_STATUS === user.userStatus) {
    options.push({
      text: intl.formatMessage(messages.reInvite),
      value: 're-invite',
    });
  }
  if ((isUserAdmin(current.viewer) || isUserSupport(current.viewer)) && user.active && !userIsLocked) {
    options.push({
      text: intl.formatMessage(messages.loginAs),
      value: 'impersonate',
    });
  }
  if (isViewEditCCProfileButtonEnabled && isUserSuperAdmin(current.viewer) && user.communityProfiles.length === 1) {
    options.push({
      text: intl.formatMessage(messages.viewEditCCProfile),
      value: 'view-edit-cc-profile',
    });
  }
  return options;
}

function prepareNavianceUserOptions({ user, intl, current, isViewEditCCProfileButtonEnabled }) {
  const options = [];
  options.push({
    text: intl.formatMessage(messages.loginAs),
    value: 'impersonate',
  });
  if (isViewEditCCProfileButtonEnabled && isUserSuperAdmin(current.viewer) && user.communityProfiles.length === 1) {
    options.push({
      text: intl.formatMessage(messages.viewEditCCProfile),
      value: 'view-edit-cc-profile',
    });
  }
  return options;
}

function prepareDropdownOptions(props) {
  const { hsAccount: { deNavianceRequired, highSchool: { isActiveNavianceSchool } } } = props;

  if (isActiveNavianceSchool || deNavianceRequired) {
    return prepareNavianceUserOptions(props);
  }
  return prepareNonNavianceUserOptions(props);
}

class HSAccountUsersTableRow extends React.PureComponent {
  static propTypes = {
    hsAccount: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    current: PropTypes.shape({
      viewer: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    handleViewCCProfile: PropTypes.func,
    isViewEditCCProfileButtonEnabled: PropTypes.bool,
  };

  state = {
    actionClicked: null,
  };

  handleActionsClicked = (e, { value }, user) => {
    this.setState({
      actionClicked: value,
    });
    if (value === 'view-edit-cc-profile') {
      this.props.handleViewCCProfile(user.communityProfiles[0].communityId.toString());
    }
  };

  handleActionsClose = () => {
    this.setState({
      actionClicked: null,
    });
  };

  render() {
    const { user, hsAccount, intl } = this.props;
    const isActiveNavianceSchool = get(hsAccount, 'highSchool.isActiveNavianceSchool');
    const institutionId = isActiveNavianceSchool ?
      get(hsAccount, 'highSchool.naviance.field_hs_na_nguid') : get(hsAccount, 'highSchool.general.field_hs_na_nces_id');
    const { actionClicked } = this.state;
    const dropdownOptions = prepareDropdownOptions(this.props);
    const lastLogin = user.lastLogin ? new Date(user.lastLogin).toISOString() : null;
    const primaryUserId = get(hsAccount, 'primaryUser.id');

    return (
      <Table.Row>
        {!isActiveNavianceSchool && (
        <Table.Cell>
          <UserStatusIcon user={user} primaryUserId={primaryUserId} />
        </Table.Cell>
        )}
        <Table.Cell>{user.firstName}</Table.Cell>
        <Table.Cell>{user.lastName}</Table.Cell>
        <Table.Cell>{user.role}</Table.Cell>
        <Table.Cell><CommunityStatusIcon user={user} institutionId={institutionId} /></Table.Cell>
        <Table.Cell><a href={`mailto:${user.email}`}>{user.email}</a><br />
          {lastLogin ?
            <FormattedDate value={lastLogin} year="numeric" month="short" day="numeric" /> :
            ''}
        </Table.Cell>
        <Table.Cell>
          { dropdownOptions.length > 0 && (
            <HasRole anyOf={appRoles.NON_VIEW_ONLY_ROLES}>
              <Dropdown
                value="none"
                selectOnBlur={false}
                selectOnNavigation={false}
                className="top right"
                floating
                options={dropdownOptions}
                onChange={(e, { value }) => this.handleActionsClicked(e, { value }, user)}
                aria-label={intl.formatMessage(messages.actions)}
                trigger={trigger}
              />
            </HasRole>
          )}
          {
            actionClicked === 'unlock' &&
            <HSAccountUnlockUser hsAccount={hsAccount} user={user} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'activate' &&
            <HSAccountToggleActivationUser hsAccount={hsAccount} user={user} activate onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'inactivate' &&
            <HSAccountToggleActivationUser hsAccount={hsAccount} user={user} activate={false} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'switch' &&
            <HSAccountSwitchPrimaryUser hsAccount={hsAccount} user={user} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 're-invite' &&
            <HSAccountReInviteUser hsAccount={hsAccount} user={user} onClose={this.handleActionsClose} />
          }
          {
            actionClicked === 'impersonate' &&
            <ImpersonateUser node={hsAccount} user={user} onClose={this.handleActionsClose} redirectTo={HS_APP_URL} />
          }
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapStateToProps = (state) => ({
  current: state.current,
});

export default injectIntl(connect(mapStateToProps)(HSAccountUsersTableRow));
