import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Radio from '../../components/Radio';
import Button from '../../components/Button';
import { messages, subscriptionTypes } from './constants';

import './styles.css';

class SubscriptionTypes extends React.PureComponent {
  static propTypes = {
    onTypeChange: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    selectedType: subscriptionTypes.UNSELECTED,
  }

  handleSubscriptionTypeChange = (e, { value }) => {
    this.setState({ selectedType: value });
  };

  handleChangeType = () => {
    this.props.onTypeChange(this.state.selectedType);
  }

  render() {
    const { intl } = this.props;
    const { selectedType } = this.state;
    const state = intl.formatMessage(messages.state);
    const county = intl.formatMessage(messages.county);
    const zip = intl.formatMessage(messages.zip);
    const sector = intl.formatMessage(messages.sector);

    return (
      <div>
        <h3><FormattedMessage {...messages.subscriptionType} /></h3>
        <div styleName="subscription-types">
          <Radio
            name="subscriptionType"
            aria-label={state}
            value={subscriptionTypes.STATE}
            onChange={this.handleSubscriptionTypeChange}
            checked={selectedType === subscriptionTypes.STATE}
            label={state}
            styleName="subscription-type"
          />
          <Radio
            name="subscriptionType"
            aria-label={county}
            value={subscriptionTypes.COUNTY}
            onChange={this.handleSubscriptionTypeChange}
            checked={selectedType === subscriptionTypes.COUNTY}
            label={county}
            styleName="subscription-type"
          />
          <Radio
            name="subscriptionType"
            aria-label={zip}
            value={subscriptionTypes.ZIP}
            onChange={this.handleSubscriptionTypeChange}
            checked={selectedType === subscriptionTypes.ZIP}
            label={zip}
            styleName="subscription-type"
          />
          <Radio
            name="subscriptionType"
            aria-label={sector}
            value={subscriptionTypes.SECTOR}
            onChange={this.handleSubscriptionTypeChange}
            checked={selectedType === subscriptionTypes.SECTOR}
            label={sector}
            styleName="subscription-type"
          />
        </div>
        <Button primary disabled={selectedType === subscriptionTypes.UNSELECTED} onClick={this.handleChangeType}>
          <FormattedMessage {...messages.next} />
        </Button>
      </div>
    );
  }
}

export default injectIntl(SubscriptionTypes);
