import gql from 'graphql-tag';

import HSUserDetails from '../../queries/hsUserDetails';

export default gql`
  mutation createHSUser($input: createHSUserInput!) {
    createHSUser(input: $input) {
      hsAccount {
        id
        userList {
          ...HSUserDetails
        }
      }
    }
  }
  ${HSUserDetails}
`;
