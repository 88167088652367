import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import HeHealthReportRVandEvents from './he-health-report-repvisits-and-events';
import HeHealthReportHeader from './he-health-report-header';
import HeHealthReportCollegeProfile from './he-health-report-college-profile';
import HeHealthReportScholarships from './he-health-report-scholarships';
import HeHealthReportAAConnection from './he-health-report-aa-connection';

const HealthReport = ({ accountDetails: { heAccount, collegeData } }) => {
  const accountData = useMemo(
    () => ({
      collegeEvents: null,
      repVisits: null,
      scholarships: null,
      moduleSubscriptions: [],
      activeMatchConnectionsLastDownloadedDatetime: null,
      activeMatchSubscriptions: null,
      activeMatchExportConfig: null,
      activeMatchMessages: null,
      ...heAccount,
    }),
    [heAccount],
  );

  const collegeDetailsData = useMemo(() => collegeData || {}, [collegeData]);

  const profileData = useMemo(
    () => ({
      address: get(heAccount, 'collegeCore.address') || {},
      name: get(heAccount, 'name') || '',
      primaryUser: get(heAccount, 'primaryUser') || {},
      scid: get(heAccount, 'scid') || '',
    }),
    [heAccount],
  );

  return (
    <React.Fragment>
      <HeHealthReportHeader collegeDetails={profileData} />
      <HeHealthReportCollegeProfile
        collegeDetails={collegeDetailsData}
        moduleSubscriptions={accountData.moduleSubscriptions}
      />
      <HeHealthReportRVandEvents
        collegeEvents={accountData.collegeEvents}
        repVisits={accountData.repVisits}
        moduleSubscriptions={accountData.moduleSubscriptions}
      />
      <HeHealthReportAAConnection
        amLastDownloadDate={accountData.activeMatchConnectionsLastDownloadedDatetime}
        amSubscriptions={accountData.activeMatchSubscriptions}
        amMessages={accountData.activeMatchMessages}
        moduleSubscriptions={accountData.moduleSubscriptions}
        amExportConfig={accountData.activeMatchExportConfig}
      />
      <HeHealthReportScholarships
        scholarships={accountData.scholarships}
        moduleSubscriptions={accountData.moduleSubscriptions}
      />
    </React.Fragment>
  );
};

HealthReport.propTypes = {
  accountDetails: PropTypes.shape({
    heAccount: PropTypes.shape({
      name: PropTypes.string,
      scid: PropTypes.string,
      collegeEvents: PropTypes.object,
      repVisits: PropTypes.array,
      scholarships: PropTypes.object,
      collegeCore: PropTypes.object,
      primaryUser: PropTypes.object,
      activeMatchSubscriptions: PropTypes.object,
      activeMatchConnectionsLastDownloadedDatetime: PropTypes.number,
      moduleSubscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          status: PropTypes.string,
        }),
      ),
    }),
    collegeData: PropTypes.object,
  }),
};

export default HealthReport;
