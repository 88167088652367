import { hot } from 'react-hot-loader/root';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import 'focus-visible/dist/focus-visible';

import './theme';
import getApolloClient from './apollo-client';
import setupUserStore from './user-store';
import adalService from './adal/adal-service';
import App from './containers/App';
import history from './history';
import routes from './routes';

import { translationMessages } from './i18n';
import LanguageProvider from './providers/LanguageProvider';
import PageTitleProvider from './providers/PageTitleProvider';
import setupGA from './configureGA';

const appDom = document.getElementById('app');
const HotApp = hot(App);

console.log(`Starting Support App @ ${process.env.VERSION}, built ${process.env.BUILDTIME} from ${process.env.BUILDBRANCH}`); // eslint-disable-line no-console

const renderUI = (store) => {
  const client = getApolloClient(store);
  if (process.env.A11Y) {
    require('./a11y').default(appDom); // eslint-disable-line global-require
  }
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={translationMessages}>
        <ApolloProvider client={client}>
          <PageTitleProvider>
            <Router history={history}>
              <HotApp idleTimeout={process.env.IDLE_TIMEOUT}>
                {routes}
              </HotApp>
            </Router>
          </PageTitleProvider>
        </ApolloProvider>
      </LanguageProvider>
    </Provider>,
    appDom,
  );
};

if (adalService.isCallback(window.location.hash)) {
  adalService.handleWindowCallback();
} else if (window === window.parent) {
  // adal refreshes tokens using a hidden iframe that redirects back to our app
  // only load the UI if we're the top level
  setupGA(history);
  const userToken = adalService.getCachedToken(adalService.config.clientId);
  adalService.getCachedUser(); // fix bug with refreshing token, it needs this._user set
  if (userToken) {
    setupUserStore(userToken, renderUI);
  } else {
    adalService.login();
  }
}
