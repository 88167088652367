import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import trimStart from 'lodash/trimStart';
import { fromGlobalId } from 'graphql-relay';
import * as constants from './constants';

export function formatTargetName(target) {
  let targetName = target.name;
  if (!targetName) {
    if (target.firstName || target.field_first_name || target.lastName || target.field_last_name) {
      targetName = `${target.firstName || target.field_first_name || ''} ${target.lastName || target.field_last_name || ''}`;
    }
  }
  if (!targetName && target.id) {
    const prefix = get(target, 'communityPerson.name') || get(target, 'contact.name') || '';
    targetName = trimStart(`${prefix} ${fromGlobalId(target.id).type}`);
  }
  if (target.date) {
    return `${targetName} ${target.date}`;
  } else if (target.schoolYear) {
    return `${targetName} ${target.schoolYear}`;
  } else if (target.reviewDate) {
    return `${targetName} ${target.reviewDate}`;
  } else {
    return targetName || '';
  }
}

export function formatChangeFieldDisplay(logEntry) {
  let changeDisplay = '';
  const action = lowerCase(get(logEntry, 'action'));
  let changed;
  try {
    changed = JSON.parse(logEntry.changed || '{}');
  } catch (e) {
    console.error('invalid JSON string'); // eslint-disable-line no-console
  }
  switch (action) {
    case constants.ADDED_USER_ACTION: {
      changeDisplay = get(changed, 'name');
      break;
    }
    case constants.CREATED_USER_ACTION: {
      changeDisplay = `with role ${get(changed, 'role')}`;
      break;
    }
    case constants.UPDATED_USER_ACTION: {
      const userAttributes = get(changed, 'UserAttributes') || [];
      const changedItems = [];
      userAttributes.forEach((item) => {
        if (item.Name === 'name') {
          changedItems.unshift(`user ${item.Value}`);
        } else if (item.Name === 'email') {
          changedItems.unshift(`email ${item.Value}`);
        } else if (item.Name === 'email_verified') {
          const emailStatus = item.Value === 'true' ? 'email verified' : 'email unverified';
          changedItems.unshift(emailStatus);
        } else if (item.Name === 'custom:role') {
          changedItems.unshift(`custom role ${item.Value}`);
        } else if (item.Name === 'custom:lockStatus') {
          const obj = JSON.parse(item.Value);
          const lockedStatus = obj.locked ? 'locked account' : 'unlocked account';
          changedItems.unshift(lockedStatus);
        }
      });
      changeDisplay = changedItems.join(', ');
      break;
    }
    case constants.DISABLED_USER_ACTION:
    case constants.ENABLED_USER_ACTION: {
      changeDisplay = get(changed, 'active') ? 'disabled user account' : 'enabled user account';
      break;
    }
    case constants.ADDED_PRIMARY_USER_ACTION:
    case constants.CREATED_PRIMARY_USER_ACTION: {
      changeDisplay = `with role ${get(changed, 'role')}`;
      break;
    }
    case constants.UPDATE_PRIMARY_USER_ACTION: {
      const changedItems = [];
      if (get(changed, 'name')) {
        changedItems.push(get(changed, 'name'));
      }
      if (get(changed, 'id')) {
        changedItems.push(get(changed, 'id'));
      }
      changeDisplay = changedItems.join(', ');
      break;
    }
    case constants.MARKED_PRIMARY_USER_ACTION: {
      changeDisplay = changed ? get(changed, 'id') : get(logEntry, 'changed');
      break;
    }
    case constants.UPDATED_MODULE_SUBSCRIPTIONS_ACTION: {
      const changedItems = [];
      changed.forEach((item) => {
        if (item.lastModifiedBy) {
          changedItems.push(capitalize(item.name));
        }
      });
      changeDisplay = changedItems.join(', ');
      break;
    }
    default:
  }
  return changeDisplay;
}
