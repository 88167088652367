import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TitleWithActions from '../../components/TitleWithActions';
import TitleWithActionsAndFilters from '../../components/TitleWithActions/TitleWithActionsAndFilters';
import SubscriptionsTable from '../../components/SubscriptionsTable';
import './styles.css';
import { FEATURE_SHOW_SUBSCRIPTION_FILTERS, REMOVE_DIVERSITY_FILTERS } from '../../featureFlags';

const getFilterCondition = (filterType, endDate) => {
  if (filterType === 'active') {
    return endDate > Date.now();
  } else if (filterType === 'inactive') {
    return endDate <= Date.now();
  }
  return true;
};

const ContentBody = (props) => {
  const {
    isUserAdmin,
    canEdit,
    heAccount,
    subscriptions: allSubscriptions,
    onClickDelete,
    onAddNewSubscription,
    onLocationClick,
    activeSubscriptions,
    enabledFeatures = [],
    initialFilterType = 'active',
  } = props;
  const { id } = heAccount;
  const name = 'Advanced Awareness and Connections Subscriptions';
  const backLink = `/he-account/${id}/`;

  const showSubscriptionFilters = enabledFeatures.includes(FEATURE_SHOW_SUBSCRIPTION_FILTERS);
  const removeDiversityFilters = enabledFeatures?.includes(REMOVE_DIVERSITY_FILTERS);
  const TitleComponent = !showSubscriptionFilters ? TitleWithActions : TitleWithActionsAndFilters;
  const filterTypeInitialValue = !showSubscriptionFilters ? 'all' : initialFilterType;

  const [filterType, setFilterType] = useState(filterTypeInitialValue);
  const subscriptions = useMemo(
    () => allSubscriptions.filter((sub) => getFilterCondition(filterType, sub.node.endDate)),
    [filterType, allSubscriptions],
  );

  const handleFilterTypeChange = (filterTypeSelected) => {
    setFilterType(filterTypeSelected);
  };

  return (
    <div data-cy="he-subscriptions-content-body-container">
      <TitleComponent tag="h2" title={name} filterType={filterType} handleFilterTypeChange={handleFilterTypeChange}>
        <TitleComponent.Action to={backLink}>Back to Overview</TitleComponent.Action>
        {canEdit && (
          <TitleComponent.Action onClick={onAddNewSubscription}>
            Add New Subscription
          </TitleComponent.Action>
        )}
      </TitleComponent>
      <SubscriptionsTable
        isUserAdmin={isUserAdmin}
        canEdit={canEdit}
        subscriptions={subscriptions}
        onLocationClick={onLocationClick}
        onClickDelete={onClickDelete}
        activeSubscriptions={activeSubscriptions}
        removeDiversityFilters={removeDiversityFilters}
      />
    </div>
  );
};

ContentBody.propTypes = {
  isUserAdmin: PropTypes.bool,
  canEdit: PropTypes.bool,
  heAccount: PropTypes.object,
  subscriptions: PropTypes.array,
  list: PropTypes.array,
  onClickDelete: PropTypes.func,
  onAddNewSubscription: PropTypes.func,
  onLocationClick: PropTypes.func,
  activeSubscriptions: PropTypes.array,
  enabledFeatures: PropTypes.array,
  initialFilterType: PropTypes.string,
};

ContentBody.defaultProps = {
  list: [],
};

export default ContentBody;
