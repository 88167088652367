import fragmentTypes from '@purple/match-graphql-schema/lib/schema/support/fragmentTypes.json';
import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import get from 'lodash/get';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { signOut } from './containers/Authorization/actions';

const httpLink = new BatchHttpLink({ uri: process.env.API_URL });

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
});

export default function getApolloClient(store) {
  const withAuth = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = get(store.getState(), 'current.viewer.jwtToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  });
  const authFailed = onError(({ networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      store.dispatch(signOut(store.getState().current.viewer));
    }
  });
  let localLink;
  /* istanbul ignore else */
  if (process.env.MOCK_GRAPHQL || process.env.NODE_ENV === 'test') {
    localLink = require('./createLocalLink').default(store); // eslint-disable-line global-require
  }
  const client = new ApolloClient({
    assumeImmutableResults: true,
    link: withAuth.concat(authFailed.concat(localLink || httpLink)),
    cache: new InMemoryCache({ fragmentMatcher, freezeResults: true }),
  });
  return client;
}
