import _get from 'lodash/get';
import { escapeCsvFieldData } from '@purple/match-graphql-schema/lib/utils';
import { ModuleNames as SubscriptionModuleNames } from '@purple/match-graphql-schema/lib/schema/common/types/SubscriptionModuleNameType/type';

export function renderColumn(column, values) {
  if (typeof column === 'string') {
    return escapeCsvFieldData(values[column]);
  }
  const value = column.format(values);
  return escapeCsvFieldData(value);
}

export function formatSchoolType(institution) {
  const type = _get(institution, 'collegeCore.type', '');
  const forProfit = _get(institution, 'collegeCore.forProfit', '');
  if (type === 'PRIVATE') return `${type} ${forProfit ? 'FOR-PROFIT' : 'NON-PROFIT'}`;
  return type;
}

export function generateHeader(columns) {
  return columns
    .map((col) => {
      if (typeof col === 'string') {
        return escapeCsvFieldData(col);
      }
      return escapeCsvFieldData(col.name);
    })
    .join(',');
}

export function isValidDate(d) {
  return d instanceof Date && !Number.isNaN(Number(d));
}

export function subscriptionsFormatter(value) {
  return (data) => {
    const subscriptions = _get(data, 'subscriptions', []);
    if (Array.isArray(subscriptions)) {
      return subscriptions.some((s) => s.name === value) ? '1' : '0';
    }
    return '0';
  };
}

export function subscriptionsDateFormatter(value, key) {
  return (data) => {
    const subscriptions = _get(data, 'subscriptions', []);

    if (Array.isArray(subscriptions)) {
      const subscription = subscriptions.find((s) => s.name === value) || {};
      const date = new Date(subscription[key]);

      return isValidDate(date) ? date.toGMTString() : '';
    }

    return '';
  };
}

export const subscriptionColumns = [
  {
    name: 'sub-repvisits',
    format: subscriptionsFormatter(SubscriptionModuleNames.REPVISITS),
  },
  {
    name: 'sub-repvisits-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.REPVISITS, 'startDate'),
  },
  {
    name: 'sub-repvisits-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.REPVISITS, 'endDate'),
  },
  {
    name: 'sub-matching',
    format: subscriptionsFormatter(SubscriptionModuleNames.MATCHING),
  },
  {
    name: 'sub-matching-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.MATCHING, 'startDate'),
  },
  {
    name: 'sub-matching-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.MATCHING, 'endDate'),
  },
  {
    name: 'sub-hubpage',
    format: subscriptionsFormatter(SubscriptionModuleNames.HUBPAGE),
  },
  {
    name: 'sub-hubpage-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.HUBPAGE, 'startDate'),
  },
  {
    name: 'sub-hubpage-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.HUBPAGE, 'endDate'),
  },
  {
    name: 'sub-community',
    format: subscriptionsFormatter(SubscriptionModuleNames.COMMUNITY),
  },
  {
    name: 'sub-community-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.COMMUNITY, 'startDate'),
  },
  {
    name: 'sub-community-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.COMMUNITY, 'endDate'),
  },
  {
    name: 'sub-amevents',
    format: subscriptionsFormatter(SubscriptionModuleNames.AMEVENTS),
  },
  {
    name: 'sub-amevents-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.AMEVENTS, 'startDate'),
  },
  {
    name: 'sub-amevents-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.AMEVENTS, 'endDate'),
  },
  {
    name: 'sub-amplus',
    format: subscriptionsFormatter(SubscriptionModuleNames.AMPLUS),
  },
  {
    name: 'sub-amplus-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.AMPLUS, 'startDate'),
  },
  {
    name: 'sub-amplus-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.AMPLUS, 'endDate'),
  },
  {
    name: 'sub-advancedawareness',
    format: subscriptionsFormatter(SubscriptionModuleNames.ADVANCEDAWARENESS),
  },
  {
    name: 'sub-advancedawareness-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.ADVANCEDAWARENESS, 'startDate'),
  },
  {
    name: 'sub-advancedawareness-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.ADVANCEDAWARENESS, 'endDate'),
  },
  {
    name: 'sub-connection',
    format: subscriptionsFormatter(SubscriptionModuleNames.CONNECTION),
  },
  {
    name: 'sub-connection-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.CONNECTION, 'startDate'),
  },
  {
    name: 'sub-connection-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.CONNECTION, 'endDate'),
  },
  {
    name: 'sub-camino',
    format: subscriptionsFormatter(SubscriptionModuleNames.CAMINO),
  },
  {
    name: 'sub-camino-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.CAMINO, 'startDate'),
  },
  {
    name: 'sub-camino-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.CAMINO, 'endDate'),
  },
  {
    name: 'sub-scholarships',
    format: subscriptionsFormatter(SubscriptionModuleNames.SCHOLARSHIPS),
  },
  {
    name: 'sub-scholarships-start-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.SCHOLARSHIPS, 'startDate'),
  },
  {
    name: 'sub-scholarships-end-date',
    format: subscriptionsDateFormatter(SubscriptionModuleNames.SCHOLARSHIPS, 'endDate'),
  },
];
