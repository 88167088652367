/* eslint-disable react/no-unused-state */
import React from 'react';
import pageTitleShape from './page-title-shape';

function injectPageTitle(WrappedComponent) {
  class InjectedPageTitleComponent extends React.Component {
    static contextTypes = { pageTitle: pageTitleShape };

    constructor(props, context) {
      super(props, context);
      this.state = { eventIndex: 0 };
      this.unsubscribe = this.context.pageTitle.subscribe(this.update);
    }

    componentDidMount() {
      if (!this.unsubscribe) {
        this.unsubscribe = this.context.pageTitle.subscribe(this.update);
      }
    }

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    }

    update = (eventIndex) => this.setState({ eventIndex });

    render() {
      const { pageTitle } = this.context;
      const props = { ...this.props, pageTitle };
      return <WrappedComponent {...props} />;
    }
  }
  return InjectedPageTitleComponent;
}

export default injectPageTitle;
