import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';

import { withRouter } from 'react-router-dom';
import RepvisitsDetails from './repvisits-details';
import { loadMorePaginationProp, SimpleLoadingComponent } from '../../utils/apollo';

const query = gql`
query RepvisitsDetails ($id: ID!, $first: Int!, $after: String) {
  node(id: $id) {
    id
    ...on HSAccount {
      name
      repVisitsAccounts {
          id
          availabilityVisible
          setupComplete
          naviance
          ncesId
          nguid
          nid
          firstVisitDay
          lastVisitDay
      }
      repVisitsAppointments(first: $first, after: $after) {
          edges {
            node {
              id
              date
              currentColleges
              appointments {
                id
                currentColleges
                startTime
                endTime
                attendees {
                  contact {
                    id
                    institutionId
                    name
                    institution {
                      name
                      title
                    }
                  ... on CommunityPerson {
                    communityId
                    field_profile_picture_file_url
                  }
                  }
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
    }
  }
}
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
        first: 20,
      },
    };
  },
  props: loadMorePaginationProp(query),
});

export default withRouter(SimpleLoadingComponent(graphqlData)(RepvisitsDetails));
