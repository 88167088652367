import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { injectIntl, defineMessages } from 'react-intl';
import without from 'lodash/without';

import Popup from '../../components/Popup';
import Form from '../../components/Form';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Image from '../../components/Image';
import Icon from '../../components/Icon';
import Confirm from '../../components/Confirm';
import Ref from '../../components/Ref';

import './styles.css';
import avatarImage from './square-image.png';

import { signOut } from '../Authorization/actions';
import { reload } from '../../utils';

const trigger = (<Image aria-hidden="true" alt="avatar" avatar styleName="avatar" src={avatarImage} />);
const messages = defineMessages({
  userDropdown: {
    id: 'user.dropdown',
    defaultMessage: 'User Dropdown',
  },
  signout: {
    id: 'user.dropdown.signout',
    defaultMessage: 'Sign Out',
  },
});
class UserInfo extends React.PureComponent {
  static propTypes = {
    current: PropTypes.object.isRequired,
    signOut: PropTypes.func.isRequired,
    mutateAddFeatureFlag: PropTypes.func.isRequired,
    mutateRemoveFeatureFlag: PropTypes.func.isRequired,
    viewer: PropTypes.shape({
      enabledFeatures: PropTypes.array.isRequired,
      globallyEnabledFeatures: PropTypes.array.isRequired,
    }).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    isError: false,
    requesting: false,
  };

  onChange = (e, { value }) => {
    /* istanbul ignore else */
    if (value === 'signout') {
      this.props.signOut(this.props.current.viewer);
    } else if (typeof value === 'string' && value.startsWith('feature:')) {
      const feature = value.substring('feature:'.length);
      if (!this.props.viewer.globallyEnabledFeatures.includes(feature)) {
        this.setState({ removeFeatureFlag: feature });
      }
      e.stopPropagation();
    } else if (value === 'addFeatureFlag' || value === 'globalFeatures') {
      e.stopPropagation();
    }
  };

  onAddFeatureKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.addFeatureFlag(e.target);
      e.target.value = '';
    }
  };

  onAddFeatureSubmit = (e) => {
    this.addFeatureFlag(e.target.elements[0]);
    e.target.elements[0].value = '';
  };

  onFailure = (transaction) => {
    this.setState({ isError: true, requesting: false });
  };

  onSuccess = () => {
    this.setState({ isError: false, requesting: false });
  };

  switchBranch = () => {
    if (Cookie.get('x-source')) {
      Cookie.remove('x-source');
    } else {
      Cookie.set('x-source', 'release');
    }
    reload();
  };

  getSourceCookieDropdownItem(sourceCookie) {
    return (
      <Dropdown.Item value="branch" key="branch" onClick={this.switchBranch}>
        <Icon name="code branch" />
        {sourceCookie || process.env.BUILDBRANCH}
      </Dropdown.Item>
    );
  }

  getFeatureToggleDropdownItems() {
    const { viewer: { enabledFeatures, globallyEnabledFeatures } } = this.props;
    const { isError, requesting } = this.state;
    return [
      <Dropdown.Item value="addFeatureFlag" key="addFeatureFlag" onClick={this.onChange}>
        <Form onSubmit={this.onAddFeatureSubmit}>
          <Input action error={isError}>
            <input placeholder="Add Feature Flag" aria-label="Add Feature Flag" onKeyDown={this.onAddFeatureKeyPress} data-cy="user-info-feature-flag-input" />
            <Button loading={requesting} aria-label="Add Feature Flag" color="teal" type="submit" icon data-cy="user-info-add-feature-flag-button"><Icon name="plus" /></Button>
          </Input>
        </Form>
      </Dropdown.Item>,
      <Dropdown.Item value="globalFeatures" key="globalFeatures" onClick={this.onChange}>
        <Icon name="globe" />
        <Popup trigger={<span styleName="global-features-overflow" data-cy="user-info-global-features-overflow">{globallyEnabledFeatures.join(', ')}</span>}>
          {globallyEnabledFeatures.map((feature) => (<div key={feature}>{feature}</div>))}
        </Popup>
      </Dropdown.Item>,
      ...without(enabledFeatures, ...globallyEnabledFeatures).map((feature) => {
        const featureKey = `feature:${feature}`;
        return <Dropdown.Item value={featureKey} key={featureKey} text={feature} icon="flag" onClick={this.onChange} data-cy="user-info-feature-flag-overflow" />;
      }),
    ];
  }

  getDropdownOptions() {
    const { viewer: { enabledFeatures }, intl } = this.props;
    const sourceCookie = Cookie.get('x-source');
    const dropdownOptions = [{
      icon: 'sign out',
      id: 'user-dropdown-signout',
      value: 'signout',
      text: intl.formatMessage(messages.signout),
    }];

    if (enabledFeatures.includes('ENABLE_FEATURE_TOGGLER')) {
      dropdownOptions.push(...this.getFeatureToggleDropdownItems());
    }
    if (enabledFeatures.includes('BRANCH_TOGGLER') || sourceCookie) {
      dropdownOptions.unshift(this.getSourceCookieDropdownItem(sourceCookie));
    }

    return dropdownOptions;
  }

  addFeatureFlag(e) {
    this.setState({ isError: false, requesting: true });
    const { onSuccess, onFailure } = this;
    this.props.mutateAddFeatureFlag({
      variables: {
        input: {
          featureFlag: e.value,
        },
      },
    }).then(onSuccess, onFailure);
  }

  removeFeatureFlag(featureFlag) {
    this.setState({ isError: false, requesting: true });
    const { onSuccess, onFailure } = this;
    this.props.mutateRemoveFeatureFlag({
      variables: {
        input: {
          featureFlag,
        },
      },
    }).then(onSuccess, onFailure);
  }

  handleRemoveCancel = () => {
    this.setState({ removeFeatureFlag: '' });
    if (this.dropdownRef) {
      this.dropdownRef.focus();
    }
  };

  handleRemoveConfirm = () => {
    const { removeFeatureFlag } = this.state;
    this.removeFeatureFlag(removeFeatureFlag);
    this.setState({ removeFeatureFlag: '' });
    if (this.dropdownRef) {
      this.dropdownRef.focus();
    }
  };

  handleRef = (ref) => {
    this.dropdownRef = ref;
  };

  render() {
    const { intl } = this.props;
    const { removeFeatureFlag } = this.state;
    const sourceCookie = Cookie.get('x-source');
    const userAlert = sourceCookie ?
      (<Icon name="code branch" circular inverted color="red" size="small" styleName="alertBubble" />) : null;
    const dropdownOptions = this.getDropdownOptions();
    return (
      <span>
        { removeFeatureFlag && (
        <Confirm
          data-cy="support-feature-flag-content-modal"
          open
          header="Remove Feature Flag?"
          content={`Are you sure you want to remove the ${removeFeatureFlag} feature flag?`}
          onCancel={this.handleRemoveCancel}
          onConfirm={this.handleRemoveConfirm}
        />
        )}
        <Ref innerRef={this.handleRef}>
          <Dropdown
            aria-label={intl.formatMessage(messages.userDropdown)}
            selectOnBlur={false}
            selectOnNavigation={false}
            styleName="userDropdown"
            scrolling
            id="user-dropdown"
            closeOnChange={false}
            onChange={this.onChange}
            className="top right"
            floating
            options={dropdownOptions}
            icon={userAlert}
            trigger={trigger}
          />
        </Ref>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  current: state.current,
});

export const UserInfoComponent = injectIntl(UserInfo);

export default connect(mapStateToProps, { signOut })(UserInfoComponent);
