import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import omit from 'lodash/omit';
import Button from '../Button';
import Input from '../Input';
import Container from '../Container';
import Loader from '../Loader';
import Dimmer from '../Dimmer';
import Segment from '../Segment';
import Form from '../Form';
import Label from '../Label';
import Message from '../Message';
import Header from '../Header';
import Icon from '../Icon';
import './styles.css';

const messages = defineMessages({
  generalError: {
    id: 'primary-user.create.error.general',
    defaultMessage: 'Unable to create user',
  },
  userExists: {
    id: 'primary-user.create.error.user_exists',
    defaultMessage: 'User with provided email already exists!',
  },
  primaryExists: {
    id: 'primary-user.create.error.primary_exists',
    defaultMessage: 'Primary User already configured!',
  },
});

const missingValueLabel = (
  <Label role="tooltip" tabIndex={-1} basic color="red" pointing data-cy="create-user-missing-value-tooltip">
    <FormattedMessage id="common.label.missingValue" defaultMessage="Please enter a value" />
  </Label>
);
const emailsNoMatchLabel = (
  <Label role="tooltip" tabIndex={-1} basic color="red" pointing data-cy="create-user-email-must-match-tooltip">
    <FormattedMessage id="primary-user.label.emailsNoMatch" defaultMessage="Email must match" />
  </Label>
);

export const elementIds = {
  firstNameId: 'create-primary-user-first-name',
  lastNameId: 'create-primary-user-last-name',
  emailId: 'create-primary-user-email',
  verifyEmailId: 'create-primary-user-verify-email',
  role: 'create-primary-user-role',
};
function validateForm(formData, props) {
  return {
    missingFirstName: !formData.firstName,
    missingLastName: !formData.lastName,
    missingEmail: !formData.email,
    missingValidateEmail: !formData.verifyEmail,
    invalidEmail: formData.email !== formData.verifyEmail,
    missingRole: props.roles && !formData.role,
  };
}

class CreateUser extends React.Component {
  static propTypes = {
    primary: PropTypes.bool,
    accountName: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    requesting: PropTypes.bool,
    createError: PropTypes.shape({
      generalError: PropTypes.bool,
      userExists: PropTypes.bool,
      primaryExists: PropTypes.bool,
    }).isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      message: PropTypes.shape({ id: PropTypes.string.isRequired }),
    })),
  };

  state = {
    errors: {},
    firstName: '',
    lastName: '',
    email: '',
    verifyEmail: '',
  };

  _onChange = (e, { value, name } = {}) => {
    value = value || /* istanbul ignore next */ e.target.value;
    name = name || /* istanbul ignore next */ e.target.name;
    this.setState({ [name]: value });
  };

  _onSubmit = (e) => {
    const formData = omit(this.state, 'errors');
    const errors = validateForm(formData, this.props);
    this.setState({ errors });
    if (!Object.keys(errors).some((key) => errors[key])) {
      this.props.onSave(formData);
    }
  };

  render() {
    const { errors, firstName, lastName, email, verifyEmail, role } = this.state;
    const { accountName, requesting, createError, onCancel } = this.props;

    const missingFirstNameLabel = errors.missingFirstName ? missingValueLabel : '';
    const missingLastNameLabel = errors.missingLastName ? missingValueLabel : '';
    const missingEmailLabel = errors.missingEmail ? missingValueLabel : '';
    const missingValidateEmailLabel = errors.missingValidateEmail ? missingValueLabel : '';
    const invalidEmailLabel = errors.invalidEmail ? emailsNoMatchLabel : '';
    const missingRoleLabel = errors.missingRole ? missingValueLabel : '';

    const errorDetails = createError && Object.keys(createError).length === 1 ? (
      <Message role="alert" negative>
        <Message.Header>
          <FormattedMessage {...messages[Object.keys(createError)[0]]} />
        </Message.Header>
      </Message>
    ) : '';

    return (
      <Container text className="createPrimaryUserPanel">
        {this.props.primary && (
        <Header as="h1">
          <FormattedMessage id="primary-user.create.header" defaultMessage="Create Primary User for" /> {accountName}
        </Header>
        )}
        {!this.props.primary && (
        <Header as="h1">
          <FormattedMessage id="user.create.header" defaultMessage="Create User for" /> {accountName}
        </Header>
        )}
        <Dimmer.Dimmable as={Form} id="form-create-primary-user" onSubmit={this._onSubmit} size="large">
          <Dimmer
            tabIndex={-1}
            role="progressbar"
            aria-describedby="Creating User..."
            aria-busy={requesting}
            inverted
            active={requesting}
          >
            <Loader active={requesting} />
          </Dimmer>
          <Segment>
            <Form.Field>
              <label htmlFor={elementIds.firstNameId}>
                <FormattedMessage id="primary-user.firstName.label" defaultMessage="First Name" />
              </label>
              <Input type="text" name="firstName" value={firstName} onChange={this._onChange}>
                <input id={elementIds.firstNameId} aria-required aria-invalid={!!missingFirstNameLabel} />
              </Input>
              {missingFirstNameLabel}
            </Form.Field>
            <Form.Field>
              <label htmlFor={elementIds.lastNameId}>
                <FormattedMessage id="primary-user.lastName.label" defaultMessage="Last Name" />
              </label>
              <Input type="text" name="lastName" value={lastName} onChange={this._onChange}>
                <input id={elementIds.lastNameId} aria-required aria-invalid={!!missingLastNameLabel} />
              </Input>
              {missingLastNameLabel}
            </Form.Field>
            <Form.Field>
              <label htmlFor={elementIds.emailId}>
                <FormattedMessage id="primary-user.email.label" defaultMessage="Email" />
              </label>
              <Input
                type="email"
                name="email"
                value={email}
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$"
                onChange={this._onChange}
              >
                <input id={elementIds.emailId} aria-required aria-invalid={!!missingEmailLabel || !!invalidEmailLabel} />
              </Input>
              {missingEmailLabel || invalidEmailLabel}
            </Form.Field>
            <Form.Field>
              <label htmlFor={elementIds.verifyEmailId}>
                <FormattedMessage id="primary-user.verifyEmail.label" defaultMessage="Verify Email" />
              </label>
              <Input
                type="email"
                name="verifyEmail"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$"
                value={verifyEmail}
                onChange={this._onChange}
              >
                <input
                  id={elementIds.verifyEmailId}
                  aria-required
                  aria-invalid={!!missingValidateEmailLabel || !!invalidEmailLabel}
                />
              </Input>
              {missingValidateEmailLabel || invalidEmailLabel}
            </Form.Field>
            {this.props.roles && (
            <Form.Field>
              <fieldset
                aria-label={elementIds.role}
                id={elementIds.role}
                styleName="role-fieldset"
                role="radiogroup"
                aria-required
                aria-invalid={!!missingRoleLabel}
              >
                <legend styleName="role-legend">
                  <FormattedMessage id="user.label.role" defaultMessage="Role" />
                </legend>
                {this.props.roles.map((roleItem) => (
                  <div className="ui radio checkbox" key={`role_${roleItem.name}`} styleName="checkbox">
                    <input
                      id={`role_${roleItem.name}`}
                      type="radio"
                      name="role"
                      aria-label={roleItem.name}
                      value={roleItem.name}
                      checked={role === roleItem.name}
                      onChange={this._onChange}
                      styleName="role-checkbox"
                    />
                    <label htmlFor={`role_${roleItem.name}`} styleName="role-label">
                      <FormattedMessage {...roleItem.message} />
                    </label>
                  </div>
                ))}
              </fieldset>
              {missingRoleLabel}
            </Form.Field>
            )}
            <Button type="button" onClick={onCancel} data-cy="create-user-cancel-button">
              <Icon name="remove" />
              <FormattedMessage id="common.button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button primary type="submit" data-cy="create-user-save-button">
              <Icon name="checkmark" />
              <FormattedMessage id="common.button.save" defaultMessage="Save" />
            </Button>
          </Segment>
        </Dimmer.Dimmable>
        {errorDetails}
      </Container>
    );
  }
}

export default CreateUser;
