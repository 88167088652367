import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import createHEUserMutation from './create-he-user-mutation';
import { SimpleLoadingComponent } from '../../utils/apollo';
import CreateSecondaryUserPage from './create-secondary-user-page';

const query = gql`
  query HEAccountDetails($id: ID!) {
    node(id: $id) {
      id
      ...on HEAccount {
        id
        name
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
});
const createHEUser = graphql(createHEUserMutation, {
  name: 'mutateCreateHEUser',
});

export default withRouter(SimpleLoadingComponent(graphqlData, createHEUser)(CreateSecondaryUserPage));
