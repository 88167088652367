import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';

import { SimpleLoadingComponent } from '../../utils/apollo';
import SearchResults from './search-results';

const query = gql`
  query searchByCategory($filter: String!, $exclude: [String]) {
    viewer {
      id
      searchByCategory(
        category: HE_ACCOUNTS,
        filter: $filter,
        exclude: $exclude,
        maxSize: 5
      ) {
        category,
        results {
          ... on Searchable {
            name
            id
            ... on HEAccount {
              scid
              collegeCore {
                address {
                  state
                }
              }
            }
          }
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ searchText, excludeScids }) => ({
    variables: {
      filter: searchText,
      exclude: excludeScids,
    },
  }),
});

export default SimpleLoadingComponent(graphqlData)(SearchResults);
