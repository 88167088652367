import gql from 'graphql-tag';

export default gql`
  mutation updateAMSubscription($input: updateAMSubscriptionInput!) {
    updateAMSubscription(input: $input) {
      amSubscription {
        id
        version
        institution
        type
        majors
        connection
        setupBy
        startDate
        endDate
        audience {
          diversity
        }
        visible
      }
    }
  }
`;
