import get from 'lodash/get';

export default function createService(props) {
  return {
    getUser: () => {
      const jwt = get(props, 'viewer.jwtToken', '');
      const data = get(props, 'viewer.idToken', {});
      return {
        jwt,
        email: get(data, 'unique_name', ''),
        firstName: get(data, 'given_name', ''),
        lastName: get(data, 'family_name', ''),
        institutionType: 'SUPPORT',
        roles: get(data, 'roles', []),
      };
    },
  };
}
