import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import List from '../../components/List';
import messages from './messages';
import styles from './styles.css';

const HeHealthReportHeader = ({ collegeDetails }) => {
  const primaryUser = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      ...collegeDetails.primaryUser,
    }),
    [collegeDetails],
  );
  const address = useMemo(
    () => ({
      city: '',
      state: '',
      street: '',
      postalCode: '',
      ...collegeDetails.address,
    }),
    [collegeDetails],
  );

  return (
    <React.Fragment>
      <Grid columns="equal" stackable>
        <Grid.Row>
          <Grid.Column width={12} as="section" data-cy="health-report-section">
            <Header as="h2">{collegeDetails.name}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4}>
            <Header as="h3">
              <FormattedMessage {...messages.title} />
            </Header>
            <div className="vcard">
              <div className="name" data-cy="health-report-profile-name">
                {collegeDetails.name}
              </div>
              <div className="address">{address.street}</div>
              <div className="address">
                {address.city}, {address.state} {address.postalCode}
              </div>
              <div className="scid">SCID: {collegeDetails.scid}</div>
            </div>
          </Grid.Column>
          {primaryUser.email || '' ? (
            <Grid.Column computer={4}>
              <Header as="h3">
                <FormattedMessage {...messages.userDataTitle} />
              </Header>
              <List as="dl">
                <List.Item
                  data-cy="primary-user-full-name"
                  as="dt"
                  icon="user"
                  content={`${primaryUser.firstName} ${primaryUser.lastName}`}
                />
                <List.Item
                  data-cy="report-primary-email"
                  as="dt"
                  icon="mail"
                  content={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <a href={`mailto:${primaryUser.email}`}>
                      {primaryUser.email}
                    </a>
                  }
                />
                <List.Item
                  data-cy="report-primary-role"
                  as="dt"
                  icon="key"
                  className={styles.listItem}
                  content={primaryUser.role}
                />
              </List>
            </Grid.Column>
          ) : (
            <FormattedMessage {...messages.noUserContent} />
          )}
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

HeHealthReportHeader.propTypes = {
  collegeDetails: PropTypes.object,
};

export default HeHealthReportHeader;
