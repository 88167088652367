import React from 'react';
import { toGlobalId } from 'graphql-relay';
import { graphql, withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { injectIntl, defineMessages } from 'react-intl';

import PageTitle from '../../components/PageTitle';
import Form from '../../components/Form';
import Button from '../../components/Button';
import { SimpleGraphQLComponent } from '../../utils/apollo';
import './styles.css';

const messages = defineMessages({
  search: {
    id: 'school.core.lookup.search',
    defaultMessage: 'Search',
  },
  instructions: {
    id: 'school.core.lookup.instructions',
    defaultMessage: 'Enter a Nid or an NGuid:',
  },
});

const query = gql`
  query schoolCoreQuery($id: ID!) {
    node(id: $id) {
      id
      ...on SchoolCore {
        schoolId
        name
        address {
          city
          state
          postalCodeLoc {
            latitude
            longitude
          }
        }
        url
        _links {
          self {
            href
          }
        }
      }
    }
  }`;

/* eslint-disable class-methods-use-this */
class SchoolCoreLookup extends React.Component {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    current: PropTypes.shape({
      viewer: PropTypes.shape({
        jwtToken: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    queryHighSchool: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }),
  };

  constructor(...args) {
    super(...args);
    this.state = {
      requesting: false,
      nguid: undefined,
      nid: undefined,
      use: undefined,
      querySent: false,
    };
  }

  onNidChange = (e) => {
    const { value } = e.target;
    this.setState({ nid: value, use: 'nid' });
  };

  onNguidChange = (e) => {
    const { value } = e.target;
    this.setState({ nguid: value, use: 'nguid' });
  };

  fetchSchoolCoreInfo = () => {
    const { nguid, nid, use } = this.state;
    const id = toGlobalId('SchoolCore', use === 'nguid' ? nguid : nid);

    this.props.queryHighSchool.refetch({
      id,
    }).then((data) => {
      this.setState({ schoolCore: get(data, 'data.node'), querySent: true });
    }, () => {
      this.setState({ requesting: false, querySent: true });
    });
  };

  renderNidInput = (use, nid) => (
    <div>
      <input
        placeholder="Nid"
        aria-label="The high school's nid"
        data-cy="school-core-lookup-nid-input"
        styleName="school-core-input-field"
        onChange={this.onNidChange}
        value={use === 'nid' ? nid : ''}
      />
    </div>
  )

  renderNguidInput = (use, nguid) => (
    <div>
      <input
        placeholder="NGuid"
        aria-label="The high school's NGuid"
        data-cy="school-core-lookup-nguid-input"
        styleName="school-core-input-field"
        onChange={this.onNguidChange}
        value={use === 'nguid' ? nguid : ''}
      />
    </div>
  )

  renderSearchButton = (intl, requesting) => (
    <Button
      loading={requesting}
      aria-label="Search for High School"
      color="teal"
      type="submit"
      icon
      data-cy="school-core-lookup-button"
    >
      {intl.formatMessage(messages.search)}
    </Button>
  )

  renderSearchResult = (schoolCore) => {
    if (schoolCore) {
      return (
        <div styleName="school-core-lookup-results" data-cy="school-core-lookup-results">
          <pre>{JSON.stringify(schoolCore, null, '\t')}</pre>
        </div>
      );
    }
    return null;
  }

  renderNoResultFound = (schoolCore) => {
    const { querySent } = this.state;

    if (querySent && !schoolCore) {
      return (
        <div styleName="school-core-lookup-no-results" data-cy="school-core-lookup-no-results">
          <p>No Result found</p>
        </div>
      );
    }
    return null;
  }

  render() {
    const { intl } = this.props;
    const { schoolCore, requesting, nid, nguid, use } = this.state;
    return (
      <div styleName="wrapper">
        <PageTitle title="School Core Lookup" />
        <div>
          <div styleName="school-core-instructions">
            {intl.formatMessage(messages.instructions)}
          </div>
          <Form onSubmit={this.fetchSchoolCoreInfo} styleName="school-core-form-inputs">
            {this.renderNidInput(use, nid)}
            {this.renderNguidInput(use, nguid)}
            {this.renderSearchButton(intl, requesting)}
          </Form>
          {this.renderSearchResult(schoolCore)}
          {this.renderNoResultFound(schoolCore)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => pick(state, 'current');

export const SchoolCoreLookupComponent = injectIntl(SchoolCoreLookup);

const graphqlData = graphql(query, {
  name: 'queryHighSchool',
  options: ({
    variables: {
      id: '',
    },
  }),
});

export default SimpleGraphQLComponent(graphqlData, connect(mapStateToProps))(withApollo((SchoolCoreLookupComponent)));
