import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import createService from './embedService';
import { setCounselorCommunityPath } from './utils';
import useScript from './useScript';

const counselorCommunityUrl = setCounselorCommunityPath();

/**
 * React hook that loads the counselor community micro bundle, and returns the entry-point components (aka top-level exports).
 *
 * @return {{ loading: boolean, error: boolean, url: string, CounselorCommunity: Component, UserProfileModal: Component, EditUserProfilePage: Component }}
 */
export default function useCounselorCommunityMicro() {
  const exports = get(global, 'CounselorCommunity.default');
  const url = counselorCommunityUrl;

  const [loading, error] = useScript({
    // only trigger loader if exports are unavailable - useScript will take no action if no url is provided
    src: !exports ? /* istanbul ignore next */ counselorCommunityUrl : null,
    checkForExisting: true,
  });

  const viewer = useSelector((state) => (state.current.viewer));

  // create entry point wrapper that applies embed service and baseUrl
  const wrapEntryPoint = (EntryPoint, service) => (targetComponentProps) => (
    <EntryPoint
      embedService={service}
      baseUrl={process.env.COUNSELOR_COMMUNITY_API}
      {...targetComponentProps}
    />
  );

  const wrappedExports = React.useMemo(() => {
    /* istanbul ignore if */
    if (!exports || !viewer) return {};
    const service = createService({ viewer });
    const CounselorCommunity = wrapEntryPoint(exports.default, service);
    const UserProfileModal = wrapEntryPoint(exports.UserProfileModal, service);
    const EditUserProfilePage = wrapEntryPoint(exports.EditUserProfilePage, service);
    return {
      CounselorCommunity,
      UserProfileModal,
      EditUserProfilePage,
    };
  }, [exports, viewer]);

  if (error) {
    return { error, loading: false, url };
  }

  if (loading) {
    return { loading, url };
  }

  return {
    url,
    ...wrappedExports,
    loading: false,
    error: undefined,
  };
}
