import React from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import Grid from '../Grid';
import './styles.css';

const audienceTypeOptions = [
  { value: 'HE', key: 'he' },
  { value: 'HS Naviance', key: 'hs-naviance' },
  { value: 'HS Non Naviance', key: 'hs' },
];

class AnnouncementDetail extends React.PureComponent {
  static propTypes = {
    announcement: PropTypes.object.isRequired,
  };

  render() {
    const { announcement } = this.props;
    let visibility = '';
    const audienceItems = [];
    const audienceArray = announcement.audience;
    if (audienceArray.length > 0) {
      let typeOption;
      audienceArray.forEach((audienceType) => {
        typeOption = audienceTypeOptions.find((audienceTypeOption) => audienceTypeOption.key === audienceType);
        if (typeOption) {
          audienceItems.push(typeOption.value);
        }
      });
      visibility = audienceItems.join(', ');
    }
    let announcementTitle;
    if (announcement.title) {
      announcementTitle = announcement.title;
    } else {
      announcementTitle = 'Untitled';
    }
    const detailsTruncated = truncate(announcement.content, { length: 140 });
    const createdDate = announcement.createdDate &&
      <FormattedDate value={announcement.createdDate} month="short" day="numeric" timeZone="UTC" />;
    return (
      <Grid styleName="announcementGrid">
        <Grid.Row styleName="announcementRow">
          <Grid.Column width={12}>
            <Link aria-label="Edit Announcement" to={`/admin/announcements/edit/${announcement.id}`}>
              {announcementTitle}
            </Link>
          </Grid.Column>
          <Grid.Column float="right" width={3} styleName="announcementStatus">
            {announcement.status}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row styleName="announcementRow">
          <Grid.Column styleName="announcementDetails" width={13}>
            {detailsTruncated}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row styleName="announcementRow">
          <Grid.Column styleName="announcementOthers" width={15}>
            <span styleName="announcementVisibility">Visibility: </span>
            {visibility}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row styleName="announcementRow">
          <Grid.Column styleName="announcementOthers" width={15}>
            Created on {createdDate} by {announcement.createdFullName}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default AnnouncementDetail;
