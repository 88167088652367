import { fromGlobalId } from 'graphql-relay';
import React from 'react';
import Icon from '../Icon';
import Popup from '../Popup';
import Table from '../Table';
import propTypes from './propTypes';

const TooManyProfiles = ({ user }) => (
  <Popup hoverable trigger={<Icon name="warning sign" color="yellow" />}>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{user.isNaviance ? 'Naviance' : 'Cognito'}</Table.HeaderCell>
          {user.communityProfiles.map((profile, index) => (
            <Table.HeaderCell key={profile.id}>Community {index}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>{user.uuid}</Table.Cell>
          {user.communityProfiles.map((profile) => (
            <Table.Cell key={profile.id}>{fromGlobalId(profile.id).id}</Table.Cell>
          ))}
        </Table.Row>
        <Table.Row>
          <Table.Cell>{user.email}</Table.Cell>
          {user.communityProfiles.map((profile) => (
            <Table.Cell key={profile.id}>{profile.mail}</Table.Cell>
          ))}
        </Table.Row>
        <Table.Row>
          <Table.Cell>{user.firstName}</Table.Cell>
          {user.communityProfiles.map((profile) => (
            <Table.Cell key={profile.id}>{profile.field_first_name}</Table.Cell>
          ))}
        </Table.Row>
        <Table.Row>
          <Table.Cell>{user.lastName}</Table.Cell>
          {user.communityProfiles.map((profile) => (
            <Table.Cell key={profile.id}>{profile.field_last_name}</Table.Cell>
          ))}
        </Table.Row>
        {!user.isNaviance && (
        <Table.Row>
          <Table.Cell>{user.active ? 'Active' : 'Inactive'}</Table.Cell>
          {user.communityProfiles.map((profile) => (
            <Table.Cell key={profile.id}>{profile.isActive ? 'Active' : 'Inactive'}</Table.Cell>
          ))}
        </Table.Row>
        )}
      </Table.Body>
    </Table>
  </Popup>
);

TooManyProfiles.propTypes = propTypes;

export default TooManyProfiles;
