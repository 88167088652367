import React from 'react';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import FocusLock from 'react-focus-lock';
import '@purple/semantic-ui-purple-theme/components/popup.css';

export default class InteractivePopup extends Popup {
  constructor(...args) {
    super(...args);
    this._extend('renderContent', 'wrapContent');
  }

  _extend(fnName, overrideFn) {
    const original = this[fnName];
    this[fnName] = (...args) => {
      const results = original(...args);
      return this[overrideFn](results, ...args);
    };
  }

  // eslint-disable-next-line class-methods-use-this
  wrapContent(children) {
    // need to wrap FocusLock in div because FocusLock creates multiple divs, and the click handler of semantic-ui gets confused
    return (
      <div>
        <FocusLock returnFocus autoFocus>
          {children}
        </FocusLock>
      </div>
    );
  }
}
