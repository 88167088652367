import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';

export function isMatchInstitition(user, communityUser) {
  return [
    communityUser.field_user_institution_uuid,
    communityUser.institutionId,
    communityUser.field_user_institution_hs_na_nces_id,
  ].includes(user.institution);
}

export function generateIcon(id, name, color) {
  return (
    <Link to={`/counselor-community/profile/${id}`} title="Community Profile">
      <Icon name={name} color={color} />
    </Link>
  );
}
