import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import { FormattedMessage, defineMessages } from 'react-intl';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Dimmer from '../../components/Dimmer';
import Message from '../../components/Message';
import updateHSUserMutation from '../../mutations/HSAccount/updateHSUser';
import { SimpleGraphQLComponent } from '../../utils/apollo';

const messages = defineMessages({
  error: {
    id: 'primary-user.switch.error',
    defaultMessage: 'Unable to switch primary user',
  },
  switch: {
    id: 'primary-user.switch.header',
    defaultMessage: 'Switch Primary User',
  },
  switchText: {
    id: 'primary-user.switch.hs-text',
    defaultMessage: 'Would you like to assign user {userName} as primary for {hsAccountName}?',
  },
});

export class HSAccountSwitchPrimaryUserComponent extends React.PureComponent {
  static propTypes = {
    hsAccount: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    mutateUpdateHSUser: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    isError: false,
    requesting: false,
  };

  _handleClose = () => {
    this.setState({ requesting: false });
    this.props.onClose();
  };

  _handleSave = () => {
    const { user, hsAccount } = this.props;

    this.setState({ isError: false, requesting: true });
    this.props.mutateUpdateHSUser({
      variables: {
        input: {
          hsAccount: {
            id: hsAccount.id,
            user: {
              id: user.id,
            },
          },
        },
      },
    }).then(this._handleClose, () => {
      this.setState({ isError: true, requesting: false });
    });
  };

  render() {
    const { user, hsAccount } = this.props;
    const { isError, requesting } = this.state;
    const userName = `${user.firstName} ${user.lastName}`;
    const errorDetails = isError ? (
      <Message role="alert" negative>
        <Message.Header><FormattedMessage {...messages.error} /></Message.Header>
      </Message>
    ) : '';

    return (
      <Dimmer.Dimmable as={Modal} open onClose={this._handleClose}>
        <Dimmer
          tabIndex={-1}
          role="progressbar"
          aria-describedby="Switching Primary User..."
          aria-busy={requesting}
          inverted
          active={requesting}
        >
          <Loader active={requesting} />
        </Dimmer>
        <Header>
          <FormattedMessage {...messages.switch} />
        </Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              {...messages.switchText}
              values={{
                userName,
                hsAccountName: hsAccount.name,
              }}
            />
          </p>
          {errorDetails}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this._handleClose} data-cy="hs-switch-primary-user-no-button">
            <Icon name="remove" />
            <FormattedMessage id="common.button.no" defaultMessage="No" />
          </Button>
          <Button primary onClick={this._handleSave} data-cy="hs-switch-primary-user-yes-button">
            <Icon name="checkmark" />
            <FormattedMessage id="common.button.yes" defaultMessage="Yes" />
          </Button>
        </Modal.Actions>
      </Dimmer.Dimmable>
    );
  }
}

const updateHSUser = graphql(updateHSUserMutation, {
  name: 'mutateUpdateHSUser',
});

export default SimpleGraphQLComponent(updateHSUser)(HSAccountSwitchPrimaryUserComponent);
