import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';
import { toOrdinalDate } from '../../utils/date-time';
import Grid from '../../components/Grid';
import Input from '../../components/Input';
import Form from '../../components/Form';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import Checkbox from '../../components/Checkbox';
import Label from '../../components/Label';
import Button from '../../components/Button';
import Message from '../../components/Message';
import {
  messages,
  subscriptionTypes,
  diversityOptions,
  maxRadiusValue,
  minRadiusValue,
} from './constants';
import ZipField from './zip-field';
import CompetitorsField from './competitors-field';
import DateRange from './date-range';
import { createAmSubscriptionInput, updateAMSubscriptionInput, getDiversity, formatToStandardUSADate } from './utils';
import { StatesComponent, CountiesComponent, SectorsComponent } from './components';
import countyOptions from '../AdvancedPeopleSearchBox/county-options';
import { withEnabledFeatures } from '../../utils/features';
import { REMOVE_DIVERSITY_FILTERS } from '../../featureFlags';

import './styles.css';

export const elementIds = {
  states: 'field13',
  diversity: 'field14',
  competitors: 'field15',
  majors: 'field16',
  connection: 'field17',
  startDate: 'field18',
  endDate: 'field19',
  counties: 'field20',
  zips: 'field21',
  radius: 'field22',
  sectors: 'field23',
  actionBack: 'action-back',
  actionSubmit: 'action-submit',
};

function checkValidRadius(value) {
  return value === null ||
    (/^\d+$/.test(value) && parseInt(value, 10) <= maxRadiusValue && parseInt(value, 10) >= minRadiusValue);
}

function validateCountyFields(data) {
  return Object.keys(data).reduce((acc, name) => {
    const value = data[name];
    acc[name] = value && value.length === 0;
    return acc;
  }, {});
}

function validateFields(formData, type, enabledFeatures) {
  return {
    startDate: !formData.startDate,
    endDate: !formData.endDate || formData.endDate < formData.startDate,
    radius: type === subscriptionTypes.ZIP && (!checkValidRadius(formData.radius)),
  };
}

function validateAddFields(formData, type, enabledFeatures) {
  const error = validateFields(formData, type, enabledFeatures);
  const errors = {
    ...error,
    states: type !== subscriptionTypes.ZIP && type !== subscriptionTypes.SECTOR && formData.states.length === 0,
    zip: type === subscriptionTypes.ZIP && formData.zips.length === 0,
    counties: type === subscriptionTypes.COUNTY && validateCountyFields(formData.counties),
    sectors: type === subscriptionTypes.SECTOR && formData.sectors.length === 0,
  };

  return errors;
}

function validateEditFields(formData, type, enabledFeatures) {
  const error = validateFields(formData, type, enabledFeatures);
  return {
    ...error,
    zip: type === subscriptionTypes.ZIP && !(/^\d{5}$/.test(formData.zipCode)),
    county: type === subscriptionTypes.COUNTY &&
     (!formData.county || !countyOptions[formData.state].includes(formData.county)),
  };
}

class SubscriptionForm extends React.PureComponent {
  static propTypes = {
    onBack: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    subscriptionType: PropTypes.string,
    subscription: PropTypes.object,
    subscriptionList: PropTypes.arrayOf(PropTypes.object),
    enabledFeatures: PropTypes.array,
    advAwarenessEndDate: PropTypes.number,
    connectionEndDate: PropTypes.number,
    currentScid: PropTypes.string,
  };

  static defaultProps = {
    subscriptionType: subscriptionTypes.STATE,
    subscriptionList: [],
    enabledFeatures: [],
  };

  constructor(props) {
    super(props);

    const initialState = this.getInitialState(props);
    if (props.subscription) {
      this.state = {
        ...initialState,
        ...props.subscription,
      };
    } else {
      this.state = initialState;
    }
  }

  getInitialState = (props) => ({
    errors: {},
    states: [],
    counties: {},
    sectors: [],
    diversity: diversityOptions[0].value,
    competitors: [],
    majors: false,
    connection: false,
    startDate: null,
    endDate: null,
    zips: [],
    radius: null,
    generalErrors: null,
    validatedEndDate: null,
    removeDiversityFilters: props.enabledFeatures.includes(REMOVE_DIVERSITY_FILTERS),
  });

  onChange = (e, { type, value, name } = {}) => {
    const { subscriptionType } = this.props;
    type = e.target.type;
    value = value || e.target.value;
    name = name || e.target.name;
    if (type === 'checkbox') {
      value = e.target.checked;
    }

    if (subscriptionType === subscriptionTypes.COUNTY) {
      if (name.startsWith('counties')) {
        const names = name.split('.');

        this.setState((prevState) => ({
          counties: {
            ...prevState.counties,
            [names[1]]: value,
          },
        }));
      } else if (name === 'states') {
        const { counties } = this.state;

        const cleanedCounties = value.reduce((acc, state) => {
          acc[state] = counties[state] || [];
          return acc;
        }, {});

        this.setState({
          states: value,
          counties: cleanedCounties,
        });
      } else {
        this.setState({ [name]: value });
      }
    } else if (name === 'radius') {
      if (checkValidRadius(value)) {
        this.setState({ [name]: value });
      }
      if (value === '') {
        this.setState({ [name]: null });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  onChangeZip = (zips) => {
    this.setState({ zips });
  };

  onChangeCompetitors = (competitors) => {
    this.setState({ competitors });
  };

  onChangeDates = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  onSuccess = (updateAdvAwarenessModuleExpiration, updateConnectionModuleExpiration) => {
    const { subscriptionType, subscription } = this.props;
    const amSubscription = !subscription ?
      createAmSubscriptionInput(subscriptionType, this.state) :
      updateAMSubscriptionInput(subscriptionType, this.state, subscription);

    this.props.onFinish(amSubscription, updateAdvAwarenessModuleExpiration, updateConnectionModuleExpiration);
  };

  getAddOrEditItem = (addItem, editItem) => (!this.props.subscription ? addItem : editItem);

  _validateForm = (e) => {
    const { subscriptionType, subscription, enabledFeatures } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate,max-len
    const errors = subscription ? validateEditFields(this.state, subscriptionType, enabledFeatures) : validateAddFields(this.state, subscriptionType, enabledFeatures);
    const { advAwarenessEndDate, connectionEndDate } = this.props;
    // ignore validation errors for start and end date during edits if values were not altered
    if (subscription) {
      if (subscription.startDate === this.state.startDate) {
        delete errors.startDate;
      }
      if (subscription.endDate === this.state.endDate) {
        delete errors.endDate;
      }
    }

    const { endDate: formEndDate, validatedEndDate, competitors, majors, connection } = this.state;
    if ((majors || competitors.length) && formEndDate > advAwarenessEndDate) {
      errors.pastAdvAwarenessModuleExpiration = true;
    }

    if (connection && formEndDate > connectionEndDate) {
      errors.pastConnectionModuleExpiration = true;
    }

    const specialCaseErrors = ['counties', 'pastAdvAwarenessModuleExpiration', 'pastConnectionModuleExpiration'];
    const keys = Object.keys(errors).filter((key) => !specialCaseErrors.includes(key));
    if (keys.some((key) => errors[key])) {
      e.preventDefault();
    } else if (subscriptionType === subscriptionTypes.COUNTY &&
        errors.counties && Object.keys(errors.counties).some((key) => errors.counties[key])) {
      e.preventDefault();
    } else if ((errors.pastAdvAwarenessModuleExpiration || errors.pastConnectionModuleExpiration) &&
        validatedEndDate !== formEndDate) {
      e.preventDefault();
    } else {
      const generalErrors = this._validateDuplicates();
      const navianceEngagementError = this._validateNavianceEngagement();
      if (navianceEngagementError) {
        generalErrors.push(navianceEngagementError);
      }
      if (generalErrors.length) {
        this.setState({ generalErrors });
        e.preventDefault();
      } else {
        this.onSuccess(!!errors.pastAdvAwarenessModuleExpiration, !!errors.pastConnectionModuleExpiration);
      }
    }

    this.setState({ errors, validatedEndDate: formEndDate });
  };

  // eslint-disable-next-line class-methods-use-this
  _getDuplicates(type, diversity, startDate, b) {
    const part = type === b.type && diversity === b.diversity;
    return part && toOrdinalDate(startDate) === toOrdinalDate(b.startDate);
  }

  _validateDuplicates = () => {
    const { subscriptionType, subscription } = this.props;
    let { subscriptionList } = this.props;
    const { diversity, zipCode, state, county, sector, startDate } = this.state;
    let { zips, states, counties, sectors } = this.state;
    const diversityText = getDiversity(diversity);

    if (subscription) {
      subscriptionList = subscriptionList.filter((s) => s.id !== subscription.id);
      zips = [zipCode];
      states = [state];
      counties = { [state]: [county] };
      sectors = [sector];
    }
    const messageKeyPrefix = 'PostGA';
    return subscriptionList.reduce((duplicates, sub) => {
      const errors = [];
      /* istanbul ignore else */
      if (this._getDuplicates(subscriptionType, diversity, startDate, sub)) {
        switch (subscriptionType) {
          case subscriptionTypes.ZIP:
            errors.push(...this._getDuplicatesByZip(zips, sub, diversityText, messageKeyPrefix));
            break;
          case subscriptionTypes.STATE:
            errors.push(...this._getDuplicatesByState(states, sub, diversityText, messageKeyPrefix));
            break;
          case subscriptionTypes.COUNTY:
            errors.push(...this._getDuplicatesByCounty(counties, sub, diversityText, messageKeyPrefix));
            break;
          case subscriptionTypes.SECTOR:
            errors.push(...this._getDuplicatesBySector(sectors, sub, diversityText, messageKeyPrefix));
            break;
        }
      }

      return duplicates.concat(...errors);
    }, []);
  }

  _validateNavianceEngagement = () => {
    const { competitors, majors, connection } = this.state;

    if (competitors.length === 0 && !majors && !connection) {
      const key = 'missingNavianceEngagement';
      return { key, messageKey: key };
    }

    return null;
  };

  _getDuplicatesByZip = (zips, sub, diversityText, messageKeyPrefix) => (
    zips.reduce((duplicates, zipItem) => {
      if (zipItem === sub.zipCode) {
        return duplicates.concat({
          key: zipItem + diversityText,
          messageKey: `duplicatedZip${messageKeyPrefix}`,
          zip: zipItem,
          diversityText,
          startDate: formatToStandardUSADate(sub.startDate),
        });
      }
      return duplicates;
    }, []));

  _getDuplicatesByState = (states, sub, diversityText, messageKeyPrefix) => (
    states.reduce((duplicates, stateItem) => {
      if (stateItem === sub.state) {
        return duplicates.concat({
          key: stateItem + diversityText,
          messageKey: `duplicatedState${messageKeyPrefix}`,
          state: stateItem,
          diversityText,
          startDate: formatToStandardUSADate(sub.startDate),
        });
      }
      return duplicates;
    }, []));

  _getDuplicatesBySector = (sectors, sub, diversityText, messageKeyPrefix) => (
    sectors.reduce((duplicates, sectorItem) => {
      if (sectorItem === sub.sector) {
        return duplicates.concat({
          key: sectorItem + diversityText,
          messageKey: `duplicatedSector${messageKeyPrefix}`,
          sector: sectorItem,
          diversityText,
          startDate: formatToStandardUSADate(sub.startDate),
        });
      }
      return duplicates;
    }, []));

  _getDuplicatesByCounty = (counties, sub, diversityText, messageKeyPrefix) => (
    get(counties, sub.state, []).reduce((duplicates, countyItem) => {
      if (countyItem === sub.county) {
        return duplicates.concat({
          key: countyItem + sub.state + diversityText,
          messageKey: `duplicatedCounty${messageKeyPrefix}`,
          state: sub.state,
          county: countyItem,
          diversityText,
          startDate: formatToStandardUSADate(sub.startDate),
        });
      }
      return duplicates;
    }, []));

  renderZipFields = () => {
    const { zips, radius, errors, zipCode } = this.state;
    const radiusValue = radius === null ? '' : radius;

    const radiusLabel = this.getAddOrEditItem(messages.radiusZips, messages.radius);
    const zipLabel = this.getAddOrEditItem(messages.chooseZips, messages.enterZip);
    const zipField = this.getAddOrEditItem(
      <ZipField id={elementIds.zips} value={zips} onChange={this.onChangeZip} />,
      <Input
        name="zipCode"
        onChange={this.onChange}
        value={zipCode}
        id={elementIds.zips}
      />,
    );

    return (
      <React.Fragment>
        <Form.Field>
          <label styleName="form-label-big" htmlFor={elementIds.zips}>
            <FormattedMessage {...zipLabel} />
          </label>
          {zipField}
          {errors.zip && (
          <Label role="tooltip" basic color="red" pointing>
            <FormattedMessage {...messages.validZip} />
          </Label>
          )}
        </Form.Field>
        <Form.Field inline>
          <label styleName="form-label" htmlFor={elementIds.radius}>
            <FormattedMessage {...radiusLabel} />
          </label>
          <Input
            name="radius"
            onChange={this.onChange}
            value={radiusValue}
            id={elementIds.radius}
            placeholder="1-100"
          />
          <Label pointing="left"><FormattedMessage {...messages.selectRadius} /></Label>
          {errors.radius && (
          <Label role="tooltip" basic color="red" pointing="left">
            <FormattedMessage {...messages.enterValue} />
          </Label>
          )}
        </Form.Field>
      </React.Fragment>
    );
  }

  renderCountiesField = (state) => {
    const { errors, counties } = this.state;
    const name = `counties.${state}`;
    const nameId = `${elementIds.counties}.${state}`;
    const countiesErrors = errors && errors.counties;
    return (
      <CountiesComponent
        id={nameId}
        key={state}
        state={state}
        value={counties[state]}
        label={<FormattedMessage {...messages.chooseCounties} values={{ state }} />}
        multiple
        name={name}
        error={countiesErrors && countiesErrors[state] && <FormattedMessage {...messages.selectCounties} />}
        onChange={this.onChange}
      />
    );
  }

  renderCountiesFields = () => {
    const { errors, states, county, state } = this.state;

    return this.getAddOrEditItem(
      states.map(this.renderCountiesField),
      <CountiesComponent
        id={elementIds.counties}
        state={state}
        value={county}
        label={<FormattedMessage {...messages.chooseCounty} values={{ state }} />}
        name="county"
        error={errors.county && <FormattedMessage {...messages.selectCounty} />}
        onChange={this.onChange}
      />,
    );
  }

  renderStatesField = () => {
    const { errors, states, state } = this.state;

    return this.getAddOrEditItem(
      <StatesComponent
        id={elementIds.states}
        value={states}
        name="states"
        multiple
        onChange={this.onChange}
        label={<FormattedMessage {...messages.chooseStates} />}
        error={errors.states}
      />,
      <StatesComponent
        id={elementIds.states}
        name="state"
        value={state}
        onChange={this.onChange}
        label={<FormattedMessage {...messages.chooseState} />}
      />,
    );
  }

  renderSectorField = () => {
    const { errors, sectors, sector } = this.state;

    return this.getAddOrEditItem(
      <SectorsComponent
        id={elementIds.sectors}
        value={sectors}
        name="sectors"
        multiple
        onChange={this.onChange}
        label={<FormattedMessage {...messages.chooseSectors} />}
        error={errors.sectors}
      />,
      <SectorsComponent
        id={elementIds.sectors}
        name="sector"
        value={sector}
        onChange={this.onChange}
        label={<FormattedMessage {...messages.chooseSector} />}
      />,
    );
  }

  render() {
    const { subscriptionType, onBack, currentScid } = this.props;
    const {
      diversity,
      majors,
      connection,
      startDate,
      endDate,
      competitors,
      errors,
      generalErrors,
      removeDiversityFilters,
    } = this.state;

    return (
      <React.Fragment>
        {generalErrors && (
          <Message error>
            <Message.Content>
              {generalErrors.map((item) => <FormattedMessage key={item.key} tagName="div" {...messages[item.messageKey]} values={item} />)}
            </Message.Content>
          </Message>
        )}
        <Grid
          as={Form}
          autoComplete="off"
          stackable
          onSubmit={this._validateForm}
        >
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              {subscriptionType !== subscriptionTypes.ZIP && (
                <React.Fragment>
                  { subscriptionType === subscriptionTypes.SECTOR && this.renderSectorField() }
                  { subscriptionType !== subscriptionTypes.SECTOR && this.renderStatesField() }
                  { subscriptionType === subscriptionTypes.COUNTY && this.renderCountiesFields() }
                </React.Fragment>
              )}
              {subscriptionType === subscriptionTypes.ZIP && this.renderZipFields()}

              {!removeDiversityFilters && (
                <Form.Group>
                  <Form.Field>
                    <label styleName="form-label-big" htmlFor={elementIds.diversity}>
                      <FormattedMessage {...messages.chooseDiversityFilter} />
                    </label>
                    <Dropdown
                      name="diversity"
                      id={elementIds.diversity}
                      selection
                      options={diversityOptions}
                      onChange={this.onChange}
                      value={diversity}
                      icon={<Icon name="chevron down" styleName="rounded-dropdown-icon" />}
                      className="custom-rounded-dropdown"
                    />
                  </Form.Field>
                </Form.Group>
              )}
              <Form.Field>
                <label styleName="form-label-big" htmlFor={elementIds.competitors}>
                  <FormattedMessage {...messages.chooseEngagement} />
                </label>
                <label styleName="form-label" htmlFor={elementIds.competitors}>
                  <FormattedMessage {...messages.selectCompetitors} />
                </label>
                <CompetitorsField
                  id={elementIds.competitors}
                  onChange={this.onChangeCompetitors}
                  competitors={competitors}
                  currentScid={currentScid}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  id={elementIds.majors}
                  name="majors"
                  onChange={this.onChange}
                  checked={majors}
                  label={{
                    htmlFor: elementIds.majors,
                    children: 'Majors',
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  id={elementIds.connection}
                  name="connection"
                  onChange={this.onChange}
                  checked={connection}
                  label={{
                    htmlFor: elementIds.connection,
                    children: 'Connection',
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label styleName="form-label-big" htmlFor={elementIds.startDate}>
                  Start and End Dates
                </label>
              </Form.Field>
              <DateRange
                ids={{ start: elementIds.startDate, end: elementIds.endDate }}
                startDate={startDate}
                endDate={endDate}
                errors={errors}
                onChange={this.onChangeDates}
              />
              {(errors.pastAdvAwarenessModuleExpiration || errors.pastConnectionModuleExpiration) && (
                <Message className="yellow">
                  <FormattedMessage {...messages.pastModuleExpiration} />
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button id={elementIds.actionBack} onClick={onBack}>
                Back
              </Button>
              <Button id={elementIds.actionSubmit} type="submit" primary>
                Finish
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export const SubscriptionFormComponent = injectIntl(SubscriptionForm);
export default withEnabledFeatures(SubscriptionFormComponent);
