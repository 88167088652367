import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'react-recompose';
import { withEnabledFeatures } from '../../utils/features';

const FeatureEnabledComponent = ({ enabledFeatures, allOf, children }) =>
  [].concat(allOf).every((feature) => enabledFeatures.includes(feature)) && children;
const FeatureEnabled = compose(
  setDisplayName('FeatureEnabled'),
  withEnabledFeatures,
)(FeatureEnabledComponent);
FeatureEnabled.propTypes = {
  allOf: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
export default FeatureEnabled;
