import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Table from '../../components/Table';
import Header from '../../components/Header';
import ModuleSubscriptionTableRow from './module-subscription-table-row';

const ModuleSubscriptionsTable = ({
  moduleNodes,
  savedModuleNodes,
  onChange,
  canEdit,
  heAccountId,
  scholarshipsMatchStartDate,
}) => (
  <div>
    <Table celled striped>
      <Header as="caption">
        <FormattedMessage
          id="modules-table.caption.modules"
          description="Modules Table"
          defaultMessage="Modules"
        />
      </Header>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage
              id="modules-table.header.module"
              description="Module Names"
              defaultMessage="Module"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="modules-table.header.status"
              description="Module Status"
              defaultMessage="Status"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="modules-table.header.quote-charge-id"
              description="Module Quote Charge Id"
              defaultMessage="Quote Charge Id"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="modules-table.header.start-date"
              description="Module start date"
              defaultMessage="Start Date"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="modules-table.header.end-date"
              description="Module End Date"
              defaultMessage="End Date"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="modules-table.header.last-modified-by"
              description="Module Last Modified By"
              defaultMessage="Last Modified By"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="modules-table.header.last-modified-date"
              description="Module Last Modified Date"
              defaultMessage="Last Modified Date"
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {moduleNodes.map((moduleNode, index) => (
          <ModuleSubscriptionTableRow
            key={moduleNode.get('name')}
            canEdit={canEdit}
            moduleNode={moduleNode}
            savedModuleNode={savedModuleNodes.get(index)}
            onChange={onChange}
            heAccountId={heAccountId}
            scholarshipsMatchStartDate={scholarshipsMatchStartDate}
          />
        ))}
      </Table.Body>
    </Table>
  </div>
);

ModuleSubscriptionsTable.propTypes = {
  moduleNodes: PropTypes.object,
  savedModuleNodes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  linkBack: PropTypes.object,
  heAccountId: PropTypes.string,
  scholarshipsMatchStartDate: PropTypes.number,
};

export default ModuleSubscriptionsTable;
