import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { get } from '@purple/match-graphql-schema/lib/utils';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import { SimpleLoadingComponent } from '../../utils/apollo';
import amCaminoFragment from '../../queries/amCaminoFragment';
import saveAMCaminoMessageMutationGql from '../../mutations/HEAccount/saveAMCaminoMessage';
import ContentHeader from './ContentHeader';
import TitleWithActions from '../../components/TitleWithActions';
import TextAreaWithCounter from '../../components/TextAreaWithCounter';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Message from '../../components/Message';

import './styles.css';

export const query = gql`
  query HEAccountSubscriptionsCamino($id: ID!) {
    node(id: $id) {
      id
      ...on HEAccount {
        scid
        name
        moduleSubscriptions {
          name
          status
          startDate
          endDate
          quoteChargeId
          manuallyInactivated
        }
        primaryUser {
          id
          firstName
          lastName
          email
          role
          active
        }
        collegeCore {
          address {
            street
            street2
            city
            state
            postalCode
          }
        }
        ...AMCamino
      }
    }
  }
  ${amCaminoFragment}
`;

export function HESubscriptionsCaminoComponent({ heAccount, amCamino, version, saveAMCaminoMessage, saveAMCaminoMessageResult }) {
  const { id } = heAccount;
  const { defaultMessage } = amCamino;
  const {
    loading: saving,
    error: savingError,
    updateAMMessageCamino,
  } = saveAMCaminoMessageResult;
  const backLink = `/he-account/${id}/`;

  const [text, setText] = useState(defaultMessage || '');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const onChange = useCallback((e, { value }) => {
    setText(value);
  }, [setText]);

  const saveChanges = useCallback(() => {
    setIsSubmitted(true);

    saveAMCaminoMessage({
      variables: {
        input: {
          heAccountId: id,
          version,
          camino: {
            defaultMessage: text,
          },
        },
      },
    });
  }, [text, id, version, saveAMCaminoMessage]);

  const hasChanges = (defaultMessage || '') !== text;

  useEffect(() => {
    let timer;

    if (isSubmitted && updateAMMessageCamino) {
      /* istanbul ignore else */
      if (!savingError) {
        setShowSuccess(true);
      }

      setIsSubmitted(false);

      /* istanbul ignore next */
      const hideSuccess = () => setShowSuccess(false);
      timer = setTimeout(hideSuccess, 3000);
    }

    return () => clearTimeout(timer);
  }, [updateAMMessageCamino, savingError]);

  return (
    <React.Fragment>
      {savingError && <Message error header="Oops, something went wrong" content={savingError.message} />}
      {showSuccess && (
        <Message
          success
          header="Success"
          content="Message saved."
        />
      )}
      <div>
        <ContentHeader heAccount={heAccount} />
        <TitleWithActions tag="h2" title="Camino Advanced Awareness Message">
          <TitleWithActions.Action to={backLink}>Back to Overview</TitleWithActions.Action>
        </TitleWithActions>
        <div styleName="camino-page-content">
          <Form>
            <TextAreaWithCounter
              styleName="camino-input"
              name="camino-message"
              value={text}
              aria-label="Edit Camino Message"
              onChange={onChange}
            />
            <div styleName="camino-buttons">
              <Button loading={saving} data-cy="he-camino-save-button" primary floated="right" disabled={saving || !hasChanges} onClick={saveChanges}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

const caminoShape = PropTypes.shape({
  camino: PropTypes.shape({
    defaultMessage: PropTypes.string,
  }),
});

HESubscriptionsCaminoComponent.propTypes = {
  amCamino: PropTypes.object.isRequired,
  heAccount: PropTypes.shape({
    id: PropTypes.string,
    scid: PropTypes.string,
    name: PropTypes.string,
    primaryUser: PropTypes.object,
    collegeCore: PropTypes.object,
    activeMatchMessages: caminoShape,
  }),
  saveAMCaminoMessage: PropTypes.func.isRequired,
  saveAMCaminoMessageResult: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    updateAMMessageCamino: PropTypes.shape({
      messages: caminoShape,
    }),
  }).isRequired,
  version: PropTypes.number,
};

const graphqlData = graphql(query, {
  options: ({ match: { params: { id } } }) => ({
    variables: {
      id,
    },
  }),
  props: ({ data, ownProps }) => ({
    ...ownProps,
    heAccount: data.node,
    version: get(data.node, 'activeMatchMessages.version', null),
    amCamino: get(data.node, 'activeMatchMessages.camino', { defaultMessage: '' }),
    data: {
      // required for SimpleLoadingComponent compatibility
      loading: data.loading,
      error: data.error,
    },
  }),
});

const saveAMCaminoMessageMutation = graphql(saveAMCaminoMessageMutationGql, {
  name: 'saveAMCaminoMessage',
  options: {
    // https://github.com/apollographql/react-apollo/issues/3250#issuecomment-530970528
    ignoreResults: false,
    // extend mutation loading state to include refetch
    awaitRefetchQueries: true,
    refetchQueries: ['HEAccountSubscriptionsCamino'],
  },
});

export default compose(
  withRouter,
  saveAMCaminoMessageMutation,
  SimpleLoadingComponent(graphqlData),
)(HESubscriptionsCaminoComponent);
