import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { withRouter } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import queryString from 'query-string';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import SearchField from '../../components/SearchField';
import Segment from '../../components/Segment';
import Form from '../../components/Form';
import '../AdvancedSearch/styles.css';
import AdvancedHigherEdSearchFields from '../AdvancedHigherEdSearchFields';
import AdvancedHighSchoolSearchFields from '../AdvancedHighSchoolSearchFields';

const messages = defineMessages({
  keyword: {
    id: 'search-results.keyword',
    defaultMessage: 'Keyword',
  },
  updateSearch: {
    id: 'search-results.updateSearch',
    defaultMessage: 'Update Search',
  },
  institutionType: {
    id: 'search-results.institutionType',
    defaultMessage: 'Institution Type',
  },
  general: {
    id: 'search-results.general',
    defaultMessage: 'GENERAL',
  },
});
const institutionTypeOptions = [
  { text: 'All', value: '' },
  { text: 'High School', value: 'HS' },
  { text: 'Higher Education', value: 'HE' },
];

const sharedFields = new Set(['name', 'keyword', 'institutionType', 'city', 'state', 'postalCode']);

class AdvancedInstitutionSearchBox extends React.PureComponent {
  static propTypes = {
    keyword: PropTypes.string,
    advanced: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = pick(this.props, 'keyword', 'advanced');
  }

  onChange = (e, { type, value, name } = {}) => {
    const { advanced } = this.state;
    value = value || e.target.value;
    name = name || e.target.name;
    type = type || e.target.type;
    const nextAdvanced = { ...advanced, [name]: value };
    if (name === 'keyword') {
      this.setState({ keyword: value });
    } else if (name === 'institutionType') {
      this.setState({ advanced: pickBy(nextAdvanced, (val, key) => sharedFields.has(key)) });
    } else {
      this.setState({ advanced: pickBy(nextAdvanced) });
    }
  };

  search = (e) => {
    const { history } = this.props;
    const { advanced, keyword } = this.state;
    history.push({
      pathname: '/search-results/institutions',
      search: queryString.stringify({
        keyword,
        advanced: JSON.stringify(advanced),
      }),
    });
  };

  render() {
    const { advanced, keyword } = this.state;
    return (
      <Form onSubmit={this.search} styleName="searchBoxContainer">
        <Segment styleName="searchBoxSegment">
          <div styleName="keywordContainer">
            <SearchField
              message={messages.keyword}
              name="keyword"
              onChange={this.onChange}
              value={keyword}
              inputID="keyword"
            />
          </div>
          <div styleName="categoryTitle"><FormattedMessage {...messages.general} /></div>
          <div styleName="institutionType">
            <div styleName="dropdownContainer">
              <div styleName="institutionLabel"><FormattedMessage {...messages.institutionType} /></div>
              <div styleName="dropdownInput"><Dropdown
                id="institution-type"
                placeholder="All"
                name="institutionType"
                value={advanced.institutionType}
                onChange={this.onChange}
                fluid
                selection
                options={institutionTypeOptions}
              />
              </div>
            </div>
          </div>
          {advanced.institutionType === 'HE' && <AdvancedHigherEdSearchFields advanced={advanced} onChange={this.onChange} />}
          {advanced.institutionType === 'HS' && <AdvancedHighSchoolSearchFields advanced={advanced} onChange={this.onChange} />}
        </Segment>
        <Button primary type="submit" data-cy="institutions-update-search-button">
          <FormattedMessage {...messages.updateSearch} />
        </Button>
      </Form>
    );
  }
}

export default withRouter(AdvancedInstitutionSearchBox);
