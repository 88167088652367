export const __ = {
  reload: () => {
    /* istanbul ignore next */
    window.location.reload();
  },
};

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ?
      reducer(state, action.payload) :
      state;
  };
}

// allows to have reducers work on the same potion of a state object.
// usefull when splitting reducers under different components
export function reduceReducers(...reducers) {
  return (previous, current) =>
    reducers.reduce(
      (p, r) => r(p, current),
      previous,
    );
}

export function reload() {
  __.reload();
}

/* istanbul ignore next */
export function postMessage(contentWindow, postText, targetOrigin) {
  contentWindow.postMessage(postText, targetOrigin);
}

/**
 * Returns true if browser supports localStorage, false otherwise.
 * @ignore
 */
/* istanbul ignore next */
const _supportsLocalStorage = () => {
  try {
    const supportsLocalStorage = 'localStorage' in window && window.localStorage;
    if (supportsLocalStorage) {
      window.localStorage.setItem('storageTest', '');
      window.localStorage.removeItem('storageTest');
    }
    return supportsLocalStorage;
  } catch (e) {
    return false;
  }
};

/* istanbul ignore next */
const _supportsSessionStorage = () => {
  try {
    const supportsSessionStorage = 'sessionStorage' in window && window.sessionStorage;
    if (supportsSessionStorage) {
      window.sessionStorage.setItem('storageTest', '');
      window.sessionStorage.removeItem('storageTest');
    }
    return supportsSessionStorage;
  } catch (e) {
    return false;
  }
};

export const _saveItem = /* istanbul ignore next */(cacheLocation, key, obj) => {
  if (cacheLocation === 'localStorage') {
    if (!_supportsLocalStorage()) {
      console.log('Local storage is not supported'); // eslint-disable-line no-console
      return false;
    }
    localStorage.setItem(key, obj);
    return true;
  }

  // Default as session storage
  if (!_supportsSessionStorage()) {
    console.log('Session storage is not supported'); // eslint-disable-line no-console
    return false;
  }

  sessionStorage.setItem(key, obj);
  return true;
};

/**
 * Searches the value for the given key in the cache
 * @ignore
 */
export const _getItem = /* istanbul ignore next */(cacheLocation, key) => {
  if (cacheLocation === 'localStorage') {
    if (!_supportsLocalStorage()) {
      console.log('Local storage is not supported'); // eslint-disable-line no-console
      return null;
    }
    return localStorage.getItem(key);
  }

  // Default as session storage
  if (!_supportsSessionStorage()) {
    console.log('Session storage is not supported'); // eslint-disable-line no-console
    return null;
  }
  return sessionStorage.getItem(key);
};
