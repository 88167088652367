import gql from 'graphql-tag';

const amCaminoFragment = gql`
  fragment AMCamino on HEAccount {
    activeMatchMessages {
      id
      version
      camino {
        defaultMessage
      }
    }
  }
`;

export default amCaminoFragment;
