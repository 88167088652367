import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Button from '../Button';
import Icon from '../Icon';

const messages = defineMessages({
  toggleSearch: {
    id: 'app.sidebarToggle.buttonLabel',
    defaultMessage: 'Toggle navigation',
  },
});
const SidebarToggleButton = ({ intl, className, onClick }) => (
  <Button
    basic
    icon
    className={className}
    aria-label={intl.formatMessage(messages.toggleSearch)}
    onClick={onClick}
  >
    <Icon name="content" aria-hidden="true" size="large" />
  </Button>
);
SidebarToggleButton.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectIntl(SidebarToggleButton);
