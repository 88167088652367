import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'react-recompose';
import { withEnabledFeatures } from '../../utils/features';

const FeatureDisabledComponent = ({ enabledFeatures, anyOf, children }) =>
  [].concat(anyOf).some((feature) => !enabledFeatures.includes(feature)) && children;
const FeatureDisabled = compose(
  setDisplayName('FeatureDisabled'),
  withEnabledFeatures,
)(FeatureDisabledComponent);
FeatureDisabled.propTypes = {
  anyOf: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
export default FeatureDisabled;
