import { NOTIFICATION_COUNT_UPDATED } from './constants';

const updateNotificationCount = (count) =>
  (dispatch) =>
    dispatch({
      type: NOTIFICATION_COUNT_UPDATED,
      payload: { count },
    });

export { updateNotificationCount };
export default updateNotificationCount;
