import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import updateHEUserMutation from '../../mutations/HEAccount/updateHEUser';
import { SimpleLoadingComponent } from '../../utils/apollo';
import UpdatePrimaryUserPage from './update-primary-user-page';
import heUserDetails from '../../queries/heUserDetails';

const query = gql`
  query HEAccountDetails($id: ID!) {
    node(id: $id) {
      id
      ...on HEAccount {
        id
        name
        primaryUser {
          ...HEUserDetails
        }
      }
    }
  }
  ${heUserDetails}
`;

const graphqlData = graphql(query, {
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        id,
      },
    };
  },
});
const updateHEUser = graphql(updateHEUserMutation, {
  name: 'mutateUpdateHEUser',
});

export default withRouter(SimpleLoadingComponent(graphqlData, updateHEUser)(UpdatePrimaryUserPage));
