/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Search from 'semantic-ui-react/dist/commonjs/modules/Search';
import '@purple/semantic-ui-purple-theme/components/search.css';
import Input from '../Input';

class PurpleSearch extends Search {
  renderResultsMenu = () => {
    const { open } = this.state;
    const { id } = this.props;
    const resultsClasses = open ? 'visible' : '';
    const menuContent = this.renderMenuContent();

    if (!menuContent) return null;

    return (
      <Search.Results role="listbox" id={`${id}-results`} aria-expanded={open} className={resultsClasses}>
        {menuContent}
      </Search.Results>
    );
  };

  renderSearchInput = () => {
    const { icon, placeholder, input, id } = this.props;
    const { value, open } = this.state;

    return (
      <Input
        value={value}
        placeholder={placeholder}
        onBlur={this.handleBlur}
        onChange={this.handleSearchChange}
        onFocus={this.handleFocus}
        onClick={this.handleInputClick}
        input={{
          ...input,
          'role': 'combobox',
          'className': 'prompt',
          'aria-autocomplete': 'inline',
          'aria-activedescendant': `${id}-item-active`,
          'aria-owns': `${id}-results`,
          'aria-expanded': !!open,
          'autoComplete': 'off',
        }}
        icon={icon}
      />
    );
  };
}
if (PurpleSearch.propTypes) {
  PurpleSearch.propTypes.input = PropTypes.object;
}

export default PurpleSearch;
